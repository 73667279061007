import {
  Box,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEthers } from "@usedapp/core";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { NftResource } from "../../../../components/NftResource";
import { colors } from "../../../../core/constants/colors";
import { I18N_NAMESPACE } from "../../../../core/constants/i18n";
import { SELLER_STATUS } from "../../../../core/constants/status";
import { INftOwner, INftV2 } from "../../../../core/models/nft";
import { IUser } from "../../../../core/models/user";
import { toPlainString } from "../../../../core/utils/number";
import { userService } from "../../../../services/user.service";
import { useAppSelector } from "../../../../store/hook";
import { FixedPriceForm } from "./FixedPriceForm";
import { ListingProcessModal } from "./ListingProcessModal";
import { SellerStatusWarningModal } from "./SellerStatusWarningModal";
interface Props {
  isVisible: boolean;
  onClose: () => void;
  nft: INftV2;
  onListSuccess: () => void;
}

enum LISTING_STATE {
  COMFIRMING,
  LISTING,
  NOT_VERIFIED,
}

export const ListingFormModal = React.memo((props: Props) => {
  const { t } = useTranslation([I18N_NAMESPACE.ASSET_DETAIL]);
  const { account, library } = useEthers();
  const supportedTokens = useAppSelector((state) => state.token.supportedTokens);

  const [listingState, setListingState] = useState<LISTING_STATE>(LISTING_STATE.COMFIRMING);

  const [owner, setOwner] = useState<IUser>();
  const quantityOwnedByAccount = useMemo(() => props.nft?.accountBalance || 0, [props.nft?.accountBalance]);

  const listingSchema = useMemo(
    () =>
      yup.object({
        quantity: yup
          .number()
          .required(t("Quantity is required"))
          .integer(t("Quantity must be integer"))
          .min(1, t("Quantity must be greater than 0"))
          .max(quantityOwnedByAccount, `${t("Quantity must be less than or equal to")} ${quantityOwnedByAccount}`),
        price: yup
          .number()
          .required(t("Price is required"))
          .min(0, t("Price must greater than 0"))
          .max(Number.MAX_SAFE_INTEGER, t("Price must be a safe number"))
          .test(
            "length",
            (_) => t("Price must be a safe number"),
            (currentValue) => {
              if (!currentValue) return true;
              return toPlainString(currentValue).replace(".", "").length < 17;
            }
          )
          .test({
            name: "decimal",
            params: { decimals: yup.ref("token.decimals") },
            message: t("Decimal places must be less than or equal to ${decimals} numbers"),
            test: (currentValue, context) => {
              if (!currentValue) return true;
              const [_, fraction] = toPlainString(currentValue)?.split(".");
              const { token } = context.parent;
              return !fraction || fraction.length <= token.decimals;
            },
          }),
      }),
    [quantityOwnedByAccount]
  );

  const formik = useFormik({
    initialValues: {
      price: undefined,
      quantity: 1,
      token: supportedTokens.byId[supportedTokens.allIds[0]],
    },
    validationSchema: listingSchema,
    onSubmit: async (values) => {
      if (!values.price || !values.quantity || !account) return;

      if (owner?.sellerStatus === SELLER_STATUS.VERIFIED || props.nft.allowResell) {
        setListingState(LISTING_STATE.LISTING);
      } else {
        setListingState(LISTING_STATE.NOT_VERIFIED);
      }
    },
  });

  useEffect(() => {
    async function getOwner() {
      if (!account) return;
      const ownerResponse = await userService.getProfileById(account);
      setOwner(ownerResponse.data);
    }

    getOwner();
  }, [account]);

  return (
    <>
      <Modal isOpen={props.isVisible && listingState === LISTING_STATE.COMFIRMING} onClose={props.onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("Complete Listing")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={7}>
              <Flex justifyContent="center" alignItems="center">
                <Box borderWidth="1px" borderRadius="lg" height="80px" width="80px">
                  <NftResource
                    image={props.nft.metadata?.image}
                    animationUrl={props.nft.metadata?.animation_url}
                    muted
                  />
                </Box>

                <Flex flex="1" marginLeft="16px" justifyContent="space-between" alignItems={"center"}>
                  <Box>
                    <Box fontSize={"small"}>{props.nft?.contractName}</Box>
                    <Text color={colors.primary2} fontWeight="700" fontSize="20px">
                      {props.nft?.metadata?.name}
                    </Text>
                  </Box>
                </Flex>
              </Flex>

              <Divider />

              <FixedPriceForm
                nft={props.nft}
                owner={owner}
                formValues={formik.values}
                errors={formik.errors}
                touched={formik.touched}
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                setFieldValue={formik.setFieldValue}
                handleSubmit={formik.handleSubmit}
                isSubmitting={formik.isSubmitting}
              />
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
      {!!props.nft && (
        <ListingProcessModal
          isVisible={props.isVisible && listingState === LISTING_STATE.LISTING}
          onClose={() => {
            setListingState(LISTING_STATE.COMFIRMING);
          }}
          nft={props.nft}
          price={formik.values.price || 0}
          token={formik.values.token}
          quantity={formik.values.quantity}
          owner={owner}
          onSuccess={props.onListSuccess}
        />
      )}
      <SellerStatusWarningModal
        isVisible={props.isVisible && listingState === LISTING_STATE.NOT_VERIFIED}
        onClose={() => {
          setListingState(LISTING_STATE.COMFIRMING);
        }}
      />
    </>
  );
});
