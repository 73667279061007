import { ChainId } from "@usedapp/core";

export enum SUPPORTED_CHAIN_ID {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GOERLI = 5,
  KOVAN = 42,
}

export const CHAIN_NAME_BY_ID = {
  [ChainId.BSC]: "bsc",
  [ChainId.BSCTestnet]: "bsc_testnet",
};

// export const NETWORK_NAME = {
//   [SUPPORTED_CHAIN_ID.MAINNET]: "eth",
// };
