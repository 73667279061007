import { I18N_NAMESPACE } from "../../constants/i18n";
import appHeaderResource from "./app-header.json";
import dashboardResource from "./dashboard.json";
import marketplaceResource from "./marketplace.json";
import assetDetailResource from "./asset-detail.json";
import profileResource from "./profile.json";
import collectionDetailResource from "./collection-detail.json";
import createAssetResource from "./create-asset.json";
import appFooterResource from "./app-footer.json";
import commonComponentResource from "./common-component.json";
import connectWalletResource from "./connect-wallet.json";

export const enResources = {
  [I18N_NAMESPACE.APP_HEADER]: appHeaderResource,
  [I18N_NAMESPACE.DASHBOARD]: dashboardResource,
  [I18N_NAMESPACE.MARKETPLACE]: marketplaceResource,
  [I18N_NAMESPACE.ASSET_DETAIL]: assetDetailResource,
  [I18N_NAMESPACE.PROFILE]: profileResource,
  [I18N_NAMESPACE.COLLECTION_DETAIL]: collectionDetailResource,
  [I18N_NAMESPACE.CREATE_ASSET]: createAssetResource,
  [I18N_NAMESPACE.APP_FOOTER]: appFooterResource,
  [I18N_NAMESPACE.COMMON_COMPONENT]: commonComponentResource,
  [I18N_NAMESPACE.CONNECT_WALLET]: connectWalletResource,
};
