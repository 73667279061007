import {
  Heading,
  Modal,
  ModalBody, ModalContent, ModalOverlay,
  Spinner,
  Stack,
  Text
} from "@chakra-ui/react";
import { getChainName } from "@usedapp/core";
import React from "react";
import { env } from "../core/environment";

interface Props {
  isVisible: boolean;
}

export const WrongNetworkModal = React.memo((props: Props) => {
  return (
    <Modal isOpen={props.isVisible} onClose={() => {}} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody padding={"24px 30px"}>
          <Stack spacing={5} alignItems={"center"}>
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size={"xl"} />
            <Heading>Wrong Network</Heading>
            <Text textAlign={"center"}>
              You connected to unsupported network. Please change network to{" "}
              {env.config.supported_chain_ids.map((id) => getChainName(id)).join("/")}
            </Text>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});
