import { Box, Center, Skeleton, Spinner, Text, useTheme } from "@chakra-ui/react";
import { format } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { colors } from "../../../core/constants/colors";
import { I18N_NAMESPACE } from "../../../core/constants/i18n";
import { formatNumber } from "../../../core/utils/number";
import { useAppSelector } from "../../../store/hook";
import { ISaleStatisticPerDay } from "../models/sale";

interface Props {
  data: ISaleStatisticPerDay[];
  isLoading: boolean;
}

export const SaleHistoryChart = React.memo((props: Props) => {
  const { t } = useTranslation([I18N_NAMESPACE.ASSET_DETAIL]);
  const theme = useTheme();
  const supportedTokens = useAppSelector((state) => state.token.supportedTokens);

  const renderTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const item = payload[0];
      return (
        <Box background={"gray.800"} padding={4} borderRadius={"md"}>
          <Text color={"#fff"} fontWeight={600}>
            {format(label, "dd/MM/yyyy")}
          </Text>
          <Box color="#fff" fontSize={"sm"} fontWeight={500}>
            <Text>
              {t("Avg. price")}: ~${formatNumber(payload[0].value)}
            </Text>
            <Text>
              {t("Num. sales")}: {item.payload.noOfSales}
            </Text>
          </Box>
        </Box>
      );
    }

    return null;
  };

  if (props.isLoading) {
    return (
      <Center marginTop={5}>
        <Spinner thickness="3px" speed="0.65s" emptyColor="gray.200" color={colors.primary} size="lg" />
      </Center>
    );
  }

  return (
    <Box height={300} width={"100%"}>
      {props.data.length > 0 ? (
        <ResponsiveContainer width={"100%"} height={"100%"}>
          <ComposedChart data={props.data} margin={{ top: 5, bottom: 5 }}>
            <Line
              type="monotone"
              dataKey="price"
              stroke={theme.colors.blue["500"]}
              strokeWidth={2}
              dot={{ strokeWidth: 2 }}
            />
            <CartesianGrid stroke="#ccc" strokeDasharray="3" />
            <ReferenceLine y={0} stroke="#fff" />
            <XAxis
              dataKey="date"
              tickFormatter={(value) => {
                return format(value, "dd/MM");
              }}
              fontSize={12}
              fontWeight={500}
              axisLine={false}
              tickLine={false}
              tickMargin={10}
              interval={"preserveEnd"}
            />
            <YAxis
              fontSize={12}
              fontWeight={500}
              axisLine={false}
              tickLine={false}
              tickMargin={10}
              width={60}
              tickFormatter={(value) => `${formatNumber(value, { notation: "compact" })}$`}
            />
            <Tooltip content={renderTooltip} />
          </ComposedChart>
        </ResponsiveContainer>
      ) : (
        <Box>
          <Text color={"gray.500"} fontSize="sm">
            {t("Cannot found price history")}
          </Text>
        </Box>
      )}
    </Box>
  );
});
