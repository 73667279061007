import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEthers } from "@usedapp/core";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { create } from "ipfs-http-client";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ICreateAssetForm } from "..";
import { ProgressIcon } from "../../../components/ProgressIcon";
import { colors } from "../../../core/constants/colors";
import { I18N_NAMESPACE } from "../../../core/constants/i18n";
import { NFT_TYPE } from "../../../core/constants/nft";
import { env } from "../../../core/environment";
import { useMarketplaceAddress, useNftProxyAddress } from "../../../core/hooks/useAddress";
import {
  useERC1155BePayContract,
  useERC721BePayMinimalContract,
  useERC721Contract,
  useHelperContract,
} from "../../../core/hooks/useContract";
import { INft } from "../../../core/models/nft";
import { Quote, signQuote } from "../../../core/models/quote";
import { generateTokenId, signLazyERC1155Nft, signLazyERC721Nft } from "../../../core/utils/assets";
import { delay } from "../../../core/utils/delay";
import { getSalt } from "../../../core/utils/getSalt";
import { buildIPFSUri } from "../../../core/utils/ipfs";
import { toPlainString } from "../../../core/utils/number";
import { nftService } from "../../../services/nft.service";
import { quoteService } from "../../../services/quote.service";
import { userService } from "../../../services/user.service";
import { useAppSelector } from "../../../store/hook";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  formValues: ICreateAssetForm;
  type: string;
  onComplete: (nft: INft) => void;
}

enum STEP {
  UPLOAD_METADATA_TO_IPFS,
  MINT,
  APPROVE,
  PUT_ON_SALE,
  COMPLETE,
}

const auth =
  "Basic " + Buffer.from(env.config.ipfs.project_id + ":" + env.config.ipfs.prject_secret).toString("base64");

const client = create({
  host: env.config.ipfs.host,
  port: env.config.ipfs.port,
  protocol: env.config.ipfs.protocol,
  headers: {
    authorization: auth,
  },
});

export const CreateNftStepsModal = React.memo((props: Props) => {
  const { t } = useTranslation([I18N_NAMESPACE.CREATE_ASSET]);
  const appContext = useAppSelector((state) => state.appContext);
  const isERC1155 = useMemo(() => props.type === NFT_TYPE.ERC_1155, [props.type]);
  const isERC721 = useMemo(() => props.type === NFT_TYPE.ERC_721, [props.type]);

  const { account, library, chainId } = useEthers();
  const [step, setStep] = useState(STEP.UPLOAD_METADATA_TO_IPFS);
  const [imageUri, setImageUri] = useState<string>();
  const [metadataUri, setMetadataUri] = useState<string>();
  const [mintSignature, setMintSignature] = useState<string>();
  const [errorByStep, setErrorByStep] = useState<{ [key: number]: string | null }>({});
  const [nft, setNft] = useState<INft>();

  const nftTransferProxyAddress = useNftProxyAddress();
  const marketplaceAddress = useMarketplaceAddress();

  const erc721BePayMinimalContract = useERC721BePayMinimalContract(props.formValues.collection?.contract);
  const erc1155BePayContract = useERC1155BePayContract(props.formValues.collection?.contract);

  const collectionContract = useERC721Contract(props.formValues.collection?.contract);
  const helperContract = useHelperContract();

  const attributes = useMemo(
    () => [
      ...props.formValues.properties
        .filter((i) => !!i.name && !!i.type)
        .map((i) => ({
          trait_type: i.type,
          value: i.name,
        })),
      ...(props.formValues.isEnableTruppa
        ? props.formValues.truppaProperties.map((i) => ({
            trait_type: i.type,
            value: i.name?.toString(),
            is_truppa: true,
          }))
        : []),
    ],
    [props.formValues.isEnableTruppa, props.formValues.truppaProperties, props.formValues.properties]
  );
  const creators = useMemo(() => (account ? [{ account, value: 10000 }] : []), [account]);
  const royalties = useMemo(
    () => (account ? [{ account, value: Math.round(props.formValues.royalties * 100) }] : []),
    [account, props.formValues.royalties]
  );

  const uploadMetadataToIPFS = async (): Promise<string | undefined> => {
    if (!props.formValues.file) return;
    const image = await client.add(props.formValues.file);
    const metadata = await client.add(
      JSON.stringify({
        name: props.formValues.name?.trim(),
        ...(["video/mp4", "video/webm", "audio/mp3", "audio/webm", "audio/mpeg"].includes(props.formValues.file.type)
          ? { animation_url: buildIPFSUri(image.path) }
          : { image: buildIPFSUri(image.path) }),
        attributes,
        description: props.formValues.description?.trim(),
      })
    );

    setMetadataUri(buildIPFSUri(metadata.path));
    setImageUri(buildIPFSUri(image.path));
    setStep(STEP.MINT);
  };

  async function checkMinted(nft: INft): Promise<boolean> {
    if (!nft) return false;
    const response = await nftService.checkMinted(appContext.selectedChain.name, nft?.contract, nft?.tokenId);

    if (response.data) {
      return true;
    } else {
      await delay(2000);
      return await checkMinted(nft);
    }
  }

  const mint = async () => {
    try {
      const { price, quantity, collection, token } = props.formValues;
      if (!account || !library || !collection || !metadataUri) return;
      setErrorByStep((v) => ({ ...v, [STEP.MINT]: null }));
      const now = new Date();
      const tokenId = generateTokenId(now.valueOf(), account);
      const tokenURI = metadataUri;

      const nft: INft = {
        attributes,
        description: props.formValues.description?.trim(),
        ...(props.formValues?.file?.type &&
        ["video/mp4", "video/webm", "audio/mp3", "audio/webm", "audio/mpeg"].includes(props.formValues?.file?.type)
          ? { animationUrl: imageUri }
          : { image: imageUri }),
        name: props.formValues.name?.trim() || "",
        contractName: collection.name,
        symbol: collection.symbol || "",
        value: quantity,
        supply: quantity,
        contract: collection.contract,
        tokenId: tokenId,
        uri: metadataUri,
        contract_type: props.type,
        price: price || 0,
        creators,
        royalties,
      };

      if (props.formValues.isLazyMint) {
        const signature = isERC1155
          ? await signLazyERC1155Nft(
              library,
              {
                tokenId,
                supply: quantity,
                tokenURI,
                creators,
                royalties,
              },
              account,
              collection?.contract
            )
          : await signLazyERC721Nft(
              library,
              {
                tokenId,
                tokenURI,
                creators,
                royalties,
              },
              account,
              collection?.contract
            );

        setMintSignature(signature);
        await nftService.create({
          ...nft,
          signatures: [signature],
          chain: appContext.selectedChain.name,
        });
      } else {
        const tx = isERC1155
          ? await erc1155BePayContract?.mintAndTransfer(
              {
                tokenId,
                tokenURI,
                supply: quantity,
                creators,
                royalties,
                signatures: [ethers.constants.HashZero],
              },
              account,
              quantity
            )
          : await erc721BePayMinimalContract?.mintAndTransfer(
              {
                tokenId,
                tokenURI,
                creators,
                royalties,
                signatures: [ethers.constants.HashZero],
              },
              account
            );

        await tx?.wait();
      }

      setNft(nft);
      const minted = !props.formValues.isLazyMint ? await checkMinted(nft) : true;

      if (minted) {
        if (props.formValues.isListOnMarketplace) {
          setStep(STEP.APPROVE);
        } else {
          setStep(STEP.COMPLETE);
        }
      }
    } catch (e: any) {
      setErrorByStep((v) => ({ ...v, [STEP.MINT]: e?.data?.message || e?.message }));
    }
  };

  const approve = async () => {
    try {
      setErrorByStep((v) => ({ ...v, [STEP.APPROVE]: null }));
      const isApproved = await collectionContract?.isApprovedForAll(account, nftTransferProxyAddress);

      if (!isApproved) {
        const tx = await collectionContract?.setApprovalForAll(nftTransferProxyAddress, true);
        await tx.wait();
      }

      setStep(STEP.PUT_ON_SALE);
    } catch (e: any) {
      setErrorByStep((v) => ({ ...v, [STEP.APPROVE]: e?.data?.message || e?.message }));
    }
  };

  const putOnSale = async () => {
    try {
      const { price, quantity, collection, token } = props.formValues;
      if (!price || !account || !library || !collection || !nft) return;
      setErrorByStep((v) => ({ ...v, [STEP.PUT_ON_SALE]: null }));

      const userResponse = await userService.getProfileById(account);

      const quote: Quote = {
        maker: account.toLowerCase(),
        salt: getSalt(),
        make: {
          assetType: {
            assetClass: !props.formValues.isLazyMint ? props.type : `${props.type}_LAZY`,
            tokenId: nft.tokenId,
            contract: collection.contract,
            ...(props.formValues.isLazyMint && {
              creators,
              royalties,
              uri: metadataUri,
              signatures: mintSignature ? [mintSignature] : [],
              supply: quantity,
            }),
          },
          value: toPlainString(Math.floor(quantity)),
        },
        taker: ethers.constants.AddressZero,
        take: {
          assetType: {
            assetClass: token.isNative ? "ETH" : "ERC20",
            contract: token.underlying,
          },
          value: toPlainString(
            Math.floor(
              new BigNumber(price)
                .multipliedBy(Math.pow(10, token.decimals))
                .multipliedBy(props.formValues.quantity)
                .toNumber()
            )
          ),
        },
        type: "V1",
        data: {
          isMakeFill: true,
          payouts: [],
          originFees: toPlainString(userResponse.data?.commissionFee || 0),
          dataType: "V1",
        },
        signature: "0x",
      };

      const onChainQuote = await Quote.toOnChainQuote(quote, helperContract);
      const signature = await signQuote(library, onChainQuote, account, marketplaceAddress);

      await quoteService.create({
        ...quote,
        signature,
        chain: appContext.selectedChain.name,
      });

      setStep(STEP.COMPLETE);
    } catch (e: any) {
      setErrorByStep((v) => ({ ...v, [STEP.PUT_ON_SALE]: e.message }));
    }
  };

  const complete = () => {
    if (!nft) return;
    props.onComplete(nft);
    onClose();
  };

  useEffect(() => {
    if (!props.isVisible) return;

    const executionByStep = {
      [STEP.UPLOAD_METADATA_TO_IPFS]: uploadMetadataToIPFS,
      [STEP.MINT]: mint,
      [STEP.APPROVE]: approve,
      [STEP.PUT_ON_SALE]: putOnSale,
      [STEP.COMPLETE]: complete,
    };

    const execution = executionByStep[step];
    if (execution) {
      execution();
    }
  }, [props.isVisible, step]);

  const resetState = () => {
    setMetadataUri(undefined);
    setImageUri(undefined);
    setMintSignature(undefined);
    setNft(undefined);
    setErrorByStep({});
    setStep(STEP.UPLOAD_METADATA_TO_IPFS);
  };

  const onClose = useCallback(() => {
    props.onClose();
    resetState();
  }, [props.onClose]);

  return (
    <Modal isOpen={props.isVisible} onClose={onClose} isCentered size={"lg"} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("Follow steps")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <HStack alignItems={"flex-start"} borderRadius="lg" background="#F6F6F6" padding="20px 16px">
              <ProgressIcon
                isLoading={step === STEP.UPLOAD_METADATA_TO_IPFS}
                isSuccess={step > STEP.UPLOAD_METADATA_TO_IPFS}
              />

              <Box>
                <Text fontWeight="700" color={colors.primary2}>
                  {t("Upload")}
                </Text>
                <Text fontSize="14px" color="#000" marginTop="8px">
                  {t("Uploading of metadata to IPFS")}.
                </Text>
              </Box>
            </HStack>

            <HStack alignItems={"flex-start"} borderRadius="lg" background="#F6F6F6" padding="20px 16px">
              <ProgressIcon isLoading={step === STEP.MINT} isSuccess={step > STEP.MINT} />

              <Box>
                <Text fontWeight="700" color={colors.primary2}>
                  {t("Mint")}
                </Text>
                <Text fontSize="14px" color="#000" marginTop="8px">
                  {props.formValues.isLazyMint
                    ? t("Create NFT on bePAY marketplace")
                    : t("Create your NFT in blockchain")}
                  .
                </Text>
                {!!errorByStep[STEP.MINT] && (
                  <Box marginTop={3}>
                    <Text color={"red.500"} fontSize={"md"}>
                      {errorByStep[STEP.MINT]}
                    </Text>
                    <Button colorScheme="blue" onClick={mint} marginTop={2}>
                      {t("Try again")}
                    </Button>
                  </Box>
                )}
              </Box>
            </HStack>

            {props.formValues.isListOnMarketplace && (
              <HStack alignItems={"flex-start"} borderRadius="lg" background="#F6F6F6" padding="20px 16px">
                <ProgressIcon isLoading={step === STEP.APPROVE} isSuccess={step > STEP.APPROVE} />

                <Box>
                  <Text fontWeight="700" color={colors.primary2}>
                    {t("Approve")}
                  </Text>
                  <Text fontSize="14px" color="#000" marginTop="8px">
                    {t("Allow marketplace to read your collection")}.
                  </Text>
                  {!!errorByStep[STEP.APPROVE] && (
                    <Box marginTop={3}>
                      <Text color={"red.500"} fontSize={"md"}>
                        {errorByStep[STEP.APPROVE]}
                      </Text>
                      <Button colorScheme="blue" onClick={approve} marginTop={2}>
                        {t("Try again")}
                      </Button>
                    </Box>
                  )}
                </Box>
              </HStack>
            )}

            {props.formValues.isListOnMarketplace && (
              <HStack alignItems={"flex-start"} borderRadius="lg" background="#F6F6F6" padding="20px 16px">
                <ProgressIcon isLoading={step === STEP.PUT_ON_SALE} isSuccess={step > STEP.PUT_ON_SALE} />

                <Box>
                  <Text fontWeight="700" color={colors.primary2}>
                    {t("Put on sale")}
                  </Text>
                  <Text fontSize="14px" color="#000" marginTop="8px">
                    {t("Sign to set item price")}.
                  </Text>
                  {!!errorByStep[STEP.PUT_ON_SALE] && (
                    <Box marginTop={3}>
                      <Text color={"red.500"} fontSize={"md"}>
                        {errorByStep[STEP.PUT_ON_SALE]}
                      </Text>
                      <Button colorScheme="blue" onClick={putOnSale} marginTop={2}>
                        {t("Try again")}
                      </Button>
                    </Box>
                  )}
                </Box>
              </HStack>
            )}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});
