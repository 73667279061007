import { WarningIcon } from "@chakra-ui/icons";
import { Input } from "@chakra-ui/input";
import { AspectRatio, Heading, Stack } from "@chakra-ui/layout";
import { Flex, Image, Spinner, Tooltip } from "@chakra-ui/react";
import React, { ChangeEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../../core/constants/colors";
import { I18N_NAMESPACE } from "../../../core/constants/i18n";
import { images } from "../../../core/constants/images";

interface Props {
  isUploading: boolean;
  value: string | null | undefined;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

export const ProfileImageRow = React.memo((props: Props) => {
  const { t } = useTranslation([I18N_NAMESPACE.PROFILE]);
  return (
    <Stack spacing="24px">
      <Flex>
        <Heading size="md">{t("Profile Image")}</Heading>
        <Tooltip
          hasArrow
          label={
            <div>
              <p>{t("Recommended: 200x200 pixels")}</p>
              <p>{t("Max size", { size: 5 })}</p>
            </div>
          }
          borderRadius="lg"
          padding="6px"
          placement="top"
        >
          <WarningIcon w={4} h={4} color={colors.primary} marginLeft="6px" />
        </Tooltip>
      </Flex>

      <AspectRatio ratio={1} w="120px">
        <Flex
          borderRadius="full"
          borderWidth={props.value ? 0 : 2}
          borderColor="gray.200"
          borderStyle={"dashed"}
          alignItems="center"
          justifyContent="center"
        >
          {!props.isUploading ? (
            <>
              {props.value ? (
                <Image src={props.value} objectFit="cover" flex={1} height="full" width="full" />
              ) : (
                <Image src={images.placeholder} />
              )}
              <Input
                type="file"
                accept="image/png,image/jpeg,image/gif,image/webp"
                onChange={props.onChange}
                opacity={0}
                position="absolute"
                top={0}
                left={0}
                height="100%"
                w="100%"
              />
            </>
          ) : (
            <Spinner colorScheme="blue" size="lg" color="blue.500" />
          )}
        </Flex>
      </AspectRatio>
    </Stack>
  );
});
