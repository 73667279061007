import { ChevronDownIcon, CloseIcon, InfoOutlineIcon, MinusIcon, WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Stack,
  Switch,
  Text,
  Textarea,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { useEthers } from "@usedapp/core";
import { useFormik } from "formik";
import { cloneDeep, remove, uniqueId } from "lodash-es";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoMdAddCircle } from "react-icons/io";
import StarPicker from "react-star-picker";
import * as yup from "yup";
import { AppContent } from "../../components/AppContent";
import { ErrorText } from "../../components/ErrorText";
import { colors } from "../../core/constants/colors";
import { I18N_NAMESPACE } from "../../core/constants/i18n";
import { images } from "../../core/constants/images";
import { NFT_TYPE } from "../../core/constants/nft";
import { SELLER_STATUS } from "../../core/constants/status";
import { useERC1155BePayInstanceAddress, useERC721BePayInstanceAddress } from "../../core/hooks/useAddress";
import { ICollection } from "../../core/models/collection";
import { INft } from "../../core/models/nft";
import { IToken } from "../../core/models/token";
import { IUser } from "../../core/models/user";
import { getChainNameById } from "../../core/utils/chain";
import { toPlainString } from "../../core/utils/number";
import { collectionService } from "../../services/collection.service";
import { userService } from "../../services/user.service";
import { useAppSelector } from "../../store/hook";
import { CreateCollectionModal } from "./components/CreateCollectionModal";
import { CreateNftStepsModal } from "./components/CreateNftStepsModal";
import { CreateNftSuccessModal } from "./components/CreateNftSuccessModal";

interface Props {
  type: string;
}

export interface ICreateAssetForm {
  price: number | undefined;
  quantity: number;
  token: IToken;
  name: string | undefined;
  description: string | undefined;
  file: File | undefined;
  royalties: number;
  collection: ICollection | undefined;
  properties: { id: string; type: string | undefined; name: string | undefined | null }[];
  truppaProperties: { id: string; type: string | undefined; name: number | null }[];
  isEnableTruppa: boolean;
  isLazyMint: boolean;
  isListOnMarketplace: boolean;
}

const CreateAsset = React.memo((props: Props) => {
  const { t } = useTranslation([I18N_NAMESPACE.CREATE_ASSET]);
  const { account, chainId } = useEthers();
  const toast = useToast();
  const supportedTokens = useAppSelector((state) => state.token.supportedTokens);

  const erc721BePayInstanceAddress = useERC721BePayInstanceAddress();
  const erc1155BePayInstanceAddress = useERC1155BePayInstanceAddress();

  const defaultProperty = useMemo(() => ({ type: undefined, name: undefined }), []);

  const [isVisibleCreateCollectionModal, setIsVisibleCreateCollectionModal] = useState(false);
  const [isVisibleCreateNftStepsModal, setIsVisibleCreateNftStepsModal] = useState(false);
  const [user, setUser] = useState<IUser>();
  const defaultCollection = useMemo(
    () =>
      props.type === NFT_TYPE.ERC_721
        ? {
            contract: erc721BePayInstanceAddress.toLowerCase(),
            name: "bePAY",
            symbol: "BEP721",
            image: images.logo,
          }
        : {
            contract: erc1155BePayInstanceAddress.toLowerCase(),
            name: "bePAY",
            symbol: "BEP1155",
            image: images.logo,
          },
    [props.type]
  );

  const [collections, setCollections] = useState<ICollection[]>([defaultCollection]);
  const [createdNft, setCreatedNft] = useState<INft>();

  const FormSchema = yup.object({
    price: yup.number().when("isListOnMarketplace", {
      is: true,
      then: (schema) =>
        schema
          .required(t("Price is required"))
          .min(0, t("Price must greater than 0"))
          .max(Number.MAX_SAFE_INTEGER, t("Price must be a safe number"))
          .test(
            "length",
            (_) => t("Price must be a safe number"),
            (currentValue) => {
              if (!currentValue) return true;
              return toPlainString(currentValue).replace(".", "").length < 17;
            }
          )
          .test({
            name: "decimal",
            params: { decimals: yup.ref("token.decimals") },
            message: t("Decimal places must be less than or equal to ${decimals} numbers"),
            test: (currentValue, context) => {
              if (!currentValue) return true;
              const [_, fraction] = toPlainString(currentValue)?.split(".");
              const { token } = context.parent;
              return !fraction || fraction.length <= token.decimals;
            },
          }),
    }),
    name: yup.string().required(t("Name is required")),
    collection: yup.mixed().required(t("Collection is required")),
    file: yup.mixed().required(t("File is required")),
    royalties: yup
      .number()
      .required(t("Royalties is required"))
      .max(50, t("Royalties must be less than or equal to 50%"))
      .min(0, t("Royalties must be a positive number"))
      .test({
        name: "decimal",
        message: t("Royalties must be a safe number"),
        test: (currentValue, context) => {
          if (!currentValue) return true;
          const [_, fraction] = toPlainString(currentValue)?.split(".");
          return !fraction || fraction.length < 3;
        },
      }),
    quantity: yup
      .number()
      .required(t("Quantity is required"))
      .integer(t("Quantity must be interger"))
      .min(0, t("Quantity must be a positive number"))
      .max(Number.MAX_SAFE_INTEGER, t("Quantity must be a safe number")),
    properties: yup.array().of(
      yup
        .object({
          type: yup.string(),
          name: yup.string(),
        })
        .test("is-optional", t("Type and name are required"), ({ type, name }) => {
          if (!type && !name) return true;
          return !!type && !!name;
        })
    ),
    truppaProperties: yup.array().when("isEnableTruppa", {
      is: true,
      then: (schema) =>
        schema.of(
          yup
            .object({
              type: yup.string(),
              name: yup.mixed(),
            })
            .test("is-optional", t("Value is required"), ({ type, name }) => {
              if (!type && !name) return true;
              return !!type && !!name;
            })
        ),
      otherwise: (schema) => schema,
    }),
  });

  const formik = useFormik<ICreateAssetForm>({
    initialValues: {
      price: undefined,
      quantity: 1,
      token: supportedTokens.byId[supportedTokens.allIds[0]],
      name: undefined,
      description: undefined,
      file: undefined,
      royalties: 10,
      collection: undefined,
      isLazyMint: false,
      isListOnMarketplace: false,
      properties: [{ ...cloneDeep(defaultProperty), id: uniqueId() }],
      truppaProperties: [
        {
          id: "tangibility",
          type: "Tangibility",
          name: null,
        },
        {
          id: "rarity",
          type: "Rarity",
          name: null,
        },
        {
          id: "utility",
          type: "Utility",
          name: null,
        },
        {
          id: "popularity",
          type: "Popularity",
          name: null,
        },
        {
          id: "profitability",
          type: "Profitability",
          name: null,
        },
        {
          id: "ability-of-development",
          type: "Ability of development",
          name: null,
        },
      ],
      isEnableTruppa: false,
    },
    validationSchema: FormSchema,
    onSubmit: async (values) => {
      setIsVisibleCreateNftStepsModal(true);
    },
  });

  async function getCollections() {
    if (!account) return;
    const response = await collectionService.getCollectionsByAccount(getChainNameById(chainId), account, {
      contractType: props.type,
    });
    setCollections([defaultCollection, ...(response.data || [])]);
  }

  async function getUser() {
    if (!account) return;
    const response = await userService.getProfileById(account);
    setUser(response.data);
  }

  useEffect(() => {
    getCollections();
    getUser();

    formik.resetForm();
  }, [account]);

  useEffect(() => {
    if (user?.sellerStatus === SELLER_STATUS.VERIFIED) {
      formik.setFieldValue("isListOnMarketplace", true);
    } else {
      formik.setFieldValue("isListOnMarketplace", false);
    }
  }, [user?.sellerStatus]);

  useEffect(() => {
    formik.setFieldValue("token", supportedTokens.byId[supportedTokens.allIds[0]]);
  }, [supportedTokens.allIds.length]);

  const onChangeFile = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      formik.setFieldTouched("file", true);
      if (!e.target.files) return;
      const file = e?.target?.files[0];
      if (!file) return;

      const MAX_SIZE_IN_MB = ["image/png", "image/jpeg", "image/gif", "image/webp"].includes(file.type) ? 2 : 100;
      if (file.size > MAX_SIZE_IN_MB * 1024 * 1024) {
        toast({
          title: t("Validation"),
          description: t("NFT's size validate", { size: MAX_SIZE_IN_MB }),
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        return;
      }
      formik.setFieldValue("file", file);

      //Allow to select the same file
      e.currentTarget.value = "";
    },
    [formik]
  );

  const onRemoveFile = useCallback(() => {
    formik.setFieldValue("file", undefined);
  }, [formik]);

  const onAddProperty = useCallback(() => {
    formik.setFieldValue("properties", [
      ...formik.values.properties,
      { ...cloneDeep(defaultProperty), id: uniqueId() },
    ]);
  }, [formik]);

  const onRemoveProperty = useCallback(
    (id: string) => () => {
      formik.setFieldValue(
        "properties",
        remove(formik.values.properties, (value) => value.id !== id)
      );
    },
    [formik.values.properties]
  );

  const onClickCollection = useCallback(
    (collection: ICollection) => () => {
      formik.setFieldValue("collection", collection);
    },
    []
  );

  const onCompleteCreateCollection = useCallback(async () => {
    await getCollections();
    setIsVisibleCreateCollectionModal(false);
  }, []);

  const onCompleteCreateNft = useCallback((nft: INft) => {
    setIsVisibleCreateNftStepsModal(false);
    setCreatedNft(nft);
  }, []);

  const onChooseFile = useCallback(() => {
    const uploadFileElement = document.getElementById("upload-file");
    if (uploadFileElement) {
      uploadFileElement.click();
    }
  }, []);

  const fileUrl = useMemo(
    () => (formik.values.file ? URL.createObjectURL(formik.values.file) : null),
    [formik.values.file]
  );

  return (
    <AppContent maxWidth="650">
      <Heading>{t("Create New Item")}</Heading>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={6} marginTop={8}>
          <Stack>
            <Title>{t("Upload file")}</Title>
            <Description>
              {t("File types supported: PNG, GIF, WEBP or MP4. Max size: 100MB for video or 2MB for image/gif")}
            </Description>
            <Box>
              <Flex
                alignItems={"center"}
                justifyContent={"center"}
                borderWidth={2}
                borderColor="gray.200"
                borderStyle={"dashed"}
                borderRadius={"xl"}
                padding={"20px 60px"}
                position={"relative"}
                width={"80%"}
              >
                {formik.values.file && fileUrl ? (
                  <>
                    {["video/mp4", "video/webm", "audio/mp3", "audio/webm", "audio/mpeg"].includes(
                      formik.values.file.type
                    ) ? (
                      <video width={"100%"} height={"100%"} autoPlay controls loop src={fileUrl} playsInline />
                    ) : (
                      <Image src={fileUrl} />
                    )}
                    <IconButton
                      variant="outline"
                      aria-label="Remove file"
                      icon={<CloseIcon fontSize={12} />}
                      borderRadius="full"
                      position="absolute"
                      top={3}
                      right={3}
                      zIndex={2}
                      onClick={onRemoveFile}
                    />
                  </>
                ) : (
                  <Image src={images.placeholder} onClick={onChooseFile} />
                )}

                <Input
                  accept="image/png,image/jpeg,image/gif,image/webp,video/mp4"
                  type="file"
                  onChange={onChangeFile}
                  hidden
                  id="upload-file"
                />
              </Flex>
              {!!formik.errors.file && !!formik.touched.file && <ErrorText>{formik.errors.file}</ErrorText>}
            </Box>
          </Stack>
          <Stack>
            <Title>{t("Name")}</Title>
            <Box>
              <Input
                placeholder={t("Item name")}
                value={formik.values.name || ""}
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.name && !!formik.touched.name}
              />
              {!!formik.errors.name && !!formik.touched.name && <ErrorText>{formik.errors.name}</ErrorText>}
            </Box>
          </Stack>
          <Stack>
            <Title>{t("Description")}</Title>
            <Textarea
              placeholder={t("Provide a detailed description of your item")}
              value={formik.values.description || ""}
              name="description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Stack>
          <Stack>
            <Title>{t("Collection")}</Title>
            <Box>
              <Grid gridTemplateColumns={"1fr 1fr 1fr"} gridGap={3}>
                <Stack
                  flex={1}
                  borderWidth={1}
                  borderColor="gray.200"
                  borderRadius={"xl"}
                  minH="140px"
                  alignItems={"center"}
                  justifyContent={"center"}
                  cursor={"pointer"}
                  onClick={() => setIsVisibleCreateCollectionModal(true)}
                  _hover={{ borderColor: "gray.300" }}
                >
                  <IoMdAddCircle fontSize={42} />
                  <Text fontWeight={700} fontSize={"md"} color="rgb(53, 56, 64)">
                    {t("Create NFT")}
                  </Text>
                  <Description>{props.type.toUpperCase()}</Description>
                </Stack>
                {collections.map((c, index) => (
                  <Stack
                    key={`${c.contract}-${index}`}
                    flex={1}
                    borderWidth={1}
                    borderColor={formik.values.collection?.contract === c.contract ? "blue.500" : "gray.200"}
                    borderRadius={"xl"}
                    minH="140px"
                    alignItems={"center"}
                    justifyContent={"center"}
                    cursor={"pointer"}
                    onClick={onClickCollection(c)}
                    _hover={{
                      borderColor: formik.values.collection?.contract === c.contract ? "blue.500" : "gray.300",
                    }}
                  >
                    <Image height={"42px"} width={"42px"} objectFit={"cover"} src={c.image} borderRadius={"full"} />
                    <Text fontWeight={700} fontSize={"md"} color="rgb(53, 56, 64)">
                      {c.name}
                    </Text>
                    <Description>{c?.symbol?.toUpperCase()}</Description>
                  </Stack>
                ))}
              </Grid>
              {!!formik.errors.collection && !!formik.touched.collection && (
                <ErrorText>{formik.errors.collection}</ErrorText>
              )}
            </Box>
          </Stack>
          <Stack>
            <Title>{t("Properties")}</Title>
            <Box borderWidth={1} borderColor="gray.200" padding={4} borderRadius={"xl"}>
              <Grid gridTemplateColumns={"1fr 1fr auto"} gridGap={6}>
                <Text fontSize="sm" fontWeight={700} color="rgb(53, 56, 64)">
                  {t("Type")}
                </Text>
                <Text fontSize="sm" fontWeight={700} color="rgb(53, 56, 64)">
                  {t("Name")}
                </Text>
                <Box w="24px" />
              </Grid>
              <Box marginTop={2}>
                {formik.values.properties.map((p, index) => {
                  const isInvalid = !!formik.errors.properties && !!formik.errors.properties[index];
                  return (
                    <Box key={`property-${p.id}`}>
                      <Grid gridTemplateColumns={"1fr 1fr auto"} gridGap={3} alignItems="center">
                        <Input
                          variant="flushed"
                          value={p.type || ""}
                          name={`properties.${index}.type`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder={t("e.g. Size")}
                          isInvalid={isInvalid}
                        />
                        <Input
                          variant="flushed"
                          value={p.name || ""}
                          name={`properties.${index}.name`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder={t("e.g. M")}
                          isInvalid={isInvalid}
                        />
                        <IconButton
                          colorScheme="red"
                          borderRadius={"full"}
                          size="xs"
                          aria-label="Search database"
                          icon={<MinusIcon />}
                          onClick={onRemoveProperty(p.id)}
                        />
                      </Grid>
                      {isInvalid && (
                        <ErrorText>{formik.errors?.properties ? formik.errors?.properties[index] : ""}</ErrorText>
                      )}
                    </Box>
                  );
                })}
              </Box>
              <Button colorScheme={"blue"} variant="outline" w={"fit-content"} marginTop={4} onClick={onAddProperty}>
                {t("Add more")}
              </Button>
            </Box>
          </Stack>

          <Stack>
            <Flex alignItems={"center"} justifyContent={"space-between"}>
              <Stack>
                <Title>{t("TRUPPA")}</Title>
                <Description>{t("The formula helps users determine the NFT benchmark")}.</Description>
              </Stack>
              <Switch
                size="md"
                isChecked={formik.values.isEnableTruppa}
                onChange={formik.handleChange}
                id="isEnableTruppa"
              />
            </Flex>
            {formik.values.isEnableTruppa && (
              <Box borderWidth={1} borderColor="gray.200" padding={4} borderRadius={"xl"}>
                <Grid gridTemplateColumns={"1fr 1fr"} gridGap={6}>
                  <Text fontSize="sm" fontWeight={700} color="rgb(53, 56, 64)">
                    {t("Type")}
                  </Text>
                  <Text fontSize="sm" fontWeight={700} color="rgb(53, 56, 64)">
                    {t("Value")}
                  </Text>
                  <Box w="24px" />
                </Grid>
                <Box marginTop={2}>
                  {formik.values.truppaProperties.map((p, index) => {
                    const isInvalid =
                      !!formik.errors.truppaProperties &&
                      !!formik.errors.truppaProperties[index] &&
                      formik.touched.truppaProperties;
                    return (
                      <Box key={`truppa-property-${p.id}`}>
                        <Grid gridTemplateColumns={"1fr 1fr"} gridGap={3} alignItems="center">
                          <Center
                            height={10}
                            borderColor={"gray.200"}
                            borderBottomWidth="1px"
                            justifyContent={"flex-start"}
                          >
                            <Text fontSize={"sm"}>{!!p.type && t(p.type)}</Text>
                          </Center>
                          <StarPicker
                            size={26}
                            value={p.name || null}
                            onChange={(value) => {
                              formik.setFieldValue(`truppaProperties.${index}.name`, value);
                            }}
                          />
                          <Box w="24px" />
                        </Grid>
                        {isInvalid && (
                          <ErrorText>
                            {formik.errors?.truppaProperties ? formik.errors?.truppaProperties[index] : ""}
                          </ErrorText>
                        )}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            )}
          </Stack>
          <Stack>
            <Flex alignItems={"center"} justifyContent={"space-between"}>
              <Stack>
                <Title>{t("List on marketplace")}</Title>
                <Description>{t("Put your new NFT on bePAY's marketplace")}</Description>
              </Stack>
              <Switch
                size="md"
                isChecked={formik.values.isListOnMarketplace}
                onChange={formik.handleChange}
                id="isListOnMarketplace"
                isDisabled={user?.sellerStatus !== SELLER_STATUS.VERIFIED}
              />
            </Flex>
            {formik.values.isListOnMarketplace && (
              <Menu>
                <Box>
                  <InputGroup>
                    <InputLeftAddon width={"7.2rem"} as={MenuButton} type="button">
                      <HStack alignItems="center" spacing={1}>
                        <Image src={formik.values.token?.logoUrl} height="20px" width="20px" />
                        <Text color="#666666" fontWeight="700">
                          {formik.values.token?.symbol}
                        </Text>
                        <ChevronDownIcon color="#666666" />
                      </HStack>
                    </InputLeftAddon>
                    <Input
                      type="number"
                      value={formik.values.price === undefined ? "" : formik.values.price}
                      name="price"
                      onChange={formik.handleChange}
                      placeholder={t("Enter item price per one piece")}
                      fontWeight="700"
                      onBlur={formik.handleBlur}
                      step={"0.00000001"}
                      isInvalid={!!formik.errors.price && !!formik.touched.price}
                    />
                  </InputGroup>
                  {!!formik.errors.price && !!formik.touched.price && <ErrorText>{formik.errors.price}</ErrorText>}
                </Box>
                <MenuList minWidth="240px">
                  <MenuOptionGroup
                    value={formik.values.token.underlying}
                    title={t("Tokens")}
                    type="radio"
                    onChange={(id) =>
                      typeof id === "string" ? formik.setFieldValue("token", supportedTokens.byId[id]) : null
                    }
                  >
                    {supportedTokens.allIds.map((id) => {
                      const supportedToken = supportedTokens.byId[id];
                      return (
                        <MenuItemOption value={supportedToken.underlying} key={id}>
                          <Flex alignItems="center">
                            <Image src={supportedToken?.logoUrl} height="20px" width="20px" marginRight="8px" />
                            <Text color="#666666" fontWeight="700">
                              {supportedToken?.symbol}
                            </Text>
                          </Flex>
                        </MenuItemOption>
                      );
                    })}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
            )}
          </Stack>

          {props.type === NFT_TYPE.ERC_721 && (
            <Flex alignItems={"center"} justifyContent={"space-between"}>
              <Stack>
                <Flex>
                  <Title>{t("Free minting")}</Title>
                  <Tooltip
                    hasArrow
                    label={
                      <div>
                        <p>{t("Your NFT won't be minted on the blockchain")}.</p>
                        <p>{t("It will be stored for further minting by buyer")}.</p>
                      </div>
                    }
                    borderRadius="lg"
                    padding="6px"
                    placement="top"
                  >
                    <WarningIcon w={4} h={4} color={colors.primary} marginLeft="6px" />
                  </Tooltip>
                </Flex>
                <Description>{t("Buyer will pay gas fee for minting")}</Description>
              </Stack>
              <Switch size="md" isChecked={formik.values.isLazyMint} onChange={formik.handleChange} id="isLazyMint" />
            </Flex>
          )}

          <Stack>
            <Flex>
              <Title>{t("Royalties")}</Title>
              <Tooltip
                hasArrow
                label={
                  <div>
                    <p>
                      {t(
                        "Collect fee when user re-sells the item you created. This is deducted from the final sale price and paid to a payout address you choose. Maximum: 20%"
                      )}
                      .
                    </p>
                  </div>
                }
                borderRadius="lg"
                padding="6px"
                placement="top"
              >
                <WarningIcon w={4} h={4} color={colors.primary} marginLeft="6px" />
              </Tooltip>
            </Flex>
            <Box>
              <InputGroup>
                <Input
                  value={formik.values.royalties !== undefined ? formik.values.royalties : ""}
                  name="royalties"
                  type="number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.royalties && !!formik.touched.royalties}
                />
                <InputRightAddon children="%" />
              </InputGroup>
              {!!formik.errors.royalties && !!formik.touched.royalties && (
                <ErrorText>{formik.errors.royalties}</ErrorText>
              )}
            </Box>
          </Stack>

          {props.type === NFT_TYPE.ERC_1155 && (
            <Stack>
              <Title>{t("Number of copies")}</Title>
              <Description>{t("Amount of tokens")}</Description>
              <Box>
                <Input
                  value={formik.values.quantity}
                  name="quantity"
                  type="number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.quantity && !!formik.touched.quantity}
                />
                {!!formik.errors.quantity && !!formik.touched.quantity && (
                  <ErrorText>{formik.errors.quantity}</ErrorText>
                )}
              </Box>
            </Stack>
          )}

          <Stack spacing={4}>
            {formik.submitCount > 0 && !formik.isValid && (
              <HStack borderRadius={"xl"} padding={5} background={"red.50"} spacing={5}>
                <InfoOutlineIcon color="red.500" fontSize={32} />
                <Stack>
                  <Text fontSize={"sm"} fontWeight={700} color={"red.500"}>
                    {t("Validation errors")}
                  </Text>
                  <Text fontSize={"sm"} color={"red.500"}>
                    {t("Please fix the errors in fields above and try again")}.
                  </Text>
                </Stack>
              </HStack>
            )}

            <Button
              colorScheme={"blue"}
              width={"fit-content"}
              type="submit"
              disabled={formik.submitCount > 0 && !formik.isValid}
            >
              {t("Create Item")}
            </Button>
          </Stack>
        </Stack>
      </form>

      <CreateCollectionModal
        isVisible={isVisibleCreateCollectionModal}
        onClose={() => setIsVisibleCreateCollectionModal(false)}
        type={props.type}
        onComplete={onCompleteCreateCollection}
      />

      <CreateNftStepsModal
        isVisible={isVisibleCreateNftStepsModal}
        onClose={() => setIsVisibleCreateNftStepsModal(false)}
        type={props.type}
        formValues={formik.values}
        onComplete={onCompleteCreateNft}
      />

      <CreateNftSuccessModal
        isVisible={!!createdNft}
        onClose={() => {
          formik.resetForm();
          setCreatedNft(undefined);
        }}
        nft={createdNft}
        isLazy={formik.values.isLazyMint}
      />
    </AppContent>
  );
});

const Title = React.memo((props: React.PropsWithChildren<{}>) => {
  return (
    <Heading size={"sm"} color="rgb(53, 56, 64)">
      {props.children}
    </Heading>
  );
});

const Description = React.memo((props: React.PropsWithChildren<{}>) => {
  return (
    <Text color={"gray.500"} fontSize={"xs"}>
      {props.children}
    </Text>
  );
});

export default CreateAsset;
