import { Button, Flex, HStack, Image, Stack, Text } from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { I18N_NAMESPACE } from "../../../core/constants/i18n";
import { Quote } from "../../../core/models/quote";
import { formatNumber } from "../../../core/utils/number";
import { useAppSelector } from "../../../store/hook";

interface Props {
  bid: Quote | undefined;
  isCancelling: boolean;
  onCancelBid: () => void;
  onBid: () => void;
}

export const CurrentBid = React.memo(({ bid, isCancelling, onCancelBid, onBid }: Props) => {
  const { t } = useTranslation([I18N_NAMESPACE.ASSET_DETAIL]);
  const supportedTokens = useAppSelector((state) => state.token.supportedTokens);
  const bidToken = useMemo(
    () => (bid ? supportedTokens.byId[bid.make.assetType.contract] : null),
    [bid?.make?.assetType?.contract, supportedTokens.byId]
  );
  return (
    <Stack
      flexDir={{
        base: "column",
        md: "row",
        lg: "row",
        xl: "row",
        "2xl": "row",
      }}
      alignItems={{
        base: "flex-start",
        md: "center",
        lg: "center",
        xl: "center",
        "2xl": "center",
      }}
      justifyContent="space-between"
    >
      {bid && bidToken ? (
        <HStack alignItems={"center"}>
          <Text fontWeight={"500"}>{t("Your current bid")}:</Text>
          <Flex background="#f8f8f8" borderRadius="15px" padding="8px 16px" alignItems="center">
            <Image src={bidToken?.logoUrl} height="24px" width="24px" marginRight="8px" />
            <Text fontWeight="700" fontSize={"20px"}>
              <Text as="span">
                {formatNumber(new BigNumber(bid?.make.value || 0).div(Math.pow(10, bidToken.decimals)).toNumber())}
              </Text>
            </Text>
          </Flex>
        </HStack>
      ) : (
        <Text color={"gray.500"} fontSize="sm">
          {t("You haven't placed a bid for this nft")}
        </Text>
      )}
      {bid ? (
        <Button colorScheme="red" width="150px" onClick={onCancelBid} isLoading={isCancelling}>
          {t("Cancel bid")}
        </Button>
      ) : (
        <Button colorScheme="blue" width="150px" onClick={onBid}>
          {t("Place a bid")}
        </Button>
      )}
    </Stack>
  );
});
