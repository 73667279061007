import { chakra, Image } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { uriToHttps } from "../core/utils/ipfs";

interface Props {
  src: string | undefined;
  fallbackSources: string[];
  className?: string;
}

export const ImageWithMultipleFallback = chakra((props: Props) => {
  const [sourceUrl, setSourceUrl] = useState(props.src);
  const [errorTimes, setErrorTimes] = useState(0);

  useEffect(() => {
    setSourceUrl(props.src);
    setErrorTimes(0);
  }, [props.src, props.fallbackSources]);

  const onError = useCallback(
    (e) => {
      const fallbackSource = props.fallbackSources[errorTimes];
      if (fallbackSource) {
        setSourceUrl(fallbackSource);
        setErrorTimes((v) => v + 1);
      }
    },
    [props.fallbackSources, errorTimes, setSourceUrl, setErrorTimes]
  );

  return <Image className={props.className} src={sourceUrl} onError={onError} />;
});
