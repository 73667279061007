import { IQuoteAsset } from "../models/quote";
import Web3 from "web3";
import { Contract } from "@ethersproject/contracts";
import { store } from "../../store/";
import { utils } from "ethers";

export const encodeQuoteAsset = async (asset: IQuoteAsset, helperContract?: Contract | null) => {
  const web3 = new Web3(Web3.givenProvider);
  const supportedTokens = store.getState().token.supportedTokens;
  const abiCoder = new utils.AbiCoder();

  if (asset.assetType.assetClass === "ERC721" || asset.assetType.assetClass === "ERC1155") {
    return web3.eth.abi.encodeParameters(["address", "uint256"], [asset.assetType.contract, asset.assetType.tokenId]);
  } else if (asset.assetType.assetClass === "ERC721_LAZY") {
    // const t = abiCoder.encode(
    //   [
    //     "address",
    //     "tuple(uint256 tokenId, string tokenURI, tuple(address account, uint256 value)[] creators, tuple(address account, uint256 value)[] royalties, string[] signatures)",
    //   ],
    //   [
    //     asset.asset_type.token_address,
    //     {
    //       tokenId: asset.asset_type.token_id,
    //       tokenURI: asset.asset_type.token_uri,
    //       creators: asset.asset_type.creators,
    //       royalties: asset.asset_type.royalties,
    //       signatures: asset.asset_type.signatures,
    //     },
    //   ]
    // );

    return helperContract?.encode721(asset.assetType.contract, {
      tokenId: asset.assetType.tokenId,
      tokenURI: asset.assetType.uri,
      creators: asset.assetType.creators,
      royalties: asset.assetType.royalties,
      signatures: asset.assetType.signatures,
    });
  } else if (asset.assetType.assetClass === "ERC1155_LAZY") {
    return helperContract?.encode1155(asset.assetType.contract, {
      tokenId: asset.assetType.tokenId,
      tokenURI: asset.assetType.uri,
      creators: asset.assetType.creators,
      royalties: asset.assetType.royalties,
      supply: asset.assetType.supply,
      signatures: asset.assetType.signatures,
    });
  } else if (asset.assetType.assetClass === "ETH" || asset.assetType.assetClass === "ETH_PAY_4") {
    return "0x";
  } else {
    return web3.eth.abi.encodeParameter("address", asset.assetType.contract);
  }
};
