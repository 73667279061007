import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { shortenAddress, useEthers } from "@usedapp/core";
import BigNumber from "bignumber.js";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { colors } from "../../../core/constants/colors";
import { I18N_NAMESPACE } from "../../../core/constants/i18n";
import { NFT_TYPE } from "../../../core/constants/nft";
import { IMoralisNft, INftOwner, INftV2 } from "../../../core/models/nft";
import { Quote } from "../../../core/models/quote";
import { IUser } from "../../../core/models/user";
import { formatNumber } from "../../../core/utils/number";
import { useAppSelector } from "../../../store/hook";

interface Props {
  isLoading: boolean;
  owners: INftOwner[];
  quotes: Quote[];
  selectedQuote: Quote | undefined;
  onPurchase: (quote: Quote) => void;
  nft: INftV2 | undefined;
  onLoadMore: () => void;
  isLoadingMore: boolean;
  isEndOfList: boolean;
}

export const OwnersTab = React.memo(
  ({ owners, quotes, selectedQuote, nft, onPurchase, isEndOfList, isLoadingMore, onLoadMore, isLoading }: Props) => {
    const supportedTokens = useAppSelector((state) => state.token.supportedTokens);
    const { t } = useTranslation([I18N_NAMESPACE.ASSET_DETAIL]);
    const { account } = useEthers();
    const navigate = useNavigate();

    const onClickOwner = useCallback(
      (user: INftOwner) => () => {
        navigate(`/profile/assets/${user?.address}/minted`);
      },
      []
    );

    if (isLoading) {
      return (
        <Center marginTop={5}>
          <Spinner thickness="3px" speed="0.65s" emptyColor="gray.200" color={colors.primary} size="lg" />
        </Center>
      );
    }

    return (
      <Stack spacing={5}>
        {owners.map((owner) => {
          const quote = quotes.find((i) => i.maker === owner.address);
          const token = quote?.take.assetType.contract ? supportedTokens.byId[quote?.take.assetType.contract] : null;
          const isCurrentUser = owner.address === account?.toLowerCase();
          const remainingQuantity = parseInt(quote?.make.value || "0") - (quote?.fill || 0);
          const quantityForSale = Math.min(remainingQuantity, owner.supply);
          return (
            <HStack key={owner?.address} alignItems="center" justifyContent={"space-between"} spacing={6}>
              <Box>
                <HStack spacing={3} width={"fit-content"} onClick={onClickOwner(owner)} cursor={"pointer"}>
                  <Avatar src={owner?.image || ""} size={"sm"} />
                  <Box>
                    <Text fontSize={"sm"} fontWeight={500} _hover={{ color: "#000" }}>
                      {owner?.displayName || (owner?.address && shortenAddress(owner.address))}
                    </Text>
                    {nft?.contractType === NFT_TYPE.ERC_1155 && (
                      <Box marginTop={1}>
                        {quote ? (
                          <Text fontSize={"xs"} color={"gray.500"}>
                            {quantityForSale}/{owner.supply} {t("on sale for")}{" "}
                            <Text as="span" color={"#000"}>
                              {token?.decimals
                                ? formatNumber(
                                    new BigNumber(quote.take.value || 0)
                                      .div(Math.pow(10, token?.decimals))
                                      .div(quote.make.value)
                                      .multipliedBy(quantityForSale)
                                      .toNumber()
                                  )
                                : ""}{" "}
                              {token?.symbol}
                            </Text>{" "}
                            {t("all")}
                          </Text>
                        ) : (
                          <Text fontSize={"xs"} color={"gray.500"}>
                            {owner.supply} {t("editions")}{" "}
                            <Text as="span" color={"#000"}>
                              {t("not for sale")}
                            </Text>
                          </Text>
                        )}
                      </Box>
                    )}
                  </Box>
                </HStack>
              </Box>

              {!isCurrentUser && !!quote && nft?.contractType === NFT_TYPE.ERC_1155 && (
                <Box>
                  <Button
                    colorScheme={"blue"}
                    disabled={quote.hashKey === selectedQuote?.hashKey}
                    size={"sm"}
                    onClick={() => onPurchase(quote)}
                  >
                    {t("Buy")}
                  </Button>
                </Box>
              )}
            </HStack>
          );
        })}

        {!isEndOfList && (
          <Button colorScheme={"blue"} onClick={onLoadMore} isLoading={isLoadingMore}>
            {t("Load more")}
          </Button>
        )}
      </Stack>
    );
  }
);
