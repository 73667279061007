import Web3 from "web3";
import { EIP712 } from "../utils/EIP712";
import { Web3Provider } from "@ethersproject/providers";
import { ethers } from "ethers";

export const enc = ({ token, tokenId }: { token: string; tokenId?: string }) => {
  const web3 = new Web3(Web3.givenProvider);

  // return web3.eth.abi.encodeParameters(types, params);
  if (tokenId) {
    return web3.eth.abi.encodeParameters(["address", "uint256"], [token, tokenId]);
  } else {
    return web3.eth.abi.encodeParameter("address", token);
  }
};

export const generateTokenId = (tokenId: number, address: string) => {
  const pad = "00000000000000000000000";
  const padId = (pad + tokenId).slice(-pad.length);

  return Web3.utils.hexToNumberString(`${address}b${padId}`);
};

export async function signLazyERC721Nft(
  web3: Web3Provider,
  nft: {
    tokenId: string;
    tokenURI: string;
    creators: { account: string; value: number }[];
    royalties: { account: string; value: number }[];
  },
  account: string,
  verifyingContract: string
) {
  const chainId = Number(web3.network.chainId);

  const Types = {
    Payout: [
      { name: "account", type: "address" },
      { name: "value", type: "uint256" },
    ],
    Mint721: [
      { name: "tokenId", type: "uint256" },
      { name: "tokenURI", type: "string" },
      { name: "creators", type: "Payout[]" },
      { name: "royalties", type: "Payout[]" },
    ],
  };

  const data = EIP712.createTypeData(
    {
      name: "Mint721",
      version: "1",
      chainId,
      verifyingContract,
    },
    "Mint721",
    nft,
    Types
  );
  const signedTypedData = await EIP712.signTypedData(web3, account, data);
  return signedTypedData?.sig;
}

export async function signLazyERC1155Nft(
  web3: Web3Provider,
  nft: {
    tokenId: string;
    tokenURI: string;
    creators: { account: string; value: number }[];
    royalties: { account: string; value: number }[];
    supply: number;
  },
  account: string,
  verifyingContract: string
) {
  const chainId = Number(web3.network.chainId);

  const Types = {
    Payout: [
      { name: "account", type: "address" },
      { name: "value", type: "uint256" },
    ],
    Mint1155: [
      { name: "tokenId", type: "uint256" },
      { name: "supply", type: "uint256" },
      { name: "tokenURI", type: "string" },
      { name: "creators", type: "Payout[]" },
      { name: "royalties", type: "Payout[]" },
    ],
  };

  const data = EIP712.createTypeData(
    {
      name: "Mint1155",
      version: "1",
      chainId,
      verifyingContract,
    },
    "Mint1155",
    nft,
    Types
  );
  const signedTypedData = await EIP712.signTypedData(web3, account, data);
  return signedTypedData?.sig;
}

export const buildNftHashKey = (chain: string, contract: string, tokenId: string) => {
  return ethers.utils.keccak256(ethers.utils.toUtf8Bytes(`${chain}$${contract}$${tokenId}`));
};
