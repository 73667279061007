import { Quote } from "./quote";

export interface IOrder {
  amount: number;
  bToken: string;
  tokenId: string;
  tokenAddress: string;
  createdAt: number;
  id: number;
  paidAmount: number;
  payments?: IPayment[];
}

export interface IPayment {
  amount: number;
  createdAt: number;
  lateFee?: number;
}

export interface ISale {
  order_id: string;
  price: number;
  is_completed: boolean;
  buyer: string;
  seller: string;
  transaction_hash: string;
  asset_transfer: {
    token_address: string;
    token_id: string;
    token_name?: string;
    name?: string;
    symbol?: string;
  };
  payments: {
    [key: string]: {
      block_number: number;
      payment_amount: number;
      payment_method: number;
      transaction_hash: string;
      payment_date: number;
    };
  };
  quote: Quote;
  created_time: number;
  id: string;
  b_token_address: string;
}

export interface IOrderV2 {
  contractName: string;
  nftName: string;
  nftImage: string;
  createdAt: Date;
  buyer: string;
  total: string;
  currency: string;
  id: string;
  nftUri: string;
}

export enum ORDER_STATUS {
  ACTIVE = "ACTIVE",
  COMPLETED = "COMPLETED",
  LIQUIDATED = "LIQUIDATED",
}
export interface IPurchaseV2 {
  id: string;
  contractName: string;
  nftUri: string;
  nftName?: string;
  nftImage?: string;
  createdAt: string;
  buyer: string;
  total: string;
  currency: string;
  status: ORDER_STATUS;
  nextPaymentDate: string;
  dueDate: string;
  payments: {
    id: string;
    amount: number;
    createdAt: Date;
  }[];
  buyingQuote?: Quote;
}
