import React from "react";
import { Text } from "@chakra-ui/react";

export const ErrorText = React.memo((props: React.PropsWithChildren<{}>) => {
  return (
    <Text color={"red.400"} fontSize={"small"} marginTop={1}>
      {props.children}
    </Text>
  );
});
