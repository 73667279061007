import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { IoIosHeartEmpty, IoIosHeart } from "react-icons/io";
import {
  Tooltip,
  Avatar,
  Box,
  Button,
  Spacer,
  Flex,
  Center,
  VStack,
  Heading,
  HStack,
  IconButton,
  Stack,
  Text,
  Image,
  Skeleton,
  Circle,
} from "@chakra-ui/react";
import { shortenAddress, useEthers } from "@usedapp/core";
import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { NftResource } from "../../../components/NftResource";
import { I18N_NAMESPACE } from "../../../core/constants/i18n";
import { images } from "../../../core/constants/images";
import { MINT_TYPE } from "../../../core/constants/nft";
import { useRoyaltyInfo } from "../../../core/hooks/useRoyaltyInfo";
import { ICollection } from "../../../core/models/collection";
import { INft, INftMetadata, ISummaryNft } from "../../../core/models/nft";
import { IUser } from "../../../core/models/user";
import { uriToHttps } from "../../../core/utils/ipfs";
import { formatNumber } from "../../../core/utils/number";
import { collectionService } from "../../../services/collection.service";
import { userService } from "../../../services/user.service";
import { useAppSelector } from "../../../store/hook";
import { nftService } from "../../../services/nft.service";
import { colors } from "../../../core/constants/colors";
import { env } from "../../../core/environment";
import { buildNftHashKey } from "../../../core/utils/assets";

interface Props {
  nfts: ISummaryNft[];
}

const NextArrow = (props: any) => {
  return (
    <IconButton
      borderRadius={"full"}
      variant="outline"
      aria-label="Call Sage"
      fontSize="20px"
      top={"42%"}
      right={"-8px"}
      background={"#fff"}
      position="absolute"
      onClick={props.onClick}
      icon={<ChevronRightIcon fontSize="2xl" />}
    />
  );
};

const PrevArrow = (props: any) => {
  return (
    <IconButton
      borderRadius={"full"}
      variant="outline"
      aria-label="Call Sage"
      fontSize="20px"
      top={"42%"}
      left={"-8px"}
      background={"#fff"}
      position="absolute"
      zIndex={1}
      onClick={props.onClick}
      icon={<ChevronLeftIcon fontSize="2xl" />}
    />
  );
};

const FavoriteNft = ({ nft }: { nft: ISummaryNft }) => {
  const navigate = useNavigate();
  const { t } = useTranslation([I18N_NAMESPACE.DASHBOARD]);

  const appContext = useAppSelector((state) => state.appContext);
  const supportedTokens = useAppSelector((state: any) => state.token.supportedTokens);
  const token = useMemo(
    () => (nft.makeQuote?.takeAssetContract ? supportedTokens.byId[nft.makeQuote?.takeAssetContract] : null),
    [supportedTokens, nft.makeQuote?.takeAssetContract]
  );

  const [metadata, setMetata] = useState<INftMetadata>({
    image: nft.image,
    name: nft.name,
  });

  const hashKey = useMemo(
    () => buildNftHashKey(nft.chain, nft.contract, nft.tokenId),
    [nft.chain, nft.contract, nft.tokenId]
  );
  const fallbackSources: string[] = useMemo(
    () =>
      [`${env.config.storage_base_url}/nfts/${hashKey}_cover`, uriToHttps(metadata?.image)].filter(
        (i) => !!i
      ) as string[],
    [hashKey, metadata?.image]
  );

  const [numberOfFavorites, setNumberOfFavorites] = useState<number>(nft.numberOfFavorites ?? 0);
  const [isFavorited, setIsFavorited] = useState<boolean>(nft.favorited || false);

  const setFavorite = (value: boolean) => async () => {
    try {
      const response = await nftService.favorite({
        contract: nft.contract,
        tokenId: nft.tokenId,
        chain: appContext.selectedChain.name,
        favorited: value,
      });

      if (response.data) {
        setIsFavorited(value);
        setNumberOfFavorites(response.data.numberOfFavorites);
      }
    } catch (e) {}
  };

  const addFavorite = setFavorite(true);

  const removeFavorite = setFavorite(false);

  const onClick = useCallback(() => {
    navigate(`assets/${nft.contract}/${nft.tokenId}`);
  }, []);

  useEffect(() => {
    async function getMetadata() {
      if (nft.uri) {
        const uri = uriToHttps(nft.uri);
        if (!uri) return;

        const result = await axios.get(uri);
        setMetata(result.data);
      }
    }

    getMetadata();
  }, [nft.uri]);

  return (
    <Box padding={4}>
      <VStack
        padding={4}
        spacing={4}
        borderWidth={1}
        borderRadius="md"
        _hover={{ borderColor: "gray.300" }}
        align="left"
        justify="stretch"
        overflow="hidden"
      >
        {/* Creator & Collection Stack */}
        <HStack spacing={-2}>
          <Tooltip label={`${t("Collection")}: ${nft.contractName}`}>
            <Image
              boxSize={"32px"}
              borderRadius="full"
              fallbackSrc={images.collectionLogoDefault}
              src={nft.contractImage}
              onClick={(e: any) => {
                e.preventDefault();
                navigate(`/collections/${nft.contract}`);
              }}
              _hover={{ shadow: "md", transition: "all 0.1s ease 0s", transform: "translateY(-2px)", zIndex: 9999 }}
            />
          </Tooltip>

          <Tooltip
            label={`${t("Creator")}: ${
              nft.royaltyInfo?.displayName || (nft.royaltyInfo?.id && shortenAddress(nft.royaltyInfo.id))
            }`}
          >
            <Avatar
              size={"sm"}
              src={nft.royaltyInfo?.image || undefined}
              onClick={(e: any) => {
                e.preventDefault();
                navigate(`/profile/collections/${nft.royaltyInfo?.id}`);
              }}
              _hover={{ shadow: "md", transition: "all 0.1s ease 0s", transform: "translateY(-2px)", zIndex: 9999 }}
            />
          </Tooltip>
        </HStack>

        <Box flex={1} borderRadius="lg" overflow="hidden" onClick={onClick} cursor="pointer">
          <NftResource
            image={`${env.config.storage_base_url}/nfts/${hashKey}_thumb`}
            fallbackSources={fallbackSources}
            animationUrl={metadata.animation_url}
            muted
            maxW="480px"
            maxH="240px"
          />
        </Box>

        <Text fontWeight={700} fontSize="xl">
          {metadata.name}
        </Text>
        <HStack>
          <Text color={colors.primary} fontSize={"xl"} fontWeight={700}>
            {token?.decimals && nft.makeQuote?.takeAssetValue
              ? formatNumber(+nft.makeQuote.takeAssetValue / Math.pow(10, token?.decimals))
              : ""}{" "}
            {token?.symbol}
          </Text>
          <Spacer flex={1} />
          <HStack spacing={1}>
            <Box _hover={{ transform: "scale(1.2)", transition: "all 0.1s ease 0s" }}>
              {isFavorited ? (
                <IoIosHeart
                  onClick={(e) => {
                    e.stopPropagation();
                    removeFavorite();
                  }}
                  cursor={"pointer"}
                />
              ) : (
                <IoIosHeartEmpty
                  onClick={(e) => {
                    e.stopPropagation();
                    addFavorite();
                  }}
                  cursor={"pointer"}
                />
              )}
            </Box>
            {numberOfFavorites && numberOfFavorites > 0 && (
              <Text textColor="gray" fontSize={"md"}>
                {numberOfFavorites}
              </Text>
            )}
          </HStack>
        </HStack>
      </VStack>
    </Box>
  );
};

export const FeaturedNftSlider = React.memo((props: Props) => {
  return (
    <Slider
      infinite
      speed={500}
      autoplaySpeed={5000}
      slidesToShow={1}
      slidesToScroll={1}
      autoplay
      nextArrow={<NextArrow />}
      prevArrow={<PrevArrow />}
    >
      {props.nfts.map((nft) => (
        <FavoriteNft nft={nft} key={nft.tokenId} />
      ))}
    </Slider>
  );
});
