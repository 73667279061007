import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IToken } from "../../../core/models/token";

interface ITokenState {
  supportedTokens: {
    allIds: string[];
    byId: { [key: string]: IToken };
  };
}

const initialState: ITokenState = {
  supportedTokens: {
    allIds: [],
    byId: {},
  },
};

export const tokenSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setSupportedTokens: (state, action: PayloadAction<IToken[]>) => {
      state.supportedTokens.allIds = action.payload.map((i) => i.underlying);
      state.supportedTokens.byId = action.payload.reduce(
        (acc, cur) => ({ ...acc, [cur.underlying]: cur, [cur.underlying.toLowerCase()]: cur }),
        {}
      );
    },
  },
});

export const { setSupportedTokens } = tokenSlice.actions;

export default tokenSlice.reducer;
