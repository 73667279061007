import { CheckCircleIcon } from "@chakra-ui/icons";
import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { useEthers } from "@usedapp/core";
import BigNumber from "bignumber.js";
import { format } from "date-fns";
import { keys } from "lodash-es";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoCalendarClearOutline } from "react-icons/io5";
import { NftResource } from "../../../components/NftResource";
import { colors } from "../../../core/constants/colors";
import { I18N_NAMESPACE } from "../../../core/constants/i18n";
import { useNftMetadata } from "../../../core/hooks/data/nft";
import { IPurchaseV2 } from "../../../core/models/order";
import { formatNumber } from "../../../core/utils/number";
import { useAppSelector } from "../../../store/hook";
import { CompletedOrderDetailModal } from "./CompletedOrderDetailModal";

interface Props {
  order: IPurchaseV2;
}

export const CompletedOrder = React.memo((props: Props) => {
  const { chainId } = useEthers();
  const { t } = useTranslation([I18N_NAMESPACE.PROFILE]);
  const supportedTokens = useAppSelector((state) => state.token.supportedTokens);
  const token = useMemo(() => supportedTokens.byId[props.order.currency], [props.order.currency, supportedTokens]);

  const isPayInFull = useMemo(() => keys(props.order.payments).length === 1, [props.order.payments]);

  const [isVisibleDetail, setIsVisibleDetail] = useState(false);
  const { metadata } = useNftMetadata(props.order.nftUri);

  const onCloseDetail = () => {
    setIsVisibleDetail(false);
  };

  return (
    <>
      <Flex
        marginTop={"16px"}
        alignItems={"center"}
        borderWidth={1}
        padding={"12px 20px"}
        borderRadius={"xl"}
        gridGap={"20px"}
        cursor={"pointer"}
        onClick={() => setIsVisibleDetail(true)}
      >
        <Flex position={"relative"} height={"30px"} width={"30px"} alignItems={"center"} justifyContent={"center"}>
          <CheckCircleIcon color={colors.success} height="30px" width="30px" />
        </Flex>

        <Box width={"80px"} height={"80px"}>
          <NftResource image={metadata?.image} animationUrl={metadata?.animation_url} muted />
        </Box>

        <Box flex={1}>
          <Text fontWeight={"700"}>{metadata?.name}</Text>
          <Flex alignItems={"center"}>
            <Icon as={IoCalendarClearOutline} color={"#a6a6a6"} />
            <Text marginLeft={2} fontSize={"small"} fontWeight={500} color="gray.500">
              {props.order.createdAt && format(new Date(props.order.createdAt), "dd/MM/yyyy HH:mm")}
            </Text>
          </Flex>
          <Text fontSize={"sm"} color="gray.500">
            {t("Purchased for")}{" "}
            <Text fontWeight={700} fontSize={"md"} color={colors.primary} as="span">
              {formatNumber(new BigNumber(props.order.total).div(Math.pow(10, token.decimals)).toNumber())}{" "}
              {token.symbol}
            </Text>
          </Text>
        </Box>
      </Flex>
      <CompletedOrderDetailModal
        order={props.order}
        isVisible={isVisibleDetail}
        onClose={onCloseDetail}
        metadata={metadata}
        token={token}
      />
    </>
  );
});
