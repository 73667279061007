import { Box, Center, Grid, Spinner } from "@chakra-ui/react";
import { useEthers } from "@usedapp/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Collection } from "../../components/Collection";
import { colors } from "../../core/constants/colors";
import { I18N_NAMESPACE } from "../../core/constants/i18n";
import { ICollection } from "../../core/models/collection";
import { getChainNameById } from "../../core/utils/chain";
import { collectionService } from "../../services/collection.service";

const ProfileCollections = React.memo(() => {
  const { t } = useTranslation([I18N_NAMESPACE.PROFILE]);
  const { account, chainId } = useEthers();
  const params = useParams();
  const navigate = useNavigate();
  const [collections, setCollections] = useState<ICollection[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const isOwned = useMemo(
    () => !params.id || params.id === "owned" || params.id?.toLowerCase() === account?.toLowerCase(),
    [params.id, account]
  );
  const id = useMemo(() => (isOwned ? account : params.id), [isOwned, account, params.id]);

  useEffect(() => {
    async function getCollections() {
      if (!id) return;
      setIsLoading(true);
      const result = await collectionService.getCollectionsByAccount(getChainNameById(chainId), id);
      setCollections(result.data || []);
      setIsLoading(false);
    }

    getCollections();
  }, [id]);

  const onClickCollection = useCallback((collection: ICollection) => {
    navigate(`/collections/${collection.contract}`);
  }, []);

  return (
    <div>
      {isLoading ? (
        <Center>
          <Spinner thickness="5px" speed="0.65s" emptyColor="gray.200" color={colors.primary} size="xl" />
        </Center>
      ) : collections.length === 0 ? (
        <Box>
          <Center fontSize="20px" marginTop={"30px"} color={"rgba(4, 4, 5, 0.6)"}>
            {t("No collections found")}
          </Center>
        </Box>
      ) : (
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            lg: "repeat(2, 1fr)",
            xl: "repeat(3, 1fr)",
            "2xl": "repeat(4, 1fr)",
          }}
          gap={6}
        >
          {collections.map((collection) => (
            <Collection key={collection.contract} collection={collection} onClick={onClickCollection} />
          ))}
        </Grid>
      )}
    </div>
  );
});

export default ProfileCollections;
