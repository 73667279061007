import { CloseIcon, HamburgerIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
  Spacer,
  Spinner,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useEthers } from "@usedapp/core";
import BigNumber from "bignumber.js";
import { debounce } from "lodash-es";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import { Popover } from "react-tiny-popover";
import { colors } from "../../core/constants/colors";
import { I18N_NAMESPACE } from "../../core/constants/i18n";
import { images } from "../../core/constants/images";
import { env } from "../../core/environment";
import { INft, ISummaryNft } from "../../core/models/nft";
import { formatNumber } from "../../core/utils/number";
import { marketplaceService } from "../../services/marketplace.service";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { NftResource } from "../NftResource";
import { HeaderNavLink } from "./HeaderNavLink";
import { ProfilePopover } from "./ProfilePopover";
import { SearchNftItem } from "./SearchNftItem";

function AppHeader() {
  const { account, error, library } = useEthers();

  const navigate = useNavigate();
  const userProfile = useAppSelector((state) => state.user.profile);
  const { t, i18n } = useTranslation([I18N_NAMESPACE.APP_HEADER]);

  const navItems = [
    {
      route: "/marketplace",
      name: t("Marketplace"),
    },
    {
      route: `/profile/assets/owned/minted`,
      name: t("My NFTs"),
    },
    {
      route: "/profile/purchases",
      name: t("My Purchases"),
    },
  ];

  const languageItems = useMemo(
    () => [
      {
        value: "vi",
        name: "Tiếng Việt",
        icon: images.vnFlag,
      },
      {
        value: "en",
        name: "English",
        icon: images.auFlag,
      },
    ],
    []
  );

  const selectedLanguage = useMemo(
    () => languageItems.find((i) => i.value === i18n.resolvedLanguage),
    [languageItems, i18n.resolvedLanguage]
  );

  const onChangeLanguage = (value: string | string[]) => {
    if (typeof value !== "string") return;

    i18n.changeLanguage(value);
  };

  const SearchComponent = () => {
    const { onOpen: onOpenSearch, onClose: onCloseSearch, isOpen: isOpenSearch } = useDisclosure();
    const [searchText, setSearchText] = useState("");
    const [searchResult, setSearchResult] = useState<ISummaryNft[]>([]);
    const [isSearching, setIsSearching] = useState(false);

    const searchDebounce = useCallback(
      debounce(
        async (text: string) => {
          setIsSearching(true);
          const response = await marketplaceService.search({ q: text, limit: 10 });
          setSearchResult(response.data.result || []);
          setIsSearching(false);
        },
        300,
        { maxWait: 600 }
      ),
      []
    );

    const onSearchTextChange = useCallback(
      (e: any) => {
        const text = e.target.value as string;
        setSearchText(text);

        if (text.length > 0) {
          setIsSearching(true);
          onOpenSearch();
          searchDebounce(text);
        }
      },
      [onOpenSearch, searchDebounce]
    );

    const onClickNft = (nft: ISummaryNft) => {
      onCloseSearch();
      window.location.href = `/assets/${nft.contract}/${nft.tokenId}`;
    };

    const onDeleteSearchText = () => {
      setSearchText("");
      onCloseSearch();
      setSearchResult([]);
    };

    return (
      <Box flex={1} px={{ base: "20px", md: "30px" }}>
        <Box>
          <Popover
            isOpen={isOpenSearch && searchText.length > 0}
            onClickOutside={onCloseSearch}
            containerStyle={{ zIndex: "3", boxShadow: "rgb(4 4 5 / 20%) 0px 7px 36px", borderRadius: "12px" }}
            content={() => (
              <Stack
                background={"#fff"}
                borderRadius={{ base: "none", lg: "lg" }}
                padding={6}
                boxShadow={{ base: "sm", lg: "xl" }}
                w={{ base: "100vw", lg: "720px" }}
                spacing={4}
                maxHeight="80vh"
                overflow={"auto"}
              >
                {isSearching ? (
                  <Flex alignItems={"center"} justifyContent={"center"}>
                    <Spinner speed="0.65s" emptyColor="gray.200" color={colors.primary} />
                  </Flex>
                ) : searchResult.length > 0 ? (
                  searchResult.map((nft, index) => {
                    return (
                      <Fragment key={`${nft.contract}-${nft.tokenId}`}>
                        <SearchNftItem nft={nft} onClick={onClickNft} />
                        {index !== searchResult.length - 1 && <Divider />}
                      </Fragment>
                    );
                  })
                ) : (
                  <Box>
                    <Text fontWeight={"500"} color={"#707a83"}>
                      {t("No items found")}
                    </Text>
                  </Box>
                )}
              </Stack>
            )}
            positions={["bottom"]}
            align="start"
            padding={10}
          >
            <InputGroup maxWidth="720px">
              <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
              <Input
                placeholder={t("Search items")}
                background={"#fff"}
                value={searchText}
                onChange={onSearchTextChange}
              />
              {isOpenSearch && searchText.length > 0 && (
                <InputRightElement>
                  <CloseIcon
                    fontSize={"xs"}
                    color={"gray.500"}
                    cursor={"pointer"}
                    _hover={{ color: "#000" }}
                    onClick={onDeleteSearchText}
                  />
                </InputRightElement>
              )}
            </InputGroup>
          </Popover>
        </Box>
      </Box>
    );
  };

  const ConnectWalletButton = () => {
    const location = useLocation();
    return (
      <Button
        onClick={(e: any) => {
          e.preventDefault();
          navigate("/connect", { state: { from: location } });
        }}
        backgroundImage={
          "linear-gradient(90deg, rgba(167, 3, 178, 1) 0%, rgba(60, 52, 224, 1) 44%, rgba(1, 187, 246, 1) 100%)"
        }
        color="#fff"
        transition={"all 0.2s ease-in-out 0s"}
        backgroundSize={"150%"}
        _hover={{
          backgroundImage:
            "linear-gradient(90deg, rgba(167, 3, 178, 1) 0%, rgba(60, 52, 224, 1) 44%, rgba(1, 187, 246, 1) 100%)",
          backgroundPosition: "right center",
        }}
        _active={{
          transform: "scale(0.93)",
        }}
      >
        {t("Connect Wallet")}
      </Button>
    );
  };

  const LanguageMenuButton = () => (
    <Menu>
      <MenuButton
        paddingLeft="10px"
        as={IconButton}
        aria-label="Options"
        borderRadius={"full"}
        icon={<Image src={selectedLanguage?.icon} height={"20px"} borderRadius={"2px"} objectFit={"contain"} />}
        variant="none"
      />
      <Portal>
        <MenuList zIndex={3}>
          <MenuOptionGroup
            value={selectedLanguage?.value}
            title={t("Language")}
            type="radio"
            onChange={onChangeLanguage}
          >
            {languageItems.map((item) => (
              <MenuItemOption value={item.value} key={item.value}>
                <HStack>
                  <Image src={item.icon} height={"20px"} borderRadius={"2px"} objectFit={"contain"} />
                  <Text>{item.name}</Text>
                </HStack>
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Portal>
    </Menu>
  );

  const MobileMenuButton = () => (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            paddingLeft="10px"
            as={IconButton}
            aria-label="Menu"
            icon={isOpen ? <CloseIcon w={"16px"} h={"16px"} color={"gray.500"} /> : <HamburgerIcon w={6} h={6} />}
            variant="none"
          />
          <Portal>
            <Box
              hidden={!isOpen}
              bg={"blackAlpha.600"}
              w={"100vw"}
              h={"100vh"}
              position={"fixed"}
              top={0}
              left={0}
              zIndex={2}
            >
              <MenuList minW={"100vw"} rounded={0} mt={-1}>
                <MenuOptionGroup title={t("Navigate")} textColor={"gray.400"}>
                  {navItems.map((i) => (
                    <MenuItemOption key={i.route} h={12}>
                      <HeaderNavLink route={i.route} name={i.name} />
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>

                <MenuOptionGroup title={t("")} textColor={"gray.400"}>
                  <HStack py={4} px={8} align={"center"} justify={"stretch"}>
                    <Button
                      flexBasis={"50%"}
                      colorScheme={"blue"}
                      onClick={() => {
                        navigate("/create/start");
                      }}
                    >
                      {t("Create NFT")}
                    </Button>
                    <Box flex={1}>
                      <a href={env.config.lending_url} target={"_blank"} rel="noreferrer">
                        <Button w="100%" colorScheme={"purple"}>
                          {t("Lend")}
                        </Button>
                      </a>
                    </Box>
                  </HStack>
                </MenuOptionGroup>

                <MenuOptionGroup
                  value={selectedLanguage?.value}
                  title={t("Language")}
                  textColor={"gray.400"}
                  type="radio"
                  onChange={onChangeLanguage}
                >
                  {languageItems.map((item) => (
                    <MenuItemOption value={item.value} key={item.value} h={12}>
                      <HStack>
                        <Image src={item.icon} height={"20px"} borderRadius={"2px"} objectFit={"contain"} />
                        <Text>{item.name}</Text>
                      </HStack>
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
                {!account && (
                  <>
                    <Divider pt={6} />
                    <MenuOptionGroup>
                      <HStack px={8} py={6} w="100%" align="stretch" justify="stretch">
                        <Spacer flex={1} />
                        <ConnectWalletButton />
                        <Spacer flex={1} />
                      </HStack>
                    </MenuOptionGroup>
                  </>
                )}
              </MenuList>
            </Box>
          </Portal>
        </>
      )}
    </Menu>
  );

  const ProfilePopoverButton = () => {
    const { onOpen: onOpenProfile, onClose: onCloseProfile, isOpen: isOpenProfile } = useDisclosure();

    return (
      <ProfilePopover isVisible={isOpenProfile} onClickOutside={onCloseProfile}>
        <Flex
          borderRadius="full"
          w={{ base: "32px", md: "40px" }}
          h={{ base: "32px", md: "40px" }}
          alignItems="center"
          justifyContent="center"
          onClick={onOpenProfile}
          cursor={"pointer"}
        >
          <Avatar name="" height="100%" width="100%" size="2xl" src={userProfile?.image || ""} />
        </Flex>
      </ProfilePopover>
    );
  };

  const DesktopNav = () => (
    <Flex
      height="76px"
      boxShadow="md"
      px="20px"
      alignItems="center"
      position={"sticky"}
      top={0}
      zIndex={3}
      background={"#fff"}
    >
      <Link to="/">
        <Image src={images.logo} height="40px" width="40px" minH={"40px"} minW={"40px"} objectFit="contain" />
      </Link>

      <SearchComponent />

      <Grid gridAutoFlow="column" gridGap="20px">
        {navItems.map((i) => (
          <Center key={i.route}>
            <HeaderNavLink route={i.route} name={i.name} />
          </Center>
        ))}
      </Grid>

      <Box padding="20px" height="100%">
        <Divider orientation="vertical" />
      </Box>

      <Grid gridAutoFlow="column" gridGap="12px">
        <Button
          colorScheme={"blue"}
          onClick={() => {
            navigate("/create/start");
          }}
        >
          {t("Create NFT")}
        </Button>

        <a href={env.config.lending_url} target={"_blank"} rel="noreferrer">
          <Button colorScheme={"purple"}>{t("Lend")}</Button>
        </a>

        <Center>{account ? <ProfilePopoverButton /> : <ConnectWalletButton />}</Center>

        <LanguageMenuButton />
      </Grid>
    </Flex>
  );

  const MobileNav = () => (
    <Flex
      height="58px"
      boxShadow="md"
      px="20px"
      alignItems="center"
      position={"sticky"}
      top={0}
      zIndex={3}
      background={"#fff"}
    >
      <Link to="/">
        <Image src={images.logo} height="32px" width="32px" minH={"32px"} minW={"32px"} objectFit="contain" />
      </Link>

      <SearchComponent />

      {account && <Center>{account && <ProfilePopoverButton />}</Center>}
      <MobileMenuButton />
    </Flex>
  );

  const isDesktop = useBreakpointValue({ base: false, lg: true });

  return isDesktop ? <DesktopNav /> : <MobileNav />;
}

export default React.memo(AppHeader);
