import { Box, Center, Flex, Grid } from "@chakra-ui/layout";
import { Spinner, Stack } from "@chakra-ui/react";
import { useEthers } from "@usedapp/core";
import { uniqBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppSelect, SelectItem } from "../../components/AppSelect";
import { NftItem } from "../../components/NftItem";
import { colors } from "../../core/constants/colors";
import { I18N_NAMESPACE } from "../../core/constants/i18n";
import { INft, ISummaryNft } from "../../core/models/nft";
import { nftService } from "../../services/nft.service";
import { useAppSelector } from "../../store/hook";

function LazyMintedAssets() {
  const { account } = useEthers();
  const { t } = useTranslation([I18N_NAMESPACE.PROFILE]);
  const params = useParams();
  const navigate = useNavigate();

  const appContext = useAppSelector((state) => state.appContext);

  const orderByOptions = useMemo(
    () => [
      { value: "none", label: t("None") },
      { value: "price-asc", label: t("Price: Low to High") },
      { value: "price-desc", label: t("Price: High to Low") },
      // { value: "date-desc", label: "Recently Listed" },
    ],
    [t]
  );

  const [selectedCollectionAddress, setSelectedCollectionAddress] = useState<SelectItem>();
  const [orderBy, setOrderBy] = useState<SelectItem | undefined>(orderByOptions.find((o) => o.value === "price-desc"));
  const [nfts, setNfts] = useState<ISummaryNft[]>([]);
  const [isFetchingListedNfts, setIsFetchingListedNfts] = useState(false);

  const isOwned = useMemo(
    () => !params.id || params.id === "owned" || params.id?.toLowerCase() === account?.toLowerCase(),
    [params.id, account]
  );
  const id = useMemo(() => (isOwned ? account : params.id), [isOwned, account, params.id]);

  const filteredNfts = useMemo(() => {
    return nfts
      .filter(
        (i) =>
          !selectedCollectionAddress ||
          selectedCollectionAddress.value === "all" ||
          i.contract === selectedCollectionAddress.value
      )
      .sort((a, b) => {
        if (!orderBy?.value) return 0;

        const aListedInfo = nfts.find((i) => i.contract === a.contract && i.tokenId === a.tokenId);
        const bListedInfo = nfts.find((i) => i.contract === b.contract && i.tokenId === b.tokenId);

        // TODO: sort by price
        // if (orderBy.value === "price-asc") {
        //   return (aListedInfo?.price || 0) - (bListedInfo?.price || 0);
        // } else if (orderBy.value === "price-desc") {
        //   return (bListedInfo?.price || 0) - (aListedInfo?.price || 0);
        // } else if (orderBy.value === "date-desc") {
        //   return 0;
        // } else {
        //   return 0;
        // }

        return 0;
      });
  }, [selectedCollectionAddress, nfts, orderBy, nfts]);

  const collections = useMemo(
    () =>
      uniqBy(nfts, (nft) => nft.contract).map((i) => ({
        value: i.contract,
        label: i.contractName || "",
      })),
    [nfts]
  );

  useEffect(() => {
    async function getListedNfts() {
      if (!id) return;
      setIsFetchingListedNfts(true);
      const response = await nftService.getLazyNtfsByAccount(appContext.selectedChain.name, id, {
        offset: 0,
        limit: 10000,
      });
      setNfts(response.data.result || []);
      setIsFetchingListedNfts(false);
    }

    if (id) {
      getListedNfts();
    }
  }, [id]);

  return (
    <div>
      <Stack
        direction={{ base: "column", md: "row" }}
        justifyContent={{ base: "stretch", md: "flex-end" }}
        marginBottom={8}
        gap={{ base: 3, md: 6 }}
      >
        <Box minW="200px">
          <AppSelect
            options={[{ value: "all", label: t("All") }, ...collections]}
            value={selectedCollectionAddress}
            placeholder={t("Collections")}
            onChange={setSelectedCollectionAddress}
          />
        </Box>
        <Box minW="200px">
          <AppSelect options={orderByOptions} value={orderBy} placeholder={t('"Sort By"')} onChange={setOrderBy} />
        </Box>
      </Stack>
      {isFetchingListedNfts ? (
        <Flex alignItems="center" justifyContent="center" marginTop="40px">
          <Spinner thickness="5px" speed="0.65s" emptyColor="gray.200" color={colors.primary} size="xl" />
        </Flex>
      ) : filteredNfts.length === 0 ? (
        <Box>
          <Center fontSize="20px" marginTop={"30px"} color={"rgba(4, 4, 5, 0.6)"}>
            {t("No NFTs found")}
          </Center>
        </Box>
      ) : (
        <>
          <Grid
            templateColumns={{
              base: "repeat(1, 1fr)",
              lg: "repeat(2, 1fr)",
              xl: "repeat(3, 1fr)",
              "2xl": "repeat(4, 1fr)",
            }}
            gap={6}
          >
            {filteredNfts.map((nft) => {
              return (
                <NftItem
                  key={`${nft.contract}-${nft.tokenId}-${nft.owner}`}
                  takeAssetContract={nft?.makeQuote?.takeAssetContract}
                  takeAssetValue={parseFloat(nft?.makeQuote?.takeAssetValue || "0")}
                  isListed={!!nft?.makeQuote}
                  tokenAddress={nft.contract}
                  tokenId={nft.tokenId}
                  tokenUri={nft.uri}
                  collectionName={nft.contractName}
                  image={nft?.image}
                  nftName={nft?.name}
                  ownerOf={nft.owner}
                  favorites={nft.numberOfFavorites}
                  isFavorite={nft.favorited}
                  chain={nft.chain}
                />
              );
            })}
          </Grid>
        </>
      )}
    </div>
  );
}

export default React.memo(LazyMintedAssets);
