import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Fade,
  Grid,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
  Skeleton,
  SkeletonCircle,
  Stack,
  Text,
} from "@chakra-ui/react";
import { shortenAddress } from "@usedapp/core";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { colors } from "../../../core/constants/colors";
import { I18N_NAMESPACE } from "../../../core/constants/i18n";
import { ITopUser, IUser } from "../../../core/models/user";
import { formatNumber } from "../../../core/utils/number";

interface Props {
  users: ITopUser[];
  isLoading: boolean;
  dateOptions: { label: string; value: string }[];
  selectedDateOption: string;
  onChangeDateOption: (value: string) => void;

  userOptions: { label: string; value: string }[];
  selectedUserOption: string;
  onChangeUserOption: (value: string) => void;
}

export const TopUsers = React.memo((props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation([I18N_NAMESPACE.DASHBOARD]);
  const onClickUser = useCallback(
    (user: ITopUser) => () => {
      navigate(`profile/assets/${user.id}/minted`);
    },
    []
  );
  const selectionDateOption = useMemo(
    () => props.dateOptions.find((o) => o.value === props.selectedDateOption),
    [props.dateOptions, props.selectedDateOption]
  );
  const selectionUserOption = useMemo(
    () => props.userOptions.find((o) => o.value === props.selectedUserOption),
    [props.userOptions, props.selectedUserOption]
  );

  return (
    <Stack spacing={5}>
      <Text fontSize={"2xl"} fontWeight={700}>
        {t("Top")}{" "}
        <Menu>
          <MenuButton color={colors.primary}>
            <Text as={"span"} fontWeight={700}>
              {selectionUserOption?.label}
            </Text>{" "}
            <ChevronDownIcon />
          </MenuButton>
          <Portal>
            <MenuList minWidth="240px">
              <MenuOptionGroup
                onChange={(value) => typeof value === "string" && props.onChangeUserOption(value)}
                value={props.selectedUserOption}
                type="radio"
              >
                {props.userOptions.map((o) => (
                  <MenuItemOption key={o.value} value={o.value}>
                    {o.label}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Portal>
        </Menu>{" "}
        {t("over last")}{" "}
        <Menu>
          <MenuButton color={colors.primary}>
            <Text as={"span"} fontWeight={700}>
              {selectionDateOption?.label}
            </Text>{" "}
            <ChevronDownIcon />
          </MenuButton>
          <Portal>
            <MenuList minWidth="240px">
              <MenuOptionGroup
                onChange={(value) => typeof value === "string" && props.onChangeDateOption(value)}
                value={props.selectedDateOption}
                type="radio"
              >
                {props.dateOptions.map((o) => (
                  <MenuItemOption key={o.value} value={o.value}>
                    {o.label}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Portal>
        </Menu>
      </Text>
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(3, 1fr)",
          xl: "repeat(4, 1fr)",
          "2xl": "repeat(5, 1fr)",
        }}
        gap={6}
      >
        {props.isLoading
          ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i, index) => (
              <Fade in={props.isLoading} key={`user-skeletion-${i}`}>
                <Box width={"full"} height={14}>
                  <Skeleton height={"full"} width={"full"} borderRadius="lg" />
                </Box>
              </Fade>
            ))
          : props.users.map((user, index) => (
              <Fade in={!props.isLoading} key={user.id}>
                <Grid
                  gridTemplateColumns={"24px auto 1fr"}
                  alignItems={"center"}
                  onClick={onClickUser(user)}
                  cursor="pointer"
                >
                  <Text fontWeight={500} color={"gray.500"} fontSize="sm">
                    {index + 1}.
                  </Text>
                  <Avatar src={user.image || undefined} />
                  <Box marginLeft={3}>
                    <Text fontWeight={700} fontSize="sm">
                      {user.displayName || (user.id && shortenAddress(user.id))}
                    </Text>
                    <Text fontWeight={500} color="gray.500" fontSize="sm">
                      ${formatNumber(user?.totalInUsd || 0, { maximumFractionDigits: 2 })}
                    </Text>
                  </Box>
                </Grid>
              </Fade>
            ))}
      </Grid>
    </Stack>
  );
});
