export enum SELLER_STATUS {
  NOT_VERIFY = "NOT_VERIFY",
  SUBMITTED = "SUBMITTED",
  VERIFYING ="VERIFYING",
  VERIFIED = "VERIFIED",
  REJECTED = "REJECTED",
}

export enum CREDIT_STATUS {
  NOT_VERIFY = "NOT_VERIFY",
  SUBMITTED = "SUBMITTED",
  VERIFYING = "VERIFYING",
  VERIFIED = "VERIFIED",
  REJECTED = "REJECTED",
}
