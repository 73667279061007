import { Web3Provider } from "@ethersproject/providers";

const DOMAIN_TYPE = [
  {
    type: "string",
    name: "name",
  },
  {
    type: "string",
    name: "version",
  },
  {
    type: "uint256",
    name: "chainId",
  },
  {
    type: "address",
    name: "verifyingContract",
  },
];

export const EIP712 = {
  createTypeData: function (domainData: any, primaryType: any, message: any, types: any) {
    return {
      types: Object.assign(
        {
          EIP712Domain: DOMAIN_TYPE,
        },
        types
      ),
      domain: domainData,
      primaryType: primaryType,
      message: message,
    };
  },

  signTypedData: function (web3: Web3Provider, from: string, data: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      function cb(err: any, result: any) {
        if (err) {
          return reject(err);
        }
        if (result.error) {
          return reject(result.error);
        }

        const sig = result.result;
        const sig0 = sig.substring(2);
        const r = "0x" + sig0.substring(0, 64);
        const s = "0x" + sig0.substring(64, 128);
        const v = parseInt(sig0.substring(128, 130), 16);

        resolve({
          data,
          sig,
          v,
          r,
          s,
        });
      }

      if (web3.provider.isMetaMask) {
        if (web3.provider?.sendAsync) {
          web3?.provider?.sendAsync(
            {
              // jsonrpc: "2.0",
              method: "eth_signTypedData_v4",
              params: [from, JSON.stringify(data)],
              // id: new Date().getTime(),
            },
            cb
          );
        }
      } else {
        let send = web3.provider.sendAsync;
        if (!send) send = web3.provider.send;
        if (send?.bind) {
          send.bind(web3?.provider)(
            {
              // jsonrpc: "2.0",
              method: "eth_signTypedData",
              params: [from, data],
              // id: new Date().getTime(),
            },
            cb
          );
        }
      }
    });
  },
};
