import axios from "axios";
import useSWR from "swr";
import { INftMetadata } from "../../models/nft";
import { uriToHttps } from "../../utils/ipfs";

const fetcher = (url: string) => axios.get(url).then((res) => res.data);

export const useNftMetadata = (uri: string | undefined) => {
  const { data, error } = useSWR(uriToHttps(uri), fetcher, { shouldRetryOnError: false });

  return {
    metadata: data as INftMetadata | undefined,
    isLoading: !error && !data,
    error,
  };
};
