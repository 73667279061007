import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEthers } from "@usedapp/core";
import BigNumber from "bignumber.js";
import { ethers, utils } from "ethers";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProgressIcon } from "../../../../components/ProgressIcon";
import { colors } from "../../../../core/constants/colors";
import { I18N_NAMESPACE } from "../../../../core/constants/i18n";
import { NFT_TYPE } from "../../../../core/constants/nft";
import { PAYMENT_STRATEGY } from "../../../../core/constants/paymentStrategy";
import { LAYER_1_TOKENS } from "../../../../core/constants/token";
import { useERC20ProxyAddress, useMarketplaceAddress, useNftProxyAddress } from "../../../../core/hooks/useAddress";
import {
  useERC20Contract,
  useERC721Contract,
  useHelperContract,
  useMarketplaceContract,
} from "../../../../core/hooks/useContract";
import { INftV2 } from "../../../../core/models/nft";
import { Quote, signQuote } from "../../../../core/models/quote";
import { IToken } from "../../../../core/models/token";
import { getSalt } from "../../../../core/utils/getSalt";
import { toPlainString } from "../../../../core/utils/number";
import { quoteService } from "../../../../services/quote.service";
import { useAppSelector } from "../../../../store/hook";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onSuccess: () => void;
  quote: Quote;
  token: IToken;
  nft: INftV2;
  paymentStrategy: PAYMENT_STRATEGY | null;
  price: number;
  quantity: number;
}

enum STEP {
  APPROVE_TOKEN,
  PURCHASE,
  APPROVE_NFT,
  DONE,
}

export const PurchaseProcessModal = React.memo((props: Props) => {
  const { account, library } = useEthers();
  const { t } = useTranslation([I18N_NAMESPACE.ASSET_DETAIL]);
  const [step, setStep] = useState(STEP.APPROVE_TOKEN);
  const marketPlaceAddress = useMarketplaceAddress();
  const nftTransferProxyAddress = useNftProxyAddress();
  const marketplaceContract = useMarketplaceContract();
  const erc20ProxyAddress = useERC20ProxyAddress();
  const appContext = useAppSelector((state) => state.appContext);

  const helperContract = useHelperContract();

  const tokenContract = useERC20Contract(props.token.underlying);
  const collectionContract = useERC721Contract(props?.quote.make.assetType.contract);

  const [isPurchasing, setIsPurchasing] = useState(false);
  const [isApprovingNft, setIsApprovingNft] = useState(false);
  const [isApprovingToken, setIsApprovingToken] = useState(false);

  const [errorByStep, setErrorByStep] = useState<{ [key: number]: string | null }>({});

  useEffect(() => {
    if (!props.isVisible) return;

    const executionByStep = {
      [STEP.APPROVE_TOKEN]: approveToken,
      [STEP.PURCHASE]: purchase,
      [STEP.APPROVE_NFT]: approveNft,
      [STEP.DONE]: props.onSuccess,
    };

    const execution = executionByStep[step];
    if (execution) {
      execution();
    }
  }, [step, props.isVisible]);

  const approveToken = async () => {
    try {
      setIsApprovingToken(true);
      setErrorByStep((v) => ({ ...v, [STEP.APPROVE_TOKEN]: null }));
      if (props.token.isNative) return setStep(STEP.PURCHASE);

      const allowance = await tokenContract?.allowance(account, erc20ProxyAddress);
      const allowanceInNumber = +utils.formatUnits(allowance, props.token.decimals);

      const regularPrice = new BigNumber(props.price).div(Math.pow(10, props.token.decimals)).toNumber();

      if (!allowanceInNumber || allowanceInNumber < regularPrice) {
        const tx = await tokenContract?.approve(erc20ProxyAddress, ethers.constants.MaxUint256);
        await tx.wait();

        const allowance = await tokenContract?.allowance(account, erc20ProxyAddress);
        const allowanceInNumber = +utils.formatUnits(allowance, props.token.decimals);

        if (allowanceInNumber < regularPrice) {
          return setErrorByStep((v) => ({
            ...v,
            [STEP.APPROVE_TOKEN]: t("Approval amount is not enough to buy this NFT"),
          }));
        }
      }

      setStep(STEP.PURCHASE);
    } catch (e: any) {
      setErrorByStep((v) => ({ ...v, [STEP.APPROVE_TOKEN]: e?.data?.message || e?.message }));
    } finally {
      setIsApprovingToken(false);
    }
  };

  const getIsApproved = async () => {
    const isApprovedItem =
      props.nft.contractType === NFT_TYPE.ERC_721 && props.nft.minted
        ? await collectionContract?.getApproved(props.nft.tokenId)
        : ethers.constants.AddressZero;

    const isApprovedForAll = await collectionContract?.isApprovedForAll(account, nftTransferProxyAddress);

    return isApprovedForAll || isApprovedItem === nftTransferProxyAddress;
  };

  const approveNft = async () => {
    try {
      setIsApprovingNft(true);
      setErrorByStep((v) => ({ ...v, [STEP.APPROVE_NFT]: null }));
      const isApproved = await getIsApproved();
      if (!isApproved) {
        const tx = await collectionContract?.setApprovalForAll(nftTransferProxyAddress, true);
        await tx.wait();
      }

      setStep(STEP.DONE);
    } catch (e: any) {
      setErrorByStep((v) => ({ ...v, [STEP.APPROVE_NFT]: e?.data?.message || e?.message }));
    } finally {
      setIsApprovingNft(false);
    }
  };

  const purchase = async () => {
    try {
      if (!account || !library || typeof props.paymentStrategy !== "number") return;

      setIsPurchasing(true);
      setErrorByStep((v) => ({ ...v, [STEP.PURCHASE]: null }));

      const quote: Quote = {
        taker: props.quote.maker,
        take: {
          value: toPlainString(props.quantity),
          assetType: props.quote.make.assetType,
        },
        maker: account,
        make: {
          value: toPlainString(props.price),
          assetType: {
            ...props.quote.take.assetType,
            assetClass:
              props.paymentStrategy === PAYMENT_STRATEGY.IN_FOUR
                ? `${props.quote.take.assetType.assetClass}_PAY_4`
                : props.quote.take.assetType.assetClass,
          },
        },
        salt: props.paymentStrategy === PAYMENT_STRATEGY.IN_FOUR ? getSalt() : "0",
        type: "V1",
        data: {
          isMakeFill: false,
          originFees: toPlainString(0),
          payouts: [],
          dataType: "V1",
        },
        signature: "0x",
      };

      const onChainSellerQuote = await Quote.toOnChainQuote(props.quote, helperContract);
      const onChainBuyerQuote = await Quote.toOnChainQuote(quote, helperContract);

      const buyerSignature =
        props.paymentStrategy === PAYMENT_STRATEGY.IN_FOUR
          ? await signQuote(library, onChainBuyerQuote, account, marketPlaceAddress)
          : "0x";

      const approverSignature =
        props.paymentStrategy === PAYMENT_STRATEGY.IN_FOUR
          ? (
              await quoteService.create({
                ...quote,
                signature: buyerSignature,
                chain: appContext.selectedChain.name,
              })
            )?.data.approverSignature
          : "0x";

      const price = props.paymentStrategy === PAYMENT_STRATEGY.IN_FOUR ? Math.ceil(props.price / 4) : props.price;

      const tx = await marketplaceContract?.matchQuotes(
        onChainSellerQuote,
        { ...onChainBuyerQuote, approverSignature, makerSignature: buyerSignature },
        {
          value: LAYER_1_TOKENS.includes(props.token.underlying) ? toPlainString(price) : 0,
        }
      );
      await tx.wait();

      if (props.paymentStrategy === PAYMENT_STRATEGY.IN_FOUR) {
        setStep(STEP.APPROVE_NFT);
      } else {
        setStep(STEP.DONE);
      }
    } catch (e: any) {
      setErrorByStep((v) => ({ ...v, [STEP.PURCHASE]: e?.response?.data?.error || e?.data?.message || e?.message }));
    } finally {
      setIsPurchasing(false);
    }
  };

  return (
    <Modal isOpen={props.isVisible} onClose={props.onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("Purchase Process")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <HStack alignItems={"flex-start"} borderRadius="lg" background="#F6F6F6" padding="20px 16px">
              <ProgressIcon
                isLoading={step === STEP.APPROVE_TOKEN && isApprovingToken}
                isSuccess={step > STEP.APPROVE_TOKEN}
              />

              <Box>
                <Text fontWeight="700" color={colors.primary2}>
                  {t("Approve")}
                </Text>
                <Text fontSize="14px" color="#000" marginTop="8px">
                  {t("Allow the market to spend your")} {props.token.symbol}.
                </Text>

                {errorByStep[STEP.APPROVE_TOKEN] ? (
                  <Box marginTop="20px">
                    <Text color={"red.500"} fontSize={"md"}>
                      {errorByStep[STEP.APPROVE_TOKEN]}
                    </Text>
                    <Button colorScheme="blue" onClick={approveToken} marginTop={2}>
                      {t("Try again")}
                    </Button>
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </HStack>

            <HStack alignItems={"flex-start"} borderRadius="lg" background="#F6F6F6" padding="20px 16px">
              <ProgressIcon isLoading={step === STEP.PURCHASE && isPurchasing} isSuccess={step > STEP.PURCHASE} />
              <Box>
                <Text fontWeight="700" color={colors.primary2}>
                  {t("Purchase")}
                </Text>
                <Text fontSize="14px" color="#000" marginTop="8px">
                  {t("Once purchased, it should be confirmed on the blockchain shortly")}.
                </Text>
                {errorByStep[STEP.PURCHASE] ? (
                  <Box marginTop="20px">
                    <Text color={"red.500"} fontSize={"md"}>
                      {errorByStep[STEP.PURCHASE]}
                    </Text>
                    <Button colorScheme="blue" onClick={purchase} marginTop={2}>
                      {t("Try again")}
                    </Button>
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </HStack>

            {props.paymentStrategy === PAYMENT_STRATEGY.IN_FOUR && (
              <HStack borderRadius="lg" background="#F6F6F6" padding="20px 16px" alignItems={"flex-start"}>
                <ProgressIcon
                  isLoading={step === STEP.APPROVE_NFT && isApprovingNft}
                  isSuccess={step > STEP.APPROVE_NFT}
                />
                <Box>
                  <Text fontWeight="700" color={colors.primary2}>
                    {t("Operator Permission")}
                  </Text>
                  <Text fontSize="14px" color="#000" marginTop="8px">
                    {t("Allow the market to operate NFT until your purchase is fully paid", {
                      name: props.nft.metadata?.name,
                    })}
                  </Text>
                  {errorByStep[STEP.APPROVE_NFT] ? (
                    <Box marginTop="20px">
                      <Text color={"red.500"} fontSize={"md"}>
                        {errorByStep[STEP.APPROVE_NFT]}
                      </Text>
                      <Button colorScheme="blue" onClick={approveNft} marginTop={2}>
                        {t("Try again")}
                      </Button>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              </HStack>
            )}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});
