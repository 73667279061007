import { ViewIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Divider,
  Flex,
  Grid,
  Heading,
  HStack,
  Image,
  Skeleton,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { shortenAddress, useEthers } from "@usedapp/core";
import axios from "axios";
import { startOfDay } from "date-fns";
import { isSameDay } from "date-fns/esm";
import { maxBy, minBy, orderBy, sumBy } from "lodash";
import { sortBy } from "lodash-es";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppContent } from "../../components/AppContent";
import { SharedButton } from "../../components/SharedButton";
import { I18N_NAMESPACE } from "../../core/constants/i18n";
import { images } from "../../core/constants/images";
import { useHelperContract, useMarketplaceContract } from "../../core/hooks/useContract";
import { useRoyaltyInfo } from "../../core/hooks/useRoyaltyInfo";
import { INftActivity, INftOwner, INftQuote, INftSale, INftV2 } from "../../core/models/nft";
import { Quote } from "../../core/models/quote";
import { IUser } from "../../core/models/user";
import { uriToHttps } from "../../core/utils/ipfs";
import { nftService } from "../../services/nft.service";
import { userService } from "../../services/user.service";
import { useAppSelector } from "../../store/hook";
import { AcceptBidFormModal } from "./components/AcceptBidFormModal";
import { ActivitiesInfo } from "./components/ActivitiesInfo";
import { AssetPrimaryInfo } from "./components/AssetPrimaryInfo";
import { AssetSecondaryInfo } from "./components/AssetSecondaryInfo";
import { BidInfo } from "./components/BidInfo";
import { CurrentBid } from "./components/CurrentBid";
import { DelistState } from "./components/DelistState";
import { FavoriteNftDetail } from "./components/FavoriteNftDetail";
import { ListingState } from "./components/ListingState";
import { OwnersTab } from "./components/OwnersTab";
import { PlaceBidFormModal } from "./components/PlaceBidFormModal";
import { PurchaseFormModal } from "./components/PurchaseFormModal";
import { PurchaseState } from "./components/PurchaseState";
import { SaleHistoryChart } from "./components/SaleHistoryChart";
import { ISaleStatisticPerDay } from "./models/sale";

const AssetDetail = React.memo(() => {
  const { tokenAddress } = useParams<"tokenAddress">();
  const { tokenId } = useParams<"tokenId">();
  const { account, chainId, library } = useEthers();
  const { t } = useTranslation([I18N_NAMESPACE.ASSET_DETAIL]);
  const navigate = useNavigate();
  const appContext = useAppSelector((state) => state.appContext);

  const royaltyInfo = useRoyaltyInfo(tokenAddress, tokenId);

  const [selectedBid, setSelectedBid] = useState<Quote>();
  const [makeQuotes, setMakeQuotes] = useState<INftQuote[]>([]);
  const [takeQuotes, setTakeQuotes] = useState<INftQuote[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [royalty, setRoyalty] = useState<{ user: IUser; value: number }>();

  const [owners, setOwners] = useState<INftOwner[]>([]);
  const [ownerMetadata, setOwnerMetadata] = useState<{ limit: number; cursor?: string }>({ limit: 6 });
  const [isLoadingMoreOwners, setIsLoadingMoreOwners] = useState(false);
  const [isEndOfOwnersList, setIsEndOfOwnersList] = useState(false);

  const [activities, setActivities] = useState<INftActivity[]>([]);
  const [activityMetadata, setActivityMetadata] = useState<{ limit: number; cursor?: string }>({ limit: 6 });
  const [isLoadingMoreActivities, setIsLoadingMoreActivities] = useState(false);
  const [isEndOfActivitiesList, setIsEndOfActivitiesList] = useState(false);

  const [isFetchedActivities, setIsFetchedActivities] = useState(false);
  const [priceHistory, setPriceHistory] = useState<ISaleStatisticPerDay[]>([]);
  const [isFetchedPriceHistory, setIsFetchedPriceHistory] = useState(false);

  const [isFetchingSales, setIsFetchingSales] = useState<boolean>(true);
  const [isFetchingActivities, setIsFetchingActivities] = useState<boolean>(true);
  const [isFetchingOwners, setIsFetchingOwners] = useState<boolean>(true);

  const [views, setViews] = useState(0);

  const [nft, setNft] = useState<INftV2>();

  const isOwner = useMemo(() => (nft?.accountBalance || 0) > 0, [nft?.accountBalance]);

  const [isCancelling, setIsCancelling] = useState(false);

  const helperContract = useHelperContract();
  const marketplaceContract = useMarketplaceContract();

  const [isVisiblePurchaseModal, setIsVisiblePurchaseModal] = useState<boolean>(false);
  const [isVisibleBidModal, setIsVisibleBidModal] = useState<boolean>(false);
  const [isVisibleAcceptBid, setIsVisibleAcceptBid] = useState(false);

  const highestBid = useMemo(
    () =>
      maxBy<INftQuote>(
        takeQuotes.filter((q) => q.maker.toLowerCase() !== account?.toLowerCase()),
        (i) => (i.priceInUsd ? +i.priceInUsd / +i.take.value : 0)
      ),
    [takeQuotes, account]
  );

  const currentBid = useMemo(
    () => takeQuotes.find((q) => account && q.maker.toLowerCase() === account?.toLowerCase()),
    [takeQuotes, account]
  );

  const [buyingQuote, setBuyingQuote] = useState<Quote>();
  const buyingQuoteOwner = useMemo(
    () => owners.find((o) => o.address?.toLowerCase() === buyingQuote?.maker.toLowerCase()),
    [owners, buyingQuote?.maker]
  );

  const displayQuote = useMemo(() => {
    const quoteByUser = makeQuotes.find((i) => i.maker.toLowerCase() === account?.toLowerCase());

    if (quoteByUser) {
      return quoteByUser;
    } else if (!isOwner) {
      const cheapest = minBy<INftQuote>(makeQuotes, (i) => (i.priceInUsd ? +i.priceInUsd / +i.make.value : 0));
      return cheapest;
    } else {
      return undefined;
    }
  }, [account, makeQuotes, isOwner]);

  const getNftDetail = async (abortSignal?: AbortSignal) => {
    try {
      if (!tokenAddress || !tokenId) return;

      setIsLoading(true);
      const nftResponse = await nftService.getNftDetail(appContext.selectedChain.name, tokenAddress, tokenId, {
        params: {
          account: account?.toLowerCase(),
        },
        signal: abortSignal,
      });
      const uri = nftResponse.data.uri ? uriToHttps(nftResponse.data.uri) : undefined;
      const metadataResponse = uri ? await axios.get(uri) : undefined;

      // Set quotes
      setMakeQuotes(nftResponse.data?.quotes?.filter((q) => q.data?.isMakeFill) || []);
      setTakeQuotes(
        orderBy(
          nftResponse.data?.quotes?.filter((q) => !q.data?.isMakeFill) || [],
          [
            (i) => (i.createdAt ? new Date(i.createdAt).valueOf() : 0),
            (i) => (i.priceInUsd ? +i.priceInUsd / +i.take.value : 0),
          ],
          ["desc", "asc"]
        )
      );

      setNft({
        ...nftResponse.data,
        metadata: metadataResponse?.data,
      });
      setViews(nftResponse.data.numberOfViews);

      setIsLoading(false);
      setIsFetchingOwners(true);

      const ownerPromise = nftResponse.data.minted
        ? nftService.getOwners(appContext.selectedChain.name, tokenAddress, tokenId, {
            signal: abortSignal,
            params: { limit: ownerMetadata.limit },
          })
        : undefined;

      const royalty =
        !nftResponse.data.minted && nftResponse.data?.royalties && nftResponse.data?.royalties?.length > 0
          ? nftResponse.data?.royalties[0]
          : undefined;
      const creatorPromise = royalty ? userService.getProfileById(royalty.account, { signal: abortSignal }) : undefined;

      const [ownerResponse, creatorResponse] = await Promise.all([ownerPromise, creatorPromise]);

      // Set owners
      if (!nftResponse.data.minted) {
        setOwners([{ ...nftResponse.data.owner, supply: 1 }]);
      } else {
        setOwners(ownerResponse?.data.result || []);
        setOwnerMetadata({ limit: 6, cursor: ownerResponse?.data?.cursor });
        setIsEndOfOwnersList((ownerResponse?.data.result || []).length < ownerMetadata.limit);
      }

      if (creatorResponse && royalty) {
        setRoyalty({ user: creatorResponse.data, value: royalty.value / 100 });
      }
      setIsFetchingOwners(false);
    } catch (e) {
      if (!axios.isCancel(e)) {
        setIsLoading(false);
        setIsFetchingOwners(false);
      }
    }
  };

  const loadMoreOwners = async () => {
    try {
      if (!tokenAddress || !tokenId) return;

      setIsLoadingMoreOwners(true);

      const response = await nftService.getOwners(appContext.selectedChain.name, tokenAddress, tokenId, {
        params: {
          limit: ownerMetadata.limit,
          cursor: ownerMetadata.cursor,
        },
      });

      setOwners((v) => [...v, ...(response.data.result || [])]);
      setOwnerMetadata({ limit: 6, cursor: response.data.cursor });
      setIsLoadingMoreOwners(false);
      setIsEndOfOwnersList((response?.data.result || []).length < ownerMetadata.limit);
    } catch (e) {}
  };

  const getActivities = async () => {
    try {
      if (!tokenAddress || !tokenId || isFetchedActivities) return;
      setIsFetchingActivities(true);

      const response = await nftService.getActivities(appContext.selectedChain.name, tokenAddress, tokenId, {
        limit: activityMetadata.limit,
        cursor: activityMetadata.cursor,
      });

      setActivities(response.data.result || []);
      setActivityMetadata({ limit: 6, cursor: response.data.cursor });
      setIsEndOfActivitiesList((response?.data.result || []).length < activityMetadata.limit);
      setIsFetchedActivities(true);
      setIsFetchingActivities(false);
    } catch (e) {}
  };

  const loadMoreActivities = async () => {
    try {
      if (!tokenAddress || !tokenId) return;

      setIsLoadingMoreActivities(true);

      const response = await nftService.getActivities(appContext.selectedChain.name, tokenAddress, tokenId, {
        limit: activityMetadata.limit,
        cursor: activityMetadata.cursor,
      });

      setActivities((v) => [...v, ...(response.data.result || [])]);
      setActivityMetadata({ limit: 10, cursor: response.data.cursor });
      setIsEndOfActivitiesList((response?.data.result || []).length < activityMetadata.limit);
      setIsLoadingMoreActivities(false);
      setIsFetchedActivities(true);
    } catch (e) {}
  };

  const getSales = async () => {
    try {
      if (!tokenAddress || !tokenId || isFetchedPriceHistory) return;
      setIsFetchingSales(true);
      const response = await nftService.getSales(appContext.selectedChain.name, tokenAddress, tokenId);

      const saleStatisticByDay = (response.data || []).reduce(
        (acc: ISaleStatisticPerDay[], cur: INftSale, _: number, sales: INftSale[]) => {
          if (acc.some((i) => isSameDay(i.date, startOfDay(new Date(cur.timestamp)))) || !cur.timestamp) {
            return acc;
          }

          const salesInDay = sales.filter((s) =>
            isSameDay(startOfDay(new Date(s.timestamp)), startOfDay(new Date(cur.timestamp)))
          );
          return [
            ...acc,
            {
              date: startOfDay(new Date(cur.timestamp)),
              price: sumBy(salesInDay, (i) => +i.priceInUsd) / salesInDay.length,
              noOfSales: salesInDay.length,
            },
          ];
        },
        []
      );

      setPriceHistory(sortBy(saleStatisticByDay, (i) => i.date));
      setIsFetchingSales(false);
      setIsFetchedPriceHistory(true);
    } catch (e) {}
  };

  const view = async () => {
    if (!tokenAddress || !tokenId) return;
    const response = await nftService.view({
      chain: appContext.selectedChain.name,
      contract: tokenAddress,
      tokenId: tokenId,
    });
  };

  useEffect(() => {
    view();
  }, [tokenAddress, tokenId]);

  useEffect(() => {
    const abortController = new AbortController();
    getNftDetail(abortController.signal);

    return () => {
      abortController.abort();
    };
  }, [account]);

  useEffect(() => {
    async function getCreator() {
      if (!royaltyInfo?.receiver) return;
      const creatorResponse = await userService.getProfileById(royaltyInfo.receiver);
      setRoyalty({ user: creatorResponse.data, value: royaltyInfo?.royaltyPercent });
    }

    getCreator();
  }, [royaltyInfo?.receiver]);

  const onPurchaseSuccess = async () => {
    getNftDetail();
  };

  const onListingSuccess = () => {
    getNftDetail();
  };

  const closePlaceBidModal = () => setIsVisibleBidModal(false);

  const onBiddingSuccess = () => {
    closePlaceBidModal();
    getNftDetail();
  };

  const closeAcceptBidModal = () => setIsVisibleAcceptBid(false);

  const onAcceptBid = (bid: INftQuote) => {
    setSelectedBid(bid);
    setIsVisibleAcceptBid(true);
  };

  const onAcceptBidSuccess = () => {
    closeAcceptBidModal();
    getNftDetail();
  };

  const onDelistSuccess = () => {
    getNftDetail();
  };

  const onCancelBid = async () => {
    try {
      if (!currentBid) return;
      setIsCancelling(true);
      const onChainQuote = await Quote.toOnChainQuote(currentBid, helperContract);
      const tx = await marketplaceContract?.cancel(onChainQuote);
      await tx.wait();

      getNftDetail();
      setIsCancelling(false);
    } catch (e) {
      setIsCancelling(false);
    }
  };

  const onPurchase = async (quote: Quote) => {
    setBuyingQuote(quote);
    setIsVisiblePurchaseModal(true);
  };

  const onBid = useCallback(() => {
    setIsVisibleBidModal(true);
  }, []);

  const renderForm = () => {
    const isMyQuote = displayQuote?.maker.toLowerCase() === account?.toLowerCase();

    if (isMyQuote && isOwner && displayQuote) {
      return (
        <DelistState
          quote={displayQuote}
          onSuccess={onDelistSuccess}
          nft={nft}
          highestBid={highestBid}
          onAcceptBid={onAcceptBid}
        />
      );
    } else if (!isMyQuote && displayQuote) {
      return (
        <PurchaseState
          quote={displayQuote}
          onPurchase={onPurchase}
          onBid={onBid}
          onCancelBid={onCancelBid}
          highestBid={highestBid}
          currentBid={currentBid}
          isCancelling={isCancelling}
          nft={nft}
        />
      );
    } else if (isOwner) {
      return (
        <ListingState nft={nft} onListSuccess={onListingSuccess} highestBid={highestBid} onAcceptBid={onAcceptBid} />
      );
    } else {
      return <CurrentBid bid={currentBid} isCancelling={isCancelling} onCancelBid={onCancelBid} onBid={onBid} />;
    }
  };

  const onPhoneLayout = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <AppContent maxWidth="1100px">
        <Grid gridTemplateColumns={{ base: "1fr", md: "1fr 1.3fr" }} gridGap="40px">
          {/* NFT Box */}
          <Box>
            <Skeleton isLoaded={!isLoading}>
              <AssetPrimaryInfo nft={nft} maxMediaHeight={onPhoneLayout ? "240px" : "400px"} />
            </Skeleton>
            <Skeleton display={{ base: "none", md: "initial" }} isLoaded={!isLoading}>
              <Divider />
              <Box my={6}>
                <AssetSecondaryInfo nft={nft} />
              </Box>
            </Skeleton>
          </Box>

          {/* Detail Box */}
          <Box>
            <Box>
              <Skeleton isLoaded={!isLoading}>
                <Flex mt={onPhoneLayout ? -8 : 0} justifyContent={"space-between"}>
                  <Heading size="lg" color="#14233D">
                    {nft?.metadata?.name}
                  </Heading>
                  <SharedButton content={`Checkout ${nft?.metadata?.name} collectible on @bePAY`} />
                </Flex>
              </Skeleton>

              <Skeleton isLoaded={!isLoading}>
                <Flex marginTop={2} marginBottom={6}>
                  <Wrap spacing={"50px"}>
                    <WrapItem>
                      <Stack>
                        <HStack>
                          <ViewIcon />
                          <Text fontSize={"sm"} fontWeight={500}>
                            {t("view", { count: views })}
                          </Text>
                        </HStack>
                      </Stack>
                    </WrapItem>
                    <WrapItem>
                      <FavoriteNftDetail nft={nft} />
                    </WrapItem>
                  </Wrap>
                </Flex>
              </Skeleton>

              <Skeleton isLoaded={!isLoading}>
                <Flex marginTop={6}>
                  {!!royalty && (
                    <Stack flex={1}>
                      <Text fontSize={"sm"} color={"gray.500"} fontWeight={500}>
                        {t("Creator")}{" "}
                        <Text as="span">
                          ({royalty.value}% {t("royalties")})
                        </Text>
                      </Text>
                      <HStack
                        cursor={"pointer"}
                        onClick={() => navigate(`/profile/assets/${royalty?.user?.id}/minted`)}
                      >
                        <Avatar src={royalty?.user?.image || ""} size={"sm"} />
                        <Text fontSize={"md"} fontWeight={500}>
                          {royalty?.user?.displayName || (royalty?.user?.id && shortenAddress(royalty.user.id))}
                        </Text>
                      </HStack>
                    </Stack>
                  )}
                  <Stack flex={1}>
                    <Text fontSize={"sm"} color={"gray.500"} fontWeight={500}>
                      {t("Collection")}
                    </Text>
                    <HStack cursor={"pointer"} onClick={() => navigate(`/collections/${tokenAddress}`)}>
                      <Image
                        borderRadius={"full"}
                        src={nft?.contractImage || ""}
                        objectFit="cover"
                        boxSize={"32px"}
                        fallbackSrc={images.collectionLogoDefault}
                      />
                      <Text fontSize={"md"} fontWeight={500}>
                        {nft?.contractName}
                      </Text>
                    </HStack>
                  </Stack>
                </Flex>
              </Skeleton>
            </Box>

            <Box padding="24px 0px">
              <Divider />
            </Box>

            <Skeleton isLoaded={!isLoading}>{renderForm()}</Skeleton>

            <Skeleton isLoaded={!isLoading} display={{ base: "initial", md: "none" }}>
              <Divider my={6} />
              <Box my={6}>
                <AssetSecondaryInfo nft={nft} />
              </Box>
            </Skeleton>

            <Skeleton isLoaded={!isLoading}>
              <Tabs mt={8} variant="enclosed" colorScheme="blue" isLazy>
                <TabList>
                  <Tab fontWeight={700}>{t("Owners")}</Tab>
                  <Tab fontWeight={700}>{t("Bid")}</Tab>
                  <Tab fontWeight={700} onClick={getActivities}>
                    {t("Provenance")}
                  </Tab>
                  <Tab fontWeight={700} onClick={getSales}>
                    {t("Price history")}
                  </Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <OwnersTab
                      isLoading={isFetchingOwners}
                      owners={owners}
                      quotes={makeQuotes}
                      selectedQuote={displayQuote}
                      nft={nft}
                      onPurchase={onPurchase}
                      isEndOfList={isEndOfOwnersList}
                      isLoadingMore={isLoadingMoreOwners}
                      onLoadMore={loadMoreOwners}
                    />
                  </TabPanel>
                  <TabPanel>
                    <BidInfo bids={takeQuotes} onAcceptBid={onAcceptBid} isOwner={isOwner} nft={nft} />
                  </TabPanel>
                  <TabPanel>
                    <ActivitiesInfo
                      isLoading={isFetchingActivities}
                      activities={activities}
                      onLoadMore={loadMoreActivities}
                      isLoadingMore={isLoadingMoreActivities}
                      isEndOfList={isEndOfActivitiesList}
                    />
                  </TabPanel>
                  <TabPanel>
                    <SaleHistoryChart data={priceHistory} isLoading={isFetchingSales} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Skeleton>
          </Box>
        </Grid>
      </AppContent>
      {!!nft && !!buyingQuote && (
        <PurchaseFormModal
          isVisible={isVisiblePurchaseModal}
          onClose={() => {
            setIsVisiblePurchaseModal(false);
          }}
          nft={nft}
          quote={buyingQuote}
          quoteOwner={buyingQuoteOwner}
          onPurchaseSuccess={onPurchaseSuccess}
        />
      )}
      {!!nft && (
        <PlaceBidFormModal
          nft={nft}
          isVisible={isVisibleBidModal}
          onClose={closePlaceBidModal}
          onBiddingSuccess={onBiddingSuccess}
        />
      )}
      {!!nft && !!selectedBid && (
        <AcceptBidFormModal
          nft={nft}
          bid={selectedBid}
          isVisible={isVisibleAcceptBid}
          onClose={closeAcceptBidModal}
          onSuccess={onAcceptBidSuccess}
        />
      )}
    </>
  );
});

export default AssetDetail;
