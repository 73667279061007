import { Box, Flex, HStack } from "@chakra-ui/layout";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/modal";
import { Button, Image, Text } from "@chakra-ui/react";
import { useEthers } from "@usedapp/core";
import BigNumber from "bignumber.js";
import { ethers, utils } from "ethers";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NftResource } from "../../../../components/NftResource";
import { ProgressIcon } from "../../../../components/ProgressIcon";
import { colors } from "../../../../core/constants/colors";
import { I18N_NAMESPACE } from "../../../../core/constants/i18n";
import { APPROVE_STRATEGY } from "../../../../core/constants/nft";
import { useERC20ProxyAddress, useMarketplaceAddress, useNftProxyAddress } from "../../../../core/hooks/useAddress";
import { useERC20Contract, useERC721Contract, useHelperContract } from "../../../../core/hooks/useContract";
import { INftV2 } from "../../../../core/models/nft";
import { Quote, signQuote } from "../../../../core/models/quote";
import { IToken } from "../../../../core/models/token";
import { getSalt } from "../../../../core/utils/getSalt";
import { formatNumber, toPlainString } from "../../../../core/utils/number";
import { quoteService } from "../../../../services/quote.service";
import { useAppSelector } from "../../../../store/hook";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onSuccess: () => void;
  nft: INftV2;
  price: number;
  token: IToken;
  quantity: number;
}

enum STEP {
  APPROVE_TOKEN,
  BID,
}

export const BiddingProcessModal = React.memo((props: Props) => {
  const { account, library } = useEthers();
  const [approveStrategy, setApproveStrategy] = useState(APPROVE_STRATEGY.ONLY_ITEM);
  const collectionContract = useERC721Contract(props?.nft.contract);
  const nftTransferProxyAddress = useNftProxyAddress();
  const { t } = useTranslation([I18N_NAMESPACE.ASSET_DETAIL]);

  const erc20ProxyAddress = useERC20ProxyAddress();

  const marketplaceAddress = useMarketplaceAddress();
  const helperContract = useHelperContract();
  const tokenContract = useERC20Contract(props.token.underlying);

  const appContext = useAppSelector((state) => state.appContext);

  const [step, setStep] = useState(STEP.APPROVE_TOKEN);

  const [isApproving, setIsApproving] = useState(false);
  const [isListing, setIsListing] = useState(false);

  const [errorByStep, setErrorByStep] = useState<{ [key: number]: string | null }>({});

  const approveToken = async () => {
    try {
      setIsApproving(true);
      setErrorByStep((v) => ({ ...v, [STEP.APPROVE_TOKEN]: null }));
      if (props.token.isNative) return setStep(STEP.BID);

      const allowance = await tokenContract?.allowance(account, erc20ProxyAddress);
      const allowanceInNumber = +utils.formatUnits(allowance, props.token.decimals);

      if (!allowanceInNumber || allowanceInNumber < props.price) {
        const tx = await tokenContract?.approve(erc20ProxyAddress, ethers.constants.MaxUint256);
        await tx.wait();

        const allowance = await tokenContract?.allowance(account, erc20ProxyAddress);
        const allowanceInNumber = +utils.formatUnits(allowance, props.token.decimals);

        if (allowanceInNumber < props.price) {
          return setErrorByStep((v) => ({
            ...v,
            [STEP.APPROVE_TOKEN]: t("Approval amount is not enough to buy this NFT"),
          }));
        }
      }

      setStep(STEP.BID);
    } catch (e: any) {
      setErrorByStep((v) => ({ ...v, [STEP.APPROVE_TOKEN]: e?.data?.message || e?.message }));
    } finally {
      setIsApproving(false);
    }
  };

  const list = async () => {
    try {
      setIsListing(true);
      setErrorByStep((v) => ({ ...v, [STEP.BID]: null }));
      const { price, quantity, token, nft } = props;
      if (!price || !account || !library || !nft?.contract) return;

      const priceBN = new BigNumber(price);
      const quote: Quote = {
        maker: account.toLowerCase(),
        salt: getSalt(),
        make: {
          assetType: {
            assetClass: token.isNative ? "ETH" : "ERC20",
            contract: token.isNative ? "0x" : token.underlying.toLowerCase(),
          },
          value: toPlainString(
            Math.floor(priceBN.multipliedBy(Math.pow(10, token.decimals)).multipliedBy(quantity).toNumber())
          ),
        },

        taker: ethers.constants.AddressZero,
        take: {
          assetType: {
            assetClass: !nft.minted ? `${nft?.contractType || "ERC721"}_LAZY` : nft?.contractType || "ERC721",
            tokenId: nft?.tokenId,
            contract: nft?.contract.toLowerCase(),
            ...(!nft.minted && {
              creators: nft.creators,
              royalties: nft.royalties,
              uri: nft.uri,
              signatures: nft.signatures,
              supply: quantity,
            }),
          },
          value: toPlainString(Math.floor(quantity)),
        },
        type: "V1",
        data: {
          isMakeFill: false,
          payouts: [],
          originFees: toPlainString(0),
          dataType: "V1",
        },
        signature: "0x",
      };

      const onChainQuote = await Quote.toOnChainQuote(quote, helperContract);
      const signature = await signQuote(library, onChainQuote, account, marketplaceAddress);

      await quoteService.create({
        ...quote,
        signature,
        chain: appContext.selectedChain.name,
      });

      props.onSuccess();
    } catch (e: any) {
      setErrorByStep((v) => ({ ...v, [STEP.BID]: e?.response?.data?.error || e?.data?.message || e?.message }));
    } finally {
      setIsListing(false);
    }
  };

  useEffect(() => {
    if (!props.isVisible) {
      resetState();
      return;
    }

    const executionByStep = {
      [STEP.APPROVE_TOKEN]: approveToken,
      [STEP.BID]: list,
    };

    const execution = executionByStep[step];
    if (execution) {
      execution();
    }
  }, [props.isVisible, step]);

  const resetState = () => {
    setStep(STEP.APPROVE_TOKEN);
    setErrorByStep({});
  };

  return (
    <>
      <Modal isOpen={props.isVisible} onClose={props.onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("Bid Process")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex justifyContent="center" alignItems="center">
              <Box borderWidth="1px" borderRadius="lg" height="80px" width="80px">
                <NftResource image={props.nft.metadata?.image} animationUrl={props.nft.metadata?.animation_url} muted />
              </Box>

              <Flex flex="1" marginLeft="12px" justifyContent="space-between">
                <Box>
                  <Box fontSize={"small"}>{props.nft?.contractName}</Box>
                  <Text color={colors.primary2} fontWeight="700" fontSize="20px">
                    {props.nft?.metadata?.name}
                  </Text>
                </Box>

                <Flex flexDirection="column" alignItems="flex-end">
                  <Text color={colors.primary2} fontWeight="500">
                    {t("Price")}
                  </Text>
                  <Flex marginTop="4px">
                    <Image src={props.token?.logoUrl} height="20px" width="20px" marginRight="4px" />
                    <Text fontWeight="700" color={colors.primary}>
                      {formatNumber(props.price * props.quantity)}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>

            <HStack
              alignItems={"flex-start"}
              marginTop="20px"
              borderRadius="lg"
              background="#F6F6F6"
              padding="20px 16px"
            >
              <ProgressIcon
                isLoading={step === STEP.APPROVE_TOKEN && isApproving}
                isSuccess={step > STEP.APPROVE_TOKEN}
              />

              <Box>
                <Text fontWeight="700" color={colors.primary2}>
                  {t("Approve")}
                </Text>
                <Text fontSize="14px" color="#000" marginTop="8px">
                  {t("Allow the market to spend your")} {props.token.symbol}.
                </Text>

                {errorByStep[STEP.APPROVE_TOKEN] ? (
                  <Box marginTop="20px">
                    <Text color={"red.500"} fontSize={"md"}>
                      {errorByStep[STEP.APPROVE_TOKEN]}
                    </Text>
                    <Button colorScheme="blue" onClick={approveToken} marginTop={2}>
                      {t("Try again")}
                    </Button>
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </HStack>

            <HStack alignItems="flex-start" borderRadius="lg" marginTop="20px" background="#F6F6F6" padding="20px 16px">
              <ProgressIcon isLoading={step === STEP.BID && isListing} isSuccess={false} />
              <Box flex={1}>
                <Text fontWeight="700" color={colors.primary2}>
                  {t("Bid")}
                </Text>
                <Text fontSize="14px" color="#000" marginTop="8px">
                  {t("Bid: Sign your wallet to confirm item price")}.
                </Text>
                <Box marginTop="20px">
                  {!!errorByStep[STEP.BID] && (
                    <Box>
                      <Text color={"red.500"} fontSize={"md"}>
                        {errorByStep[STEP.BID]}
                      </Text>
                      <Button colorScheme="blue" isLoading={isListing} onClick={list} marginTop={2}>
                        {t("Try again")}
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
});
