import { Button, Flex, HStack, Image, Stack, Text } from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { I18N_NAMESPACE } from "../../../core/constants/i18n";
import { NFT_TYPE } from "../../../core/constants/nft";
import { useHelperContract, useMarketplaceContract } from "../../../core/hooks/useContract";
import { INftQuote, INftV2 } from "../../../core/models/nft";
import { Quote } from "../../../core/models/quote";
import { formatNumber } from "../../../core/utils/number";
import { useAppSelector } from "../../../store/hook";
import { HighestBid } from "./HighestBid";

interface Props {
  quote: Quote;
  nft: INftV2 | undefined;
  highestBid: INftQuote | undefined;
  onSuccess: () => void;
  onAcceptBid: (bid: INftQuote) => void;
}

export const DelistState = React.memo(({ quote, nft, highestBid, onSuccess, onAcceptBid }: Props) => {
  const supportedTokens = useAppSelector((state) => state.token.supportedTokens);
  const makeQuoteToken = useMemo(
    () => supportedTokens.byId[quote.take.assetType.contract],
    [quote.take.assetType.contract, supportedTokens.byId]
  );

  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation([I18N_NAMESPACE.ASSET_DETAIL]);

  const helperContract = useHelperContract();
  const marketplaceContract = useMarketplaceContract();

  const remainingQuantity = useMemo(
    () => parseInt(quote?.make.value || "0") - (quote?.fill || 0),
    [quote.make.value, quote.fill]
  );

  const pricePerCopy = useMemo(
    () => new BigNumber(quote?.take.value).div(quote.make.value).toNumber(),
    [quote.take.value, quote.make.value]
  );

  const totalPrice = useMemo(
    () =>
      !!quote?.take?.value
        ? new BigNumber(pricePerCopy)
            .multipliedBy(remainingQuantity)
            .div(Math.pow(10, makeQuoteToken.decimals))
            .toNumber()
        : 0,
    [quote.take.value, makeQuoteToken.decimals, remainingQuantity, pricePerCopy]
  );

  const onCancelListing = async () => {
    try {
      setIsLoading(true);
      const onChainQuote = await Quote.toOnChainQuote(quote, helperContract);
      const tx = await marketplaceContract?.cancel(onChainQuote);
      await tx.wait();

      onSuccess();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  return (
    <Stack spacing={10}>
      <Stack
        flexDir={{
          base: "column",
          md: "row",
          lg: "row",
          xl: "row",
          "2xl": "row",
        }}
        alignItems={{
          base: "flex-start",
          md: "center",
          lg: "center",
          xl: "center",
          "2xl": "center",
        }}
        justifyContent="space-between"
      >
        <HStack alignItems={"center"}>
          <Text fontWeight={"500"}>{t("Price")}:</Text>
          <Flex>
            <Flex background="#f8f8f8" borderRadius="15px" padding="8px 16px" alignItems="center">
              <Image src={makeQuoteToken?.logoUrl} height="24px" width="24px" marginRight="8px" />
              <Text fontWeight="700" fontSize={"20px"}>
                <Text as="span">{formatNumber(totalPrice)}</Text>
              </Text>
            </Flex>
          </Flex>
          {nft?.contractType === NFT_TYPE.ERC_1155 && (
            <Text fontSize={"sm"} color={"gray.500"}>
              {t("for")} {t("copy", { count: remainingQuantity })}
            </Text>
          )}
        </HStack>
        <Button colorScheme="red" width="150px" onClick={onCancelListing} isLoading={isLoading}>
          {t("Delist")}
        </Button>
      </Stack>

      {!!highestBid && <HighestBid bid={highestBid} onAcceptBid={onAcceptBid} />}
    </Stack>
  );
});
