import { Box, Flex, Spinner } from "@chakra-ui/react";
import { ChainId, useEthers } from "@usedapp/core";
import { signInAnonymously } from "firebase/auth";
import { get } from "lodash-es";
import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router";
import { AppFooter } from "../components/AppFooter";
import AppHeader from "../components/AppHeader";
import { DelayedRender } from "../components/DeplayedRenderer";
import { WrongNetworkModal } from "../components/WrongNetworkModal";
import { env } from "../core/environment";
import { auth } from "../core/firebase.provider";
import { getChainNameById } from "../core/utils/chain";
import AssetDetail from "../pages/AssestDetail";
import Assets from "../pages/Assets";
import ChooseAssetType from "../pages/ChooseAssetType";
import CollectionDetail from "../pages/CollectionDetail";
import ConnectWallet from "../pages/ConnectWallet";
import CreateAsset from "../pages/CreateAsset";
import Dashboard from "../pages/Dashboard";
import LazyMintedAssets from "../pages/LazyMintedAssets";
import Maintenance from "../pages/Maintenance";
import Marketplace from "../pages/Marketplace";
import ProfileCollections from "../pages/ProfileCollections";
import ProfileSetting from "../pages/ProfileSetting";
import Purchases from "../pages/Purchases";
import SaleHistory from "../pages/SaleHistory";
import { userService } from "../services/user.service";
import { setSelectedChain } from "../store/features/appContext/appContext.slice";
import { setSupportedTokens } from "../store/features/token/token.slice";
import { logout, setUser } from "../store/features/user/user.slice";
import { useAppDispatch, useAppSelector } from "../store/hook";
import { ProfileModule } from "./ProfileModule";

function Router() {
  const dispatch = useAppDispatch();
  const { account, chainId, library } = useEthers();
  const user = useAppSelector((state) => state.user);
  const [isWrongNetwork, setIsWrongNetwork] = useState(false);

  useEffect(() => {
    const network = get(
      env.network,
      [env.config.supported_chain_ids[0]],
      env.network[env.config.supported_chain_ids[0]]
    );
    const bERC20Instances = network?.btokens?.berc20?.instances;
    const bEther = network?.btokens?.bether;

    const logoRegistry: { [key: string]: string } = {
      usdc: "https://firebasestorage.googleapis.com/v0/b/bepay-live.appspot.com/o/tokens%2Fusdc.svg?alt=media&token=094e687e-05c5-4b61-948f-0a1308496e21",
      usdt: "https://firebasestorage.googleapis.com/v0/b/bepay-live.appspot.com/o/tokens%2Fusdt.svg?alt=media&token=0046e876-759f-4c44-995f-875ed8d92dd5",
      busd: "https://firebasestorage.googleapis.com/v0/b/bepay-live.appspot.com/o/tokens%2Fbusd.png?alt=media&token=8d3c172f-cfdd-4162-9a3f-e8f5cf59a17c",
      wbnb: "https://firebasestorage.googleapis.com/v0/b/bepay-live.appspot.com/o/tokens%2Fwbnb.png?alt=media&token=9d952ee9-08c1-4315-9446-7b3fd9f834f5",
    };

    dispatch(
      setSupportedTokens([
        ...Object.entries(bERC20Instances).map(([key, token]) => {
          return {
            address: token.address,
            logoUrl: logoRegistry[key],
            symbol: key.toUpperCase(),
            underlying: token.underlying,
            decimals: token.decimals,
            isNative: false,
          };
        }),
        {
          address: bEther.address,
          logoUrl:
            "https://firebasestorage.googleapis.com/v0/b/bepay-live.appspot.com/o/tokens%2Fbnb.png?alt=media&token=9d97f264-4e79-47d8-9973-6145f1e0a736",
          symbol: "BNB",
          underlying: "0x",
          isNative: true,
          decimals: bEther.decimals,
        },
      ])
    );

    if (chainId) {
      dispatch(
        setSelectedChain({
          id: chainId,
          name: getChainNameById(chainId),
        })
      );
    }
  }, [chainId]);

  useEffect(() => {
    if (account && chainId && !env.config.supported_chain_ids.includes(chainId)) {
      setIsWrongNetwork(true);
    } else {
      setIsWrongNetwork(false);
    }
  }, [chainId, account]);

  useEffect(() => {
    async function getProfile() {
      if (!account) return;
      const response = await userService.getProfileById(account);

      if (response.data) {
        dispatch(setUser(response.data));
      }
    }

    getProfile();
  }, [account]);

  useEffect(() => {
    if (account && user?.profile?.id && account?.toLowerCase() !== user?.profile?.id) {
      dispatch(logout());
    }
  }, [account]);

  useEffect(() => {
    signInAnonymously(auth);
  }, []);

  if (env.config.is_maintaining) {
    return <Maintenance />;
  }

  if (!library) {
    return (
      <Flex alignItems="center" justifyContent={"center"} height={"100vh"} width={"100vw"}>
        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
      </Flex>
    );
  }

  return (
    <Flex minHeight={"100vh"} flexDirection="column">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={"Turn your products or services into publicly tradable items"} />
        <meta property="og:description" content={"Turn your products or services into publicly tradable items"} />
        <meta property="og:title" content={"bePAY: NFT Marketplace"} />
        <meta
          property="og:image"
          content={
            "https://firebasestorage.googleapis.com/v0/b/bepay-dev.appspot.com/o/logo.png?alt=media&token=145bfe9e-aaca-485f-8d8c-e1c65389420b"
          }
        ></meta>
        <meta property="og:type" content="article" />
        <title>bePAY NFT Marketplace</title>
      </Helmet>
      <AppHeader />
      <Box position={"relative"} flex={1}>
        <Routes>
          <Route index element={<Dashboard />} />
          <Route path="marketplace" element={<Marketplace />} />
          <Route path="profile">
            <Route element={<ProfileModule />}>
              <Route
                path="assets/owned/minted"
                element={<GuardedElement element={<Assets />} isActive={!!account} />}
              />
              <Route
                path="assets/owned/lazy"
                element={<GuardedElement element={<LazyMintedAssets />} isActive={!!account} />}
              />
              <Route path="assets/:id/minted" element={<Assets />} />
              <Route path="assets/:id/lazy" element={<LazyMintedAssets />} />
              <Route path="sale-history" element={<GuardedElement element={<SaleHistory />} isActive={!!account} />} />
              <Route path="purchases" element={<GuardedElement element={<Purchases />} isActive={!!account} />} />
              <Route
                path="collections/owned"
                element={<GuardedElement element={<ProfileCollections />} isActive={!!account} />}
              />
              <Route path="collections/:id" element={<ProfileCollections />} />
            </Route>

            <Route path="setting" element={<GuardedElement element={<ProfileSetting />} isActive={!!account} />} />
          </Route>

          <Route path="create" element={<GuardedElement element={<Outlet />} isActive={!!account} />}>
            <Route path="start" element={<ChooseAssetType />} />
            <Route path="erc-721" element={<CreateAsset type="ERC721" />} />
            <Route path="erc-1155" element={<CreateAsset type="ERC1155" />} />
          </Route>

          <Route path="collections">
            <Route path=":id" element={<CollectionDetail />} />
          </Route>

          <Route path="connect" element={<ConnectWallet />} />
          <Route path="assets/:tokenAddress/:tokenId" element={<AssetDetail />} />
        </Routes>
      </Box>
      <AppFooter />

      <WrongNetworkModal isVisible={isWrongNetwork} />
    </Flex>
  );
}

const GuardedElement = (props: { element: React.ReactElement | null; isActive: boolean }) => {
  const location = useLocation();

  return (
    <Suspense fallback={<Box></Box>}>
      {props.isActive ? props.element : <Navigate replace to={"/connect"} state={{ from: location }} />}
    </Suspense>
  );
};

export default React.memo(Router);
