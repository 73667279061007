import { CheckIcon, CopyIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  Heading,
  HStack,
  Link,
  Text,
  useClipboard,
  VStack,
} from "@chakra-ui/react";
import { shortenAddress, useEthers } from "@usedapp/core";
import React, { useState } from "react";
import { colors } from "../../../core/constants/colors";
import { IMoralisNft, INftAttribute, INftV2 } from "../../../core/models/nft";
import { getExplorerAddressUrl } from "../../../core/utils/getExplorerUrl";
import { AiFillStar } from "react-icons/ai";
import { fill } from "lodash-es";
import { useTranslation } from "react-i18next";
import { I18N_NAMESPACE } from "../../../core/constants/i18n";

interface Props {
  nft: INftV2 | undefined;
}

export const AssetSecondaryInfo = React.memo(({ nft }: Props) => {
  const { chainId } = useEthers();
  const { t } = useTranslation([I18N_NAMESPACE.ASSET_DETAIL]);
  const { hasCopied: hasCopiedTokenId, onCopy: onCopyTokenId } = useClipboard(nft?.tokenId || "");
  return (
    <VStack spacing={3} align="stretch">
      {(nft?.metadata?.attributes || []).length > 0 && (
        <>
          <Heading size="sm">{t("Properties")}</Heading>
          <Grid gridTemplateColumns={{ base: "1fr 1fr", lg: "1fr 1fr 1fr" }} gridGap="16px" marginTop="8px">
            {(nft?.metadata?.attributes || []).map((a: INftAttribute) => (
              <Flex
                borderRadius="15px"
                border={`1px solid ${colors.primary}`}
                background="#EFF5FF"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                padding="16px"
                key={a.trait_type}
              >
                <Text
                  fontSize="11px"
                  fontWeight="500"
                  color={colors.primary}
                  textTransform="uppercase"
                  textAlign={"center"}
                >
                  {a.is_truppa && a.trait_type ? t(a.trait_type) : a.trait_type?.toString()?.replaceAll("_", " ")}
                </Text>
                {a.is_truppa ? (
                  <HStack marginTop={1} spacing={0}>
                    {fill(Array(a.value ? +a.value : 0), null).map((i, index) => (
                      <AiFillStar color={"gold"} fontSize={24} key={`star-${index}`} />
                    ))}
                  </HStack>
                ) : (
                  <Text
                    fontWeight="500"
                    color="rgb(53, 56, 64)"
                    fontSize="15px"
                    paddingTop="4px"
                    textTransform="capitalize"
                    textAlign="center"
                  >
                    {a.value?.toString()?.replaceAll("_", " ")}
                  </Text>
                )}
              </Flex>
            ))}
          </Grid>
        </>
      )}
      <Box py={3}>
        <Heading size="sm">{t("Details")}</Heading>
        <Grid pt={3} gridGap="4px">
          <Flex alignItems="center" justifyContent="space-between">
            <Text color="#434F64">{t("Contract Address")}</Text>
            <Link
              color={colors.primary}
              fontWeight="500"
              target={"_blank"}
              href={nft?.contract && chainId ? getExplorerAddressUrl(nft?.contract, chainId) : ""}
            >
              {nft?.contract ? shortenAddress(nft?.contract) : ""}
            </Link>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between">
            <Text color="#434F64">{t("Token ID")}</Text>
            <Flex alignItems={"center"} gridGap={2} cursor={"pointer"} onClick={onCopyTokenId} fontWeight="500">
              {hasCopiedTokenId ? (
                <>
                  <Text>{t("Copied")}</Text>
                  <CheckIcon />
                </>
              ) : (
                <>
                  <Text whiteSpace="nowrap" overflow="hidden" maxWidth="200px" textOverflow="ellipsis">
                    {nft?.tokenId}
                  </Text>
                  <CopyIcon />
                </>
              )}
            </Flex>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between">
            <Text color="#434F64">{t("Token Standard")}</Text>
            <Text fontWeight="500">{nft?.contractType}</Text>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between">
            <Text color="#434F64">{t("Blockchain")}</Text>
            <Text fontWeight="500">BNB Chain</Text>
          </Flex>
        </Grid>
      </Box>
    </VStack>
  );
});
