import { Box, Flex, Image, Text } from "@chakra-ui/react";
import axios from "axios";
import BigNumber from "bignumber.js";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../core/constants/colors";
import { I18N_NAMESPACE } from "../../core/constants/i18n";
import { INft, INftMetadata, ISummaryNft } from "../../core/models/nft";
import { uriToHttps } from "../../core/utils/ipfs";
import { formatNumber } from "../../core/utils/number";
import { useAppSelector } from "../../store/hook";
import { NftResource } from "../NftResource";

interface Props {
  nft: ISummaryNft;
  onClick: (nft: ISummaryNft) => void;
}

export const SearchNftItem = React.memo(({ nft, onClick }: Props) => {
  const { t, i18n } = useTranslation([I18N_NAMESPACE.APP_HEADER]);
  const supportedTokens = useAppSelector((state) => state.token.supportedTokens);
  const token = useMemo(
    () => (nft.makeQuote?.takeAssetContract ? supportedTokens.byId[nft.makeQuote?.takeAssetContract] : null),
    [nft.makeQuote?.takeAssetContract, supportedTokens.byId]
  );

  const remainingQuantity = useMemo(
    () => parseInt(nft.makeQuote?.makeAssetValue || "0") - (nft?.makeQuote?.fill || 0),
    [nft.makeQuote?.makeAssetValue, nft.makeQuote?.fill]
  );

  const pricePerCopy = useMemo(
    () => new BigNumber(nft.makeQuote?.takeAssetValue || 0).div(nft.makeQuote?.makeAssetValue || 0).toNumber(),
    [nft.makeQuote?.takeAssetValue, nft.makeQuote?.makeAssetValue]
  );

  const totalPrice = useMemo(
    () =>
      !!token
        ? new BigNumber(pricePerCopy).multipliedBy(remainingQuantity).div(Math.pow(10, token.decimals)).toNumber()
        : 0,
    [pricePerCopy, remainingQuantity, token?.decimals]
  );

  const [metadata, setMetata] = useState<INftMetadata>({
    image: nft.image,
    name: nft.name,
    // animation_url: nft.animation_url,
  });

  useEffect(() => {
    async function getMetadata() {
      if (nft.uri) {
        const uri = uriToHttps(nft.uri);
        if (!uri) return;

        const result = await axios.get(uri);
        setMetata(result.data);
      }
    }

    getMetadata();
  }, [nft.uri]);

  return (
    <Flex cursor={"pointer"} onClick={() => onClick(nft)}>
      <Box
        borderWidth="1px"
        borderRadius="lg"
        height={{
          base: "60px",
          md: "80px",
          lg: "80px",
          xl: "80px",
          "2xl": "80px",
        }}
        width={{
          base: "60px",
          md: "80px",
          lg: "80px",
          xl: "80px",
          "2xl": "80px",
        }}
      >
        <NftResource image={metadata.image} animationUrl={metadata.animation_url} muted />
      </Box>
      <Flex flex="1" marginLeft="12px" justifyContent="space-between" alignItems={"center"}>
        <Box flex={1}>
          <Box fontSize={"small"}>{nft?.contractName}</Box>
          <Text color={colors.primary2} fontWeight="700">
            {metadata.name}
          </Text>
        </Box>
        {!!nft.makeQuote && (
          <Flex flexDirection="column" alignItems="flex-end">
            <Text color={colors.primary2} fontWeight="500">
              {t("Price")}
            </Text>
            <Flex marginTop="4px" alignItems={"center"}>
              <Image src={token?.logoUrl} height="20px" width="20px" marginRight="4px" />
              <Text fontWeight="700" color={colors.primary}>
                {formatNumber(totalPrice)}
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
});
