import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { enResources } from "./en";
import enAppHeaderResource from "./en/app-header.json";
import { viResources } from "./vi";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: enResources,
      vi: viResources,
    },
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
