import React, { useEffect, useState } from "react";
import { HStack, Stack, Link, VStack, Flex, Avatar, Heading, Text, Box, Spinner, Center } from "@chakra-ui/react";
import { IoIosHeartEmpty, IoIosHeart } from "react-icons/io";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/modal";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { userService } from "../../../services/user.service";
import Web3 from "web3";
import { nftService } from "../../../services/nft.service";
import { UserFavorite } from "../../../core/models/user-favorite";
import { shortenAddress } from "@usedapp/core";
import { generateNonce } from "../../../core/utils/securityUtils";
import { useTranslation } from "react-i18next";
import { I18N_NAMESPACE } from "../../../core/constants/i18n";
import { INftV2 } from "../../../core/models/nft";
import { useSelector } from "react-redux";
import { useAppSelector } from "../../../store/hook";

interface Props {
  nft: INftV2 | undefined;
}

export const FavoriteNftDetail = React.memo(({ nft }: Props) => {
  const { t } = useTranslation([I18N_NAMESPACE.ASSET_DETAIL]);

  const appContext = useAppSelector((state) => state.appContext);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [numberOfFavorites, setNumberOfFavorites] = useState<number>(0);
  const [users, setUsers] = useState<UserFavorite[]>();
  const [isEndOfList, setIsEndOfList] = useState<boolean>(false);
  const [isFavorited, setIsFavorited] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const pageSize = 10;

  useEffect(() => {
    if (openModal) {
      const getFavoritedUsers = async () => {
        const result = await nftService.getFavoritedUsers(appContext.selectedChain.name, nft?.contract, nft?.tokenId);

        if (result?.data) {
          setUsers(result.data);
        }
        setIsLoading(false);
        setIsEndOfList(!result?.data || (result.data?.length || 0) < pageSize);
      };
      getFavoritedUsers();
    }
  }, [openModal]);

  useEffect(() => {
    setNumberOfFavorites(nft?.numberOfFavorites || 0);
  }, [nft?.numberOfFavorites]);

  useEffect(() => {
    setIsFavorited(nft?.favorited || false);
  }, [nft?.favorited]);

  const UserDetail = ({ image: avatar, displayName: name, id: address }: UserFavorite) => {
    return (
      <Flex
        py={3}
        px={3}
        w={"full"}
        borderColor={"gray.200"}
        borderTopWidth={"1px"}
        borderStyle={"solid"}
        cursor={"pointer"}
        onClick={() => {
          navigate(`/profile/assets/${address}/minted`);
        }}
      >
        <HStack spacing={3}>
          <Avatar src={avatar} />
          <Box>
            <Heading fontSize={"md"}>{name}</Heading>
            <Text fontSize={"md"}>{address ? shortenAddress(address) : ""}</Text>
          </Box>
        </HStack>
      </Flex>
    );
  };

  const setFavorite = (value: boolean) => async () => {
    try {
      if (!nft) return;
      const response = await nftService.favorite({
        contract: nft.contract,
        tokenId: nft.tokenId,
        chain: appContext.selectedChain.name,
        favorited: value,
      });

      if (response.data) {
        setIsFavorited(value);
        setNumberOfFavorites(response.data.numberOfFavorites);
      }
    } catch (e) {}
  };

  const addFavorite = setFavorite(true);

  const removeFavorite = setFavorite(false);

  const closeModal = () => {
    setOpenModal(false);
    setIsLoading(true);
    setUsers(undefined);
  };

  const FavouriteList = () => {
    return (
      <Modal
        isOpen={openModal}
        onClose={() => {
          closeModal();
        }}
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("Favorited by")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody paddingLeft={"0"} paddingRight={"0"}>
            {isLoading ? (
              <Center alignItems="center" justifyContent="center" marginTop={10} overflow="hidden">
                <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
              </Center>
            ) : (
              <InfiniteScroll
                style={{
                  overflow: "hidden",
                }}
                next={() => {
                  setPage((p) => p + 1);
                }}
                hasMore={!isEndOfList}
                loader={
                  <Flex alignItems="center" justifyContent="center" marginTop={10}>
                    <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
                  </Flex>
                }
                dataLength={users?.length || 0}
              >
                <VStack spacing={0}>
                  {!!users &&
                    users.map((user, index) => (
                      <UserDetail key={index} image={user.image} displayName={user.displayName} id={user.id} />
                    ))}
                </VStack>
              </InfiniteScroll>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };

  return (
    <>
      <Stack>
        <HStack>
          {isFavorited ? (
            <IoIosHeart onClick={removeFavorite} cursor={"pointer"} />
          ) : (
            <IoIosHeartEmpty onClick={addFavorite} cursor={"pointer"} />
          )}

          <Link
            fontSize={"sm"}
            fontWeight={500}
            onClick={() => {
              setOpenModal(true);
            }}
          >
            {t("favorite", { count: numberOfFavorites })}
          </Link>
        </HStack>
      </Stack>

      {numberOfFavorites > 0 && <FavouriteList />}
    </>
  );
});
