import { Box, Divider, Flex, Heading, HStack, Link, Spacer, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { BsDiscord, BsFacebook, BsTelegram, BsTwitter, BsYoutube } from "react-icons/bs";
import { I18N_NAMESPACE } from "../../core/constants/i18n";
import { AppContent } from "../AppContent";

export const AppFooter = React.memo(() => {
  const { t } = useTranslation([I18N_NAMESPACE.APP_FOOTER]);
  const communities = [
    { id: "facebook", icon: <BsFacebook size={20} />, link: "https://www.facebook.com/bepaybnpl" },
    { id: "twitter", icon: <BsTwitter size={20} />, link: "https://twitter.com/bePAY_BNPL" },
    { id: "telegram", icon: <BsTelegram size={20} />, link: "https://t.me/bePayvietnam" },
    { id: "youtube", icon: <BsYoutube size={20} />, link: "https://www.youtube.com/channel/UC8NVWQCELIJaMLYuNo_6xDg" },
    { id: "discord", icon: <BsDiscord size={20} />, link: "https://discord.com/invite/KPR7T3J8xP" },
  ];
  return (
    <Box background={"gray.50"} padding={"10px 0px"}>
      <AppContent>
        <Stack spacing={4} align={{ base: "center", md: "initial" }}>
          <Heading size={"sm"}>{t("Join bePAY community")}</Heading>
          <HStack>
            {communities.map((c) => (
              <Link
                key={c.id}
                background={"gray.100"}
                _hover={{ background: "gray.200" }}
                padding={4}
                borderRadius="lg"
                href={c.link}
                target="_blank"
              >
                {c.icon}
              </Link>
            ))}
          </HStack>
        </Stack>

        <Divider pt={6} />

        <Stack direction={["column", "column", "row"]} py={4} align={{ base: "center", lg: "initial" }} justifyContent="flex-end">
          <Text align={{ base: "center", lg: "initial" }} fontSize={"sm"} color={"rgba(4, 4, 5, 0.4)"}>
            &copy; 2021-2022 <a href="https://bepay.finance" target={"_blank"} rel="noreferrer">bePAY Foundation</a>. All rights reserved
          </Text>
          <Spacer />
          <Link
            href="https://firebasestorage.googleapis.com/v0/b/bepay-live.appspot.com/o/NFT%20Terms%20and%20License%20%26%20Terms%20Of%20Service%20_%20bePay%20(1).pdf?alt=media&token=f81c3ac1-71e8-44ba-bf5c-5d985f39c93c"
            target={"_blank"}
            pr={4}
          >
            <Title>{t("Terms")}</Title>
          </Link>
          <Link
            href="https://firebasestorage.googleapis.com/v0/b/bepay-live.appspot.com/o/Privacy%20Policy%20(2).pdf?alt=media&token=418552bf-9be9-479d-9504-7283d0046022"
            target={"_blank"}
          >
            <Title>{t("Privacy policy")}</Title>
          </Link>
        </Stack>
      </AppContent>
    </Box>
  );
});

const Title = React.memo((props: React.PropsWithChildren<{}>) => {
  return (
    <Text fontWeight={700} fontSize="sm" color={"gray.500"} _hover={{ color: "gray.600" }}>
      {props.children}
    </Text>
  );
});
