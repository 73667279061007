import { ChakraProvider } from "@chakra-ui/react";
import { Global } from "@emotion/react";
import { ChainId, Config, DAppProvider } from "@usedapp/core";
import "focus-visible/dist/focus-visible";
import React from "react";
import ReactDOM from "react-dom";
import { MoralisProvider } from "react-moralis";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { env } from "./core/environment";
import "./core/i18n";
import { GlobalStyles, theme } from "./core/theme";
import reportWebVitals from "./reportWebVitals";
import Router from "./router";
import { persistor, store } from "./store";

const dAppConfig: Config = {
  readOnlyChainId: env.config.supported_chain_ids[0],
  readOnlyUrls: {
    [ChainId.Ropsten]: "https://eth-ropsten.alchemyapi.io/v2/58qN8AFEgTP96MnqoLRBRIcYri_d9YXB",
    [ChainId.Rinkeby]: "https://eth-rinkeby.alchemyapi.io/v2/es1Qzjzn5AgNC48y7-ghzrPDBH1QB4Xh",
    [ChainId.Kovan]: "https://eth-kovan.alchemyapi.io/v2/yEmCJrcuxW_UxsbYoUEc329YbPLXMFm2",
    [ChainId.BSCTestnet]: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    [ChainId.BSC]: "https://bsc-dataseed1.ninicoin.io",
  },
};

ReactDOM.render(
  <React.StrictMode>
    <DAppProvider config={dAppConfig}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <MoralisProvider appId={env.config.moralis.app_id} serverUrl={env.config.moralis.server_url}>
            <ChakraProvider theme={theme}>
              <Global styles={GlobalStyles} />
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            </ChakraProvider>
          </MoralisProvider>
        </PersistGate>
      </Provider>
    </DAppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
