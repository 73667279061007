import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { env } from "../../../core/environment";
import { getChainNameById } from "../../../core/utils/chain";

interface Chain {
  id: number;
  name: string;
}

interface ITokenState {
  selectedChain: Chain;
}

const initialState: ITokenState = {
  selectedChain: {
    id: env.config.supported_chain_ids[0],
    name: getChainNameById(env.config.supported_chain_ids[0]),
  },
};

export const appContextSlice = createSlice({
  name: "appContextt",
  initialState,
  reducers: {
    setSelectedChain: (state, action: PayloadAction<Chain>) => {
      state.selectedChain = action.payload;
    },
  },
});

export const { setSelectedChain } = appContextSlice.actions;

export default appContextSlice.reducer;
