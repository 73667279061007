import { CheckIcon, CopyIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Flex,
  HStack,
  Stack,
  Text,
  Image,
  useClipboard,
  useBreakpointValue,
  Center,
} from "@chakra-ui/react";
import { shortenAddress, useEthers } from "@usedapp/core";
import React from "react";
import { IoClipboard, IoLogOutOutline, IoPersonCircleOutline, IoSettingsOutline } from "react-icons/io5";
import { Popover } from "react-tiny-popover";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { BiUserCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { IToken } from "../../core/models/token";
import { useERC20Balance } from "../../core/hooks/useERC20Balance";
import { logout } from "../../store/features/user/user.slice";
import { LAYER_1_TOKENS } from "../../core/constants/token";
import { useTranslation } from "react-i18next";
import { I18N_NAMESPACE } from "../../core/constants/i18n";

interface Props {
  isVisible: boolean;
  onClickOutside: (e?: MouseEvent) => void;
  children: JSX.Element;
}

const Token = ({ token }: { token: IToken }) => {
  const { t } = useTranslation([I18N_NAMESPACE.APP_HEADER]);
  const balance = useERC20Balance(token.underlying);
  return (
    <HStack alignItems={"center"}>
      <Image src={token?.logoUrl} height="45px" width="45px" marginRight="4px" />
      <Box>
        <Text fontSize={"small"} color={"rgb(110, 110, 110)"} fontWeight={500}>
          {t("Balance")}
        </Text>
        <Text fontWeight="700" fontSize={"15px"}>
          {balance} {token.symbol}
        </Text>
      </Box>
    </HStack>
  );
};

export const ProfilePopover = React.memo((props: Props) => {
  const { t, i18n } = useTranslation([I18N_NAMESPACE.APP_HEADER]);
  const userProfile = useAppSelector((state) => state.user.profile);
  const supportedTokens = useAppSelector((state) => state.token.supportedTokens);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { account, deactivate } = useEthers();
  const { hasCopied: hasCopiedAddress, onCopy: onCopyAddress } = useClipboard(account || "");

  const actions = [
    {
      name: t("My profile"),
      icon: <IoPersonCircleOutline fontSize={"23px"} />,
      onClick: () => {
        navigate("/profile/assets/owned/minted");
        props.onClickOutside();
      },
    },
    {
      name: t("Edit profile"),
      icon: <IoSettingsOutline fontSize={"23px"} />,
      onClick: () => {
        navigate("/profile/setting");
        props.onClickOutside();
      },
    },
    {
      name: t("Sign out"),
      icon: <IoLogOutOutline fontSize={"23px"} />,
      onClick: () => {
        deactivate();
        dispatch(logout());
        props.onClickOutside();
      },
    },
  ];

  const isDesktop = useBreakpointValue({ base: false, md: true });

  return (
    <Popover
      isOpen={props.isVisible}
      onClickOutside={props.onClickOutside}
      containerStyle={{
        width: isDesktop ? "auto" : "100vw",
        zIndex: "3",
        boxShadow: "rgb(4 4 5 / 20%) 0px 7px 36px",
        borderRadius: "12px",
      }}
      content={
        <Stack background={"#fff"} borderRadius={"lg"} padding={{ base: 8, md: 4 }} spacing={3}>
          <Stack
            spacing={1}
            _hover={{ background: "rgba(4, 4, 5, 0.05)" }}
            padding={3}
            borderRadius={"xl"}
            cursor={"pointer"}
            onClick={onCopyAddress}
          >
            <Text fontWeight={700} fontSize={"lg"}>
              {userProfile?.displayName}
            </Text>
            <HStack alignItems={"center"} fontSize={"small"} fontWeight={500} color={"gray.500"}>
              {hasCopiedAddress ? (
                <>
                  <Text>{t("Copied")}</Text>
                  <CheckIcon />
                </>
              ) : (
                <>
                  <Text>{account ? shortenAddress(account) : ""}</Text>
                  <CopyIcon />
                </>
              )}
            </HStack>
          </Stack>

          <Stack minW={"320px"} borderRadius={"xl"} borderWidth={1} padding={3} spacing={3}>
            {supportedTokens.allIds
              .filter((id) => {
                const token = supportedTokens.byId[id];
                return !LAYER_1_TOKENS.includes(token.underlying);
              })
              .map((id) => {
                const token = supportedTokens.byId[id];
                return <Token token={token} key={id} />;
              })}
          </Stack>

          <Box>
            {actions.map((item) => (
              <HStack
                padding={2}
                _hover={{ background: "rgba(4, 4, 5, 0.05)" }}
                borderRadius={"xl"}
                cursor={"pointer"}
                onClick={item.onClick}
                key={item.name}
              >
                {item.icon}
                <Text fontWeight={"700"} fontSize={"13px"}>
                  {item.name}
                </Text>
              </HStack>
            ))}
          </Box>
        </Stack>
      }
      positions={["bottom", "left"]}
      align="end"
      padding={10}
    >
      {props.children}
    </Popover>
  );
});
