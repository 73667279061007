import { CloseIcon, WarningIcon } from "@chakra-ui/icons";
import { Input } from "@chakra-ui/input";
import { AspectRatio, Center, Heading, Stack } from "@chakra-ui/layout";
import { Button, Flex, IconButton, Image, Spinner, Tooltip } from "@chakra-ui/react";
import React, { ChangeEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../../core/constants/colors";
import { I18N_NAMESPACE } from "../../../core/constants/i18n";
import { images } from "../../../core/constants/images";

interface Props {
  isUploading: boolean;
  value: string | null | undefined;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onRemoveVideo: () => void;
}

export const ProfileVideoRow = React.memo((props: Props) => {
  const { t } = useTranslation([I18N_NAMESPACE.PROFILE]);
  const onChooseFile = () => {
    document.getElementById("upload-video")?.click();
  };
  return (
    <Stack spacing="24px">
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex>
          <Heading size="md">{t("Profile Video")}</Heading>
          <Tooltip
            hasArrow
            label={
              <div>
                <p>{t("Recommended: 16:9 ratio")}</p>
                <p>{t("Max size", { size: 100 })}</p>
              </div>
            }
            borderRadius="lg"
            padding="6px"
            placement="top"
          >
            <WarningIcon w={4} h={4} color={colors.primary} marginLeft="6px" />
          </Tooltip>
        </Flex>

        <Button colorScheme={"blue"} variant={"ghost"} onClick={onChooseFile}>
          {t("Choose file")}
        </Button>
      </Flex>
      <AspectRatio ratio={16 / 9} w="full">
        <Flex
          borderRadius="xl"
          borderWidth={2}
          borderColor="gray.200"
          borderStyle={"dashed"}
          alignItems="center"
          justifyContent="center"
        >
          {!props.isUploading ? (
            <>
              {props.value ? (
                <>
                  <video width={"75%"} height={"100%"} autoPlay controls loop src={props.value} playsInline />
                  <IconButton
                    variant="outline"
                    aria-label="Remove file"
                    icon={<CloseIcon fontSize={12} />}
                    borderRadius="full"
                    position="absolute"
                    top={3}
                    right={3}
                    zIndex={2}
                    onClick={props.onRemoveVideo}
                  />
                </>
              ) : (
                <Center height={"full"} width={"full"} onClick={onChooseFile}>
                  <Image src={images.placeholder} width="50%" />
                </Center>
              )}
              <Input type="file" accept="video/mp4,video/webm" onChange={props.onChange} hidden id="upload-video" />
            </>
          ) : (
            <Spinner colorScheme="blue" size="lg" color="blue.500" />
          )}
        </Flex>
      </AspectRatio>
    </Stack>
  );
});
