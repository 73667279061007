import { useContractCall, useEthers, useTokenAllowance } from "@usedapp/core";
import { useMemo } from "react";
import { utils } from "ethers";
import ERC20Abi from "../../abis/ERC20.json";
import { Interface } from "@ethersproject/abi";
import { Falsy } from "@usedapp/core/dist/esm/src/model/types";

export const useDecimals = (address: string | Falsy): number | undefined => {
  const ERC20_INTERFACE = new Interface(ERC20Abi);
  const [decimals] =
    useContractCall(
      address && {
        abi: ERC20_INTERFACE,
        address: address,
        method: "decimals",
        args: [],
      }
    ) ?? [];

  return useMemo(() => {
    return decimals;
  }, [decimals]);
};

export const useAllowance = (address: string | Falsy, spender: string | Falsy) => {
  const { account } = useEthers();
  const allowance = useTokenAllowance(address, account, spender);
  const decimals = useDecimals(address);
  return useMemo(() => (allowance ? +utils.formatUnits(allowance, decimals) : undefined), [allowance, decimals]);
};
