export default {
  baseStyle: {
    dialogContainer: {
      // paddingBottom: 12,
    },
    dialog: {
      paddingBottom: "20px",
    },
  },
};
