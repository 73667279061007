import { useEthers, useTokenBalance } from "@usedapp/core";
import { useMemo } from "react";
import { useDecimals } from "./useAllowance";
import { utils } from "ethers";
import { Falsy } from "@usedapp/core/dist/esm/src/model/types";

export const useERC20Balance = (address: string | Falsy) => {
  const { account } = useEthers();
  const balance = useTokenBalance(address, account);
  const decimals = useDecimals(address);
  return useMemo(() => (balance && decimals ? +utils.formatUnits(balance, decimals) : 0), [balance, decimals]);
};
