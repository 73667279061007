import { WarningIcon } from "@chakra-ui/icons";
import { Box, Flex, Heading, Stack, Text, Image, Button, Tooltip } from "@chakra-ui/react";
import { useEthers } from "@usedapp/core";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { AppContent } from "../../components/AppContent";
import { colors } from "../../core/constants/colors";
import { I18N_NAMESPACE } from "../../core/constants/i18n";
import { images } from "../../core/constants/images";

const ConnectWallet = React.memo(() => {
  const { t } = useTranslation([I18N_NAMESPACE.CONNECT_WALLET]);
  const { activateBrowserWallet, account, error, library } = useEthers();
  const navigate = useNavigate();
  const location: Location = useLocation();

  const from: string = useMemo(
    () => (location?.state as any)?.from?.pathname || "/",
    [(location?.state as any)?.from?.pathname]
  );

  useEffect(() => {
    if (error?.message === "No Ethereum provider was found on window.ethereum.") {
      window.open("https://metamask.io/download.html");
    }
  }, [error]);

  useEffect(() => {
    if (!account) return;
    navigate(from);
  }, [account]);

  return (
    <AppContent>
      <Flex alignItems={"center"} flexDirection={"column"} marginTop={10}>
        <Stack spacing={4}>
          <Heading size={"lg"}>{t("Connect your wallet")}.</Heading>
          <Text display={"inline-flex"}>
            {t("Connect with one of our available")}
            <Tooltip
              hasArrow
              label={t(
                "A crypto wallet is an application or hardware device that allows individuals to store and retrieve digital items."
              )}
              borderRadius="lg"
              padding="6px"
              placement="top"
            >
              <Flex margin="0px 6px" fontWeight={500} color={colors.primary}>
                {t("wallet")} <WarningIcon w={3} h={3} color={colors.primary} marginLeft="4px" />
              </Flex>
            </Tooltip>
            {t("providers")}.
          </Text>
          <Box>
            <Button variant={"outline"} width={"100%"} padding={"24px 20px"} onClick={() => activateBrowserWallet()}>
              <Flex alignItems={"center"} flex={1}>
                <Image src={images.metamask} height={"24px"} width={"24px"} marginRight={4} />
                <Box fontWeight={700} flex={1} textAlign={"left"}>
                  Metamask
                </Box>
                <Text fontWeight={500} fontSize={"small"} color={"#6b6b6b"}>
                  {t("Popular")}
                </Text>
              </Flex>
            </Button>
          </Box>
        </Stack>
      </Flex>
    </AppContent>
  );
});

export default ConnectWallet;
