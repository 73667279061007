import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Flex, Icon, Image, Stack, Text, Tooltip, Link, HStack } from "@chakra-ui/react";
import { shortenAddress, useEthers } from "@usedapp/core";
import axios from "axios";
import BigNumber from "bignumber.js";
import { format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoCalendarClearOutline, IoPersonOutline, IoTimeOutline } from "react-icons/io5";
import { Link as RouterLink } from "react-router-dom";
import { NftResource } from "../../../components/NftResource";
import { colors } from "../../../core/constants/colors";
import { I18N_NAMESPACE } from "../../../core/constants/i18n";
import { useNftMetadata } from "../../../core/hooks/data/nft";
import { useNft } from "../../../core/hooks/useNft";
import { IMoralisNft } from "../../../core/models/nft";
import { IOrderV2, ISale } from "../../../core/models/order";
import { getExplorerTxUrl } from "../../../core/utils/getExplorerUrl";
import { uriToHttps } from "../../../core/utils/ipfs";
import { formatNumber } from "../../../core/utils/number";
import { nftService } from "../../../services/nft.service";
import { useAppSelector } from "../../../store/hook";

interface Props {
  order: IOrderV2;
}

export const OrderItem = React.memo((props: Props) => {
  const { chainId } = useEthers();
  const { t } = useTranslation([I18N_NAMESPACE.PROFILE]);

  const supportedTokens = useAppSelector((state) => state.token.supportedTokens);
  const token = useMemo(() => supportedTokens.byId[props.order.currency], [props.order.currency, supportedTokens]);

  const { metadata } = useNftMetadata(props.order.nftUri);

  return (
    <Flex
      marginTop={"16px"}
      alignItems={"center"}
      borderWidth={1}
      padding={"12px 20px"}
      borderRadius={"xl"}
      gridGap={"20px"}
    >
      <Box height="80px" width="80px">
        <NftResource image={metadata?.image} animationUrl={metadata?.animation_url} muted />
      </Box>

      <Box flex={1}>
        <Text color={colors.primary2} fontWeight="700" fontSize="20px">
          {metadata?.name}
        </Text>

        <Flex alignItems={"center"}>
          <Icon as={IoCalendarClearOutline} color={"#a6a6a6"} />
          <Text marginLeft={2} fontSize={"small"} fontWeight={500} color="gray.500">
            {props.order.createdAt && format(new Date(props.order.createdAt), "dd/MM/yyyy HH:mm")}
          </Text>
        </Flex>

        <HStack alignItems={"center"}>
          <Text fontSize={"sm"} color="gray.500">
            <RouterLink to={`/profile/assets/${props.order.buyer}/minted`}>
              <Link>
                <Text fontSize={"small"} fontWeight={700} as="span" cursor={"pointer"}>
                  {shortenAddress(props.order.buyer)}
                </Text>
              </Link>
            </RouterLink>{" "}
            {t("purchased for")}{" "}
            {!!chainId && (
              <Link target={"_blank"} href={getExplorerTxUrl(props.order.id, chainId)}>
                <Tooltip hasArrow label={t("View on explorer")} borderRadius="lg" padding="6px" placement="top">
                  <Text fontWeight={700} fontSize={"md"} color={colors.primary} as="span">
                    {formatNumber(new BigNumber(props.order.total).div(Math.pow(10, token.decimals)).toNumber())}{" "}
                    {token.symbol}
                  </Text>
                </Tooltip>
              </Link>
            )}
          </Text>
        </HStack>
      </Box>
    </Flex>
  );
});
