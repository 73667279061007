export enum I18N_NAMESPACE {
  APP_HEADER = "app-header",
  DASHBOARD = "dashboard",
  MARKETPLACE = "marketplace",
  ASSET_DETAIL = "asset-detail",
  PROFILE = "profile",
  COLLECTION_DETAIL = "collection-detail",
  CREATE_ASSET = "create-asset",
  CONNECT_WALLET = "connect-wallet",
  APP_FOOTER = "app-footer",
  COMMON_COMPONENT = "common-component",
}
