import { Heading } from "@chakra-ui/react";
import React from "react";
import { Link, useMatch, useNavigate, useResolvedPath } from "react-router-dom";

export const HeaderNavLink = React.memo((props: { route: string; name: string }) => {
  const resolved = useResolvedPath(props.route);
  const match = useMatch({ path: resolved.pathname, end: true });
  return (
    <Link to={props.route} key={props.route}>
      <Heading size="sm" color={match ? "#000" : "rgba(4, 4, 5, 0.6)"} _hover={{ color: "#000" }}>
        {props.name}
      </Heading>
    </Link>
  );
});
