import { Contract } from "@ethersproject/contracts";
import { Interface } from "@ethersproject/abi";
import { JsonRpcSigner, Web3Provider } from "@ethersproject/providers";
import { getAddress } from "@ethersproject/address";
import { AddressZero } from "@ethersproject/constants";

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

// account is not optional
function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

// account is optional
function getProviderOrSigner(
  library?: Web3Provider,
  account?: string | null
): Web3Provider | JsonRpcSigner | undefined {
  return account && library ? getSigner(library, account) : library;
}

export const getContract = (
  address: string,
  abi: Interface,
  library?: Web3Provider,
  account?: string | null
): Contract => {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, abi, getProviderOrSigner(library, account) as any);
};
