import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
  useClipboard,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BsClipboard, BsFacebook, BsInstagram, BsTwitter } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { I18N_NAMESPACE } from "../core/constants/i18n";

interface Props {
  link?: string;
  content?: string;
}

export const SharedButton = React.memo((props: Props) => {
  const navigate = useNavigate();
  const link = useMemo(() => props.link || window.location.href, [props.link, window.location.href]);
  const { onCopy: onCopyLink, hasCopied } = useClipboard(link);

  const { t } = useTranslation([I18N_NAMESPACE.COMMON_COMPONENT]);

  const popupCenter = ({ url, title, w, h }: { url: string; title: string; w: number; h: number }) => {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;
    const newWindow = window.open(
      url,
      title,
      `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    );

    if (newWindow) newWindow.focus();
  };

  const options = [
    {
      id: "fb",
      icon: <BsFacebook />,
      label: "Facebook",
      onClick: () => {
        const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(link)}&quote=${encodeURI(
          props.content || ""
        )}`;
        popupCenter({ url, title: "Post to Facebook", w: 600, h: 600 });
      },
    },
    {
      id: "twitter",
      icon: <BsTwitter />,
      label: "Twitter",
      onClick: () => {
        const url = `https://twitter.com/intent/tweet?url=${encodeURI(link)}&text=${encodeURI(props.content || "")}`;
        popupCenter({ url, title: "Post to Twitter", w: 600, h: 400 });
      },
    },
    {
      id: "copy",
      icon: <BsClipboard />,
      label: t("Copy"),
      onClick: () => {
        onCopyLink();
      },
    },
  ];
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton aria-label="Share" variant="outline" icon={<ExternalLinkIcon />} />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader>
          <Text fontWeight={"500"}>{t("Share link to this page")}</Text>
        </PopoverHeader>
        <PopoverBody>
          <HStack alignSelf={"center"} padding={2}>
            {options.map((o) => (
              <Stack alignItems={"center"} flex={1} cursor={"pointer"} onClick={o.onClick} key={o.id}>
                <Box
                  padding={3}
                  borderRadius={"full"}
                  borderColor={"gray.200"}
                  _hover={{ borderColor: "gray.300" }}
                  borderWidth={1}
                >
                  {o.icon}
                </Box>
                <Text fontSize={"xs"} color={"gray.500"}>
                  {o.id === "copy" && hasCopied ? t("Copied!") : o.label}
                </Text>
              </Stack>
            ))}
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
});
