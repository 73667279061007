import { Box, Flex, Heading, Spinner, Stack } from "@chakra-ui/react";
import { useEthers } from "@usedapp/core";
import { orderBy } from "lodash-es";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../core/constants/colors";
import { I18N_NAMESPACE } from "../../core/constants/i18n";
import { useAccountBorrow } from "../../core/hooks/useAccountBorrow";
import { useAccountCredit } from "../../core/hooks/useAccountCredit";
import { useMarketplaceContract } from "../../core/hooks/useContract";
import { IPurchaseV2, ISale, ORDER_STATUS } from "../../core/models/order";
import { marketplaceService } from "../../services/marketplace.service";
import { userService } from "../../services/user.service";
import { useAppSelector } from "../../store/hook";
import { CompletedOrder } from "./components/CompletedOrder";
import { UpcomingOrder } from "./components/UpcomingOrder";
import Ably from "ably/promises";
import { env } from "../../core/environment";

const Purchases = React.memo(() => {
  const { t } = useTranslation([I18N_NAMESPACE.PROFILE]);
  const borrowAmount = useAccountBorrow();
  const creditAmount = useAccountCredit();
  const { account, library } = useEthers();
  const appContext = useAppSelector((state) => state.appContext);

  const marketplaceContract = useMarketplaceContract();

  const [upcomingOrders, setUpcomingOrders] = useState<IPurchaseV2[]>([]);
  const [completedOrders, setCompletedOrders] = useState<IPurchaseV2[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [watchedTxHash, setWatchedTxHash] = useState<string>();

  useEffect(() => {
    if (!watchedTxHash) return;
    const client = new Ably.Realtime(env.config.ably_private_key);

    const channel = client.channels.get(`purchases-${watchedTxHash.toLowerCase()}`);
    channel.subscribe((e) => {
      getOrders();
      channel.unsubscribe();
    });

    return () => {
      channel.unsubscribe();
    };
  }, [watchedTxHash]);

  const setOrders = useCallback((orders: IPurchaseV2[]) => {
    setCompletedOrders(
      orderBy(
        orders.filter((i) => i.status !== ORDER_STATUS.ACTIVE),
        "created_time",
        "desc"
      )
    );
    setUpcomingOrders(
      orderBy(
        orders.filter((i) => i.status === ORDER_STATUS.ACTIVE),
        "created_time",
        "desc"
      )
    );
  }, []);

  const getOrders = async () => {
    if (!account) return;

    const response = await userService.getPurchases(account, { chain: appContext.selectedChain.name });
    setOrders(response.data || []);
    setIsLoading(false);
  };

  const onRepaySuccess = async (hash: string) => {
    getOrders();
    setWatchedTxHash(hash);
  };

  useEffect(() => {
    getOrders();
  }, [account]);

  return (
    <Stack spacing={8}>
      <Flex borderRadius="lg" borderWidth={1} width={{ lg: "70%" }} alignSelf={"center"} margin="10px 0px">
        <Box
          p={{ base: 3, md: 4 }}
          flex={1}
          borderRadius="lg"
          background={colors.primary}
          textAlign={"center"}
          fontWeight={500}
          color={"#fff"}
          fontSize={{ base: "md", md: "xl" }}
        >
          {t("Total you owe")}: <b>{(borrowAmount / 1e18).toFixed(2)}$</b>
        </Box>
        <Box p={{ base: 3, md: 4 }} flex={1} textAlign={"center"} fontWeight={500} fontSize={{ base: "md", md: "xl" }}>
          {t("Credit balance")}: <b>{((creditAmount - borrowAmount) / 1e18).toFixed(2)}$</b>
        </Box>
      </Flex>

      {isLoading ? (
        <Flex alignItems={"center"} justifyContent={"center"}>
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </Flex>
      ) : (
        <>
          {upcomingOrders.length > 0 && (
            <Box>
              <Heading size="small">{t("Upcoming Payments")}</Heading>
              {upcomingOrders.map((order) => (
                <UpcomingOrder order={order} key={`order-${order.id}`} onRepaySuccess={onRepaySuccess} />
              ))}
            </Box>
          )}

          {completedOrders.length > 0 && (
            <Box>
              <Heading size="small">{t("Completed Payments")}</Heading>
              {completedOrders.map((order) => (
                <CompletedOrder order={order} key={`order-${order.id}`} />
              ))}
            </Box>
          )}
        </>
      )}
    </Stack>
  );
});

export default Purchases;
