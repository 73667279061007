export const uriToHttps = (uri: string | undefined): string | undefined => {
  if (!uri) return undefined;
  const protocol = uri.split(":")[0].toLowerCase();
  switch (protocol) {
    case "https":
      return uri
        .replace("https://ipfs.moralis.io:2053", "https://bepay-prod.infura-ipfs.io")
        .replace("https://gateway.moralisipfs.com", "https://bepay-prod.infura-ipfs.io");
    case "http":
      return "https" + uri.substr(4), uri;
    case "ipfs":
      const hash = uri.replace("ipfs/", "").match(/^ipfs:(\/\/)?(.*)$/i)?.[2];
      return `https://bepay-prod.infura-ipfs.io/ipfs/${hash}/`;
    case "ipns":
      const name = uri.match(/^ipns:(\/\/)?(.*)$/i)?.[2];
      return `https://bepay-prod.infura-ipfs.io/ipns/${name}/`;
    default:
      return undefined;
  }
};

export const buildIPFSUri = (cid: string) => `ipfs://ipfs/${cid}`;
