import { Box } from "@chakra-ui/react";
import parse from "html-react-parser";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ExpandableText from "../../../components/ExpandableText";
import { NftResource } from "../../../components/NftResource";
import { I18N_NAMESPACE } from "../../../core/constants/i18n";
import { env } from "../../../core/environment";
import { INftV2 } from "../../../core/models/nft";
import { buildNftHashKey } from "../../../core/utils/assets";
import { uriToHttps } from "../../../core/utils/ipfs";
import { AssetPreviewModal } from "./AssetPreviewModal";

interface Props {
  nft: INftV2 | undefined;
  maxMediaHeight?: string;
}

export const AssetPrimaryInfo = ({ nft, maxMediaHeight }: Props) => {
  const { t } = useTranslation([I18N_NAMESPACE.ASSET_DETAIL]);
  const [isVisiblePreview, setIsVisiblePreview] = useState(false);

  const hashKey = useMemo(
    () => (!!nft ? buildNftHashKey(nft.chain, nft.contract, nft.tokenId) : null),
    [nft?.chain, nft?.contract, nft?.tokenId]
  );
  const fallbackSources: string[] = useMemo(
    () =>
      [`${env.config.storage_base_url}/nfts/${hashKey}_raw`, uriToHttps(nft?.metadata?.image)].filter(
        (i) => !!i
      ) as string[],
    [hashKey, nft?.metadata?.image]
  );

  return (
    <Box>
      <Box
        onClick={(e: any) => {
          e.preventDefault();
          setIsVisiblePreview(true);
        }}
        cursor={"zoom-in"}
      >
        <NftResource
          image={`${env.config.storage_base_url}/nfts/${hashKey}_cover`}
          fallbackSources={fallbackSources}
          animationUrl={nft?.metadata?.animation_url}
          showVideoControls
          muted
          maxH={maxMediaHeight}
        />
      </Box>

      <Box py={5} cursor={"default"}>
        <ExpandableText visibleLines={3} collapedButtonText={t("[Show more]")} expandedButtonText={t("[Show less]")}>
          {!!nft?.metadata?.description && parse(nft?.metadata?.description)}
        </ExpandableText>
      </Box>

      {!!nft && <AssetPreviewModal nft={nft} isVisible={isVisiblePreview} onClose={() => setIsVisiblePreview(false)} />}
    </Box>
  );
};
