import {
  Divider,
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  HStack,
  Spinner,
  Stack,
  Text,
  useBreakpointValue,
  Skeleton,
} from "@chakra-ui/react";
import { useEthers } from "@usedapp/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { AppContent } from "../../components/AppContent";
import { SelectItem } from "../../components/AppSelect";
import { I18N_NAMESPACE } from "../../core/constants/i18n";
import { useRoyaltiesRegistryContract } from "../../core/hooks/useContract";
import { ICollection, ITopCollection } from "../../core/models/collection";
import { INft, ISummaryNft } from "../../core/models/nft";
import { ITopUser, IUser } from "../../core/models/user";
import { collectionService } from "../../services/collection.service";
import { marketplaceService } from "../../services/marketplace.service";
import { nftService } from "../../services/nft.service";
import { userService } from "../../services/user.service";
import { useAppSelector } from "../../store/hook";
import { NftItem } from "../Marketplace/components/NftItem";
import { FeaturedNftSlider } from "./components/FeaturedNftSlider";
import { TopCollections } from "./components/TopCollections";
import { TopUsers } from "./components/TopUsers";

const Dashboard = React.memo(() => {
  const { account } = useEthers();
  const navigate = useNavigate();
  const { t } = useTranslation([I18N_NAMESPACE.DASHBOARD]);
  const appContext = useAppSelector((state) => state.appContext);

  const royaltiesRegistryContract = useRoyaltiesRegistryContract();

  const [topCollections, setTopCollections] = useState<ITopCollection[]>([]);
  const [selectedTopCollectionDateOption, setSelectedTopCollectionDateOption] = useState("7");

  const [nfts, setNfts] = useState<ISummaryNft[]>([]);
  const [favoriteNfts, setFavoriteNfts] = useState<ISummaryNft[]>([]);

  const [topUsers, setTopUsers] = useState<ITopUser[]>([]);
  const [selectedTopUserDateOption, setSelectedTopUserDateOption] = useState("7");
  const [selectedUserOption, setSelectedUserOption] = useState("seller");

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTopCollection, setIsLoadingTopCollection] = useState(false);
  const [isLoadingTopUser, setIsLoadingTopUser] = useState(false);
  const [isLoadingFavorites, setIsLoadingLoadingFavorites] = useState(false);

  const [page, setPage] = useState(1);
  const [isEndOfList, setIsEndOfList] = useState(false);
  const pageSize = 15;

  const dateOptions = useMemo(
    () => [
      {
        value: "1",
        label: t("1 day"),
      },
      {
        value: "7",
        label: t("7 days"),
      },
      {
        value: "30",
        label: t("30 days"),
      },
    ],
    [t]
  );

  const userOptions = useMemo(
    () => [
      {
        value: "seller",
        label: t("sellers"),
      },
      {
        value: "buyer",
        label: t("buyers"),
      },
    ],
    [t]
  );

  async function getTopFavoriteNfts() {
    setIsLoadingLoadingFavorites(true);
    const response = await marketplaceService.getTopFavoriteNfts({
      limit: 5,
    });
    const nftWithRoyalty = await Promise.all(
      response.data.map(async (nft) => {
        if (!nft.minted && nft.royalties) {
          return {
            nft,
            royalty: nft.royalties[0].account,
          };
        }

        const royalties = await royaltiesRegistryContract?.getRoyalties(nft.contract, nft.tokenId);
        return {
          nft,
          royalty: royalties && royalties[0] ? royalties[0]["account"] : null,
        };
      })
    );

    const accountsResponse = await userService.getProfileByIds(
      nftWithRoyalty.filter((i) => !!i.royalty).map((i) => i.royalty)
    );
    setFavoriteNfts(
      nftWithRoyalty.map((i) => ({
        ...i.nft,
        royaltyInfo: accountsResponse.data.find((u) => u.id?.toLowerCase() === i.royalty?.toLowerCase()) || {
          id: i.royalty,
        },
      })) || []
    );
    setIsLoadingLoadingFavorites(false);
  }

  // Get top collections
  useEffect(() => {
    async function getTopCollections() {
      setIsLoadingTopCollection(true);
      const response = await marketplaceService.getTopCollections({
        limit: 10,
        days: +selectedTopCollectionDateOption,
      });
      setTopCollections(response?.data || []);
      setIsLoadingTopCollection(false);
    }

    getTopCollections();
  }, [selectedTopCollectionDateOption]);

  // Get top users
  useEffect(() => {
    async function getTopUsers() {
      setIsLoadingTopUser(true);

      const params = {
        chain: appContext.selectedChain.name,
        limit: 10,
        days: +selectedTopUserDateOption,
      };
      const response = await (selectedUserOption === "seller"
        ? marketplaceService.getTopSellers(params)
        : marketplaceService.getTopBuyers(params));
      setTopUsers(response?.data || []);
      setIsLoadingTopUser(false);
    }

    getTopUsers();
  }, [selectedTopUserDateOption, selectedUserOption]);

  useEffect(() => {
    getTopFavoriteNfts();
  }, [account]);

  useEffect(() => {
    async function getNfts() {
      setIsLoading(true);
      const response = await nftService.get({
        sortField: "priceInUsd",
        offset: (page - 1) * pageSize,
        limit: pageSize,
      });
      setIsLoading(false);
      setIsEndOfList(!response?.data || (response.data?.result?.length || 0) < pageSize);

      if (page > 1) {
        setNfts((v) => [...v, ...(response.data.result || [])]);
      } else {
        setNfts(response?.data.result || []);
      }
    }

    getNfts();
  }, [page]);

  const onClickMint = useCallback(() => {
    navigate("create/start");
  }, [navigate]);

  const onClickExplore = useCallback(() => {
    navigate("marketplace");
  }, [navigate]);

  const onLoadMore = useCallback(() => {
    setPage((p) => p + 1);
  }, [setPage]);

  return (
    <AppContent>
      <Stack spacing={10}>
        <Skeleton isLoaded={!isLoadingFavorites}>
          <Stack direction={{ base: "column", md: "row" }} spacing={{ base: "10", md: "10" }}>
            <Box width={{ base: "100%", md: "50%" }}>
              <FeaturedNftSlider nfts={favoriteNfts} />
            </Box>

            <Stack spacing={8} alignSelf="center" display={{ base: "none", md: "initial" }}>
              <Heading>{t("Sell Your NFT Now")}</Heading>
              <Text color={"gray.800"} fontSize={"lg"}>
                {t("The World's First Buy Now Pay Later NFT Marketplace!")}
              </Text>
              <HStack spacing={5}>
                <Button w={28} colorScheme={"blue"} onClick={onClickMint}>
                  {t("Create NFT")}
                </Button>
                <Button w={28} colorScheme={"blue"} variant={"outline"} onClick={onClickExplore}>
                  {t("Explore")}
                </Button>
              </HStack>
            </Stack>
          </Stack>
        </Skeleton>

        <TopCollections
          collections={topCollections}
          isLoading={isLoadingTopCollection}
          dateOptions={dateOptions}
          selectedDateOption={selectedTopCollectionDateOption}
          onChangeDateOption={setSelectedTopCollectionDateOption}
        />

        <TopUsers
          users={topUsers}
          isLoading={isLoadingTopUser}
          dateOptions={dateOptions}
          selectedDateOption={selectedTopUserDateOption}
          onChangeDateOption={setSelectedTopUserDateOption}
          userOptions={userOptions}
          selectedUserOption={selectedUserOption}
          onChangeUserOption={setSelectedUserOption}
        />

        <Stack>
          <Text fontSize={"2xl"} fontWeight={700}>
            {t("Explore")}
          </Text>

          <Box w={"full"}>
            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
                lg: "repeat(3, 1fr)",
                xl: "repeat(4, 1fr)",
                "2xl": "repeat(5, 1fr)",
              }}
              gap={6}
            >
              {nfts.map((nft, index) => (
                <NftItem nft={nft} key={`${nft.contract}-${nft.tokenId}-${index}`} />
              ))}
            </Grid>

            {!isEndOfList && (
              <Button
                variant={"outline"}
                onClick={onLoadMore}
                colorScheme="blue"
                marginTop={7}
                width="full"
                isLoading={isLoading}
              >
                {t("Load more")}
              </Button>
            )}
          </Box>
        </Stack>
      </Stack>
    </AppContent>
  );
});

export default Dashboard;
