import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
  Text,
} from "@chakra-ui/react";
import { useEthers } from "@usedapp/core";
import BigNumber from "bignumber.js";
import { ethers, utils } from "ethers";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProgressIcon } from "../../../components/ProgressIcon";
import { colors } from "../../../core/constants/colors";
import { I18N_NAMESPACE } from "../../../core/constants/i18n";
import { useERC20ProxyAddress } from "../../../core/hooks/useAddress";
import { useERC20Contract, useMarketplaceContract } from "../../../core/hooks/useContract";
import { INftMetadata } from "../../../core/models/nft";
import { IPurchaseV2 } from "../../../core/models/order";
import { Quote } from "../../../core/models/quote";
import { useAppSelector } from "../../../store/hook";

interface Props {
  isVisible: boolean;
  purchase: IPurchaseV2;
  periodPrice: number;
  metadata: INftMetadata | undefined;
  onClose: () => void;
  onSuccess: (hash: string) => void;
}

enum STEP {
  APPROVE_TOKEN,
  REPAY,
}

export const RepayProcessModal = React.memo((props: Props) => {
  const { account, library } = useEthers();
  const { t } = useTranslation([I18N_NAMESPACE.PROFILE]);
  const toast = useToast();

  const supportedTokens = useAppSelector((state) => state.token.supportedTokens);
  const token = useMemo(
    () => supportedTokens.byId[props.purchase.currency],
    [props.purchase.currency, supportedTokens]
  );

  const marketplaceContract = useMarketplaceContract();
  const erc20ProxyAddress = useERC20ProxyAddress();
  const tokenContract = useERC20Contract(props.purchase.currency);

  const [step, setStep] = useState(STEP.APPROVE_TOKEN);
  const [errorByStep, setErrorByStep] = useState<{ [key: number]: string | null }>({});

  const approveToken = async () => {
    try {
      setErrorByStep((v) => ({ ...v, [STEP.APPROVE_TOKEN]: null }));
      if (token.isNative) return setStep(STEP.REPAY);

      const allowance = await tokenContract?.allowance(account, erc20ProxyAddress);
      const allowanceInNumber = +utils.formatUnits(allowance, 0);

      if (!allowanceInNumber || allowanceInNumber < props.periodPrice) {
        const tx = await tokenContract?.approve(erc20ProxyAddress, ethers.constants.MaxUint256);
        await tx.wait();

        const allowance = await tokenContract?.allowance(account, erc20ProxyAddress);
        const allowanceInNumber = +utils.formatUnits(allowance, 0);

        if (allowanceInNumber < props.periodPrice) {
          return setErrorByStep((v) => ({
            ...v,
            [STEP.APPROVE_TOKEN]: t("Approval amount is not enough to buy this NFT"),
          }));
        }
      }

      setStep(STEP.REPAY);
    } catch (e: any) {
      setErrorByStep((v) => ({ ...v, [STEP.APPROVE_TOKEN]: e?.data?.message || e?.message }));
    }
  };

  const repay = async () => {
    try {
      setErrorByStep((v) => ({ ...v, [STEP.REPAY]: null }));
      if (!props.purchase.buyingQuote) return;

      const onChainQuote = await Quote.toOnChainQuote(props.purchase.buyingQuote);
      const tx = await marketplaceContract?.repay(onChainQuote, {
        value: token.isNative ? props.periodPrice : 0,
      });
      await tx.wait();
      toast({
        title: t("Success"),
        description: `${t("You successfully paid for")} ${props.metadata?.name}.`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });

      props.onSuccess(tx.hash);
    } catch (e: any) {
      setErrorByStep((v) => ({ ...v, [STEP.REPAY]: e?.data?.message || e?.message }));
    }
  };

  useEffect(() => {
    if (!props.isVisible) {
      setStep(STEP.APPROVE_TOKEN);
      setErrorByStep({});
      return;
    }

    const executionByStep = {
      [STEP.APPROVE_TOKEN]: approveToken,
      [STEP.REPAY]: repay,
    };

    const execution = executionByStep[step];
    if (execution) {
      execution();
    }
  }, [step, props.isVisible]);

  const steps = useMemo(
    () => [
      {
        id: STEP.APPROVE_TOKEN,
        title: t("Approve"),
        description: `${t("Allow the market to spend your")} ${token.symbol}.`,
        onRetry: approveToken,
      },
      {
        id: STEP.REPAY,
        title: t("Repay"),
        description: `${t("The outstanding balance shall be reduced by the instalment amount")}.`,
        onRetry: repay,
      },
    ],
    [t, approveToken, repay]
  );

  return (
    <Modal isOpen={props.isVisible} onClose={props.onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("Repay Process")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            {steps.map((i) => (
              <HStack alignItems={"flex-start"} borderRadius="lg" background="#F6F6F6" padding="20px 16px" key={i.id}>
                <ProgressIcon isLoading={step === i.id && !errorByStep[i.id]} isSuccess={step > i.id} />

                <Box>
                  <Text fontWeight="700" color={colors.primary2}>
                    {i.title}
                  </Text>
                  <Text fontSize="14px" color="#000" marginTop="8px">
                    {i.description}
                  </Text>

                  {errorByStep[i.id] ? (
                    <Box marginTop="20px">
                      <Text color={"red.500"} fontSize={"md"}>
                        {errorByStep[i.id]}
                      </Text>
                      <Button colorScheme="blue" onClick={i.onRetry} marginTop={2}>
                        {t("Try again")}
                      </Button>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              </HStack>
            ))}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});
