import {
  Box,
  Divider,
  Flex,
  Grid,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEthers } from "@usedapp/core";
import BigNumber from "bignumber.js";
import { format } from "date-fns";
import { values } from "lodash-es";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NftResource } from "../../../components/NftResource";
import { colors } from "../../../core/constants/colors";
import { I18N_NAMESPACE } from "../../../core/constants/i18n";
import { IMoralisNft, INftMetadata } from "../../../core/models/nft";
import { IPurchaseV2, ISale } from "../../../core/models/order";
import { IToken } from "../../../core/models/token";
import { getExplorerTxUrl } from "../../../core/utils/getExplorerUrl";
import { formatNumber, ordinalSurfixOf } from "../../../core/utils/number";

interface Props {
  order: IPurchaseV2;
  isVisible: boolean;
  onClose: () => void;
  metadata: INftMetadata | undefined;
  token: IToken;
}

export const CompletedOrderDetailModal = React.memo((props: Props) => {
  const { chainId } = useEthers();
  const { t, i18n } = useTranslation([I18N_NAMESPACE.PROFILE]);

  const dateFormat = useMemo(
    () => (i18n.resolvedLanguage === "vi" ? "dd/MM/yyyy" : "MM/dd/yyyy"),
    [i18n.resolvedLanguage]
  );

  return (
    <>
      <Modal isOpen={props.isVisible} onClose={props.onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader></ModalHeader>
          <ModalBody>
            <Flex justifyContent="center" alignItems="center">
              <Box borderWidth="1px" borderRadius="lg" height="80px" width="80px">
                <NftResource image={props.metadata?.image} animationUrl={props.metadata?.animation_url} muted />
              </Box>

              <Flex flex="1" marginLeft="12px" justifyContent="space-between">
                <Box>
                  <Box fontSize={"small"}>{props.order.contractName}</Box>
                  <Text color={colors.primary2} fontWeight="700" fontSize="20px">
                    {props.metadata?.name}
                  </Text>
                </Box>
              </Flex>
            </Flex>

            <Grid gridTemplateColumns={"1fr 1fr"} gridGap={3} marginTop={4}>
              <Box>
                <Text fontSize={"small"} fontWeight={"500"} color={"#6b6b6b"}>
                  {t("Item price")}
                </Text>
                <Flex alignItems={"center"}>
                  <Image src={props.token?.logoUrl} height="18px" width="18px" marginRight="6px" />
                  <Text fontWeight={500} color={colors.primary1}>
                    {formatNumber(new BigNumber(props.order.total).div(Math.pow(10, props.token.decimals)).toNumber())}
                  </Text>
                </Flex>
              </Box>

              <Box>
                <Text fontSize={"small"} fontWeight={"500"} color={"#6b6b6b"}>
                  {t("Purchased date")}
                </Text>
                <Text fontWeight={500}>{format(new Date(props.order.createdAt), dateFormat)}</Text>
              </Box>
            </Grid>

            <Box margin="16px 0px">
              <Divider />
            </Box>

            <Box>
              <Text fontWeight={700}>{t("Payment completed")}</Text>
              <Stack marginTop={3}>
                {values(props.order.payments).map((p, index) => (
                  <Flex
                    borderWidth={1}
                    padding={4}
                    borderRadius="xl"
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Stack>
                      <Text fontWeight={500} fontSize="md">
                        {t(`The ${ordinalSurfixOf(index + 1)} payment`)}
                      </Text>
                      <Text fontSize={"sm"} color="gray.500">
                        {p.createdAt && format(new Date(p.createdAt), dateFormat)}
                      </Text>
                    </Stack>
                    <Flex flexDirection={"column"} justifyContent={"center"} alignItems={"flex-end"}>
                      <Flex alignItems={"center"} justifyItems={"flex-end"}>
                        <Image src={props.token?.logoUrl} height="18px" width="18px" marginRight="6px" />
                        <Text fontWeight={500} color={colors.primary1}>
                          {formatNumber(new BigNumber(p.amount).div(Math.pow(10, props.token.decimals)).toNumber())}
                        </Text>
                      </Flex>
                      {!!chainId && (
                        <a href={getExplorerTxUrl(p.id, chainId)} target={"_blank"}>
                          <Text fontSize={"small"} fontWeight={500} cursor={"pointer"} color={colors.primary}>
                            {t("View on Explorer")}
                          </Text>
                        </a>
                      )}
                    </Flex>
                  </Flex>
                ))}
              </Stack>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
});
