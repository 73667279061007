import { ChevronRightIcon } from "@chakra-ui/icons";
import { Flex, Heading, HStack, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AppContent } from "../../components/AppContent";
import { I18N_NAMESPACE } from "../../core/constants/i18n";

const ChooseAssetType = React.memo(() => {
  const { t } = useTranslation([I18N_NAMESPACE.CREATE_ASSET]);
  const options = [
    {
      id: "erc-721",
      route: "/create/erc-721",
      title: t("Single"),
      description: t("Choose single if you would like to mint a NFT per once"),
    },
    {
      id: "erc-1155",
      route: "/create/erc-1155",
      title: t("Multiple"),
      description: t("Choose bulks if you would like to mint NFT with multiple copies per once"),
    },
  ];
  return (
    <AppContent maxWidth="800">
      <Stack spacing={10}>
        <Heading>{t("Choose Type")}</Heading>
        {/* <Text fontSize={"lg"} fontWeight="500" color="gray.500">
          Choose “Single” for one of a kind or “Multiple” if you want to sell one collectible multiple times
        </Text> */}
        <Stack spacing={6}>
          {options.map((o) => (
            <Link key={o.id} to={o.route}>
              <Flex
                borderRadius={"xl"}
                borderColor="gray.200"
                borderWidth={1}
                padding={5}
                justifyContent={"space-between"}
                alignItems="center"
                _hover={{
                  borderColor: "gray.300",
                  background: "gray.50",
                }}
                cursor="pointer"
              >
                <Stack>
                  <Heading size={"md"}>{o.title}</Heading>
                  <Text fontWeight={400} fontSize={"md"} color="gray.500">
                    {o.description}
                  </Text>
                </Stack>
                <HStack spacing={1}>
                  <Text color={"blue.500"} fontWeight="500">
                    {t("Start")}
                  </Text>
                  <ChevronRightIcon color={"blue.500"} />
                </HStack>
              </Flex>
            </Link>
          ))}
        </Stack>
      </Stack>
    </AppContent>
  );
});

export default ChooseAssetType;
