import { Box, Divider, Flex } from "@chakra-ui/layout";
import { Button, HStack, Image, Text } from "@chakra-ui/react";
import { useEthers } from "@usedapp/core";
import axios from "axios";
import BigNumber from "bignumber.js";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosHeart, IoIosHeartEmpty } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Web3 from "web3";
import { colors } from "../core/constants/colors";
import { I18N_NAMESPACE } from "../core/constants/i18n";
import { env } from "../core/environment";
import { useNftMetadata } from "../core/hooks/data/nft";
import { INftMetadata } from "../core/models/nft";
import { buildNftHashKey } from "../core/utils/assets";
import { uriToHttps } from "../core/utils/ipfs";
import { formatNumber } from "../core/utils/number";
import { generateNonce } from "../core/utils/securityUtils";
import { nftService } from "../services/nft.service";
import { userService } from "../services/user.service";
import { useAppSelector } from "../store/hook";
import { NftResource } from "./NftResource";

interface Props {
  // nft: IMoralisNft;
  // listedNft: IListedNft | undefined;
  isListed?: boolean;
  takeAssetContract?: string;
  takeAssetValue?: number;
  collectionName?: string;
  nftName?: string;
  image?: string;
  tokenUri?: string;
  ownerOf: string | undefined;
  tokenAddress: string;
  tokenId: string;
  isLoading?: boolean;
  favorites?: number;
  isFavorite?: boolean;
  chain: string;
}

export const NftItem = React.memo((props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation([I18N_NAMESPACE.PROFILE]);
  const appContext = useAppSelector((state) => state.appContext);

  const { account } = useEthers();
  const supportedTokens = useAppSelector((state) => state.token.supportedTokens);
  const token = useMemo(() => {
    return props.takeAssetContract ? supportedTokens.byId[props.takeAssetContract] : null;
  }, [supportedTokens, props.takeAssetContract]);

  const isOwned = useMemo(
    () => !!account && props.ownerOf?.toLowerCase() === account?.toLowerCase(),
    [props.ownerOf, account]
  );
  const [isHoverFavorite, setIsHoverFavorite] = useState<boolean>(false);
  const [numberOfFavorites, setNumberOfFavorites] = useState<number>(props.favorites ?? 0);
  const [isFavorited, setIsFavorited] = useState<boolean>(props.isFavorite || false);

  const { metadata } = useNftMetadata(props.tokenUri);

  const hashKey = useMemo(
    () => buildNftHashKey(props.chain, props.tokenAddress, props.tokenId),
    [props.chain, props.tokenAddress, props.tokenId]
  );
  const fallbackSources: string[] = useMemo(
    () =>
      [
        `${env.config.storage_base_url}/nfts/${hashKey}_cover`,
        `${env.config.storage_base_url}/nfts/${hashKey}_raw`,
        uriToHttps(metadata?.image),
      ].filter((i) => !!i) as string[],
    [hashKey, metadata?.image]
  );

  const onPressItem = () => {
    navigate(`/assets/${props.tokenAddress}/${props.tokenId}`);
  };

  const setFavorite = (value: boolean) => async () => {
    try {
      const response = await nftService.favorite({
        contract: props.tokenAddress,
        tokenId: props.tokenId,
        chain: appContext.selectedChain.name,
        favorited: value,
      });

      if (response.data) {
        setIsFavorited(value);
        setNumberOfFavorites(response.data.numberOfFavorites);
      }
    } catch (e) {}
  };

  const addFavorite = setFavorite(true);

  const removeFavorite = setFavorite(false);

  const FavoriteDetail = () => {
    return (
      <HStack spacing={1}>
        <Box _hover={{ transform: "scale(1.2)", transition: "all 0.1s ease 0s" }}>
          {isFavorited ? (
            <IoIosHeart
              onClick={(e) => {
                e.stopPropagation();
                removeFavorite();
              }}
              cursor={"pointer"}
            />
          ) : (
            <IoIosHeartEmpty
              onClick={(e) => {
                e.stopPropagation();
                addFavorite();
              }}
              cursor={"pointer"}
            />
          )}
        </Box>
        <Text fontSize={"sm"} fontWeight={500}>
          {numberOfFavorites}
        </Text>
      </HStack>
    );
  };

  return (
    <Flex
      borderWidth="1px"
      borderRadius="lg"
      flexDirection="column"
      overflow="hidden"
      onClick={onPressItem}
      transition={"all 0.1s ease 0s"}
      _hover={{
        boxShadow: "lg",
        transform: "translateY(-4px)",
      }}
    >
      <Box padding={1}>
        <NftResource
          image={`${env.config.storage_base_url}/nfts/${hashKey}_thumb`}
          fallbackSources={fallbackSources}
          animationUrl={metadata?.animation_url}
          muted
        />
      </Box>

      <Flex padding={3} flex={1} flexDirection="column">
        <Flex justifyContent="space-between" flex={1} gridGap={"20px"}>
          <Box flex={1} width={"0%"}>
            <Box color="gray.500" fontWeight="semibold" letterSpacing="wide" fontSize="xs" textTransform="uppercase">
              {props.collectionName}
            </Box>
            <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight" isTruncated>
              {metadata?.name}
            </Box>
          </Box>
        </Flex>

        {!!props.isListed && (
          <Box marginTop={2}>
            <Flex alignItems="center">
              <Image src={token?.logoUrl} height="16px" width="16px" marginRight="4px" />
              <Text fontSize="14px" color={colors.primary1} fontWeight="700">
                {props?.takeAssetValue
                  ? formatNumber(new BigNumber(props.takeAssetValue).div(Math.pow(10, token?.decimals || 0)).toNumber())
                  : ""}
              </Text>
            </Flex>
          </Box>
        )}

        {(isOwned || (!isOwned && props.isListed)) && (
          <Box m="8px 0px">
            <Divider />
          </Box>
        )}

        <Box>
          {isOwned && (
            <Button colorScheme={props.isListed ? "red" : "blue"} padding="0px 30px" size="sm">
              {props.isListed ? t("Delist") : t("List")}
            </Button>
          )}

          {!isOwned && props.isListed && (
            <Button colorScheme={"blue"} size="sm">
              {t("Buy now")}
            </Button>
          )}
        </Box>

        <Box m="8px 0px">
          <Divider />
        </Box>

        <Flex alignItems="end" justifyContent="end" fontSize={"14px"} fontWeight={500} padding={"4px"}>
          <FavoriteDetail />
        </Flex>
      </Flex>
    </Flex>
  );
});
