import { Box, Button, Flex, Grid, HStack, Spinner, Stack } from "@chakra-ui/react";
import { useEthers } from "@usedapp/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { AppContent } from "../../components/AppContent";
import { AppSelect, SelectItem } from "../../components/AppSelect";
import { I18N_NAMESPACE } from "../../core/constants/i18n";
import { ICollection } from "../../core/models/collection";
import { INft, ISummaryNft } from "../../core/models/nft";
import { collectionService } from "../../services/collection.service";
import { marketplaceService } from "../../services/marketplace.service";
import { nftService } from "../../services/nft.service";
import { NftItem } from "./components/NftItem";

const Marketplace = React.memo(() => {
  const { t } = useTranslation([I18N_NAMESPACE.MARKETPLACE]);

  const [collections, setCollections] = useState<ICollection[]>([]);
  const [nfts, setNfts] = useState<ISummaryNft[]>([]);
  const [selectedCollection, setSelectedCollection] = useState<SelectItem>();
  const [orderBy, setOrderBy] = useState<SelectItem>();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { account } = useEthers();

  const [page, setPage] = useState(1);
  const [isEndOfList, setIsEndOfList] = useState(false);
  const pageSize = 15;

  const collectionOptions = useMemo(
    () => collections.map((c) => ({ value: c.contract, label: c.name || "" })),
    [collections]
  );
  const orderByOptions = useMemo(
    () => [
      { value: "none", label: t("None") },
      { value: "asc", label: t("Price: Low to High") },
      { value: "desc", label: t("Price: High to Low") },
    ],
    [t]
  );

  useEffect(() => {
    async function getCollections() {
      const response = await collectionService.getOnSale();
      setCollections(response?.data || []);
    }

    getCollections();
  }, []);

  useEffect(() => {
    async function getNfts() {
      const collectionId = selectedCollection?.value.toString();
      const response = await nftService.get({
        sortDirection: orderBy?.value !== "none" ? orderBy?.value?.toString() : undefined,
        sortField: "priceInUsd",
        contract: collectionId,
        offset: (page - 1) * pageSize,
        limit: pageSize,
      });
      setIsLoading(false);
      setIsLoadingMore(false);
      setIsEndOfList(!response?.data.result || (response.data?.result.length || 0) < pageSize);

      if (page > 1) {
        setNfts((v) => [...v, ...(response.data.result || [])]);
      } else {
        setNfts(response?.data.result || []);
      }
    }

    getNfts();
  }, [orderBy, selectedCollection, page, account]);

  const onChangeCollection = useCallback((collection: SelectItem) => {
    setSelectedCollection(collection);
    setPage(1);
    setIsLoading(true);
  }, []);

  const onChangeOrderBy = useCallback((orderBy: SelectItem) => {
    setOrderBy(orderBy);
    setPage(1);
    setIsLoading(true);
  }, []);

  const onLoadMore = useCallback(() => {
    setPage((p) => p + 1);
    setIsLoadingMore(true);
  }, [setPage]);

  return (
    <AppContent>
      <Stack
        direction={{ base: "column", md: "row" }}
        justifyContent={{ base: "space-between", md: "flex-end" }}
        align={{ base: "stretch", md: "flex-end" }}
        gap="2"
      >
        <Box minW="200px">
          <AppSelect
            options={[{ value: "", label: t("All") }, ...collectionOptions]}
            value={selectedCollection}
            placeholder={t("Collections")}
            onChange={onChangeCollection}
          />
        </Box>
        <Box minW="200px">
          <AppSelect options={orderByOptions} value={orderBy} placeholder={t("Sort By")} onChange={onChangeOrderBy} />
        </Box>
      </Stack>
      {isLoading ? (
        <Flex alignItems="center" justifyContent="center" marginTop={6}>
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </Flex>
      ) : (
        <Box w={"full"}>
          <Grid
            templateColumns={{
              base: "repeat(1, 1fr)",
              md: "repeat(2, 1fr)",
              lg: "repeat(3, 1fr)",
              xl: "repeat(4, 1fr)",
              "2xl": "repeat(5, 1fr)",
            }}
            gap={6}
          >
            {nfts.map((nft, index) => (
              <NftItem nft={nft} key={`${nft.contract}-${nft.tokenId}-${index}`} />
            ))}
          </Grid>

          {!isEndOfList && (
            <Button
              variant={"outline"}
              onClick={onLoadMore}
              colorScheme="blue"
              marginTop={7}
              width="full"
              isLoading={isLoadingMore}
            >
              {t("Load more")}
            </Button>
          )}
        </Box>
      )}
    </AppContent>
  );
});

export default Marketplace;
