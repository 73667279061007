import { WarningIcon } from "@chakra-ui/icons";
import { Box, Heading } from "@chakra-ui/layout";
import { Button, Flex, Spinner, Tooltip } from "@chakra-ui/react";
import { TransactionStatus, useEthers } from "@usedapp/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../../core/constants/colors";
import { I18N_NAMESPACE } from "../../../core/constants/i18n";
import { CREDIT_STATUS } from "../../../core/constants/status";
import { useCreditStatus } from "../../../core/hooks/useStatus";
import { useAppSelector } from "../../../store/hook";

interface Props {
  onVerifyBuyer: () => void;
  onClaim: () => void;
  isVerifingBuyer: boolean;
  isClamingCredit: boolean;
  claimTransactionState: TransactionStatus;
}

export const VerifyCreditRow = React.memo((props: Props) => {
  const { account } = useEthers();
  const userProfile = useAppSelector((state) => state.user.profile);
  const isClaimed = useCreditStatus(account);
  const { t } = useTranslation([I18N_NAMESPACE.PROFILE]);
  const textByStatus: { [key: string]: string } = {
    [CREDIT_STATUS.NOT_VERIFY]: t("Haven’t verified yet"),
    [CREDIT_STATUS.REJECTED]: t("Rejected"),
    [CREDIT_STATUS.SUBMITTED]: t("Submitted"),
    [CREDIT_STATUS.VERIFIED]: t("Verified"),
    [CREDIT_STATUS.VERIFYING]: t("Verifying"),
  };

  const renderAction = () => {
    if (isClaimed) {
      return <div />;
    } else if (
      !userProfile?.creditStatus ||
      userProfile.creditStatus === CREDIT_STATUS.NOT_VERIFY ||
      userProfile.creditStatus === CREDIT_STATUS.REJECTED
    ) {
      return (
        <a href="https://share.hsforms.com/1hZYJa8kER02j3iDqoTjkhg2n1gz" target={"_blank"}>
          <Button
            colorScheme="blue"
            variant="ghost"
            background="blue.50"
            // onClick={props.onVerifyBuyer}
            // isLoading={props.isVerifingBuyer}
          >
            {t("Verify Now")}
          </Button>
        </a>
      );
    } else if (userProfile.creditStatus === CREDIT_STATUS.VERIFIED && !isClaimed) {
      return (
        <Button
          colorScheme="blue"
          variant="ghost"
          background="blue.50"
          onClick={props.onClaim}
          isLoading={props.isClamingCredit}
        >
          {t("Claim")}
        </Button>
      );
    } else if (userProfile.creditStatus === CREDIT_STATUS.VERIFYING) {
      return <Spinner thickness="3px" speed="0.65s" emptyColor="gray.200" color="blue.500" />;
    } else {
      return <div />;
    }
  };

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Box>
        <Flex>
          <Heading size="md">{t("Buy Now Pay Later")}</Heading>
          <Tooltip
            hasArrow
            label={t("Each verified account will get $250 credit limit.")}
            borderRadius="lg"
            padding="6px"
            placement="top"
          >
            <WarningIcon w={4} h={4} color={colors.primary} marginLeft="6px" />
          </Tooltip>
        </Flex>
        <Box color={"#A6A6A6"}>
          {isClaimed ? t("Claimed") : textByStatus[userProfile?.creditStatus || CREDIT_STATUS.NOT_VERIFY]}
        </Box>
      </Box>

      {renderAction()}
    </Flex>
  );
});
