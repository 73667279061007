import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const FIREBASE_CONFIG = {
  apiKey: "AIzaSyBvoHC91fN1nDzUsLuH1YTiFD-r6q6ZmDw",
  authDomain: "bepay-dev.firebaseapp.com",
  projectId: "bepay-dev",
  storageBucket: "bepay-dev.appspot.com",
  messagingSenderId: "571892265521",
  appId: "1:571892265521:web:90390581563134da7e5b10",
  measurementId: "G-5BQBETBSK2",
};

export const app = initializeApp(FIREBASE_CONFIG);
export const auth = getAuth(app);
export const storage = getStorage(app);
