import { WarningIcon } from "@chakra-ui/icons";
import { Box, Flex, HStack, Stack } from "@chakra-ui/layout";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/modal";
import { Button, Image, Radio, RadioGroup, Text, Tooltip } from "@chakra-ui/react";
import { useEthers } from "@usedapp/core";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NftResource } from "../../../../components/NftResource";
import { ProgressIcon } from "../../../../components/ProgressIcon";
import { colors } from "../../../../core/constants/colors";
import { I18N_NAMESPACE } from "../../../../core/constants/i18n";
import { APPROVE_STRATEGY, NFT_TYPE } from "../../../../core/constants/nft";
import { useMarketplaceAddress, useNftProxyAddress } from "../../../../core/hooks/useAddress";
import { useERC721Contract, useHelperContract } from "../../../../core/hooks/useContract";
import { INftOwner, INftV2 } from "../../../../core/models/nft";
import { Quote, signQuote } from "../../../../core/models/quote";
import { IToken } from "../../../../core/models/token";
import { IUser } from "../../../../core/models/user";
import { getSalt } from "../../../../core/utils/getSalt";
import { formatNumber, toPlainString } from "../../../../core/utils/number";
import { quoteService } from "../../../../services/quote.service";
import { useAppSelector } from "../../../../store/hook";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onSuccess: () => void;
  nft: INftV2;
  price: number;
  token: IToken;
  quantity: number;
  owner: IUser | undefined;
}

enum STEP {
  APPROVE_TOKEN,
  LIST,
}

export const ListingProcessModal = React.memo((props: Props) => {
  const { account, library } = useEthers();
  const [approveStrategy, setApproveStrategy] = useState(APPROVE_STRATEGY.ONLY_ITEM);
  const collectionContract = useERC721Contract(props?.nft.contract);
  const nftTransferProxyAddress = useNftProxyAddress();
  const { t } = useTranslation([I18N_NAMESPACE.ASSET_DETAIL]);

  const marketplaceAddress = useMarketplaceAddress();
  const helperContract = useHelperContract();
  const appContext = useAppSelector((state) => state.appContext);

  const [step, setStep] = useState(STEP.APPROVE_TOKEN);

  const [isApproving, setIsApproving] = useState(false);
  const [isListing, setIsListing] = useState(false);

  const [errorByStep, setErrorByStep] = useState<{ [key: number]: string | null }>({});

  const getIsApproved = async () => {
    const isApprovedItem =
      props.nft.contractType === NFT_TYPE.ERC_721 && props.nft.minted
        ? await collectionContract?.getApproved(props.nft.tokenId)
        : ethers.constants.AddressZero;

    const isApprovedForAll = await collectionContract?.isApprovedForAll(account, nftTransferProxyAddress);

    return isApprovedForAll || isApprovedItem === nftTransferProxyAddress;
  };

  const onApprove = async () => {
    try {
      setIsApproving(true);
      setErrorByStep((v) => ({ ...v, [STEP.APPROVE_TOKEN]: null }));

      await approve();
      setStep(STEP.LIST);
    } catch (e: any) {
      setErrorByStep((v) => ({ ...v, [STEP.APPROVE_TOKEN]: e?.data?.message || e?.message }));
    } finally {
      setIsApproving(false);
    }
  };

  const approve = useCallback(async () => {
    const tx = await (approveStrategy === APPROVE_STRATEGY.ALL ||
    props.nft.contractType === NFT_TYPE.ERC_1155 ||
    !props.nft.minted
      ? collectionContract?.setApprovalForAll(nftTransferProxyAddress, true)
      : collectionContract?.approve(nftTransferProxyAddress, props.nft.tokenId));

    await tx.wait();
  }, []);

  const approveToken = async () => {
    try {
      setIsApproving(true);
      setErrorByStep((v) => ({ ...v, [STEP.APPROVE_TOKEN]: null }));

      const isApproved = await getIsApproved();

      if (isApproved) {
        setStep(STEP.LIST);
        return;
      }

      if (!isApproved && (props.nft.contractType === NFT_TYPE.ERC_1155 || !props.nft.minted)) {
        await approve();
        setStep(STEP.LIST);
      }
    } catch (e: any) {
      setErrorByStep((v) => ({ ...v, [STEP.APPROVE_TOKEN]: e?.data?.message || e?.message }));
    } finally {
      setIsApproving(false);
    }
  };

  const list = async () => {
    try {
      setIsListing(true);
      setErrorByStep((v) => ({ ...v, [STEP.LIST]: null }));

      const { price, quantity, token, nft, owner } = props;
      if (!price || !account || !library || !nft?.contract) return;

      const priceBN = new BigNumber(price);
      const quote: Quote = {
        maker: account.toLowerCase(),
        salt: getSalt(),
        make: {
          assetType: {
            assetClass: !nft.minted ? `${nft?.contractType || "ERC721"}_LAZY` : nft?.contractType || "ERC721",
            tokenId: nft?.tokenId,
            contract: nft?.contract.toLowerCase(),
            ...(!nft.minted && {
              creators: nft.creators,
              royalties: nft.royalties,
              uri: nft.uri,
              signatures: nft.signatures,
              supply: quantity,
            }),
          },
          value: toPlainString(Math.floor(quantity)),
        },
        taker: ethers.constants.AddressZero,
        take: {
          assetType: {
            assetClass: token.isNative ? "ETH" : "ERC20",
            contract: token.isNative ? "0x" : token.underlying.toLowerCase(),
          },
          value: toPlainString(
            Math.floor(priceBN.multipliedBy(Math.pow(10, token.decimals)).multipliedBy(quantity).toNumber())
          ),
        },
        type: "V1",
        data: {
          isMakeFill: true,
          payouts: [],
          originFees: toPlainString(owner?.commissionFee || 75000000000000000),
          dataType: "V1",
        },
        signature: "0x",
      };

      const onChainQuote = await Quote.toOnChainQuote(quote, helperContract);
      const signature = await signQuote(library, onChainQuote, account, marketplaceAddress);

      await quoteService.create({
        ...quote,
        signature,
        chain: appContext.selectedChain.name,
      });

      props.onSuccess();
    } catch (e: any) {
      setErrorByStep((v) => ({ ...v, [STEP.LIST]: e?.response?.data?.error || e?.data?.message || e?.message }));
    } finally {
      setIsListing(false);
    }
  };

  useEffect(() => {
    if (!props.isVisible) {
      resetState();
      return;
    }

    const executionByStep = {
      [STEP.APPROVE_TOKEN]: approveToken,
      [STEP.LIST]: list,
    };

    const execution = executionByStep[step];
    if (execution) {
      execution();
    }
  }, [props.isVisible, step]);

  const resetState = () => {
    setStep(STEP.APPROVE_TOKEN);
    setErrorByStep({});
  };

  return (
    <>
      <Modal isOpen={props.isVisible} onClose={props.onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("Complete Listing")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex justifyContent="center" alignItems="center">
              <Box borderWidth="1px" borderRadius="lg" height="80px" width="80px">
                <NftResource image={props.nft.metadata?.image} animationUrl={props.nft.metadata?.animation_url} muted />
              </Box>

              <Flex flex="1" marginLeft="12px" justifyContent="space-between">
                <Box>
                  <Box fontSize={"small"}>{props.nft?.contractName}</Box>
                  <Text color={colors.primary2} fontWeight="700" fontSize="20px">
                    {props.nft?.metadata?.name}
                  </Text>
                </Box>

                <Flex flexDirection="column" alignItems="flex-end">
                  <Text color={colors.primary2} fontWeight="500">
                    {t("Price")}
                  </Text>
                  <Flex marginTop="4px">
                    <Image src={props.token?.logoUrl} height="20px" width="20px" marginRight="4px" />
                    <Text fontWeight="700" color={colors.primary}>
                      {formatNumber(props.price * props.quantity)}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>

            <HStack alignItems="flex-start" borderRadius="lg" marginTop="20px" background="#F6F6F6" padding="20px 16px">
              <ProgressIcon isLoading={isApproving} isSuccess={step > STEP.APPROVE_TOKEN} />
              <Box flex={1}>
                <Text fontWeight="700" color={colors.primary2}>
                  {t("Approve")}
                </Text>
                <Text fontSize="14px" color="#000" marginTop="8px">
                  {t("To get set up for listing for the first time, you must approve this item for sale.")}
                </Text>

                {!isApproving &&
                  props.nft.contractType !== NFT_TYPE.ERC_1155 &&
                  props.nft.minted &&
                  step === STEP.APPROVE_TOKEN && (
                    <>
                      <RadioGroup
                        onChange={setApproveStrategy}
                        value={approveStrategy}
                        marginTop={"16px"}
                        marginLeft="16px"
                        colorScheme="blue"
                      >
                        <Stack>
                          <Radio value={APPROVE_STRATEGY.ONLY_ITEM}>
                            <Text fontSize="14px" color={colors.primary2} fontWeight="500">
                              {t("Approve this NFT only.")}
                            </Text>
                          </Radio>
                          <Radio value={APPROVE_STRATEGY.ALL}>
                            <Flex>
                              <Text fontSize="14px" color={colors.primary2} fontWeight="500">
                                {t("Approve all of my NFTs in this collection.")}
                              </Text>
                              <Tooltip
                                hasArrow
                                label={t(
                                  "Choosing this option so you won't need to approve any NFT for sale from this collection (save gas fee)."
                                )}
                                borderRadius="lg"
                                padding="6px"
                                placement="top"
                              >
                                <WarningIcon w={4} h={4} color={colors.primary} marginLeft="6px" />
                              </Tooltip>
                            </Flex>
                          </Radio>
                        </Stack>
                      </RadioGroup>
                      {!errorByStep[STEP.APPROVE_TOKEN] && (
                        <Box marginTop="8px">
                          <Button colorScheme="blue" onClick={onApprove} isLoading={isApproving} marginTop={2}>
                            {t("Approve")}
                          </Button>
                        </Box>
                      )}
                    </>
                  )}

                {!!errorByStep[STEP.APPROVE_TOKEN] && (
                  <Box marginTop="8px">
                    <Text color={"red.500"} fontSize={"md"}>
                      {errorByStep[STEP.APPROVE_TOKEN]}
                    </Text>
                    <Button colorScheme="blue" onClick={onApprove} isLoading={isApproving} marginTop={2}>
                      {t("Try again")}
                    </Button>
                  </Box>
                )}
              </Box>
            </HStack>

            <HStack alignItems="flex-start" borderRadius="lg" marginTop="20px" background="#F6F6F6" padding="20px 16px">
              <ProgressIcon isLoading={step === STEP.LIST && isListing} isSuccess={false} />
              <Box flex={1}>
                <Text fontWeight="700" color={colors.primary2}>
                  {t("Put on sale")}
                </Text>
                <Text fontSize="14px" color="#000" marginTop="8px">
                  {t("Sign your wallet to confirm item price")}.
                </Text>
                <Box marginTop="20px">
                  {!!errorByStep[STEP.LIST] && (
                    <Box>
                      <Text color={"red.500"} fontSize={"md"}>
                        {errorByStep[STEP.LIST]}
                      </Text>
                      <Button colorScheme="blue" isLoading={isListing} onClick={list} marginTop={2}>
                        {t("Try again")}
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
});
