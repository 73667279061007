import { CheckIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Flex,
  Grid,
  Heading,
  HStack,
  useClipboard,
  Text,
  Image,
  Stack,
  SkeletonCircle,
  Skeleton,
  VStack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import { shortenAddress } from "@usedapp/core";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContent } from "../../components/AppContent";
import { NftItem } from "../../components/NftItem";
import { images } from "../../core/constants/images";
import { ICollection } from "../../core/models/collection";
import { INft, INftV2, ISummaryNft } from "../../core/models/nft";
import { IUser } from "../../core/models/user";
import { collectionService } from "../../services/collection.service";
import { nftService } from "../../services/nft.service";
import { userService } from "../../services/user.service";
import { Helmet } from "react-helmet";
import { SharedButton } from "../../components/SharedButton";
import { useTranslation } from "react-i18next";
import { I18N_NAMESPACE } from "../../core/constants/i18n";
import ExpandableText from "../../components/ExpandableText";
import { useAppSelector } from "../../store/hook";
import { isEmpty } from "lodash-es";

const CollectionDetail = () => {
  const params = useParams();
  const { t } = useTranslation([I18N_NAMESPACE.COLLECTION_DETAIL]);
  const navigate = useNavigate();
  const appContext = useAppSelector((state) => state.appContext);

  const { hasCopied: hasCopiedAddress, onCopy: onCopyAddress } = useClipboard(params?.id || "");
  const [collection, setCollection] = useState<ICollection>();
  const [mintedNfts, setMintedNfts] = useState<ISummaryNft[]>();
  const [lazyNfts, setLazyNfts] = useState<ISummaryNft[]>();
  const [nftCount, setNftCount] = useState<number>();
  const [owner, setOwner] = useState<IUser>();

  const [isFetchingNfts, setIsFetchingNfts] = useState(false);
  const [isFetchingCollectionInfo, setIsFetchingCollectionInfo] = useState(false);

  useEffect(() => {
    async function getDetail() {
      if (!params.id) return;
      setIsFetchingCollectionInfo(true);
      const result = await collectionService.getDetail(appContext.selectedChain.name, params.id);

      if (result.data.owner) {
        const ownerResult = await userService.getProfileById(result.data.owner);
        setOwner(ownerResult.data);
      }

      setCollection(result.data);
      setIsFetchingCollectionInfo(false);
    }

    async function getMintedNfts() {
      if (!params.id) return;
      setIsFetchingNfts(true);
      const response = await collectionService.getMintedNfts(appContext.selectedChain.name, params.id, {
        offset: 0,
        limit: 50,
      });
      setMintedNfts(response?.data.result || []);
      setIsFetchingNfts(false);
    }

    async function getLazyNfts() {
      if (!params.id) return;
      setIsFetchingNfts(true);
      const response = await collectionService.getLazyNfts(appContext.selectedChain.name, params.id, {
        offset: 0,
        limit: 100000,
      });
      setLazyNfts(response?.data.result || []);
      setNftCount(response.data.total);
      setIsFetchingNfts(false);
    }

    getDetail();
    getMintedNfts();
    getLazyNfts();
  }, []);

  const onClickOwner = () => {
    navigate(`/profile/assets/${owner?.id}/minted`);
  };

  return (
    <VStack>
      <Box height={{ base: "120px", md: "240px" }} backgroundColor="gray.200" width="100%">
        <Skeleton isLoaded={!isFetchingCollectionInfo} height={"full"} width={"full"}>
          <Image
            src={collection?.banner}
            fallbackSrc={images.collectionCoverDefault}
            objectFit={"cover"}
            width="100%"
            height="100%"
          />
        </Skeleton>
      </Box>
      <AppContent>
        <Grid gridTemplateColumns={{ base: "1fr", md: "1fr 2.3fr" }} gridGap={12} paddingBottom={5}>
          <Box position={"relative"}>
            <Flex flexDirection="column" alignItems="flex-start">
              <Box
                height={"140px"}
                width={"140px"}
                position="absolute"
                top="-100px"
                background={"#fff"}
                padding={2}
                borderRadius={"full"}
              >
                <SkeletonCircle isLoaded={!isFetchingCollectionInfo} height={"full"} width={"full"}>
                  <Image
                    height="100%"
                    width="100%"
                    // size="2xl"
                    src={collection?.image || ""}
                    borderRadius={"full"}
                    fallbackSrc={images.collectionLogoDefault}
                  />
                </SkeletonCircle>
              </Box>

              <Skeleton isLoaded={!isFetchingCollectionInfo} marginTop={14} minHeight={"40px"} w={"100%"}>
                <Flex pt={0} width={"100%"} justifyContent={"space-between"}>
                  <Heading size="lg">{collection?.name}</Heading>
                  <SharedButton content={`Checkout ${collection?.name}'s profile on @bePAY`} />
                </Flex>
              </Skeleton>

              <Skeleton isLoaded={!isFetchingCollectionInfo} marginTop={"12px"}>
                <Flex alignItems={"center"}>
                  <HStack
                    fontWeight="700"
                    color={"rgb(128, 128, 128)"}
                    fontSize={"14px"}
                    background="rgba(4, 4, 5, 0.04)"
                    padding="4px 12px"
                    borderRadius="full"
                    onClick={onCopyAddress}
                    cursor={"pointer"}
                    alignItems={"center"}
                    transition={"all 0.12s ease-in-out 0s"}
                    _hover={{
                      background: "rgba(4, 4, 5, 0.08)",
                    }}
                  >
                    {hasCopiedAddress ? (
                      <>
                        <Text>{t("Copied")}</Text>
                        <CheckIcon />
                      </>
                    ) : (
                      <>
                        <Image src={images.ethHighlight} height={4} width={4} />
                        <Text>{params.id && shortenAddress(params.id)}</Text>
                      </>
                    )}
                  </HStack>
                  {!!owner && (
                    <>
                      <Text padding={"0px 8px"} fontSize={"small"} color={"gray.400"}>
                        {t("by")}
                      </Text>
                      <HStack onClick={onClickOwner} cursor={"pointer"} _hover={{ color: "#000" }}>
                        <Avatar size={"sm"} src={owner?.image || ""} />
                        <Text fontWeight={500}>{owner.displayName || (owner.id && shortenAddress(owner.id))}</Text>
                      </HStack>
                    </>
                  )}
                </Flex>
              </Skeleton>

              {/* <Skeleton isLoaded={!isFetchingCollectionInfo} marginTop={"20px"} width={"full"}>
                <Flex borderWidth={1} gap={4} borderRadius={"xl"} width={"100%"}>
                  <Stack alignItems={"center"} padding={3}>
                    <Text fontSize={"small"} color={"gray.500"} textAlign="center">
                      {t("Collection of")}
                    </Text>
                    <Text fontWeight={700}>{nftCount || "—"}</Text>
                  </Stack>
                  <Stack alignItems={"center"} padding={3}>
                    <Text fontSize={"small"} color={"gray.500"} textAlign="center">
                      {t("Highest price")}
                    </Text>
                    <Text fontWeight={700}>—</Text>
                  </Stack>
                  <Stack alignItems={"center"} padding={3}>
                    <Text fontSize={"small"} color={"gray.500"} textAlign="center">
                      {t("Total Volume")}
                    </Text>
                    <Text fontWeight={700}>—</Text>
                  </Stack>
                </Flex>
              </Skeleton> */}

              {(isFetchingCollectionInfo || !!collection?.description) && (
                <Skeleton isLoaded={!isFetchingCollectionInfo} marginTop={"20px"} width={"full"}>
                  <ExpandableText
                    textColor={"gray.500"}
                    fontSize="sm"
                    visibleLines={{ base: 3, md: 0 }}
                    collapedButtonText={t("[Read more]")}
                    expandedButtonText={t("[Read less]")}
                    wordBreak={"break-word"}
                  >
                    {collection?.description}
                  </ExpandableText>
                </Skeleton>
              )}
            </Flex>
          </Box>

          <Tabs variant="enclosed">
            <TabList>
              <Tab fontWeight={700}>{t("Minted")}</Tab>
              <Tab fontWeight={700}>{t("Lazy")}</Tab>
            </TabList>
            <TabPanels>
              <TabPanel padding={"20px 0px"}>
                <Skeleton isLoaded={!isFetchingCollectionInfo} width={"full"}>
                  <Grid
                    templateColumns={{
                      base: "repeat(1, 1fr)",
                      lg: "repeat(2, 1fr)",
                      xl: "repeat(3, 1fr)",
                      "2xl": "repeat(4, 1fr)",
                    }}
                    gap={6}
                  >
                    {mintedNfts?.map((nft) => (
                      <NftItem
                        key={nft.tokenId}
                        tokenAddress={nft.contract}
                        tokenId={nft.tokenId}
                        tokenUri={nft.uri}
                        takeAssetContract={nft.makeQuote?.takeAssetContract}
                        ownerOf={nft.owner}
                        collectionName={nft.contractName}
                        nftName={nft.name}
                        image={nft.image}
                        isListed={!isEmpty(nft?.makeQuote)}
                        takeAssetValue={parseFloat(nft?.makeQuote?.takeAssetValue || "0")}
                        chain={nft.chain}
                      />
                    ))}
                  </Grid>
                </Skeleton>
              </TabPanel>
              <TabPanel padding={"20px 0px"}>
                <Skeleton isLoaded={!isFetchingCollectionInfo} width={"full"}>
                  <Grid
                    templateColumns={{
                      base: "repeat(1, 1fr)",
                      lg: "repeat(2, 1fr)",
                      xl: "repeat(3, 1fr)",
                      "2xl": "repeat(4, 1fr)",
                    }}
                    gap={6}
                  >
                    {lazyNfts?.map((nft) => (
                      <NftItem
                        key={nft.tokenId}
                        tokenAddress={nft.contract}
                        tokenId={nft.tokenId}
                        tokenUri={nft.uri}
                        takeAssetContract={nft.makeQuote?.takeAssetContract}
                        ownerOf={nft.owner}
                        collectionName={nft.contractName}
                        nftName={nft.name}
                        image={nft.image}
                        isListed={!isEmpty(nft?.makeQuote)}
                        takeAssetValue={parseFloat(nft?.makeQuote?.takeAssetValue || "0")}
                        chain={nft.chain}
                      />
                    ))}
                  </Grid>
                </Skeleton>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Grid>
      </AppContent>
    </VStack>
  );
};

export default React.memo(CollectionDetail);
