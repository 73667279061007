import { Avatar } from "@chakra-ui/avatar";
import { CheckIcon } from "@chakra-ui/icons";
import { Box, Divider, Flex, Grid, Heading, HStack, Link, Stack } from "@chakra-ui/layout";
import { AspectRatio, Button, Image, Skeleton, SkeletonCircle, Text, useClipboard } from "@chakra-ui/react";
import { shortenAddress, useEthers } from "@usedapp/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import { Outlet, useLocation, useNavigate, useParams } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { AppContent } from "../components/AppContent";
import ExpandableText from "../components/ExpandableText";
import { SharedButton } from "../components/SharedButton";
import { I18N_NAMESPACE } from "../core/constants/i18n";
import { images } from "../core/constants/images";
import { IUser } from "../core/models/user";
import { userService } from "../services/user.service";

export const ProfileModule = React.memo(() => {
  const { account } = useEthers();
  const { t } = useTranslation([I18N_NAMESPACE.PROFILE]);
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState<IUser>();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const isOwned = useMemo(
    () => !params.id || params.id === "owned" || params.id?.toLowerCase() === account?.toLowerCase(),
    [params.id, account]
  );

  const { hasCopied: hasCopiedAddress, onCopy: onCopyAddress } = useClipboard((isOwned ? account : params.id) || "");

  const facebookLink = useMemo(() => userProfile?.links?.find((i) => i.type === "facebook"), [userProfile]);
  const instagramLink = useMemo(() => userProfile?.links?.find((i) => i.type === "instagram"), [userProfile]);
  const twitterLink = useMemo(() => userProfile?.links?.find((i) => i.type === "twitter"), [userProfile]);

  const getIdFromLink = (link: string) => {
    const match = link.match(/^(?:.*)\/(?:pages\/[A-Za-z0-9-]+\/)?(?:profile\.php\?id=)?([A-Za-z0-9.]+)/);
    if (match) {
      return `@${match[1]}`;
    }
    return link;
  };

  const facebookId = useMemo(() => {
    if (!facebookLink?.url) return "";
    return getIdFromLink(facebookLink?.url);
  }, [facebookLink]);

  const instagramId = useMemo(() => {
    if (!instagramLink?.url) return "";
    return getIdFromLink(instagramLink?.url);
  }, [instagramLink]);

  const twitterId = useMemo(() => {
    if (!twitterLink?.url) return "";
    return getIdFromLink(twitterLink?.url);
  }, [twitterLink]);

  const navItems = useMemo(
    () =>
      [
        {
          route: `collections/${isOwned ? "owned" : params.id}`,
          name: t("Collections"),
          visible: true,
          matchRoutes: [`collections/owned`, `collections/${params.id}`],
        },
        {
          route: "purchases",
          name: t("Purchases"),
          visible: isOwned,
          matchRoutes: [`purchases`],
        },
        {
          route: `assets/${isOwned ? "owned" : params.id}/minted`,
          name: t("Owned"),
          visible: true,
          matchRoutes: [`assets/owned/minted`, `assets/${params.id}/minted`],
        },
        {
          route: `assets/${isOwned ? "owned" : params.id}/lazy`,
          name: t("Lazy mint"),
          visible: true,
          matchRoutes: [`assets/owned/lazy`, `assets/${params.id}/lazy`],
        },
        {
          route: "sale-history",
          name: t("Sales History"),
          visible: isOwned,
          matchRoutes: [`sale-history`],
        },
      ].filter((i) => i.visible),
    [params.id, isOwned, t]
  );

  useEffect(() => {
    async function getProfile() {
      const id = params?.id || account;
      if (!id) return;
      setIsLoading(true);
      const response = await userService.getProfileById(id);
      setUserProfile(response?.data || {});
      setIsLoading(false);
    }

    getProfile();
  }, [params.id, account]);

  const onClickSetting = useCallback(() => {
    navigate("../setting");
  }, [navigate]);

  return (
    <>
      <Box height="260px" backgroundColor="gray.200" width="100%">
        {!!userProfile?.cover && (
          <Box width={"100%"} height={"100%"} position={"relative"}>
            <Image src={userProfile?.cover} objectFit={"cover"} width="100%" height="100%" />
          </Box>
        )}
      </Box>
      <AppContent>
        <Grid gridTemplateColumns={{ base: "1fr", md: "1fr 2.3fr" }} gridGap={10} pb={{ base: 3, lg: 5 }}>
          <Box position={"relative"}>
            <Stack flexDirection="column" alignItems="flex-start" spacing={8}>
              <Box w={"full"}>
                <Box
                  height="140px"
                  width="140px"
                  position="absolute"
                  top="-70px"
                  background={"#fff"}
                  padding={2}
                  borderRadius={"full"}
                  transition={"all 0.1s ease 0s"}
                >
                  {isLoading ? (
                    <SkeletonCircle size="full" />
                  ) : (
                    <Avatar name="" height="100%" width="100%" size="2xl" src={userProfile?.image || ""} />
                  )}
                </Box>

                <Box height="70px" />
                <Skeleton isLoaded={!isLoading} width={"fit-content"} minW={"40%"}>
                  <HStack
                    fontWeight="700"
                    marginTop="20px"
                    color={"rgb(128, 128, 128)"}
                    fontSize={"14px"}
                    background="rgba(4, 4, 5, 0.04)"
                    padding="4px 12px"
                    borderRadius="full"
                    onClick={onCopyAddress}
                    cursor={"pointer"}
                  >
                    {hasCopiedAddress ? (
                      <>
                        <Text>{t("Copied")}</Text>
                        <CheckIcon />
                      </>
                    ) : (
                      <>
                        <Image src={images.ethHighlight} height={4} width={4} />
                        <Text>
                          {isOwned && account
                            ? shortenAddress(account)
                            : userProfile?.id
                            ? shortenAddress(userProfile.id)
                            : ""}
                        </Text>
                      </>
                    )}
                  </HStack>
                </Skeleton>
                <Skeleton isLoaded={!isLoading}>
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    marginTop="12px"
                    gridGap={"10px"}
                    w={"100%"}
                  >
                    <Heading size="lg">{userProfile?.displayName || t("Unnamed")}</Heading>

                    <HStack>
                      <SharedButton
                        link={`${window.location.origin}/profile/assets/${userProfile?.id}/minted`}
                        content={t("Check out profile on @bePAY", {
                          name: userProfile?.displayName || userProfile?.id,
                        })}
                      />
                      {isOwned && (
                        <Button variant="outline" onClick={onClickSetting}>
                          {t("Edit Profile")}
                        </Button>
                      )}
                    </HStack>
                  </Flex>
                </Skeleton>
              </Box>

              {!!userProfile?.video && (
                <AspectRatio ratio={16 / 9} w="full">
                  <Box borderRadius={"xl"}>
                    <video width={"100%"} height={"100%"} autoPlay controls loop src={userProfile?.video} />
                  </Box>
                </AspectRatio>
              )}

              {userProfile?.links?.some((i) => !!i.url) && (
                <Skeleton isLoaded={!isLoading} width={"full"}>
                  <Stack width={"100%"}>
                    <Text fontSize={"lg"} fontWeight={600}>
                      {t("Links")}
                    </Text>
                    <Box width={"100%"}>
                      <Divider />
                    </Box>
                    <Stack direction={{ base: "column", md: "row" }} py={2} spacing={4}>
                      {!!facebookLink && (
                        <HStack alignItems={"center"}>
                          <FaFacebook fontSize={"24px"} />
                          <Link fontWeight={"500"} fontSize={"md"} href={facebookLink?.url} target={"_blank"}>
                            {facebookId}
                          </Link>
                        </HStack>
                      )}
                      {!!instagramLink && (
                        <HStack alignItems={"center"}>
                          <FaInstagram fontSize={"24px"} />
                          <Link fontWeight={"500"} fontSize={"md"} href={instagramLink?.url} target={"_blank"}>
                            {instagramId}
                          </Link>
                        </HStack>
                      )}
                      {!!twitterLink && (
                        <HStack alignItems={"center"}>
                          <FaTwitter fontSize={"24px"} />
                          <Link fontWeight={"500"} fontSize={"md"} href={twitterLink?.url} target={"_blank"}>
                            {twitterId}
                          </Link>
                        </HStack>
                      )}
                    </Stack>
                  </Stack>
                </Skeleton>
              )}

              {!!userProfile?.bio && (
                <Skeleton isLoaded={!isLoading} width={"full"}>
                  <Stack width={"full"}>
                    <Text fontSize={"lg"} fontWeight={600}>
                      {t("Bio")}
                    </Text>
                    <Box width={"100%"}>
                      <Divider />
                    </Box>
                    <ExpandableText
                      fontSize={"15px"}
                      color={"rgb(128, 128, 128)"}
                      visibleLines={3}
                      collapedButtonText={t("[Show more]")}
                      expandedButtonText={t("[Show less]")}
                    >
                      {userProfile?.bio}
                    </ExpandableText>
                  </Stack>
                </Skeleton>
              )}
            </Stack>
          </Box>

          <Box>
            <HStack spacing={[3, 6]} boxShadow={"rgb(230 230 230) 0px -1px 0px 0px inset"}>
              {navItems.map((i) => (
                <ProfileLink route={i.route} name={i.name} key={i.route} matchRoutes={i.matchRoutes} />
              ))}
            </HStack>

            <Box marginTop={6}>
              <Outlet />
            </Box>
          </Box>
        </Grid>
      </AppContent>
    </>
  );
});

const ProfileLink = (props: { route: string; name: string; matchRoutes: string[] }) => {
  const location = useLocation();
  const isMatched = props.matchRoutes.map((r) => `/profile/${r}` === location.pathname).some((i) => i);

  return (
    <RouterLink to={props.route} key={props.route}>
      <Box
        fontWeight="700"
        color={isMatched ? "#000" : "rgba(4, 4, 5, 0.6)"}
        _hover={{ color: "#000" }}
        borderBottom={`3px solid ${isMatched ? "#000" : "transparent"}`}
        padding={"12px 0px"}
        fontSize={["sm", "lg"]}
        transition={"all 0.1s ease 0s"}
      >
        {props.name}
      </Box>
    </RouterLink>
  );
};
