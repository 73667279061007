import { ChevronDownIcon, WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../../../core/constants/colors";
import { I18N_NAMESPACE } from "../../../../core/constants/i18n";
import { NFT_TYPE } from "../../../../core/constants/nft";
import { INftOwner, INftV2 } from "../../../../core/models/nft";
import { IUser } from "../../../../core/models/user";
import { formatNumber } from "../../../../core/utils/number";
import { useAppSelector } from "../../../../store/hook";

interface Props {
  nft: INftV2 | undefined;
  owner: IUser | undefined;
  formValues: FormikValues;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  isSubmitting: boolean;

  handleSubmit: React.FormEventHandler<HTMLFormElement>;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  handleBlur: React.FocusEventHandler<HTMLInputElement>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<any>> | Promise<void>;
}

export const FixedPriceForm = React.memo(
  ({
    nft,
    owner,
    formValues: form,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
  }: Props) => {
    const { t } = useTranslation([I18N_NAMESPACE.ASSET_DETAIL]);

    const supportedTokens = useAppSelector((state) => state.token.supportedTokens);

    return (
      <>
        <form onSubmit={handleSubmit}>
          <Stack spacing={7}>
            <Stack>
              <Heading size="sm">{t("Price")}</Heading>
              <Box>
                <Menu>
                  <InputGroup>
                    <InputLeftAddon width={"7.2rem"} as={MenuButton} type="button">
                      <HStack alignItems="center" spacing={1}>
                        <Image src={form.token?.logoUrl} height="20px" width="20px" />
                        <Text color="#666666" fontWeight="700">
                          {form.token?.symbol}
                        </Text>
                        <ChevronDownIcon color="#666666" />
                      </HStack>
                    </InputLeftAddon>
                    <Input
                      type="number"
                      value={form.price === undefined ? "" : form.price}
                      name="price"
                      onChange={handleChange}
                      placeholder={t("Enter item price for one piece")}
                      fontWeight="700"
                      onBlur={handleBlur}
                      step={"0.00000001"}
                    />
                  </InputGroup>
                  {!!errors.price && touched.price && (
                    <Text color={"red.500"} fontSize={"small"} marginTop={1}>
                      {errors.price}
                    </Text>
                  )}

                  <MenuList minWidth="240px">
                    <MenuOptionGroup
                      value={form.token.underlying}
                      title="Tokens"
                      type="radio"
                      onChange={(value) => {
                        if (typeof value !== "string") return;
                        setFieldValue("token", supportedTokens.byId[value]);
                      }}
                    >
                      {supportedTokens.allIds.map((id) => {
                        const supportedToken = supportedTokens.byId[id];
                        return (
                          <MenuItemOption value={supportedToken.underlying} key={id}>
                            <Flex alignItems="center">
                              <Image src={supportedToken?.logoUrl} height="20px" width="20px" marginRight="8px" />
                              <Text color="#666666" fontWeight="700">
                                {supportedToken?.symbol}
                              </Text>
                            </Flex>
                          </MenuItemOption>
                        );
                      })}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              </Box>
            </Stack>

            <Box>
              <Flex>
                <Heading size="sm">{t("Fee")}</Heading>
                <Tooltip
                  hasArrow
                  label={t("Once sold, the following fees will be deducted.")}
                  borderRadius="lg"
                  padding="6px"
                  placement="top"
                >
                  <WarningIcon w={4} h={4} color={colors.primary} marginLeft="6px" />
                </Tooltip>
              </Flex>

              <Box marginTop="8px">
                <Box fontWeight="500" color="#434F64">
                  {t("Service fee")}: <b>{formatNumber(2.5, { maximumFractionDigits: 2 })}%</b>
                </Box>
                {!!owner?.commissionFee && (
                  <Box fontWeight="500" color="#434F64">
                    {t("Buy Now Pay Later fee")}:{" "}
                    <b>{formatNumber((owner.commissionFee / 1e18) * 100, { maximumFractionDigits: 2 })}%</b>
                  </Box>
                )}
              </Box>
            </Box>

            {nft?.contractType === NFT_TYPE.ERC_1155 && (
              <Stack>
                <Heading size="sm">{t("Number of copies")}</Heading>
                <Box>
                  <Input
                    placeholder={t("E.g: 10")}
                    value={form.quantity}
                    name="quantity"
                    type={"number"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  {!!errors.quantity && touched.quantity && (
                    <Text color={"red.500"} fontSize={"small"} marginTop={1}>
                      {errors.quantity}
                    </Text>
                  )}
                </Box>
              </Stack>
            )}
          </Stack>

          <Box padding="24px 0px">
            <Divider />
          </Box>

          <Flex justifyContent="flex-end">
            <Button colorScheme="blue" width="150px" type="submit" isLoading={isSubmitting}>
              {t("List")}
            </Button>
          </Flex>
        </form>
      </>
    );
  }
);
