import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { NftResource } from "../../../components/NftResource";
import { IMoralisNft, INftV2 } from "../../../core/models/nft";

interface Props {
  nft: INftV2;
  isVisible: boolean;
  onClose: () => void;
}

export const AssetPreviewModal = React.memo((props: Props) => {
  return (
    <Modal isOpen={props.isVisible} onClose={props.onClose} size={"full"}>
      <ModalOverlay />
      <ModalContent borderRadius={0} justifyContent={"center"}>
        <Box display={"flex"} flexDir="column" w={"80vh"} h={"80vh"} alignSelf="center">
          <NftResource
            animationUrl={props.nft.metadata?.animation_url}
            image={props.nft.metadata?.image}
            muted
            showVideoControls
          />
        </Box>
        <IconButton
          position={"absolute"}
          bottom={8}
          right={8}
          aria-label={"Close preview"}
          icon={<CloseIcon />}
          onClick={props.onClose}
        />
      </ModalContent>
    </Modal>
  );
});
