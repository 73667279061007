import { ChainId } from "@usedapp/core";

interface IEnv {
  config: IEnvConfig;
  network: {
    [key: number]: IEnvNetwork;
  };
}

interface IEnvConfig {
  moralis: {
    app_id: string;
    server_url: string;
    api_key: string;
  };
  default_network_name: string;
  supported_chain_ids: number[];
  base_url: string;
  ipfs: {
    project_id: string;
    prject_secret: string;
    host: string;
    port: number;
    protocol: string;
  };
  lending_url: string;
  ably_private_key: string;
  storage_base_url: string;
  is_maintaining: boolean;
}

interface IEnvNetwork {
  contractHelper: {
    address: string;
  };
  controller: {
    address: string;
  };
  btokens: {
    bether: {
      name: string;
      symbol: string;
      decimals: number;
      address: string;
    };
    berc20: {
      beacon: {
        address: string;
      };
      factory: {
        address: string;
      };
      instances: {
        [key: string]: {
          name: string;
          symbol: string;
          decimals: number;
          address: string;
          underlying: string;
        };
      };
    };
  };
  royaltiesRegistry: {
    address: string;
  };
  transferProxies: {
    erc20: {
      address: string;
    };
    nft: {
      address: string;
    };
    erc721_lazy: {
      address: string;
    };
    erc1155_lazy: {
      address: string;
    };
  };
  nftMarket: {
    address: string;
  };

  nfts: {
    erc721: {
      factory: {
        address: string;
      };
      instances: {
        erc721: {
          name: string;
          symbol: string;
          address: string;
        };
      };
    };
    erc1155: {
      factory: {
        address: string;
      };
      instances: {
        erc1155: {
          name: string;
          symbol: string;
          address: string;
        };
      };
    };
  };
}

const getEnv = (): IEnv => {
  const config: IEnvConfig = {
    test1: require("./test1/config.json"),
    test2: require("./test2/config.json"),
    dev: require("./dev/config.json"),
    live: require("./live/config.json"),
    default: require("./dev/config.json"),
  }[process.env.REACT_APP_HOST_ENV || "default"];

  const bscTestnetNetwork: IEnvNetwork = {
    test1: require("./test1/bsc_testnet.json"),
    test2: require("./test2/bsc_testnet.json"),
    dev: require("./dev/bsc_testnet.json"),
    live: require("./live/bsc_testnet.json"),
    default: require("./dev/bsc_testnet.json"),
  }[process.env.REACT_APP_HOST_ENV || "default"];

  const bscNetwork: IEnvNetwork = {
    test1: require("./test1/bsc.json"),
    test2: require("./test2/bsc.json"),
    dev: require("./dev/bsc.json"),
    live: require("./live/bsc.json"),
    default: require("./live/bsc.json"),
  }[process.env.REACT_APP_HOST_ENV || "default"];

  return {
    config,
    network: {
      [ChainId.BSCTestnet]: bscTestnetNetwork,
      [ChainId.BSC]: bscNetwork,
    },
  } as IEnv;
};

export const env = getEnv();
