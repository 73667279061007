import { extendTheme } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { colors } from "../constants/colors";
import Accordion from "./components/accordion";
import Button from "./components/button";
import Input from "./components/input";
import Modal from "./components/modal";
import Textarea from "./components/textarea";

export const theme = extendTheme({
  colors: {
    blue: {
      400: colors.primary1,
      500: colors.primary,
      900: colors.primary2,
    },
  },
  components: {
    Button,
    Input,
    Textarea,
    Accordion,
    Modal,
  },
  fonts: {
    heading: '"Inter",sans-serif;',
    body: '"Inter",sans-serif;',
  },
});

export const GlobalStyles = css`
  /*
    This will hide the focus indicator if the element receives focus    via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }

  /* width */
  ::-webkit-scrollbar {
    background-color: transparent;
    border-radius: 0;
    height: 8px;
    width: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #b8b8b8;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #a1a1a1;
  }
`;
