import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../core/firebase.provider";

const upload = async (path: string, file: File) => {
  const storageRef = ref(storage, path);
  const snapshot = await uploadBytes(storageRef, file);
  return getDownloadURL(snapshot.ref);
};

export const storageSerivce = {
  upload,
};
