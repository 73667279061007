import { WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useEtherBalance, useEthers } from "@usedapp/core";
import BigNumber from "bignumber.js";
import { format } from "date-fns";
import { utils } from "ethers";
import { head, keys, sumBy, values } from "lodash-es";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorModal } from "../../../components/ErrorModal";
import { NftResource } from "../../../components/NftResource";
import { colors } from "../../../core/constants/colors";
import { I18N_NAMESPACE } from "../../../core/constants/i18n";
import { LAYER_1_TOKENS } from "../../../core/constants/token";
import { useERC20Balance } from "../../../core/hooks/useERC20Balance";
import { IMoralisNft, INftMetadata } from "../../../core/models/nft";
import { IPurchaseV2, ISale } from "../../../core/models/order";
import { IToken } from "../../../core/models/token";
import { formatNumber } from "../../../core/utils/number";
import { RepayProcessModal } from "./RepayProcessModal";

interface Props {
  order: IPurchaseV2;
  isVisible: boolean;
  onClose: () => void;
  onRepaySuccess: (hash: string) => void;
  isPaying: boolean;
  token: IToken;
  metadata: INftMetadata | undefined;
  repayError: string | undefined;
}

export const UpcomingOrderDetailModal = React.memo((props: Props) => {
  const { account } = useEthers();
  const { t, i18n } = useTranslation([I18N_NAMESPACE.PROFILE]);

  const dateFormat = useMemo(
    () => (i18n.resolvedLanguage === "vi" ? "dd/MM/yyyy" : "MM/dd/yyyy"),
    [i18n.resolvedLanguage]
  );

  const [isVisibleRepayProcess, setIsVisibleRepayProcess] = useState(false);

  const paidAmount = useMemo(() => sumBy(values(props.order.payments), (i) => +i.amount), [props.order.payments]);

  const remainingAmount = useMemo(() => +props.order.total - paidAmount, [props.order.total, paidAmount]);
  const initialPaymentAmount = useMemo(
    () =>
      props.order.payments && head(values(props.order.payments)) ? head(values(props.order.payments))?.amount || 0 : 0,
    [props.order.payments]
  );
  const paymentPeriods = useMemo(
    () => Math.ceil(+props.order.total / Math.ceil(+props.order.total / 4)),
    [props.order.total]
  );
  const paidPeriods = useMemo(() => keys(props.order.payments).length, [props.order.payments]);
  const currentPeriod = useMemo(() => paidPeriods + 1, [paidPeriods]);
  const principal = useMemo(() => Math.ceil(+props.order.total / 4), [props.order.total]);
  const periodPrice = useMemo(
    () => Math.min(principal, +props.order.total - (currentPeriod - 1) * principal),
    [props.order.total, principal, currentPeriod]
  );
  const lateFee = 0;

  const isNativeToken = useMemo(() => LAYER_1_TOKENS.includes(props.token?.underlying), [props.token?.underlying]);

  const userTokenBalance = useERC20Balance(props.token?.underlying && !isNativeToken ? props.token?.underlying : null);
  const ethBalanceInBigNumber = useEtherBalance(account);
  const ethBalance = useMemo(
    () => (ethBalanceInBigNumber ? +utils.formatEther(ethBalanceInBigNumber) : 0),
    [ethBalanceInBigNumber]
  );

  const walletBalance = useMemo(
    () => (props.token?.underlying && LAYER_1_TOKENS.includes(props.token?.underlying) ? ethBalance : userTokenBalance),
    [props.token?.underlying, ethBalance, userTokenBalance]
  );
  const [isInsufficientFunds, setIsInsufficientFunds] = useState(false);

  const onRepay = () => {
    if ((walletBalance || 0) * Math.pow(10, props.token.decimals) < periodPrice) {
      setIsInsufficientFunds(true);
    } else {
      setIsVisibleRepayProcess(true);
    }
  };

  const onRepaySuccess = (hash: string) => {
    setIsVisibleRepayProcess(false);
    props.onRepaySuccess(hash);
  };

  return (
    <>
      <Modal isOpen={props.isVisible && !isVisibleRepayProcess} onClose={props.onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader></ModalHeader>
          <ModalBody>
            <Flex justifyContent="center" alignItems="center">
              <Box borderWidth="1px" borderRadius="lg" height="80px" width="80px">
                <NftResource image={props?.metadata?.image} animationUrl={props?.metadata?.animation_url} muted />
              </Box>

              <Flex flex="1" marginLeft="12px" justifyContent="space-between">
                <Box>
                  <Box fontSize={"small"}>{props?.order.contractName}</Box>
                  <Text color={colors.primary2} fontWeight="700" fontSize="20px">
                    {props?.metadata?.name}
                  </Text>
                </Box>
              </Flex>
            </Flex>

            <Grid gridTemplateColumns={"1fr 1fr"} gridGap={3} marginTop={4}>
              <Box>
                <Text fontSize={"small"} fontWeight={"500"} color={"#6b6b6b"}>
                  {t("Item price")}
                </Text>
                <Flex alignItems={"center"}>
                  <Image src={props.token?.logoUrl} height="18px" width="18px" marginRight="6px" />
                  <Text fontWeight={500} color={colors.primary1}>
                    {formatNumber(new BigNumber(props.order.total).div(Math.pow(10, props.token.decimals)).toNumber())}
                  </Text>
                </Flex>
              </Box>
              <Box>
                <Text fontSize={"small"} fontWeight={"500"} color={"#6b6b6b"}>
                  {t("You have paid")}
                </Text>
                <Flex alignItems={"center"}>
                  <Image src={props.token?.logoUrl} height="18px" width="18px" marginRight="6px" />
                  <Text fontWeight={500} color={colors.primary1}>
                    {formatNumber(
                      new BigNumber(props.order.total)
                        .minus(remainingAmount)
                        .div(Math.pow(10, props.token.decimals))
                        .toNumber()
                    )}
                  </Text>
                </Flex>
              </Box>
              <Box>
                <Text fontSize={"small"} fontWeight={"500"} color={"#6b6b6b"}>
                  {t("Initial payment")}
                </Text>
                <Flex alignItems={"center"}>
                  <Image src={props.token?.logoUrl} height="18px" width="18px" marginRight="6px" />
                  <Text fontWeight={500} color={colors.primary1}>
                    {formatNumber(
                      new BigNumber(initialPaymentAmount).div(Math.pow(10, props.token.decimals)).toNumber()
                    )}
                  </Text>
                </Flex>
              </Box>
              <Box>
                <Text fontSize={"small"} fontWeight={"500"} color={"#6b6b6b"}>
                  {t("Remaining")}
                </Text>
                <Flex alignItems={"center"}>
                  <Image src={props.token?.logoUrl} height="18px" width="18px" marginRight="6px" />
                  <Text fontWeight={500} color={colors.primary1}>
                    {formatNumber(new BigNumber(remainingAmount).div(Math.pow(10, props.token.decimals)).toNumber())}
                  </Text>
                </Flex>
              </Box>

              <Box>
                <Text fontSize={"small"} fontWeight={"500"} color={"#6b6b6b"}>
                  {t("Purchased date")}
                </Text>
                <Text fontWeight={500}>{format(new Date(props.order.createdAt), dateFormat)}</Text>
              </Box>
            </Grid>

            <Box margin="16px 0px">
              <Divider />
            </Box>

            <Box textAlign={"center"}>
              <Text fontWeight={"700"}>
                {t("period payments", { currentPeriod: currentPeriod, paymentPeriods: paymentPeriods })}
              </Text>
            </Box>

            <Box marginTop={3}>
              <Flex alignItems={"center"} padding={"4px 0px"}>
                <Text fontSize={"14px"} fontWeight={"500"} color={colors.primary2} flex={1}>
                  {t("Payment amount")}:
                </Text>
                <Flex alignItems={"center"} marginLeft={4} flex={1}>
                  <Image src={props.token?.logoUrl} height="18px" width="18px" marginRight="6px" />
                  <Text fontWeight={500} color={colors.primary1}>
                    {formatNumber(new BigNumber(periodPrice).div(Math.pow(10, props.token.decimals)).toNumber())}
                  </Text>
                </Flex>
              </Flex>
              <Flex alignItems={"center"} padding={"4px 0px"}>
                <Flex fontSize={"14px"} fontWeight={"500"} color={colors.primary2} flex={1}>
                  {t("Late payment fee")}:
                  <Tooltip
                    hasArrow
                    label={t("Late payment fee: 0.5% per week (of the unpaid instalment amount)")}
                    borderRadius="lg"
                    padding="6px"
                    placement="top"
                  >
                    <WarningIcon w={3} h={3} color={colors.primary} marginLeft="4px" />
                  </Tooltip>
                </Flex>
                <Flex alignItems={"center"} marginLeft={4} flex={1}>
                  <Image src={props.token?.logoUrl} height="18px" width="18px" marginRight="6px" />
                  <Text fontWeight={500} color={colors.primary1}>
                    {formatNumber(new BigNumber(lateFee).div(Math.pow(10, props.token.decimals)).toNumber())}
                  </Text>
                </Flex>
              </Flex>
              <Flex alignItems={"center"} borderTopWidth={1} padding={"4px 0px"} borderStyle={"dashed"}>
                <Flex fontWeight={"900"} color={colors.primary2} flex={1}>
                  {t("Total")}:
                </Flex>
                <Flex alignItems={"center"} marginLeft={4} flex={1}>
                  <Image src={props.token?.logoUrl} height="18px" width="18px" marginRight="6px" />
                  <Text fontWeight={700} color={colors.primary1} fontSize={"20px"}>
                    {formatNumber(
                      new BigNumber(periodPrice + lateFee).div(Math.pow(10, props.token.decimals)).toNumber()
                    )}
                  </Text>
                </Flex>
              </Flex>
            </Box>

            <Stack alignItems={"center"} flexDirection={"column"} margin={"24px 0px 12px 0px"}>
              <Text textAlign={"center"} color="red.500" fontSize={"md"}>
                {props.repayError}
              </Text>
              <Button colorScheme={"blue"} width={"150px"} onClick={onRepay} isLoading={props.isPaying}>
                {t("Pay")}
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <ErrorModal
        isVisible={isInsufficientFunds}
        onClose={() => {
          setIsInsufficientFunds(false);
        }}
        message={t("Insufficient funds")}
      />
      <RepayProcessModal
        isVisible={isVisibleRepayProcess}
        onClose={() => setIsVisibleRepayProcess(false)}
        onSuccess={onRepaySuccess}
        purchase={props.order}
        periodPrice={periodPrice}
        metadata={props.metadata}
      />
    </>
  );
});
