import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { useFormik } from "formik";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { NftResource } from "../../../../components/NftResource";
import { colors } from "../../../../core/constants/colors";
import { I18N_NAMESPACE } from "../../../../core/constants/i18n";
import { NFT_TYPE } from "../../../../core/constants/nft";
import { INftV2 } from "../../../../core/models/nft";
import { Quote } from "../../../../core/models/quote";
import { formatNumber } from "../../../../core/utils/number";
import { useAppSelector } from "../../../../store/hook";
import { AcceptBidProcessModal } from "./AcceptBidProcessModal";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onSuccess: () => void;
  nft: INftV2;
  bid: Quote;
}

enum ACCEPT_BID_STATE {
  CONFIRMING,
  ACCEPTING,
  SUCCESS,
}

const Title = React.memo((props: React.PropsWithChildren<{}>) => {
  return <Heading size={"sm"}>{props.children}</Heading>;
});

const Description = React.memo((props: React.PropsWithChildren<{}>) => {
  return (
    <Text color={"gray.500"} fontSize={"xs"}>
      {props.children}
    </Text>
  );
});

export const AcceptBidFormModal = React.memo((props: Props) => {
  const { t } = useTranslation([I18N_NAMESPACE.ASSET_DETAIL]);
  const supportedTokens = useAppSelector((state) => state.token.supportedTokens);

  const [acceptBidState, setAcceptBidState] = useState<ACCEPT_BID_STATE>(ACCEPT_BID_STATE.CONFIRMING);

  const maximumQuantity = useMemo(
    () => Math.min(props.nft.accountBalance || Number.MAX_SAFE_INTEGER, +props.bid.take.value),
    [props.nft.accountBalance, props.bid.take.value]
  );

  const purchaseSchema = useMemo(
    () =>
      yup.object({
        quantity: yup
          .number()
          .required(t("Quantity is required"))
          .integer(t("Quantity must be integer"))
          .min(1, t("Quantity must be greater than 0"))
          .max(maximumQuantity, `${t("Quantity must be less than or equal to")} ${maximumQuantity}`),
      }),
    [maximumQuantity]
  );

  const token = useMemo(
    () => (props.bid?.make?.assetType?.contract ? supportedTokens.byId[props.bid?.make?.assetType?.contract] : null),
    [props.bid.make.assetType.contract]
  );
  const bidAmountPerCopy = useMemo(
    () => (props.bid.make.value ? +props.bid.make.value : 0) / +props.bid.take.value,
    [props.bid.make.value, props.bid.take.value]
  );

  const formik = useFormik({
    initialValues: {
      quantity: 1,
    },
    validationSchema: purchaseSchema,
    onSubmit: (values) => {
      setAcceptBidState(ACCEPT_BID_STATE.ACCEPTING);
    },
  });
  return (
    <>
      <Modal
        isOpen={props.isVisible && acceptBidState === ACCEPT_BID_STATE.CONFIRMING}
        onClose={props.onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("Accept Bid")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody marginTop="12px">
            <form onSubmit={formik.handleSubmit}>
              <Stack spacing={7}>
                <Flex justifyContent="center" alignItems="center">
                  <Box borderWidth="1px" borderRadius="lg" height="80px" width="80px">
                    <NftResource
                      image={props.nft.metadata?.image}
                      animationUrl={props.nft.metadata?.animation_url}
                      muted
                    />
                  </Box>

                  <Flex flex="1" marginLeft="16px" justifyContent="space-between" alignItems={"center"}>
                    <Box>
                      <Box fontSize={"small"}>{props.nft?.contractName}</Box>
                      <Text color={colors.primary2} fontWeight="700" fontSize="20px">
                        {props.nft?.metadata?.name}
                      </Text>
                    </Box>
                  </Flex>
                </Flex>

                <Divider />

                <Stack spacing={5}>
                  {props.nft.contractType === NFT_TYPE.ERC_1155 && (
                    <Stack>
                      <Title>{t("Number of copies")}</Title>
                      <Box>
                        <Input
                          type="number"
                          value={formik.values.quantity}
                          name="quantity"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {!!formik.errors.quantity && formik.touched.quantity && (
                          <Text color={"red.500"} fontSize={"small"} marginTop={1}>
                            {formik.errors.quantity}
                          </Text>
                        )}
                      </Box>
                    </Stack>
                  )}

                  <Flex justifyContent={"space-between"} alignItems="center">
                    <Box>
                      <Title>{t("Bid amount")}</Title>
                      <Description>{t("Bid amount per each copy")}</Description>
                    </Box>
                    <Flex marginTop="4px" alignItems="center">
                      <Image src={token?.logoUrl} height="20px" width="20px" marginRight="4px" />
                      <Text fontWeight="700" color={colors.primary}>
                        {bidAmountPerCopy &&
                          token &&
                          formatNumber(new BigNumber(bidAmountPerCopy).div(Math.pow(10, token.decimals)).toNumber())}
                      </Text>
                    </Flex>
                  </Flex>

                  <Flex justifyContent={"space-between"} alignItems="center">
                    <Title>{t("Total")}</Title>
                    <Flex marginTop="4px" alignItems="center">
                      <Image src={token?.logoUrl} height="20px" width="20px" marginRight="4px" />
                      <Text fontWeight="700" color={colors.primary}>
                        {bidAmountPerCopy &&
                          token &&
                          formatNumber(
                            new BigNumber(bidAmountPerCopy)
                              .multipliedBy(formik.values.quantity || 0)
                              .div(Math.pow(10, token.decimals))
                              .toNumber()
                          )}
                      </Text>
                    </Flex>
                  </Flex>

                  <Divider />

                  <HStack justifyContent="flex-end">
                    <Button colorScheme="blue" alignSelf={"center"} type="submit" width={"120px"}>
                      {t("Accept")}
                    </Button>
                  </HStack>
                </Stack>
              </Stack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>

      {!!props.nft && token && (
        <AcceptBidProcessModal
          isVisible={props.isVisible && acceptBidState === ACCEPT_BID_STATE.ACCEPTING}
          onClose={() => {
            setAcceptBidState(ACCEPT_BID_STATE.CONFIRMING);
          }}
          nft={props.nft}
          bid={props.bid}
          token={token}
          onSuccess={props.onSuccess}
          price={new BigNumber(bidAmountPerCopy).multipliedBy(formik.values.quantity).toNumber()}
          quantity={formik.values.quantity}
        />
      )}
    </>
  );
});
