import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

const Maintenance = React.memo(() => {
  return (
    <Flex alignItems={"center"} justifyContent={"center"} w="100vw" h="100vh">
      <Text fontWeight={700}>The website is currently under maintenance</Text>
    </Flex>
  );
});

export default Maintenance;
