import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Image,
  Center,
  HStack,
  IconButton,
  Input,
  Textarea,
  Button,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { getChainName, useEthers } from "@usedapp/core";
import { useFormik } from "formik";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { ErrorText } from "../../../components/ErrorText";
import { images } from "../../../core/constants/images";
import { storageSerivce } from "../../../services/storage.service";
import * as yup from "yup";
import { useERC1155BePayFactoryContract, useERC721BePayFactoryContract } from "../../../core/hooks/useContract";
import { getSalt } from "../../../core/utils/getSalt";
import { collectionService } from "../../../services/collection.service";
import { NFT_TYPE } from "../../../core/constants/nft";
import { generatePushId } from "../../../core/utils/generatePushId";
import { useTranslation } from "react-i18next";
import { I18N_NAMESPACE } from "../../../core/constants/i18n";
import { create } from "ipfs-http-client";
import { buildIPFSUri } from "../../../core/utils/ipfs";
import { env } from "../../../core/environment";
import { CHAIN_NAME_BY_ID } from "../../../core/constants/chains";
import { get } from "lodash-es";
import { getChainNameById } from "../../../core/utils/chain";
import { useAppSelector } from "../../../store/hook";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  type: string;
  onComplete: () => Promise<void>;
}

const auth =
  "Basic " + Buffer.from(env.config.ipfs.project_id + ":" + env.config.ipfs.prject_secret).toString("base64");

const client = create({
  host: env.config.ipfs.host,
  port: env.config.ipfs.port,
  protocol: env.config.ipfs.protocol,
  headers: {
    authorization: auth,
  },
});

export const CreateCollectionModal = React.memo((props: Props) => {
  const { t } = useTranslation([I18N_NAMESPACE.CREATE_ASSET]);
  const isERC1155 = useMemo(() => props.type === NFT_TYPE.ERC_1155, [props.type]);
  const isERC721 = useMemo(() => props.type === NFT_TYPE.ERC_721, [props.type]);

  const toast = useToast();
  const appContext = useAppSelector((state) => state.appContext);

  const { account, chainId } = useEthers();
  const [isUploadingCover, setIsUploadingCover] = useState(false);
  const [isUploadingLogo, setIsUploadingLogo] = useState(false);
  const erc721BePayFactoryContract = useERC721BePayFactoryContract();
  const erc1155BePayFactoryContract = useERC1155BePayFactoryContract();

  const FormSchema = yup.object({
    logo: yup.string().required(t("Logo is required")),
    cover: yup.string().required(t("Cover is required")),
    name: yup.string().required(t("Name is required")),
    symbol: yup.string().required(t("Symbol is required")),
  });

  const formik = useFormik<{
    logo: string | undefined;
    cover: string | undefined;
    name: string | undefined;
    description: string | undefined;
    symbol: string | undefined;
  }>({
    initialValues: {
      logo: undefined,
      cover: undefined,
      name: undefined,
      description: undefined,
      symbol: undefined,
    },
    validationSchema: FormSchema,
    onSubmit: async (values) => {
      if (!values.logo || !values.cover) return;

      const metadata = await client.add(
        JSON.stringify({
          name: values.name?.trim(),
          description: values.description?.trim(),
          symbol: values.symbol,
          type: props.type,
          owner: account,
          image: values.logo,
          banner: values.cover,
        })
      );
      await client.pin.add(metadata.path);

      const salt = getSalt();
      const factoryContract = isERC721 ? erc721BePayFactoryContract : erc1155BePayFactoryContract;
      const tx = await factoryContract?.createToken(
        values.name,
        values.symbol,
        "",
        buildIPFSUri(metadata.path),
        [],
        salt
      );
      await tx?.wait();

      const address: string = await factoryContract?.getAddress(
        values.name,
        values.symbol,
        "",
        buildIPFSUri(metadata.path),
        [],
        salt
      );

      const response = await collectionService.create({
        contract: address.toLowerCase(),
        chain: appContext.selectedChain?.name,
      });

      formik.resetForm();
      await props.onComplete();
    },
  });

  useEffect(() => {
    formik.resetForm();
  }, [account]);

  const onChangeCover = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e?.target?.files[0];
    if (!file) return;

    const MAX_SIZE_IN_MB = 5;
    if (file.size > MAX_SIZE_IN_MB * 1024 * 1024) {
      toast({
        title: t("Validation"),
        description: t("Banner's size validate", { size: MAX_SIZE_IN_MB }),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    setIsUploadingCover(true);
    const url = await storageSerivce.upload(`collection/cover/${generatePushId()}`, file);
    formik.setFieldValue("cover", url);
    setIsUploadingCover(false);
    formik.setFieldTouched("cover", true);
  };

  const onChangeLogo = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e?.target?.files[0];
    if (!file) return;

    const MAX_SIZE_IN_MB = 5;
    if (file.size > MAX_SIZE_IN_MB * 1024 * 1024) {
      toast({
        title: t("Validation"),
        description: t("Logo's size validate", { size: MAX_SIZE_IN_MB }),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }
    setIsUploadingLogo(true);
    const url = await storageSerivce.upload(`collection/logo/${generatePushId()}`, file);
    formik.setFieldValue("logo", url);
    setIsUploadingLogo(false);
    formik.setFieldTouched("logo", true);
  };

  const onRemoveCover = () => {
    formik.setFieldValue("cover", undefined);
  };

  return (
    <Modal isOpen={props.isVisible} onClose={props.onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("Create Collection")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody marginTop="12px">
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={6}>
              <Stack>
                <Title>{t("Logo")}</Title>
                <Box>
                  <HStack alignItems={"flex-start"} spacing={6}>
                    <Box h={32} w={32} position="relative">
                      <Center
                        borderWidth={formik.values.logo ? 0 : 2}
                        borderColor="gray.200"
                        borderStyle={"dashed"}
                        borderRadius={"full"}
                        height={"full"}
                        width={"full"}
                        position={"relative"}
                      >
                        {isUploadingLogo ? (
                          <Spinner colorScheme="blue" size="lg" color="blue.500" />
                        ) : formik.values.logo ? (
                          <Image
                            src={formik.values.logo}
                            borderRadius={"full"}
                            objectFit="cover"
                            width={"full"}
                            height={"full"}
                          />
                        ) : (
                          <Image src={images.placeholder} objectFit={"contain"} />
                        )}
                      </Center>
                      <Input
                        accept="image/png,image/jpeg,image/gif,image/webp"
                        type="file"
                        onChange={onChangeLogo}
                        opacity={0}
                        position="absolute"
                        top={0}
                        left={0}
                        height="100%"
                        w="100%"
                      />
                    </Box>
                    <Box flex={2}>
                      <Text color={"gray.600"}>
                        {t("We recommend an image of at least 300x300. Supported file types: JPG, PNG, GIF")}.
                      </Text>
                      <Text color={"gray.600"}>{t("Max size", { size: 5 })}.</Text>
                    </Box>
                  </HStack>
                  {!!formik.errors.logo && !!formik.touched.logo && <ErrorText>{formik.errors.logo}</ErrorText>}
                </Box>
              </Stack>
              <Stack>
                <Title>{t("Cover")}</Title>
                <Box>
                  <Flex
                    alignItems={"center"}
                    justifyContent={"center"}
                    borderWidth={2}
                    borderColor="gray.200"
                    borderStyle={"dashed"}
                    borderRadius={"xl"}
                    padding={"20px 60px"}
                    position={"relative"}
                    minH={20}
                  >
                    {isUploadingCover ? (
                      <Spinner colorScheme="blue" size="lg" color="blue.500" />
                    ) : formik.values.cover ? (
                      <>
                        <Image src={formik.values.cover} />
                        <IconButton
                          variant="outline"
                          aria-label="Remove cover"
                          icon={<CloseIcon fontSize={12} />}
                          borderRadius="full"
                          position="absolute"
                          top={3}
                          right={3}
                          zIndex={2}
                          onClick={onRemoveCover}
                        />
                      </>
                    ) : (
                      <Image src={images.placeholder} w="60%" />
                    )}

                    <Input
                      accept="image/png,image/jpeg,image/gif,image/webp"
                      type="file"
                      onChange={onChangeCover}
                      opacity={0}
                      position="absolute"
                      top={0}
                      left={0}
                      height="100%"
                      w="100%"
                    />
                  </Flex>
                  {!!formik.errors.cover && !!formik.touched.cover && <ErrorText>{formik.errors.cover}</ErrorText>}
                </Box>
              </Stack>

              <Stack>
                <Title>{t("Name")}</Title>
                <Description>{t("Token name cannot be changed in future")}</Description>
                <Box>
                  <Input
                    autoComplete="off"
                    placeholder={t("Collection name")}
                    value={formik.values.name}
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={!!formik.errors.name && !!formik.touched.name}
                  />
                  {!!formik.errors.name && !!formik.touched.name && <ErrorText>{formik.errors.name}</ErrorText>}
                </Box>
              </Stack>
              <Stack>
                <Title>{t("Symbol")}</Title>
                <Box>
                  <Input
                    placeholder={t("Enter token symbol")}
                    value={formik.values.symbol}
                    name="symbol"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={!!formik.errors.symbol && !!formik.touched.symbol}
                  />
                  {!!formik.errors.symbol && !!formik.touched.symbol && <ErrorText>{formik.errors.symbol}</ErrorText>}
                </Box>
              </Stack>
              <Stack>
                <Title>{t("Description")}</Title>
                <Textarea
                  placeholder={t("Provide a detailed description of your collection")}
                  value={formik.values.description}
                  name="description"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Stack>

              <Button colorScheme={"blue"} isLoading={formik.isSubmitting} type="submit">
                {t("Create Collection")}
              </Button>
            </Stack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});

const Title = React.memo((props: React.PropsWithChildren<{}>) => {
  return (
    <Heading size={"sm"} color="rgb(53, 56, 64)">
      {props.children}
    </Heading>
  );
});

const Description = React.memo((props: React.PropsWithChildren<{}>) => {
  return (
    <Text color={"gray.500"} fontSize={"xs"}>
      {props.children}
    </Text>
  );
});
