import { ChainId } from "@usedapp/core";

export const getExplorerTxUrl = (txHash: string, chainId: ChainId) => {
  const explorerByChain: any = {
    [ChainId.Rinkeby]: `https://rinkeby.etherscan.io/tx/${txHash}`,
    [ChainId.Mainnet]: `https://etherscan.io/tx/${txHash}`,
    [ChainId.Ropsten]: `https://ropsten.etherscan.io/tx/${txHash}`,
    [ChainId.BSCTestnet]: `https://testnet.bscscan.com/tx/${txHash}`,
    [ChainId.BSC]: `https://bscscan.com/tx/${txHash}`,
  };
  return explorerByChain[chainId];
};

export const getExplorerAddressUrl = (address: string, chainId: ChainId) => {
  const explorerByChain: any = {
    [ChainId.Rinkeby]: `https://rinkeby.etherscan.io/address/${address}`,
    [ChainId.Mainnet]: `https://etherscan.io/address/${address}`,
    [ChainId.Ropsten]: `https://ropsten.etherscan.io/address/${address}`,
    [ChainId.BSCTestnet]: `https://testnet.bscscan.com/address/${address}`,
    [ChainId.BSC]: `https://bscscan.com/address/${address}`,
  };
  return explorerByChain[chainId];
};

