import { ChainId, useEthers } from "@usedapp/core";
import { useMemo } from "react";
import {
  MARKETPLACE_ADDRESS,
  NFT_TRANSFER_PROXY_ADDRESS,
  ERC20_TRANSFER_PROXY_ADDRESS,
  TOKEN_CONTROLLER_ADDRESS,
  ROYALTIES_REGISTRY_ADDRESS,
  ERC721_BEPAY_FACTORY_ADDRESS,
  ERC1155_BEPAY_FACTORY_ADDRESS,
  HELPER_CONTRACT_ADDRESS,
  ERC721_LAZY_TRANSFER_PROXY_ADDRESS,
  ERC1155_LAZY_TRANSFER_PROXY_ADDRESS,
  ERC1155_BEPAY_INSTANCE_ADDRESS,
  ERC721_BEPAY_INSTANCE_ADDRESS,
} from "../constants/addresses";

export const useMarketplaceAddress = () => {
  const { chainId } = useEthers();
  return useMemo(() => MARKETPLACE_ADDRESS[chainId || ChainId.Rinkeby], [chainId]);
};

export const useTokenControllerAddress = () => {
  const { chainId } = useEthers();
  return useMemo(() => TOKEN_CONTROLLER_ADDRESS[chainId || ChainId.Rinkeby], [chainId]);
};

export const useNftProxyAddress = () => {
  const { chainId } = useEthers();
  return useMemo(() => NFT_TRANSFER_PROXY_ADDRESS[chainId || ChainId.Rinkeby], [chainId]);
};

export const useRoyaltiesRegistryAddress = () => {
  const { chainId } = useEthers();
  return useMemo(() => ROYALTIES_REGISTRY_ADDRESS[chainId || ChainId.Rinkeby], [chainId]);
};

export const useERC721BePayFactoryAddress = () => {
  const { chainId } = useEthers();
  return useMemo(() => ERC721_BEPAY_FACTORY_ADDRESS[chainId || ChainId.Rinkeby], [chainId]);
};

export const useERC721LazyTransferProxyAddress = () => {
  const { chainId } = useEthers();
  return useMemo(() => ERC721_LAZY_TRANSFER_PROXY_ADDRESS[chainId || ChainId.Rinkeby], [chainId]);
};

export const useERC721BePayInstanceAddress = () => {
  const { chainId } = useEthers();
  return useMemo(() => ERC721_BEPAY_INSTANCE_ADDRESS[chainId || ChainId.Rinkeby], [chainId]);
};

export const useERC1155LazyTransferProxyAddress = () => {
  const { chainId } = useEthers();
  return useMemo(() => ERC1155_LAZY_TRANSFER_PROXY_ADDRESS[chainId || ChainId.Rinkeby], [chainId]);
};

export const useERC1155BePayFactoryAddress = () => {
  const { chainId } = useEthers();
  return useMemo(() => ERC1155_BEPAY_FACTORY_ADDRESS[chainId || ChainId.Rinkeby], [chainId]);
};

export const useERC1155BePayInstanceAddress = () => {
  const { chainId } = useEthers();
  return useMemo(() => ERC1155_BEPAY_INSTANCE_ADDRESS[chainId || ChainId.Rinkeby], [chainId]);
};

export const useERC20ProxyAddress = () => {
  const { chainId } = useEthers();
  return useMemo(() => ERC20_TRANSFER_PROXY_ADDRESS[chainId || ChainId.Rinkeby], [chainId]);
};

export const useHelperContractAddress = () => {
  const { chainId } = useEthers();
  return useMemo(() => HELPER_CONTRACT_ADDRESS[chainId || ChainId.Rinkeby], [chainId]);
};
