import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { I18N_NAMESPACE } from "../../../../core/constants/i18n";

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

export const CreditStatusWarningModal = React.memo((props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation([I18N_NAMESPACE.ASSET_DETAIL]);
  return (
    <Modal isOpen={props.isVisible} onClose={props.onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader />
        <ModalBody padding={"24px 30px"}>
          <Stack spacing={5} alignItems={"center"}>
            <InfoOutlineIcon color={"red.400"} fontSize={50} />
            <Text fontWeight={500} textAlign={"center"}>
              {t("Verify you identification to unlock Buy Now Pay Later feature.")}
            </Text>
            <Button variant={"outline"} onClick={() => navigate("/profile/setting")}>
              {t("Go to Settings")}
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});
