import { Interface } from "@ethersproject/abi";
import { Contract } from "@ethersproject/contracts";
import { useEthers } from "@usedapp/core";
import { useMemo } from "react";
import BTokenAbi from "../../abis/BToken.json";
import ERC20Abi from "../../abis/ERC20.json";
import ERC721Abi from "../../abis/ERC721.json";
import TokenControllerAbi from "../../abis/TokenController.json";
import NftMarketAbi from "../../abis/NftMarket.json";
import RoyaltiesRegistry from "../../abis/RoyaltiesRegistry.json";
import HelperContractAbi from "../../abis/ContractHelper.json";
import ERC721BePayMinimal from "../../abis/ERC721BePayMinimal.json";
import ERC1155BePay from "../../abis/ERC1155BePay.json";
import ERC1155BePayFactory from "../../abis/ERC1155BePayFactory.json";
import ERC721BePayFactory from "../../abis/ERC721BePayFactory.json";
import { getContract } from "../utils/getContract";
import {
  useERC1155BePayFactoryAddress,
  useERC721BePayFactoryAddress,
  useHelperContractAddress,
  useMarketplaceAddress,
  useRoyaltiesRegistryAddress,
  useTokenControllerAddress,
} from "./useAddress";

export const useContract = (address?: string, ABI?: any) => {
  const { account, library } = useEthers();
  return useMemo(() => {
    if (!address) return null;

    try {
      return getContract(address, ABI, library, account);
    } catch (e) {
      return null;
    }
  }, [address, ABI, library, account]);
};

export const useERC721Contract = (address?: string) => {
  const ERC721_INTERFACE = new Interface(ERC721Abi);
  return useContract(address, ERC721_INTERFACE);
};

export const useERC721BePayMinimalContract = (address?: string) => {
  const ERC721_BEPAY_MINIMAL_INTERFACE = new Interface(ERC721BePayMinimal);
  return useContract(address, ERC721_BEPAY_MINIMAL_INTERFACE);
};

export const useERC721BePayFactoryContract = () => {
  const address = useERC721BePayFactoryAddress();
  const ERC721_BEPAY_FACTORY = new Interface(ERC721BePayFactory);
  return useContract(address, ERC721_BEPAY_FACTORY);
};

export const useERC1155BePayContract = (address?: string) => {
  const ERC1155_BEPAY = new Interface(ERC1155BePay);
  return useContract(address, ERC1155_BEPAY);
};

export const useERC1155BePayFactoryContract = () => {
  const address = useERC1155BePayFactoryAddress();
  const ERC1155_BEPAY_FACTORY = new Interface(ERC1155BePayFactory);
  return useContract(address, ERC1155_BEPAY_FACTORY);
};

export const useERC20Contract = (address: string) => {
  const ERC20_INTERFACE = new Interface(ERC20Abi);
  return useContract(address, ERC20_INTERFACE);
};

export const useTokenControllerContract = () => {
  const address = useTokenControllerAddress();
  const TOKEN_CONTROLLER_INTERFACE = new Interface(TokenControllerAbi);
  return useContract(address, TOKEN_CONTROLLER_INTERFACE);
};

export const useBTokenContract = (address: string) => {
  const BTOKEN_INTERFACE = new Interface(BTokenAbi);
  return useContract(address, BTOKEN_INTERFACE);
};

export const useMarketplaceContract = () => {
  const NFT_MARKET_INTERFACE = new Interface(NftMarketAbi);
  const marketplaceAddress = useMarketplaceAddress();
  return useContract(marketplaceAddress, NFT_MARKET_INTERFACE);
};

export const useRoyaltiesRegistryContract = () => {
  const ROYALTIES_REGISTRY_INTERFACE = new Interface(RoyaltiesRegistry);
  const royaltiesRegistryAddress = useRoyaltiesRegistryAddress();
  return useContract(royaltiesRegistryAddress, ROYALTIES_REGISTRY_INTERFACE);
};

export const useHelperContract = () => {
  const HELPER_CONTRACT_INTERFACE = new Interface(HelperContractAbi);
  const helperContractAddress = useHelperContractAddress();
  return useContract(helperContractAddress, HELPER_CONTRACT_INTERFACE);
};
