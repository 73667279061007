import { ApplicationVerifier, signInWithPhoneNumber } from "@firebase/auth";
import axios from "../core/https";
import { auth } from "../core/firebase.provider";

const requestOtp = (phone: string, appVerifier: ApplicationVerifier) => {
  return signInWithPhoneNumber(auth, phone, appVerifier);
};

const signIn = (body: { message: string; signature: string }) =>
  axios.post<{ token: string }>("/accounts/sign-in", body, {
    headers: {
      requireToken: "0",
    },
  });

export const authService = {
  requestOtp,
  signIn,
};
