export const APPROVE_STRATEGY = {
  ONLY_ITEM: "only-item",
  ALL: "all",
};

export const NFT_TYPE = {
  ERC_721: "ERC721",
  ERC_1155: "ERC1155",
};

export enum MINT_TYPE {
  NORMAL,
  LAZY,
}

export type NFTTypeKeys = keyof typeof NFT_TYPE;
