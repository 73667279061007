import { Box, Button, CircularProgress, Flex, Image, Text } from "@chakra-ui/react";
import { useBlockNumber, useEthers } from "@usedapp/core";
import BigNumber from "bignumber.js";
import { addWeeks, differenceInDays, differenceInHours, differenceInMinutes, isAfter, isBefore } from "date-fns";
import { head, keys, sumBy, values } from "lodash-es";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NftResource } from "../../../components/NftResource";
import { colors } from "../../../core/constants/colors";
import { I18N_NAMESPACE } from "../../../core/constants/i18n";
import { useNftMetadata } from "../../../core/hooks/data/nft";
import { useMarketplaceContract } from "../../../core/hooks/useContract";
import { IPurchaseV2 } from "../../../core/models/order";
import { formatNumber } from "../../../core/utils/number";
import { useAppSelector } from "../../../store/hook";
import { UpcomingOrderDetailModal } from "./UpcomingOrderDetailModal";

interface Props {
  order: IPurchaseV2;
  onRepaySuccess: (hash: string) => void;
}

export const UpcomingOrder = React.memo((props: Props) => {
  const { library } = useEthers();
  const { t } = useTranslation([I18N_NAMESPACE.PROFILE]);
  const supportedTokens = useAppSelector((state) => state.token.supportedTokens);
  const token = useMemo(() => supportedTokens.byId[props.order.currency], [props.order.currency, supportedTokens]);
  const marketplaceContract = useMarketplaceContract();
  const blockNumber = useBlockNumber();

  const [isPaying, setIsPaying] = useState(false);
  const [repayError, setRepayError] = useState<string>();

  const [isVisibleDetail, setIsVisibleDetail] = useState(false);
  const [now, setNow] = useState<number>();

  const { metadata } = useNftMetadata(props.order.nftUri);

  useEffect(() => {
    async function getNow() {
      if (!blockNumber) return;
      const block = await library?.getBlock(blockNumber);
      const now = (block?.timestamp || 0) * 1000;
      setNow(now);
    }

    getNow();
  }, [blockNumber]);

  useEffect(() => {
    if (isVisibleDetail) {
      setRepayError(undefined);
    }
  }, [isVisibleDetail]);

  const paidAmount = useMemo(() => sumBy(values(props.order.payments), (i) => +i.amount), [props.order.payments]);

  const remainingAmount = useMemo(() => +props.order.total - paidAmount, [props.order.total, paidAmount]);
  const paymentPeriods = useMemo(
    () => Math.ceil(+props.order.total / Math.ceil(+props.order.total / 4)),
    [props.order.total]
  );

  const paidPeriods = useMemo(() => keys(props.order.payments).length, [props.order.payments]);
  const currentPeriod = useMemo(() => paidPeriods + 1, [paidPeriods]);
  const dueDate = useMemo(() => {
    const latestPayment = head(values(props.order.payments));
    return latestPayment && paidPeriods > 0 ? addWeeks(new Date(latestPayment.createdAt), 2 * paidPeriods) : null;
  }, [props.order]);

  const closeDetail = () => {
    setIsVisibleDetail(false);
  };

  const onRepaySuccess = (hash: string) => {
    closeDetail();
    props.onRepaySuccess(hash);
  };

  const renderDueDate = useCallback(() => {
    if (!dueDate || !now) return;

    if (isBefore(now, dueDate)) {
      const diffInDays = differenceInDays(dueDate, now);
      const diffInHours = differenceInHours(dueDate, now);
      const diffInMinutes = differenceInMinutes(dueDate, now);
      return (
        <Text fontSize={"small"} color={"#A6A6A6"} fontWeight={500}>
          {t("Due in", {
            day: diffInDays,
            hour: diffInHours - diffInDays * 24,
            minute: diffInMinutes - diffInHours * 60,
          })}
        </Text>
      );
    }

    if (isAfter(dueDate, now))
      return (
        <Text fontSize={"small"} color={"red.500"} fontWeight={500}>
          {t("days overdue", { day: differenceInDays(dueDate, now) })}
        </Text>
      );
  }, [dueDate, now]);

  return (
    <>
      <Flex
        marginTop={"16px"}
        alignItems={"center"}
        borderWidth={1}
        padding={"12px 20px"}
        borderRadius={"xl"}
        gridGap={"12px"}
      >
        <Flex position={"relative"} height={"50px"} width={"50px"} alignItems={"center"} justifyContent={"center"}>
          <CircularProgress
            height={"50px"}
            width={"50px"}
            value={(100 / paymentPeriods) * (currentPeriod - 1)}
            position={"absolute"}
            top={0}
            left={0}
          />
          <Text fontWeight={700}>{currentPeriod - 1}</Text>
        </Flex>

        <Box width={"80px"} height={"80px"}>
          <NftResource image={metadata?.image} animationUrl={metadata?.animation_url} muted />
        </Box>

        <Flex
          flexDir={{
            base: "column",
            md: "row",
            lg: "row",
            xl: "row",
            "2xl": "row",
          }}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
          flex={1}
        >
          <Box flex={1}>
            <Text fontWeight={"700"}>{metadata?.name}</Text>

            <Flex fontSize={"small"} alignItems={"center"}>
              <Text>{t("Remaining")}:</Text>
              <Image src={token?.logoUrl} height="18px" width="18px" marginRight={"4px"} marginLeft={"6px"} />
              <Text fontWeight={700} color={colors.primary1} fontSize={"md"}>
                {formatNumber(new BigNumber(remainingAmount).div(Math.pow(10, token.decimals)).toNumber())}
              </Text>
            </Flex>

            {renderDueDate()}
          </Box>

          <Button
            colorScheme={"blue"}
            onClick={() => setIsVisibleDetail(true)}
            isLoading={isPaying}
            width={"fit-content"}
            padding={"0px 30px"}
            size={"sm"}
            marginTop={3}
          >
            {t("Pay")}
          </Button>
        </Flex>
      </Flex>
      <UpcomingOrderDetailModal
        order={props.order}
        metadata={metadata}
        isVisible={isVisibleDetail}
        onClose={closeDetail}
        token={token}
        isPaying={isPaying}
        repayError={repayError}
        onRepaySuccess={onRepaySuccess}
      />
    </>
  );
});
