import { Box, Button, Divider, Flex, Image, Text, Spacer, HStack, Stack } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NftResource } from "../../../components/NftResource";
import { colors } from "../../../core/constants/colors";
import { INft, INftMetadata, ISummaryNft } from "../../../core/models/nft";
import { formatNumber, toPlainString } from "../../../core/utils/number";
import { useAppSelector } from "../../../store/hook";
import { IoIosHeart, IoIosHeartEmpty } from "react-icons/io";
import { userService } from "../../../services/user.service";
import { nftService } from "../../../services/nft.service";
import Web3 from "web3";
import { generateNonce } from "../../../core/utils/securityUtils";
import { useTranslation } from "react-i18next";
import { I18N_NAMESPACE } from "../../../core/constants/i18n";
import BigNumber from "bignumber.js";
import { uriToHttps } from "../../../core/utils/ipfs";
import axios from "axios";
import { env } from "../../../core/environment";
import { buildNftHashKey } from "../../../core/utils/assets";

interface Props {
  nft: ISummaryNft;
}

export const NftItem = React.memo(({ nft }: Props) => {
  const { t } = useTranslation([I18N_NAMESPACE.MARKETPLACE]);
  const navigate = useNavigate();
  const appContext = useAppSelector((state) => state.appContext);

  const supportedTokens = useAppSelector((state) => state.token.supportedTokens);
  const token = useMemo(
    () =>
      supportedTokens.allIds.length > 0 && nft.makeQuote?.takeAssetContract
        ? supportedTokens.byId[nft.makeQuote.takeAssetContract]
        : null,
    [supportedTokens, nft.makeQuote?.takeAssetContract]
  );

  const [numberOfFavorites, setNumberOfFavorites] = useState<number>(nft.numberOfFavorites ?? 0);
  const [isFavorited, setIsFavorited] = useState<boolean>(!!nft.favorited);

  const [metadata, setMetata] = useState<INftMetadata>({
    image: nft.image,
    name: nft.name,
  });

  const hashKey = useMemo(
    () => buildNftHashKey(nft.chain, nft.contract, nft.tokenId),
    [nft.chain, nft.contract, nft.tokenId]
  );
  const fallbackSources: string[] = useMemo(
    () =>
      [
        `${env.config.storage_base_url}/nfts/${hashKey}_cover`,
        `${env.config.storage_base_url}/nfts/${hashKey}_raw`,
        uriToHttps(metadata?.image),
      ].filter((i) => !!i) as string[],
    [hashKey, metadata?.image]
  );

  useEffect(() => {
    async function getMetadata() {
      if (nft.uri) {
        const uri = uriToHttps(nft.uri);
        if (!uri) return;

        const result = await axios.get(uri);
        setMetata(result.data);
      }
    }

    getMetadata();
  }, [nft.uri]);

  const onPressItem = () => {
    navigate(`/assets/${nft.contract}/${nft.tokenId}`);
  };

  const setFavorite = (value: boolean) => async () => {
    try {
      if (!nft) return;
      console.log(value);
      const response = await nftService.favorite({
        contract: nft.contract,
        tokenId: nft.tokenId,
        chain: appContext.selectedChain.name,
        favorited: value,
      });

      if (response.data) {
        setIsFavorited(value);
        setNumberOfFavorites(response.data.numberOfFavorites);
      }
    } catch (e) {}
  };

  const addFavorite = setFavorite(true);

  const removeFavorite = setFavorite(false);

  const FavoriteDetail = () => {
    return (
      <HStack spacing={1}>
        <Box _hover={{ transform: "scale(1.2)", transition: "all 0.1s ease 0s" }}>
          {isFavorited ? (
            <IoIosHeart
              onClick={(e) => {
                e.stopPropagation();
                removeFavorite();
              }}
              cursor={"pointer"}
            />
          ) : (
            <IoIosHeartEmpty
              onClick={(e) => {
                e.stopPropagation();
                addFavorite();
              }}
              cursor={"pointer"}
            />
          )}
        </Box>
        <Text fontSize={"sm"} fontWeight={500}>
          {numberOfFavorites}
        </Text>
      </HStack>
    );
  };

  return (
    <Flex
      borderWidth="1px"
      borderRadius="lg"
      flexDirection="column"
      overflow="hidden"
      onClick={onPressItem}
      _hover={{ boxShadow: "lg", transition: "all 0.1s ease 0s", transform: "translateY(-4px)" }}
      cursor={"pointer"}
    >
      <Box padding={1}>
        <NftResource
          image={`${env.config.storage_base_url}/nfts/${hashKey}_thumb`}
          fallbackSources={fallbackSources}
          animationUrl={metadata.animation_url}
          muted
        />
      </Box>

      <Box padding={3}>
        <Flex flex={1} flexDirection="column">
          <Flex justifyContent="space-between" flex={1} gridGap={"20px"}>
            <Box flex={1} width={"100%"}>
              <Box color="gray.500" fontWeight="semibold" letterSpacing="wide" fontSize="xs" textTransform="uppercase">
                {nft?.contractName || ""}
              </Box>
              <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight" isTruncated>
                {metadata.name || ""}
              </Box>
            </Box>
          </Flex>

          <Box marginTop={2}>
            <Flex alignItems="center">
              <Image src={token?.logoUrl} height="16px" width="16px" marginRight="4px" />
              <Text fontSize="sm" color={colors.primary1} fontWeight="700">
                {nft.makeQuote?.takeAssetValue
                  ? formatNumber(
                      new BigNumber(+nft.makeQuote.takeAssetValue).div(Math.pow(10, token?.decimals || 0)).toNumber()
                    )
                  : ""}
              </Text>
            </Flex>
          </Box>
        </Flex>

        <Box m="4px 0px">
          <Divider />
        </Box>

        <Button colorScheme={"blue"} margin={"8px 0px"} width={"full"}>
          {t("Buy")}
        </Button>

        <Box>
          <Box m="4px 0px">
            <Divider />
          </Box>
          {token?.underlying !== "eth" ? (
            <Box padding={"6px 0px"}>
              <Text fontSize={"14px"} fontWeight={500}>
                {t("Pay today at")}
              </Text>
              <Flex alignItems="center" justifyContent="space-between" w={"100%"} marginTop={1}>
                <Flex alignItems={"center"}>
                  <Image src={token?.logoUrl} height="16px" width="16px" margin="0px 4px" />
                  <Text fontSize="sm" color={colors.primary1} fontWeight="700">
                    {nft.makeQuote?.takeAssetValue
                      ? formatNumber(
                          new BigNumber(+nft.makeQuote.takeAssetValue)
                            .div(4)
                            .div(Math.pow(10, token?.decimals || 0))
                            .toNumber()
                        )
                      : ""}
                  </Text>
                </Flex>
                <FavoriteDetail />
              </Flex>
            </Box>
          ) : (
            <>
              <Flex alignItems="end" justifyContent="end" fontSize={"14px"} fontWeight={500} padding={"4px"}>
                <FavoriteDetail />
              </Flex>
            </>
          )}
        </Box>
      </Box>
    </Flex>
  );
});
