import { AxiosRequestConfig } from "axios";
import { isBefore } from "date-fns";
import Web3 from "web3";
import { authService } from "../../../services/auth.service";
import { store } from "../../../store";
import { setAuthentication } from "../../../store/features/user/user.slice";

export const tokenInterceptor = async (config: AxiosRequestConfig<any>) => {
  if (config.headers) {
    const user = store.getState()?.user;

    if (
      user?.authentication?.token &&
      user.authentication.exp &&
      user.authentication.exp * 1000 > new Date().valueOf()
    ) {
      delete config.headers["requireToken"];
      config.headers["Authorization"] = `Bearer ${user.authentication.token}`;
    } else {
      if (config.headers["requireToken"] === "0") {
        delete config.headers["requireToken"];
        return config;
      }

      const web3 = new Web3((window as any).web3.currentProvider);
      const accounts = await web3.eth.getAccounts();

      if (!accounts[0]) return config;

      const address = accounts[0].toLowerCase();
      const message = "Welcome to bePAY Marketplace";
      const signature = await web3.eth.personal.sign(web3.utils.fromUtf8(message), address, "");

      const signInResponse = await authService.signIn({ message, signature });

      if (signInResponse?.data?.token) {
        config.headers["Authorization"] = `Bearer ${signInResponse.data.token}`;
        store.dispatch(setAuthentication({ token: signInResponse.data.token }));
      }

      delete config.headers["requireToken"];
    }
  }

  return config;
};
