import { Avatar, Button, Flex, HStack, Image, Stack, Text } from "@chakra-ui/react";
import { shortenAddress } from "@usedapp/core";
import BigNumber from "bignumber.js";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { I18N_NAMESPACE } from "../../../core/constants/i18n";
import { INftQuote } from "../../../core/models/nft";
import { formatNumber } from "../../../core/utils/number";
import { useAppSelector } from "../../../store/hook";

interface Props {
  bid: INftQuote;
  onAcceptBid: (bid: INftQuote) => void;
}

export const HighestBid = React.memo(({ bid, onAcceptBid }: Props) => {
  const { t } = useTranslation([I18N_NAMESPACE.ASSET_DETAIL]);
  const navigate = useNavigate();
  const supportedTokens = useAppSelector((state) => state.token.supportedTokens);
  const bidToken = useMemo(
    () => (bid ? supportedTokens.byId[bid.make.assetType.contract] : null),
    [bid?.make?.assetType?.contract, supportedTokens.byId]
  );

  const remainingQuantity = useMemo(
    () => parseInt(bid?.take.value || "0") - (bid?.fill || 0),
    [bid.make.value, bid.fill]
  );

  const pricePerCopy = useMemo(
    () => new BigNumber(bid?.make.value).div(bid.take.value).toNumber(),
    [bid.take.value, bid.make.value]
  );

  const totalPrice = useMemo(
    () =>
      !!bid?.make?.value && !!bidToken
        ? new BigNumber(pricePerCopy).multipliedBy(remainingQuantity).div(Math.pow(10, bidToken.decimals)).toNumber()
        : 0,
    [bid.take.value, bidToken?.decimals]
  );

  const onClickOwner = useCallback(() => {
    navigate(`/profile/assets/${bid?.makerInfo?.id}/minted`);
  }, [bid?.makerInfo?.id]);
  return (
    <Stack
      flexDir={{
        base: "column",
        md: "row",
        lg: "row",
        xl: "row",
        "2xl": "row",
      }}
      alignItems={{
        base: "flex-start",
        md: "center",
        lg: "center",
        xl: "center",
        "2xl": "center",
      }}
      justifyContent="space-between"
    >
      <Stack>
        <Text fontWeight={"500"}>{t("Highest bid")}</Text>
        <Flex marginTop="12px" alignItems={"center"} justifyContent="space-between">
          <HStack alignItems={"center"} spacing={3}>
            <Flex background="#f8f8f8" borderRadius="15px" padding="8px 12px" alignItems="center">
              <Image src={bidToken?.logoUrl} height="24px" width="24px" marginRight="8px" />
              <Text fontWeight="700" fontSize={"20px"}>
                <Text as="span">
                  {totalPrice}
                  <Text as="span" fontSize={"sm"} fontWeight={500} color={"gray.500"}>
                    {" "}
                    / {t("copy", { count: remainingQuantity })}
                  </Text>
                </Text>
              </Text>
            </Flex>
            <HStack>
              <Text fontSize={"small"} color={"gray.400"}>
                {t("by")}
              </Text>
              <HStack onClick={onClickOwner} cursor={"pointer"} _hover={{ color: "#000" }}>
                <Avatar size={"sm"} src={bid?.makerInfo?.image || ""} />
                <Text fontWeight={500} fontSize="sm">
                  {bid?.makerInfo?.displayName || (bid?.makerInfo?.id && shortenAddress(bid.makerInfo.id))}
                </Text>
              </HStack>
            </HStack>
          </HStack>
        </Flex>
      </Stack>
      <Button colorScheme="blue" width="150px" onClick={() => onAcceptBid(bid)}>
        {t("Accept")}
      </Button>
    </Stack>
  );
});
