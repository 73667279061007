import React from "react";
import Select, { OnChangeValue } from "react-select";

export interface SelectItem {
  value: string | number;
  label: string;
}

const selectCustomStyles = {
  control: (styles: any, { isDisabled }: any) => ({
    borderWidth: 1,
    borderColor: "var(--chakra-colors-gray-200)",
    height: 44,
    borderRadius: 8,
    display: "flex",
    // backgroundColor: isDisabled ? colors.smoke : "#fff",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    paddingLeft: 16,
  }),
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => ({
    ...styles,
    // backgroundColor: isDisabled ? null : isSelected ? colors.primaryColor : isFocused ? "#FCF0ED" : null,

    ":active": {
      ...styles[":active"],
      backgroundColor: "#FCF0ED",
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

interface Props {
  placeholder?: string;
  options: ReadonlyArray<SelectItem>;
  value?: SelectItem;
  onChange?: (value: SelectItem) => void;
  disabled?: boolean;
}

export const AppSelect = React.memo((props: Props) => {
  return (
    <Select
      value={props.value}
      onChange={(item: OnChangeValue<SelectItem, false>) => props.onChange && props.onChange(item as SelectItem)}
      isDisabled={props.disabled}
      options={props.options}
      styles={selectCustomStyles}
      placeholder={props.placeholder || "Chọn..."}
    />
  );
});
