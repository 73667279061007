import axios from "../core/https";
import { IOrderV2, IPurchaseV2, ISale } from "../core/models/order";
import { IBuyerLicense, ISellerLicense, IUser } from "../core/models/user";

const updateProfile = (body: IUser) => axios.put("/accounts", body);

const getProfile = () => axios.get<IUser>("/accounts/profile");
const getProfileById = (id: string, config?: { signal?: AbortSignal }) =>
  axios.get<IUser>(`/accounts/${id.toLowerCase()}`, {
    headers: {
      requireToken: "0",
    },
    ...config
  });

const getProfileByIds = (ids: string[]) =>
  axios.get<IUser[]>(`/accounts/get-by-ids`, {
    headers: {
      requireToken: "0",
    },
    params: {
      id: ids.join(","),
    },
  });

const watch = (id: string) =>
  axios.post(
    `/accounts/${id.toLowerCase()}/watch`,
    {},
    {
      headers: {
        requireToken: "0",
      },
    }
  );

const submitSellerDoc = () => axios.post<IUser>("/accounts/submit-seller-doc");
const submitCreditDoc = () => axios.post<IUser>("/accounts/submit-credit-doc");

const getBuyerLicense = (params: { chain: string }) =>
  axios.get<{ signature: string; license: IBuyerLicense }>("/accounts/buyer-license", { params });
const getSellerLicense = () =>
  axios.get<{ signature: string; license: ISellerLicense }>("/accounts/get-seller-license");

const getOrders = (id: string, params: { chain: string }) =>
  axios.get<IOrderV2[]>(`/accounts/${id}/orders`, {
    headers: {
      requireToken: "0",
    },
    params,
  });
const getPurchases = (id: string, params: { chain: string }) =>
  axios.get<IPurchaseV2[]>(`/accounts/${id}/purchases`, {
    headers: {
      requireToken: "0",
    },
    params,
  });

export const userService = {
  updateProfile,
  getProfile,
  submitSellerDoc,
  submitCreditDoc,
  getProfileById,
  watch,
  getBuyerLicense,
  getSellerLicense,
  getOrders,
  getPurchases,
  getProfileByIds,
};
