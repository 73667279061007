import { Box, Button, Stack } from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { I18N_NAMESPACE } from "../../../core/constants/i18n";
import { INftOwner, INftQuote, INftV2 } from "../../../core/models/nft";
import { HighestBid } from "./HighestBid";
import { ListingFormModal } from "./ListingFormModal";

interface Props {
  nft: INftV2 | undefined;
  highestBid: INftQuote | undefined;
  onListSuccess: () => void;
  onAcceptBid: (bid: INftQuote) => void;
}

export const ListingState = React.memo(({ highestBid, nft, onListSuccess, onAcceptBid }: Props) => {
  const { t } = useTranslation([I18N_NAMESPACE.ASSET_DETAIL]);
  const [isVisibleListingForm, setIsVisibleListingForm] = useState(false);

  return (
    <Stack spacing={10}>
      <Box>
        <Button
          colorScheme={"blue"}
          onClick={() => {
            setIsVisibleListingForm(true);
          }}
        >
          {t("List")}
        </Button>
      </Box>

      {!!highestBid && <HighestBid bid={highestBid} onAcceptBid={onAcceptBid} />}

      {!!nft && (
        <ListingFormModal
          isVisible={isVisibleListingForm}
          onClose={() => {
            setIsVisibleListingForm(false);
          }}
          nft={nft}
          onListSuccess={onListSuccess}
        />
      )}
    </Stack>
  );
});
