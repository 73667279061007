import { CheckIcon, CopyIcon } from "@chakra-ui/icons";
import { Input, InputGroup } from "@chakra-ui/input";
import { Grid, Heading, HStack, Stack } from "@chakra-ui/layout";
import {
  Button,
  Flex,
  Image,
  InputLeftElement,
  Text,
  Textarea,
  useBreakpointValue,
  useClipboard,
  useToast,
} from "@chakra-ui/react";
import { useContractFunction, useEthers } from "@usedapp/core";
import { useFormik } from "formik";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppContent } from "../../components/AppContent";
import { colors } from "../../core/constants/colors";
import { I18N_NAMESPACE } from "../../core/constants/i18n";
import { images } from "../../core/constants/images";
import { useTokenControllerContract } from "../../core/hooks/useContract";
import { storageSerivce } from "../../services/storage.service";
import { userService } from "../../services/user.service";
import { setUser } from "../../store/features/user/user.slice";
import { fetchUserProfile } from "../../store/features/user/user.thunk";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { ProfileBannerRow } from "./components/ProfileBannerRow";
import { ProfileImageRow } from "./components/ProfileImageRow";
import { ProfileVideoRow } from "./components/ProfileVideoRow";
import { VerifyCreditRow } from "./components/VerifyCreditRow";
import { VerifySellerRow } from "./components/VerifySellerRow";

const ProfileSetting = React.memo(() => {
  const { library, account } = useEthers();
  const { t } = useTranslation([I18N_NAMESPACE.PROFILE]);

  const { hasCopied, onCopy } = useClipboard(account || "");
  const toast = useToast();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userProfile = useAppSelector((state) => state.user.profile);
  const appContext = useAppSelector((state) => state.appContext);

  const formik = useFormik({
    initialValues: {
      avatar: userProfile?.image,
      banner: userProfile?.cover,
      username: userProfile?.displayName || "",
      bio: userProfile?.bio || "",
      facebook: userProfile?.links?.find((i) => i.type === "facebook")?.url || "",
      instagram: userProfile?.links?.find((i) => i.type === "instagram")?.url || "",
      twitter: userProfile?.links?.find((i) => i.type === "twitter")?.url || "",
      video: userProfile?.video,
    },
    onSubmit: async (values) => {
      const user = {
        bio: values.bio,
        cover: values.banner,
        displayName: values.username,
        image: values.avatar,
        links: [
          {
            type: "facebook",
            url: values.facebook,
          },
          {
            type: "instagram",
            url: values.instagram,
          },
          {
            type: "twitter",
            url: values.twitter,
          },
        ],
        video: values.video || null,
      };

      const updateResult = await userService.updateProfile(user);

      if ([200, 201, 204].includes(updateResult.status)) {
        if (account) {
          const profileResult = await userService.getProfileById(account);
          dispatch(setUser(profileResult.data));
        }

        toast({
          title: t("Success"),
          description: t("Profile updated"),
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
    },
  });

  const [isUploadingAvatar, setIsUploadingAvatar] = useState(false);
  const [isUploadingBanner, setIsUploadingBanner] = useState(false);
  const [isUploadingVideo, setIsUploadingVideo] = useState(false);
  const [isVerifingSeller, setIsVerifingSeller] = useState(false);
  const [isVerifingBuyer, setIsVerifingBuyer] = useState(false);

  const tokenControllerContract = useTokenControllerContract();
  const registerSellerFunc = useContractFunction(tokenControllerContract as any, "registerSeller");
  const registerBuyerFunc = useContractFunction(tokenControllerContract as any, "registerBuyer");

  const isRegisteringSeller = useMemo(() => registerSellerFunc.state.status === "Mining", [registerSellerFunc.state]);
  const isClaimingCredit = useMemo(() => registerBuyerFunc.state.status === "Mining", [registerBuyerFunc.state]);

  useEffect(() => {
    const getProfile = async () => {
      if (!account) return;
      const response = await userService.getProfileById(account);

      if (response.data) {
        setUser(response.data);
      }
    };

    getProfile();

    const interval = setInterval(() => {
      getProfile();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [account]);

  useEffect(() => {
    if (!userProfile?.id) return;

    formik.setValues({
      avatar: userProfile?.image,
      banner: userProfile?.cover,
      username: userProfile?.displayName || "",
      bio: userProfile?.bio || "",
      facebook: userProfile?.links?.find((i) => i.type === "facebook")?.url || "",
      instagram: userProfile?.links?.find((i) => i.type === "instagram")?.url || "",
      twitter: userProfile?.links?.find((i) => i.type === "twitter")?.url || "",
      video: userProfile?.video,
    });
  }, [userProfile?.id]);

  const onChangeAvatar = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e?.target?.files[0];
    if (!file) return;

    const MAX_SIZE_IN_MB = 5;
    if (file.size > MAX_SIZE_IN_MB * 1024 * 1024) {
      toast({
        title: t("Validation"),
        description: t("Avatar's max size", { size: MAX_SIZE_IN_MB }),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }
    setIsUploadingAvatar(true);
    const url = await storageSerivce.upload(`user/avatar/${account}`, file);
    formik.setFieldValue("avatar", url);
    setIsUploadingAvatar(false);
  };

  const onChangeBanner = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e?.target?.files[0];
    if (!file) return;

    const MAX_SIZE_IN_MB = 5;
    if (file.size > MAX_SIZE_IN_MB * 1024 * 1024) {
      toast({
        title: t("Validation"),
        description: t("Banner's max size", { size: MAX_SIZE_IN_MB }),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }
    setIsUploadingBanner(true);
    const url = await storageSerivce.upload(`user/banner/${account}`, file);
    formik.setFieldValue("banner", url);
    setIsUploadingBanner(false);
  };

  const onChangeVideo = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e?.target?.files[0];
    if (!file) return;

    const MAX_SIZE_IN_MB = 100;
    if (file.size > MAX_SIZE_IN_MB * 1024 * 1024) {
      toast({
        title: t("Validation"),
        description: t("Video's max size", { size: MAX_SIZE_IN_MB }),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    setIsUploadingVideo(true);
    const url = await storageSerivce.upload(`user/video/${account}`, file);
    formik.setFieldValue("video", url);
    setIsUploadingVideo(false);
  };

  const onVerifySeller = async () => {
    setIsVerifingSeller(true);
    await userService.submitSellerDoc();
    await dispatch(fetchUserProfile());
    setIsVerifingSeller(false);
  };

  const onRegisterSeller = async () => {
    const response = await userService.getSellerLicense();
    registerSellerFunc.send(response.data.license, response.data.signature);
  };

  const onVerifyBuyer = async () => {
    setIsVerifingBuyer(true);
    await userService.submitCreditDoc();
    await dispatch(fetchUserProfile());
    setIsVerifingBuyer(false);
  };

  const onClaim = async () => {
    const response = await userService.getBuyerLicense({ chain: appContext.selectedChain.name });
    registerBuyerFunc.send(response.data.license, response.data.signature);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <AppContent maxWidth="1100px">
        <Grid gridTemplateColumns={{ base: "1fr", md: "1fr 1fr" }} gridGap="40px" margin="10px 0px">
          <ProfileImageRow isUploading={isUploadingAvatar} value={formik.values.avatar} onChange={onChangeAvatar} />
          <ProfileBannerRow isUploading={isUploadingBanner} value={formik.values.banner} onChange={onChangeBanner} />
          <Stack spacing="40px">
            <Stack spacing="12px">
              <Heading size="md">{t("Username")}</Heading>
              <Input
                placeholder={t("Enter your name")}
                borderRadius="xl"
                value={formik.values.username}
                onChange={formik.handleChange}
                name="username"
              />
            </Stack>
            <Stack spacing="12px">
              <Heading size="md">{t("Bio")}</Heading>
              <Textarea
                placeholder={t("Tell the world your story!")}
                borderRadius="xl"
                onChange={formik.handleChange}
                name="bio"
                value={formik.values.bio}
                minHeight={"150px"}
              />
            </Stack>
            <Stack spacing="12px">
              <Heading size="md">{t("Social Media")}</Heading>
              <InputGroup>
                <InputLeftElement
                  children={<Image src={images.facebook} h="20px" w="20px" objectFit="contain" zIndex={-1} />}
                  borderRadius="xl"
                />
                <Input
                  placeholder={t("Paste your Facebook here")}
                  borderRadius="xl"
                  onChange={formik.handleChange}
                  name="facebook"
                  value={formik.values.facebook}
                />
              </InputGroup>
              <InputGroup>
                <InputLeftElement
                  children={<Image src={images.twitter} h="20px" w="20px" objectFit="contain" zIndex={-1} />}
                  borderRadius="xl"
                />
                <Input
                  placeholder={t("Paste your Twitter here")}
                  borderRadius="xl"
                  onChange={formik.handleChange}
                  name="twitter"
                  value={formik.values.twitter}
                />
              </InputGroup>
              <InputGroup>
                <InputLeftElement
                  children={<Image src={images.instagram} h="20px" w="20px" objectFit="contain" zIndex={-1} />}
                  borderRadius="xl"
                />
                <Input
                  placeholder={t("Paste your Instagram here")}
                  borderRadius="xl"
                  onChange={formik.handleChange}
                  name="instagram"
                  value={formik.values.instagram}
                />
              </InputGroup>
            </Stack>
            <Stack spacing="12px">
              <Heading size="md">{t("Wallet Address")}</Heading>
              <Flex
                borderRadius="xl"
                padding="12px"
                background={"#F8F8F8"}
                alignItems="center"
                justifyContent="space-between"
                onClick={onCopy}
                cursor="pointer"
                fontWeight="700"
                color={colors.primary}
              >
                {hasCopied ? (
                  <HStack>
                    <Text>{t("Copied")}</Text>
                    <CheckIcon color={colors.primary} fontSize="18px" />
                  </HStack>
                ) : (
                  <HStack>
                    <Text whiteSpace="nowrap" maxW={"75vw"} overflow="hidden" textOverflow="ellipsis">
                      {account ? account : ""}
                    </Text>
                    <CopyIcon color={colors.primary} fontSize="20px" />
                  </HStack>
                )}
              </Flex>
            </Stack>

            <VerifyCreditRow
              onVerifyBuyer={onVerifyBuyer}
              onClaim={onClaim}
              isVerifingBuyer={isVerifingBuyer}
              isClamingCredit={isClaimingCredit}
              claimTransactionState={registerBuyerFunc.state}
            />

            <VerifySellerRow
              onVerifySeller={onVerifySeller}
              onRegister={onRegisterSeller}
              isVerifingSeller={isVerifingSeller}
              isRegisteringSeller={isRegisteringSeller}
            />
          </Stack>

          <ProfileVideoRow
            isUploading={isUploadingVideo}
            value={formik.values.video}
            onChange={onChangeVideo}
            onRemoveVideo={() => formik.setFieldValue("video", undefined)}
          />

          <Flex justifyContent="flex-end">
            <Button colorScheme="blue" type="submit" isLoading={formik.isSubmitting}>
              {t("Save Update")}
            </Button>
          </Flex>
        </Grid>
      </AppContent>
    </form>
  );
});

export default ProfileSetting;
