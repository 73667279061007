import { Box } from "@chakra-ui/layout";
import React from "react";

interface Props {
  maxWidth?: string;
}

export const AppContent = React.memo((props: React.PropsWithChildren<Props>) => {
  return (
    <Box
      width="full"
      maxWidth={{
        base: "100vw", // 0-48em,
        lg: props.maxWidth || "full",
        xl: props.maxWidth || "1300px", // 80em+
        "2xl": props.maxWidth || "1600px", // 80em+
      }}
      m="0px auto"
      p={{ base: 5, lg: 8, xl: 10 }}
    >
      {props.children}
    </Box>
  );
});
