import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  AspectRatio,
  Fade,
  Grid,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Collection } from "../../../components/Collection";
import { colors } from "../../../core/constants/colors";
import { I18N_NAMESPACE } from "../../../core/constants/i18n";
import { ICollection, ITopCollection } from "../../../core/models/collection";

interface Props {
  collections: ITopCollection[];
  isLoading: boolean;
  dateOptions: { label: string; value: string }[];
  selectedDateOption: string;
  onChangeDateOption: (value: string) => void;
}

export const TopCollections = React.memo((props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation([I18N_NAMESPACE.DASHBOARD]);
  const selectionDateOption = useMemo(
    () => props.dateOptions.find((o) => o.value === props.selectedDateOption),
    [props.dateOptions, props.selectedDateOption]
  );

  const onClickCollection = useCallback((collection: ICollection) => {
    navigate(`/collections/${collection.contract}`);
  }, []);

  return (
    <Stack spacing={5}>
      <Text fontSize={"2xl"} fontWeight={700}>
        {t("Top collections over last")}{" "}
        <Menu>
          <MenuButton color={colors.primary}>
            <Text as={"span"} fontWeight={700}>
              {selectionDateOption?.label}
            </Text>{" "}
            <ChevronDownIcon />
          </MenuButton>
          <Portal>
            <MenuList minWidth="240px">
              <MenuOptionGroup
                onChange={(value) => typeof value === "string" && props.onChangeDateOption(value)}
                value={props.selectedDateOption}
                type="radio"
              >
                {props.dateOptions.map((o) => (
                  <MenuItemOption key={o.value} value={o.value}>
                    {o.label}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Portal>
        </Menu>
      </Text>
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(3, 1fr)",
          lg: "repeat(4, 1fr)",
          xl: "repeat(5, 1fr)",
          "2xl": "repeat(5, 1fr)",
        }}
        gap={6}
      >
        {props.isLoading
          ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
              <Fade in={props.isLoading} key={`collection-skeleton-${i}`}>
                <AspectRatio ratio={1 / 1} borderRadius="lg">
                  <Skeleton h={"full"} w={"full"} borderRadius="lg"></Skeleton>
                </AspectRatio>
              </Fade>
            ))
          : props.collections.map((collection) => (
              <Fade in={!props.isLoading} key={collection.contract}>
                <Collection
                  collection={{
                    contract: collection.contract,
                    banner: collection.banner,
                    description: collection.description,
                    image: collection.image,
                    name: collection.name,
                    symbol: collection.symbol,
                    ownerInfo: {
                      address: collection.accountAddress,
                      id: collection.accountAddress,
                      image: collection.accountImage,
                      displayName: collection.accountName,
                    },
                    owner: collection.accountAddress,
                  }}
                  onClick={onClickCollection}
                />
              </Fade>
            ))}
      </Grid>
    </Stack>
  );
});
