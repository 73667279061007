export const formatNumber = (
  number: number,
  options?: {
    maximumFractionDigits?: number;
    notation?: "standard" | "scientific" | "engineering" | "compact" | undefined;
  }
) => {
  return new Intl.NumberFormat("en-EN", {
    maximumFractionDigits: options?.maximumFractionDigits || 18,
    notation: options?.notation,
  }).format(number);
};

export const toPlainString = (num: string | number) => {
  return ("" + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/, function (a, b, c, d, e) {
    return e < 0 ? b + "0." + Array(1 - e - c.length).join("0") + c + d : b + c + d + Array(e - d.length + 1).join("0");
  });
};

export const ordinalSurfixOf = (i: number) => {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
};

export const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);
