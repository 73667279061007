import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { NftResource } from "../../../components/NftResource";
import { colors } from "../../../core/constants/colors";
import { I18N_NAMESPACE } from "../../../core/constants/i18n";
import { INft } from "../../../core/models/nft";
import { nftService } from "../../../services/nft.service";
import { useAppSelector } from "../../../store/hook";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  nft: INft | undefined;
  isLazy: boolean;
}

export const CreateNftSuccessModal = React.memo((props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation([I18N_NAMESPACE.CREATE_ASSET]);

  const onViewNft = useCallback(() => {
    navigate(`/assets/${props.nft?.contract}/${props.nft?.tokenId}`);
  }, [props.nft?.contract, props.nft?.tokenId]);

  return (
    <Modal isOpen={props.isVisible} onClose={props.onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody padding={"30px"}>
          <Stack flexDirection={"column"} alignContent={"center"} justifyContent={"center"} spacing={6}>
            <Box height={"250px"} width={"250px"} alignSelf={"center"} borderWidth={1} borderRadius={"md"}>
              <NftResource image={props.nft?.image} animationUrl={props.nft?.animationUrl} muted />
            </Box>
            <Box textAlign={"center"}>
              <Text>
                {t("Your")}{" "}
                <Text as="b" color={colors.primary}>
                  {props.nft?.name}
                </Text>{" "}
                {t("NFT is successfully created")}
              </Text>
            </Box>

            <Stack>
              <Button variant={"outline"} onClick={props.onClose}>
                {t("Create another")}
              </Button>
              <Button colorScheme={"blue"} onClick={onViewNft}>
                {t("View your NFT")}
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});
