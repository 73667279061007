import { Box, Center, Flex, Spinner } from "@chakra-ui/react";
import { useEthers } from "@usedapp/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { I18N_NAMESPACE } from "../../core/constants/i18n";
import { IOrderV2, ISale } from "../../core/models/order";
import { marketplaceService } from "../../services/marketplace.service";
import { userService } from "../../services/user.service";
import { useAppSelector } from "../../store/hook";
import { OrderItem } from "./components/OrderItem";

const SaleHistory = React.memo(() => {
  const { account } = useEthers();
  const { t } = useTranslation([I18N_NAMESPACE.PROFILE]);
  const appContext = useAppSelector((state) => state.appContext);

  const [orders, setOrders] = useState<IOrderV2[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function getSaleHistory() {
      if (!account) return;
      setIsLoading(true);
      const response = await userService.getOrders(account, { chain: appContext.selectedChain.name });
      setOrders(_.orderBy(response.data || [], "created_time", "desc"));
      setIsLoading(false);
    }

    getSaleHistory();
  }, [account]);

  return (
    <>
      {isLoading ? (
        <Flex alignItems={"center"} justifyContent={"center"}>
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </Flex>
      ) : orders.length === 0 ? (
        <Box>
          <Center fontSize="20px" marginTop={"30px"} color={"rgba(4, 4, 5, 0.6)"}>
            {t("No sales found")}
          </Center>
        </Box>
      ) : (
        orders.map((order, index) => <OrderItem key={`sale-${order.id}-${index}`} order={order} />)
      )}
    </>
  );
});

export default SaleHistory;
