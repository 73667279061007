import * as React from 'react';
import { MutableRefObject } from 'react';

export const useIsOverflow = (ref: MutableRefObject<HTMLElement>, callback?: Function | undefined) => {
  const [isOverflow, setIsOverflow] = React.useState<boolean | undefined>(undefined);

  React.useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
      const hasOverflow = current.scrollHeight > current.clientHeight;

      setIsOverflow(hasOverflow);

      if (callback) callback(hasOverflow);
    };

    if (current) {
      if ('ResizeObserver' in window) {
        const resizeObserver = new ResizeObserver(trigger)
        resizeObserver.observe(current);
        return () => {
          resizeObserver.unobserve(current);
        }
      }

      trigger();
    }
  }, [callback, ref]);

  return isOverflow;
};
