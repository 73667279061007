import { CheckCircleIcon, CheckIcon } from "@chakra-ui/icons";
import { Box, Flex, Spinner } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { colors } from "../core/constants/colors";

interface Props {
  isLoading: boolean;
  isSuccess: boolean;
}

export const ProgressIcon = React.memo(({ isLoading, isSuccess }: Props) => {
  const renderContent = useCallback(() => {
    if (isSuccess) {
      return <CheckCircleIcon color={colors.success} height="23px" width="23px" />;
    } else if (isLoading) {
      return (
        <Flex alignItems="center">
          <Spinner thickness="3px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="md" />
        </Flex>
      );
    } else {
      return <CheckIcon color={"gray.300"} height="23px" width="23px" />;
    }
  }, [isLoading, isSuccess]);

  return (
    <Box height={"26px"} width={"26px"}>
      {renderContent()}
    </Box>
  );
});
