import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  message: string;
}

export const ErrorModal = React.memo((props: Props) => {
  return (
    <Modal isOpen={props.isVisible} onClose={props.onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader />
        <ModalBody padding={"24px 30px"}>
          <Stack spacing={5} alignItems={"center"}>
            <InfoOutlineIcon color={"red.400"} fontSize={50} />
            <Text fontWeight={500} textAlign={"center"}>
              {props.message}
            </Text>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});
