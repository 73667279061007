import { WarningIcon } from "@chakra-ui/icons";
import { Box, Heading } from "@chakra-ui/layout";
import { Button, Flex, Spinner, Tooltip } from "@chakra-ui/react";
import { useEthers } from "@usedapp/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../../core/constants/colors";
import { I18N_NAMESPACE } from "../../../core/constants/i18n";
import { SELLER_STATUS } from "../../../core/constants/status";
import { useAppSelector } from "../../../store/hook";

interface Props {
  onVerifySeller: () => void;
  onRegister: () => void;
  isVerifingSeller: boolean;
  isRegisteringSeller: boolean;
}

export const VerifySellerRow = React.memo((props: Props) => {
  const userProfile = useAppSelector((state) => state.user.profile);
  const { t } = useTranslation([I18N_NAMESPACE.PROFILE]);
  const textByStatus: { [key: string]: string } = {
    [SELLER_STATUS.NOT_VERIFY]: t("Haven’t verified yet"),
    [SELLER_STATUS.REJECTED]: t("Rejected"),
    [SELLER_STATUS.SUBMITTED]: t("Submitted"),
    [SELLER_STATUS.VERIFIED]: t("Verified"),
    [SELLER_STATUS.VERIFYING]: t("Verifying"),
  };

  const renderAction = () => {
    if (
      !userProfile?.sellerStatus ||
      userProfile.sellerStatus === SELLER_STATUS.NOT_VERIFY ||
      userProfile.sellerStatus === SELLER_STATUS.REJECTED
    ) {
      return (
        <a href="https://share.hsforms.com/1VNtRMU7tR2WUKZ_bA0fLXQ2n1gz" target={"_blank"}>
          <Button
            colorScheme="blue"
            variant="ghost"
            background="blue.50"
            // onClick={props.onVerifySeller}
            // isLoading={props.isVerifingSeller}
          >
            {t("Verify Now")}
          </Button>
        </a>
      );
    } else if (userProfile.sellerStatus === SELLER_STATUS.VERIFYING) {
      return <Spinner thickness="3px" speed="0.65s" emptyColor="gray.200" color="blue.500" />;
    } else {
      return <div />;
    }
  };

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Box>
        <Flex>
          <Heading size="md">{t("Listing")}</Heading>
          <Tooltip
            hasArrow
            label={t("You must verify account to start listing item.")}
            borderRadius="lg"
            padding="6px"
            placement="top"
          >
            <WarningIcon w={4} h={4} color={colors.primary} marginLeft="6px" />
          </Tooltip>
        </Flex>
        <Box color={"#A6A6A6"}>{textByStatus[userProfile?.sellerStatus || SELLER_STATUS.NOT_VERIFY]}</Box>
      </Box>
      {renderAction()}
    </Flex>
  );
});
