import { ChainId } from "@usedapp/core";
import axios from "../core/https";
import { IPagingResponse } from "../core/models/api";
import { ICollection, ITopCollection } from "../core/models/collection";
import { INft, ISummaryNft } from "../core/models/nft";
import { ISale } from "../core/models/order";
import { ITopUser, IUser } from "../core/models/user";
import { getIpAddressClient } from "../core/utils/getIpAddressClient";

const saveDraft = (body: INft) => axios.post("/marketplace/nfts", body);

const confirm = (id: string) =>
  axios.put(
    `/marketplace/nfts/${id}/confirm`,
    {},
    {
      headers: {
        requireToken: "0",
      },
    }
  );
const delist = (id: string) => axios.put(`/marketplace/nfts/${id}/delist`);

const sync = (tokenAddress: string, tokenId: string, ownerOf: string) =>
  axios.put(
    `/marketplace/nfts/${tokenAddress}/${tokenId}/${ownerOf}/sync`,
    {},
    {
      headers: {
        requireToken: "0",
      },
    }
  );

const getViews = async (
  tokenAddress: string,
  tokenId: string,
  account: string | null | undefined,
  chainId?: ChainId
) => {
  const ipResponse = await getIpAddressClient();
  const ipAddress = ipResponse.data.ip || "";
  return axios.post<number>(
    `/marketplace/nfts/count-view`,
    {
      type: 0,
      token_address: tokenAddress,
      token_id: tokenId,
      account: account,
      chain_id: chainId,
    },
    {
      headers: {
        requireToken: "0",
        "X-Client-IP": ipAddress,
      },
    }
  );
};

const getTopCollections = (params?: { limit: number; days: number }) =>
  axios.get<ITopCollection[]>(`/collections/top-sale`, {
    headers: {
      requireToken: "0",
    },
    params,
  });

const getTopFavoriteNfts = (params: { limit: number }) =>
  axios.get<ISummaryNft[]>(`/nfts/top-favorites`, {
    headers: {
      requireToken: "0",
    },
    params,
  });

const getTopBuyers = (params?: { chain: string; limit: number; days: number }) =>
  axios.get<ITopUser[]>(`/orders/top-buyer`, {
    headers: {
      requireToken: "0",
    },
    params,
  });

const getTopSellers = (params?: { chain: string; limit: number; days: number }) =>
  axios.get<ITopUser[]>(`/orders/top-seller`, {
    headers: {
      requireToken: "0",
    },
    params,
  });

const search = (params: { limit?: number; q?: string }) =>
  axios.get<IPagingResponse<ISummaryNft[]>>(`/search`, {
    headers: {
      requireToken: "0",
    },
    params,
  });

export const marketplaceService = {
  saveDraft,
  confirm,
  delist,
  sync,
  getViews,
  getTopCollections,
  getTopFavoriteNfts,
  getTopBuyers,
  getTopSellers,
  search,
};
