import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEtherBalance, useEthers } from "@usedapp/core";
import BigNumber from "bignumber.js";
import { addWeeks, format } from "date-fns";
import { utils } from "ethers";
import { useFormik } from "formik";
import React, { Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdDateRange, MdPayment } from "react-icons/md";
import * as yup from "yup";
import { ErrorModal } from "../../../../components/ErrorModal";
import { NftResource } from "../../../../components/NftResource";
import { colors } from "../../../../core/constants/colors";
import { I18N_NAMESPACE } from "../../../../core/constants/i18n";
import { NFT_TYPE } from "../../../../core/constants/nft";
import { PAYMENT_STRATEGY } from "../../../../core/constants/paymentStrategy";
import { LAYER_1_TOKENS } from "../../../../core/constants/token";
import { useERC20Balance } from "../../../../core/hooks/useERC20Balance";
import { INftOwner, INftV2 } from "../../../../core/models/nft";
import { Quote } from "../../../../core/models/quote";
import { clamp, formatNumber } from "../../../../core/utils/number";
import { useAppSelector } from "../../../../store/hook";
import { CreditStatusWarningModal } from "./CreditStatusWarningModal";
import { PurchaseProcessModal } from "./PurchaseProcessModal";
import { PurchaseSuccessModal } from "./PurchaseSuccessModal";

enum PURCHASE_STATE {
  CONFIRMING,
  PURCHASING,
  SUCCESS,
  NOT_VERIFIED,
  INSUFFICIENT_BALANCE,
  LISTED_ERROR,
}

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onPurchaseSuccess: () => void;
  nft: INftV2;
  quote: Quote;
  quoteOwner: INftOwner | undefined;
}

const Title = React.memo((props: React.PropsWithChildren<{}>) => {
  return <Heading size={"sm"}>{props.children}</Heading>;
});

const Description = React.memo((props: React.PropsWithChildren<{}>) => {
  return (
    <Text color={"gray.500"} fontSize={"xs"}>
      {props.children}
    </Text>
  );
});

export const PurchaseFormModal = React.memo((props: Props) => {
  const { account } = useEthers();
  const supportedTokens = useAppSelector((state) => state.token.supportedTokens);
  const { t } = useTranslation([I18N_NAMESPACE.ASSET_DETAIL]);
  const [purchaseState, setPurchaseState] = useState<PURCHASE_STATE>(PURCHASE_STATE.CONFIRMING);

  const token = useMemo(
    () =>
      props.quote?.take?.assetType?.contract ? supportedTokens.byId[props.quote?.take?.assetType?.contract] : null,
    [props.quote.take.assetType.contract]
  );
  const pricePerCopy = useMemo(
    () => (props.quote.take.value ? +props.quote.take.value : 0) / +props.quote.make.value,
    [props.quote.take.value, props.quote.make.value]
  );

  const userUnderlyingTokenBalance = useERC20Balance(
    token?.underlying && !LAYER_1_TOKENS.includes(token?.underlying) ? token?.underlying : null
  );
  const ethBalanceInBigNumber = useEtherBalance(account);
  const ethBalance = useMemo(
    () => (ethBalanceInBigNumber ? +utils.formatEther(ethBalanceInBigNumber) : 0),
    [ethBalanceInBigNumber]
  );
  const walletBalance = useMemo(
    () => (token?.underlying && LAYER_1_TOKENS.includes(token?.underlying) ? ethBalance : userUnderlyingTokenBalance),
    [token?.underlying, ethBalance, userUnderlyingTokenBalance]
  );

  const paymentStrategies = useMemo(
    () => [
      {
        id: PAYMENT_STRATEGY.FULL,
        label: t("Pay full"),
        icon: <MdPayment size={24} />,
      },
      {
        id: PAYMENT_STRATEGY.IN_FOUR,
        label: t("Pay in 4"),
        icon: <MdDateRange size={24} />,
      },
    ],
    [t]
  );

  const remainngCopies = useMemo(
    () =>
      Math.min(props.quoteOwner?.supply || Number.MAX_SAFE_INTEGER, +props.quote.make.value - (props.quote.fill || 0)),
    [props.quote.make.value, props.quote.fill]
  );

  const purchaseSchema = useMemo(
    () =>
      yup.object({
        quantity: yup
          .number()
          .required(t("Quantity is required"))
          .integer(t("Quantity must be integer"))
          .min(1, t("Quantity must be greater than 0"))
          .max(remainngCopies, `${t("Quantity must be less than or equal to")} ${remainngCopies}`),
      }),
    [remainngCopies]
  );

  const formik = useFormik({
    initialValues: {
      paymentStrategy: PAYMENT_STRATEGY.FULL,
      quantity: 1,
    },
    validationSchema: purchaseSchema,
    onSubmit: (values) => {
      if (!token) return;
      const total = pricePerCopy * values.quantity;
      const principal = Math.ceil(total / 4);
      const walletBalanceMantissa = (walletBalance || 0) * Math.pow(10, token.decimals);

      if (values.paymentStrategy === PAYMENT_STRATEGY.IN_FOUR && principal > walletBalanceMantissa) {
        setPurchaseState(PURCHASE_STATE.INSUFFICIENT_BALANCE);
      } else if (values.paymentStrategy === PAYMENT_STRATEGY.FULL && total > walletBalanceMantissa) {
        setPurchaseState(PURCHASE_STATE.INSUFFICIENT_BALANCE);
      } else {
        setPurchaseState(PURCHASE_STATE.PURCHASING);
      }
    },
  });

  const onPurchaseSuccess = async () => {
    setPurchaseState(PURCHASE_STATE.SUCCESS);
  };

  const price = useMemo(() => pricePerCopy * formik.values.quantity, [pricePerCopy, formik.values.quantity]);
  const paymentPeriods = useMemo(() => (!!price ? Math.ceil(+price / Math.ceil(+price / 4)) : 0), [price]);

  return (
    <>
      <Modal isOpen={props.isVisible && purchaseState === PURCHASE_STATE.CONFIRMING} onClose={props.onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("Complete Payment")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={formik.handleSubmit}>
              <Stack spacing={7}>
                <Flex justifyContent="center" alignItems="center">
                  <Box borderWidth="1px" borderRadius="lg" height="80px" width="80px">
                    <NftResource
                      image={props.nft.metadata?.image}
                      animationUrl={props.nft.metadata?.animation_url}
                      muted
                    />
                  </Box>

                  <Flex flex="1" marginLeft="16px" justifyContent="space-between" alignItems={"center"}>
                    <Box>
                      <Box fontSize={"small"}>{props.nft?.contractName}</Box>
                      <Text color={colors.primary2} fontWeight="700" fontSize="20px">
                        {props.nft?.metadata?.name}
                      </Text>
                    </Box>
                  </Flex>
                </Flex>

                <Divider />

                <Stack spacing={5}>
                  {props.nft.contractType === NFT_TYPE.ERC_1155 && (
                    <Stack>
                      <Title>{t("Number of copies")}</Title>
                      <Box>
                        <Input
                          type="number"
                          value={formik.values.quantity}
                          name="quantity"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {!!formik.errors.quantity && formik.touched.quantity && (
                          <Text color={"red.500"} fontSize={"small"} marginTop={1}>
                            {formik.errors.quantity}
                          </Text>
                        )}
                      </Box>
                    </Stack>
                  )}

                  <Flex justifyContent={"space-between"} alignItems="center">
                    <Box>
                      <Title>{t("Price")}</Title>
                      {props.nft.contractType === NFT_TYPE.ERC_1155 && (
                        <Description>{t("Price amount per each copy")}</Description>
                      )}
                    </Box>
                    <Flex marginTop="4px" alignItems="center">
                      <Image src={token?.logoUrl} height="20px" width="20px" marginRight="4px" />
                      <Text fontWeight="700" color={colors.primary}>
                        {pricePerCopy &&
                          token &&
                          formatNumber(new BigNumber(pricePerCopy).div(Math.pow(10, token.decimals)).toNumber())}
                      </Text>
                    </Flex>
                  </Flex>

                  <Flex justifyContent={"space-between"} alignItems="center">
                    <Title>{t("Total")}</Title>
                    <Flex marginTop="4px" alignItems="center">
                      <Image src={token?.logoUrl} height="20px" width="20px" marginRight="4px" />
                      <Text fontWeight="700" color={colors.primary}>
                        {pricePerCopy &&
                          token &&
                          formatNumber(
                            clamp(
                              new BigNumber(price).div(Math.pow(10, token.decimals)).toNumber(),
                              new BigNumber(price).div(Math.pow(10, token.decimals)).toNumber(),
                              new BigNumber(pricePerCopy)
                                .multipliedBy(formik.values.quantity || 0)
                                .div(Math.pow(10, token.decimals))
                                .toNumber()
                            )
                          )}
                      </Text>
                    </Flex>
                  </Flex>
                </Stack>

                <Divider />

                <Stack>
                  <Box flex={1}>
                    <Title>{t("Purchase options")}</Title>
                  </Box>

                  <HStack spacing={5}>
                    {paymentStrategies.map((p) => (
                      <Center
                        key={p.id}
                        cursor={"pointer"}
                        onClick={() => {
                          formik.setFieldValue("paymentStrategy", p.id);
                        }}
                        borderWidth={formik.values.paymentStrategy === p.id ? 2 : 1}
                        flex={1}
                        borderRadius={"lg"}
                        height={28}
                        borderColor={formik.values.paymentStrategy === p.id ? "blue.500" : "black.500"}
                      >
                        <Stack flexDir={"column"} alignItems={"center"}>
                          {p.icon}
                          <Text>{p.label}</Text>
                        </Stack>
                      </Center>
                    ))}
                  </HStack>
                </Stack>
                {formik.values.paymentStrategy === PAYMENT_STRATEGY.IN_FOUR && (
                  <Stack alignItems="center" spacing={4}>
                    {new Array(paymentPeriods).fill(null).map((_, period) => {
                      const principal = Math.ceil(new BigNumber(price).div(4).toNumber());
                      const periodPrice = token
                        ? Math.min(
                            principal,
                            new BigNumber(price).minus(new BigNumber(period).multipliedBy(principal)).toNumber()
                          )
                        : 0;

                      return (
                        <Fragment key={`period-${period}`}>
                          <Flex flex={1} alignItems={"center"} justifyContent={"space-between"} w="full">
                            <HStack>
                              <Text
                                fontSize={"md"}
                                fontWeight={500}
                                color={period === 0 ? "#000" : "#000"}
                                textAlign={"center"}
                              >
                                {period === 0
                                  ? t("Initial payment")
                                  : `${t("On")} ${format(addWeeks(new Date(), period * 2), "dd/MM/yyyy")}`}
                              </Text>
                            </HStack>
                            <Flex borderRadius="lg" alignItems={"center"} justifyContent={"center"}>
                              <Image src={token?.logoUrl} height="20px" width="20px" marginRight="4px" />
                              <Text
                                fontWeight={period === 0 ? 700 : 500}
                                color={period === 0 ? colors.primary : "#a6a6a6"}
                              >
                                {token &&
                                  formatNumber(
                                    new BigNumber(periodPrice).div(Math.pow(10, token?.decimals)).toNumber()
                                  )}
                              </Text>
                            </Flex>
                          </Flex>
                        </Fragment>
                      );
                    })}
                  </Stack>
                )}

                <Divider />

                <HStack justifyContent="flex-end">
                  <Button colorScheme="blue" alignSelf={"center"} type="submit" width={"120px"}>
                    {t("Pay")}
                  </Button>
                </HStack>
              </Stack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>

      {!!props.quote && props.nft && token && (
        <PurchaseProcessModal
          isVisible={props.isVisible && purchaseState === PURCHASE_STATE.PURCHASING}
          onClose={() => {
            setPurchaseState(PURCHASE_STATE.CONFIRMING);
          }}
          nft={props.nft}
          quote={props.quote}
          token={token}
          paymentStrategy={formik.values.paymentStrategy}
          onSuccess={onPurchaseSuccess}
          price={new BigNumber(pricePerCopy).multipliedBy(formik.values.quantity).toNumber()}
          quantity={formik.values.quantity}
        />
      )}

      {!!props.nft && (
        <PurchaseSuccessModal
          isVisible={props.isVisible && purchaseState === PURCHASE_STATE.SUCCESS}
          onClose={() => {
            setPurchaseState(PURCHASE_STATE.CONFIRMING);
            props.onPurchaseSuccess();
            props.onClose();
          }}
          nft={props.nft}
        />
      )}
      <CreditStatusWarningModal
        isVisible={props.isVisible && purchaseState === PURCHASE_STATE.NOT_VERIFIED}
        onClose={() => {
          setPurchaseState(PURCHASE_STATE.CONFIRMING);
        }}
      />
      <ErrorModal
        isVisible={props.isVisible && purchaseState === PURCHASE_STATE.INSUFFICIENT_BALANCE}
        onClose={() => {
          setPurchaseState(PURCHASE_STATE.CONFIRMING);
        }}
        message={t("Insufficient funds")}
      />
      <ErrorModal
        isVisible={props.isVisible && purchaseState === PURCHASE_STATE.LISTED_ERROR}
        onClose={() => {
          setPurchaseState(PURCHASE_STATE.CONFIRMING);
        }}
        message={t("Not found. This item is not listed!")}
      />
    </>
  );
});
