import logo from "../../assets/images/logo.png";
import usdc from "../../assets/images/usdc.svg";
import walletSolid from "../../assets/images/wallet-solid.png";
import uploadSolid from "../../assets/images/upload-solid.png";
import facebook from "../../assets/images/facebook.png";
import instagram from "../../assets/images/instagram.png";
import twitter from "../../assets/images/twitter.png";
import placeholder from "../../assets/images/placeholder.webp";
import metamask from "../../assets/images/metamask.webp";
import ethHighlight from "../../assets/images/eth-highlight.svg";
import grid from "../../assets/images/grid.png";
import vnFlag from "../../assets/images/vn.svg";
import auFlag from "../../assets/images/au.svg";
import avatarDefault from "../../assets/images/avatar-default.png";
import collectionLogoDefault from "../../assets/images/collection-logo-default.png";
import collectionLogo1 from "../../assets/images/collection-logo-1.png";
import collectionLogo2 from "../../assets/images/collection-logo-2.png";
import collectionCover1 from "../../assets/images/collection-cover-1.png";
import collectionCover2 from "../../assets/images/collection-cover-2.png";
import collectionCoverDefault from "../../assets/images/default-cover-1.png";

export const images = {
  logo,
  usdc,
  walletSolid,
  uploadSolid,
  facebook,
  instagram,
  twitter,
  placeholder,
  metamask,
  ethHighlight,
  grid,
  vnFlag,
  auFlag,
  avatarDefault,
  collectionCover1,
  collectionCover2,
  collectionCoverDefault,
  collectionLogo1,
  collectionLogo2,
  collectionLogoDefault,
};
