import axios from "../core/https";
import { IPagingResponse } from "../core/models/api";
import { ICollection } from "../core/models/collection";
import { INftV2, ISummaryNft } from "../core/models/nft";

const getDetail = (chain: string, id: string) =>
  axios.get<ICollection>(`/collections/${chain}/${id}`, {
    headers: {
      requireToken: "0",
    },
  });

const getOnSale = () =>
  axios.get<ICollection[]>(`/collections/on-sale`, {
    headers: {
      requireToken: "0",
    },
  });

const getCollectionsByAccount = (chain: string, id: string, params?: { contractType: string }) =>
  axios.get<ICollection[]>(`/collections/by-account/${chain}/${id}`, {
    headers: {
      requireToken: "0",
    },
    params,
  });

const create = (body: { contract: string; chain: string }) => axios.post<ICollection>(`/collections`, body);

const getMintedNfts = (chain: string, collection: string, params: { offset: number; limit: number }) =>
  axios.get<IPagingResponse<ISummaryNft[]>>(`/collections/${chain}/${collection}/minted`, {
    headers: {
      requireToken: "0",
    },
    params,
  });

const getLazyNfts = (chain: string, collection: string, params: { offset: number; limit: number }) =>
  axios.get<IPagingResponse<ISummaryNft[]>>(`/collections/${chain}/${collection}/lazy`, {
    headers: {
      requireToken: "0",
    },
    params,
  });

export const collectionService = {
  getDetail,
  getOnSale,
  getCollectionsByAccount,
  create,
  getMintedNfts,
  getLazyNfts,
};
