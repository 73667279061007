import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
import { IUser } from "../../../core/models/user";
import { fetchUserProfile } from "./user.thunk";

interface IUserState {
  authentication: {
    token?: string;
    exp?: number;
  };
  profile: IUser | null;
}

const initialState: IUserState = {
  authentication: {},
  profile: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAuthentication: (state, action: PayloadAction<{ token: string }>) => {
      const decoded: any = jwt_decode(action.payload.token);

      state.authentication.exp = decoded?.exp;
      state.authentication.token = action.payload.token;
    },
    setUser: (state, action: PayloadAction<IUser>) => {
      state.profile = action.payload;
    },
    logout: (state) => {
      state.profile = null;
      state.authentication = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
      state.profile = action.payload;
    });
  },
});

export const { setAuthentication, setUser, logout } = userSlice.actions;

export default userSlice.reducer;
