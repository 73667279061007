import { Contract } from "@ethersproject/contracts";
import { Web3Provider } from "@ethersproject/providers";
import { ethers } from "ethers";
import { EIP712 } from "../utils/EIP712";
import { getSalt } from "../utils/getSalt";
import { toPlainString } from "../utils/number";
import { encodeQuoteAsset } from "../utils/quote";
import { utils } from "ethers";

export class Quote {
  maker: string = ethers.constants.AddressZero;
  make: IQuoteAsset = { assetType: { assetClass: "", contract: "" }, value: "0" };
  taker?: string;
  take: IQuoteAsset = { assetType: { assetClass: "", contract: "" }, value: "0" };
  salt: string = getSalt();
  signature: string = "0x";
  data?: {
    isMakeFill: boolean;
    payouts: { account: string; value: number }[];
    originFees: string;
    dataType: string;
  };
  approverSignature?: string;
  fill?: number = 0;

  type?: string;
  id?: string;
  hashKey?: string;

  static async toOnChainQuote(quote: Quote, helperContract?: Contract | null): Promise<IOnChainQuote> {
    const makeAssetData = await encodeQuoteAsset(quote.make, helperContract);
    const takeAssetData = await encodeQuoteAsset(quote.take, helperContract);

    const abiCoder = new utils.AbiCoder();
    const dataEncode = quote.data
      ? abiCoder.encode(
          ["tuple(tuple(address account, uint256 value)[] payouts, uint256 originFees, bool isMakeFill)"],
          [
            {
              payouts: quote.data?.payouts,
              originFees: toPlainString(quote.data?.originFees),
              isMakeFill: quote.data?.isMakeFill,
            },
          ]
        )
      : "0x";

    return {
      maker: quote.maker,
      makeAsset: {
        assetType: {
          assetClass: id(quote.make.assetType.assetClass),
          data: makeAssetData,
        },
        value: toPlainString(quote.make?.value),
      },
      taker: quote.taker || ethers.constants.AddressZero,
      takeAsset: {
        assetType: {
          assetClass: id(quote.take.assetType.assetClass),
          data: takeAssetData,
        },
        value: toPlainString(quote.take?.value),
      },
      start: 0,
      end: 0,
      salt: quote.salt,
      dataType: quote.type ? id(quote.type) : "0xffffffff",
      data: dataEncode,
      makerSignature: quote.signature || "0x",
      approverSignature: quote.approverSignature || "0x",
    };
  }
}

export interface IOnChainQuote {
  maker: string;
  makeAsset: {
    assetType: {
      assetClass: string;
      data: string;
    };
    value: string;
  };
  taker: string;
  takeAsset: {
    assetType: {
      assetClass: string;
      data: string;
    };
    value: string;
  };
  start: number;
  end: number;
  dataType: string;
  data: string;
  salt: string;
  makerSignature: string;
  approverSignature: string;
}

export interface IQuoteAssetType {
  assetClass: string;
  contract: string;
  tokenId?: string;
  underlying?: string;
  creators?: { account: string; value: number }[];
  royalties?: { account: string; value: number }[];
  uri?: string;
  signatures?: string[];
  supply?: number;
}

export interface IQuoteAsset {
  assetType: IQuoteAssetType;
  value: string;
}

const Types = {
  AssetType: [
    { name: "assetClass", type: "bytes4" },
    { name: "data", type: "bytes" },
  ],
  Asset: [
    { name: "assetType", type: "AssetType" },
    { name: "value", type: "uint256" },
  ],
  Quote: [
    { name: "maker", type: "address" },
    { name: "makeAsset", type: "Asset" },
    { name: "taker", type: "address" },
    { name: "takeAsset", type: "Asset" },
    { name: "salt", type: "uint256" },
    { name: "start", type: "uint256" },
    { name: "end", type: "uint256" },
    { name: "dataType", type: "bytes4" },
    { name: "data", type: "bytes" },
  ],
};

function id(str: string) {
  return ethers.utils.id(str).substring(0, 10);
}

export async function signQuote(web3: Web3Provider, quote: IOnChainQuote, account: string, verifyingContract: string) {
  const chainId = Number(web3.network.chainId);
  const data = EIP712.createTypeData(
    {
      name: "BEPAY",
      version: "1",
      chainId,
      verifyingContract,
    },
    "Quote",
    quote,
    Types
  );
  const signedTypedData = await EIP712.signTypedData(web3, account, data);
  return signedTypedData?.sig;
}
