import { WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useEthers } from "@usedapp/core";
import { ethers } from "ethers";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProgressIcon } from "../../../../components/ProgressIcon";
import { colors } from "../../../../core/constants/colors";
import { I18N_NAMESPACE } from "../../../../core/constants/i18n";
import { APPROVE_STRATEGY, NFT_TYPE } from "../../../../core/constants/nft";
import { LAYER_1_TOKENS } from "../../../../core/constants/token";
import { useMarketplaceAddress, useNftProxyAddress } from "../../../../core/hooks/useAddress";
import { useERC721Contract, useHelperContract, useMarketplaceContract } from "../../../../core/hooks/useContract";
import { INftV2 } from "../../../../core/models/nft";
import { Quote, signQuote } from "../../../../core/models/quote";
import { IToken } from "../../../../core/models/token";
import { getSalt } from "../../../../core/utils/getSalt";
import { toPlainString } from "../../../../core/utils/number";
import { userService } from "../../../../services/user.service";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onSuccess: () => void;
  bid: Quote;
  token: IToken;
  nft: INftV2;
  price: number;
  quantity: number;
}

enum STEP {
  APPROVE_TOKEN,
  ACCEPT_BID,
  DONE,
}

export const AcceptBidProcessModal = React.memo((props: Props) => {
  const { account, library } = useEthers();
  const { t } = useTranslation([I18N_NAMESPACE.ASSET_DETAIL]);
  const [step, setStep] = useState(STEP.APPROVE_TOKEN);
  const marketplaceAddress = useMarketplaceAddress();
  const nftTransferProxyAddress = useNftProxyAddress();
  const marketplaceContract = useMarketplaceContract();

  const helperContract = useHelperContract();

  const collectionContract = useERC721Contract(props?.bid.take.assetType.contract);

  const [isAcceptingBid, setIsAcceptingBid] = useState(false);

  const [isApproving, setIsApproving] = useState(false);
  const [approveStrategy, setApproveStrategy] = useState(APPROVE_STRATEGY.ONLY_ITEM);

  const [errorByStep, setErrorByStep] = useState<{ [key: number]: string | null }>({});

  useEffect(() => {
    if (!props.isVisible) return;

    const executionByStep = {
      [STEP.APPROVE_TOKEN]: approveToken,
      [STEP.ACCEPT_BID]: acceptBid,
      [STEP.DONE]: props.onSuccess,
    };

    const execution = executionByStep[step];
    if (execution) {
      execution();
    }
  }, [step, props.isVisible]);

  const getIsApproved = async () => {
    const isApprovedItem =
      props.nft.contractType === NFT_TYPE.ERC_721 && props.nft.minted
        ? await collectionContract?.getApproved(props.nft.tokenId)
        : ethers.constants.AddressZero;

    const isApprovedForAll = await collectionContract?.isApprovedForAll(account, nftTransferProxyAddress);

    return isApprovedForAll || isApprovedItem === nftTransferProxyAddress;
  };

  const onApprove = async () => {
    try {
      setIsApproving(true);
      setErrorByStep((v) => ({ ...v, [STEP.APPROVE_TOKEN]: null }));

      await approve();

      setStep(STEP.ACCEPT_BID);
    } catch (e: any) {
      setErrorByStep((v) => ({ ...v, [STEP.APPROVE_TOKEN]: e?.data?.message || e?.message }));
    } finally {
      setIsApproving(false);
    }
  };

  const approve = useCallback(async () => {
    const tx = await (approveStrategy === APPROVE_STRATEGY.ALL ||
    props.nft.contractType === NFT_TYPE.ERC_1155 ||
    !props.nft.minted
      ? collectionContract?.setApprovalForAll(nftTransferProxyAddress, true)
      : collectionContract?.approve(nftTransferProxyAddress, props.nft.tokenId));

    await tx.wait();
  }, []);

  const approveToken = async () => {
    try {
      setIsApproving(true);
      setErrorByStep((v) => ({ ...v, [STEP.APPROVE_TOKEN]: null }));

      const isApproved = await getIsApproved();

      if (isApproved) {
        setStep(STEP.ACCEPT_BID);
        return;
      }

      if (!isApproved && (props.nft.contractType === NFT_TYPE.ERC_1155 || !props.nft.minted)) {
        await approve();
        setStep(STEP.ACCEPT_BID);
      }
    } catch (e: any) {
      setErrorByStep((v) => ({ ...v, [STEP.APPROVE_TOKEN]: e?.data?.message || e?.message }));
    } finally {
      setIsApproving(false);
    }
  };

  const acceptBid = async () => {
    try {
      if (!account || !library) return;

      setIsAcceptingBid(true);
      setErrorByStep((v) => ({ ...v, [STEP.ACCEPT_BID]: null }));

      const userProfileResponse = await userService.getProfileById(account);

      const quote: Quote = {
        taker: props.bid.maker.toLowerCase(),
        take: {
          value: toPlainString(props.price),
          assetType: props.bid.make.assetType,
        },
        maker: account.toLowerCase(),
        make: {
          value: toPlainString(props.quantity),
          assetType: {
            ...props.bid.take.assetType,
            assetClass: props.bid.take.assetType.assetClass,
          },
        },
        salt: getSalt(),
        type: "V1",
        data: {
          isMakeFill: true,
          originFees: toPlainString(userProfileResponse.data?.commissionFee || 0),
          payouts: [],
          dataType: "V1",
        },
        signature: "0x",
      };

      const onChainBuyerQuote = await Quote.toOnChainQuote(props.bid, helperContract);
      const onChainSellerQuote = await Quote.toOnChainQuote(quote, helperContract);

      const sellerSignature = await signQuote(library, onChainSellerQuote, account, marketplaceAddress);

      const tx = await marketplaceContract?.matchQuotes(
        { ...onChainSellerQuote, sellerSignature: sellerSignature },
        onChainBuyerQuote,
        {
          value: LAYER_1_TOKENS.includes(props.token.underlying) ? toPlainString(props.price) : 0,
        }
      );
      await tx.wait();

      setStep(STEP.DONE);
    } catch (e: any) {
      setErrorByStep((v) => ({ ...v, [STEP.ACCEPT_BID]: e?.data?.message || e?.message }));
    } finally {
      setIsAcceptingBid(false);
    }
  };

  return (
    <Modal isOpen={props.isVisible} onClose={props.onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("Accept Bid Process")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <HStack alignItems="flex-start" borderRadius="lg" marginTop="20px" background="#F6F6F6" padding="20px 16px">
              <ProgressIcon isLoading={isApproving} isSuccess={step > STEP.APPROVE_TOKEN} />
              <Box flex={1}>
                <Text fontWeight="700" color={colors.primary2}>
                  {t("Approve")}
                </Text>
                <Text fontSize="14px" color="#000" marginTop="8px">
                  {t("To get set up for selling for the first time, you must approve this item for sale.")}
                </Text>

                {!isApproving &&
                  props.nft.contractType !== NFT_TYPE.ERC_1155 &&
                  props.nft.minted &&
                  step === STEP.APPROVE_TOKEN && (
                    <>
                      <RadioGroup
                        onChange={setApproveStrategy}
                        value={approveStrategy}
                        marginTop={"16px"}
                        marginLeft="16px"
                        colorScheme="blue"
                      >
                        <Stack>
                          <Radio value={APPROVE_STRATEGY.ONLY_ITEM}>
                            <Text fontSize="14px" color={colors.primary2} fontWeight="500">
                              {t("Approve this NFT only.")}
                            </Text>
                          </Radio>
                          <Radio value={APPROVE_STRATEGY.ALL}>
                            <Flex>
                              <Text fontSize="14px" color={colors.primary2} fontWeight="500">
                                {t("Approve all of my NFTs in this collection.")}
                              </Text>
                              <Tooltip
                                hasArrow
                                label={t(
                                  "Choosing this option so you won't need to approve any NFT for sale from this collection (save gas fee)."
                                )}
                                borderRadius="lg"
                                padding="6px"
                                placement="top"
                              >
                                <WarningIcon w={4} h={4} color={colors.primary} marginLeft="6px" />
                              </Tooltip>
                            </Flex>
                          </Radio>
                        </Stack>
                      </RadioGroup>
                      {!errorByStep[STEP.APPROVE_TOKEN] && (
                        <Box marginTop="8px">
                          <Button colorScheme="blue" onClick={onApprove} isLoading={isApproving} marginTop={2}>
                            {t("Approve")}
                          </Button>
                        </Box>
                      )}
                    </>
                  )}

                {!!errorByStep[STEP.APPROVE_TOKEN] && (
                  <Box marginTop="8px">
                    <Text color={"red.500"} fontSize={"md"}>
                      {errorByStep[STEP.APPROVE_TOKEN]}
                    </Text>
                    <Button colorScheme="blue" onClick={onApprove} isLoading={isApproving} marginTop={2}>
                      {t("Try again")}
                    </Button>
                  </Box>
                )}
              </Box>
            </HStack>

            <HStack alignItems={"flex-start"} borderRadius="lg" background="#F6F6F6" padding="20px 16px">
              <ProgressIcon isLoading={step === STEP.ACCEPT_BID && isAcceptingBid} isSuccess={step > STEP.ACCEPT_BID} />
              <Box>
                <Text fontWeight="700" color={colors.primary2}>
                  {t("Accept")}
                </Text>
                <Text fontSize="14px" color="#000" marginTop="8px">
                  {t("Sign the message for accepting the bid")}.
                </Text>
                {errorByStep[STEP.ACCEPT_BID] ? (
                  <Box marginTop="20px">
                    <Text color={"red.500"} fontSize={"md"}>
                      {errorByStep[STEP.ACCEPT_BID]}
                    </Text>
                    <Button colorScheme="blue" onClick={acceptBid} marginTop={2}>
                      {t("Try again")}
                    </Button>
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </HStack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});
