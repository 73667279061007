import { AspectRatio, Avatar, Box, Center, Flex, HStack, Image, Link, Stack, Text } from "@chakra-ui/react";
import { shortenAddress } from "@usedapp/core";
import React, { MouseEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { I18N_NAMESPACE } from "../core/constants/i18n";
import { images } from "../core/constants/images";
import { ICollection } from "../core/models/collection";

interface Props {
  collection: ICollection;
  onClick?: (collection: ICollection) => void;
}
export const Collection = React.memo(({ collection, onClick }: Props) => {
  const { t } = useTranslation([I18N_NAMESPACE.COMMON_COMPONENT]);
  const navigate = useNavigate();

  const onClickCollection = useCallback(
    (e) => {
      e.stopPropagation();
      if (onClick) {
        onClick(collection);
      }
    },
    [collection]
  );

  const onClickCreator = useCallback(
    (e) => {
      e.stopPropagation();
      navigate(`/profile/assets/${collection.ownerInfo?.id}/minted`);
    },
    [collection?.ownerInfo?.id]
  );

  return (
    <Stack
      borderRadius={"xl"}
      borderWidth={1}
      spacing={4}
      overflow="hidden"
      onClick={onClickCollection}
      key={collection.contract}
      cursor={"pointer"}
      transition={"all 0.1s ease 0s"}
      _hover={{
        boxShadow: "lg",
        transform: "translateY(-4px)",
      }}
    >
      <Flex flexDir={"column"} alignItems={"center"} position="relative">
        <Box position={"relative"} width={"full"}>
          <Image
            src={collection.banner}
            fallbackSrc={images.collectionCoverDefault}
            objectFit={"cover"}
            height={"100px"}
            width={"full"}
          />
          <Box
            padding={"6px 16px"}
            borderRadius={"3xl"}
            backdropFilter={"blur(6px)"}
            backgroundColor={"rgba(255, 255, 255, 0.2)"}
            position="absolute"
            top={2}
            right={2}
          >
            <Text color={"#fff"} fontWeight={"500"}>
              {collection.symbol?.toUpperCase()}
            </Text>
          </Box>
        </Box>
        <Box
          borderRadius={"full"}
          padding={1}
          width={"70px"}
          height={"70px"}
          background={"#fff"}
          position="absolute"
          bottom={0}
        >
          <Image
            src={collection.image}
            fallbackSrc={images.collectionLogoDefault}
            width="full"
            height={"full"}
            borderRadius={"full"}
            objectFit={"cover"}
          />
        </Box>
        <Box height={"35px"} />
      </Flex>

      <Center flexDir={"column"}>
        <Text textAlign={"center"} fontSize={"md"} fontWeight={600}>
          {collection.name}
        </Text>
        <Flex alignItems={"center"} alignSelf={"center"}>
          <Text padding={"0px 8px"} fontSize={"small"} color={"gray.400"}>
            {t("by")}
          </Text>

          <Link onClick={onClickCreator}>
            <HStack>
              <Avatar size={"sm"} src={collection.ownerInfo?.image || ""} />
              <Text fontWeight={500} fontSize="sm">
                {collection.ownerInfo?.displayName || (collection.ownerInfo?.id && shortenAddress(collection.ownerInfo.id))}
              </Text>
            </HStack>
          </Link>
        </Flex>
      </Center>

      <Text
        textAlign={"center"}
        fontSize="sm"
        color={"gray.500"}
        textOverflow="ellipsis"
        overflow="hidden"
        style={{ display: "-webkit-box", lineClamp: 2, WebkitLineClamp: 2, WebkitBoxOrient: "vertical" }}
      >
        {collection.description}
      </Text>
      <br />
    </Stack>
  );
});
