import { Avatar, Box, Button, Flex, HStack, Link, Stack, Text } from "@chakra-ui/react";
import { shortenAddress, useEthers } from "@usedapp/core";
import BigNumber from "bignumber.js";
import { addMinutes, format } from "date-fns";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { I18N_NAMESPACE } from "../../../core/constants/i18n";
import { NFT_TYPE } from "../../../core/constants/nft";
import { INftQuote, INftV2 } from "../../../core/models/nft";
import { useAppSelector } from "../../../store/hook";

interface Props {
  bids: INftQuote[];
  isOwner: boolean;
  nft: INftV2 | undefined;
  onAcceptBid: (bid: INftQuote) => void;
}

export const BidInfo = React.memo((props: Props) => {
  const { account } = useEthers();
  const { t } = useTranslation([I18N_NAMESPACE.ASSET_DETAIL]);
  const navigate = useNavigate();
  const supportedTokens = useAppSelector((state) => state.token.supportedTokens);

  const onPressAddress = useCallback(
    (address: string) => () => {
      navigate(`/profile/assets/${address}/minted`);
    },
    []
  );

  return (
    <Stack spacing={5}>
      {props.bids.length > 0 ? (
        props.bids.map((bid) => {
          const token = supportedTokens.byId[bid.make.assetType.contract];
          const remainingQuantity = parseInt(bid?.take.value || "0") - (bid?.fill || 0);
          const pricePerCopy = new BigNumber(bid.make.value).div(bid.take.value).toNumber();
          return (
            <Flex alignItems={"center"} justifyContent="space-between" key={bid.hashKey}>
              <HStack>
                <Avatar size={"sm"} src={bid.makerInfo?.image || undefined} />
                <Box>
                  <Text fontSize={"sm"} fontWeight={400}>
                    <Text as={"span"} fontWeight={500}>
                      {new BigNumber(pricePerCopy)
                        .multipliedBy(remainingQuantity)
                        .div(Math.pow(10, token.decimals))
                        .toNumber()}{" "}
                      {token.symbol}{" "}
                    </Text>
                    <Text as={"span"} color={"gray.500"} fontWeight={400}>
                      {t("by")}
                    </Text>{" "}
                    <Text as="span" fontWeight={500}>
                      <Link onClick={onPressAddress(bid.maker)}>
                        {bid.makerInfo?.displayName || shortenAddress(bid.maker)}
                      </Link>
                    </Text>
                    {props.nft?.contractType === NFT_TYPE.ERC_1155 && (
                      <Text as={"span"} color={"gray.500"}>
                        {" "}
                        {t("for")} {t("copy", { count: remainingQuantity })}
                      </Text>
                    )}
                  </Text>
                  <Text fontSize={"xs"} fontWeight={300} color="gray.500" marginTop={1}>
                    {!!bid.createdAt &&
                      format(
                        addMinutes(new Date(bid.createdAt), new Date(bid.createdAt).getTimezoneOffset() * -1),
                        "dd/MM/yyyy HH:mm"
                      )}
                  </Text>
                </Box>
              </HStack>
              {props.isOwner && (
                <Button
                  colorScheme={"blue"}
                  size="sm"
                  onClick={() => props.onAcceptBid(bid)}
                  disabled={bid.maker.toLowerCase() === account?.toLowerCase()}
                >
                  {t("Accept")}
                </Button>
              )}
            </Flex>
          );
        })
      ) : (
        <Box>
          <Text color={"gray.500"} fontSize="sm">
            {t("No bid yet")}
          </Text>
        </Box>
      )}
    </Stack>
  );
});
