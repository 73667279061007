import axios from "../core/https";
import { INft } from "../core/models/nft";
import { Quote } from "../core/models/quote";

export interface ICreateQuoteRequest extends Quote {
  chain: string;
  signature: string;
}

export interface IGetApproverSignatureRequest extends Quote {
  make_asset_data: string;
  take_asset_data: string;
  data_encode: string;
}

const create = (body: ICreateQuoteRequest) => axios.post<Quote>("/quotes", body);
const delist = (id: string) => axios.get(`/marketplace/quotes/${id}/delist`);
const getApproverSignature = (body: IGetApproverSignatureRequest) =>
  axios.post<string>(`/marketplace/quotes/buy`, body);

export const quoteService = {
  create,
  delist,
  getApproverSignature,
};
