import { Text, Button, TextProps, useBreakpointValue, VStack } from '@chakra-ui/react'
import { MutableRefObject, useRef, useState } from 'react'
import { useIsOverflow } from '../core/hooks/useIsOverflow';


type BreakpointsValues = Partial<Record<string, number>> | number[];
interface ExpandableTextProps extends TextProps {
  visibleLines: number | BreakpointsValues;
  collapedButtonText: string;
  expandedButtonText: string;
}

export default function ExpandableText({ visibleLines, collapedButtonText, expandedButtonText, ...props }: ExpandableTextProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const textRef = useRef<HTMLParagraphElement>(null);
  const isOverflow = useIsOverflow(textRef as MutableRefObject<HTMLParagraphElement>);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  let noOfLines = useBreakpointValue(visibleLines as BreakpointsValues);
  if (noOfLines === undefined) {
    noOfLines = visibleLines as number;
  }

  const text = typeof props.children === "string"
    ? props.children as string : "";

  return (
    <VStack alignItems={"flex-start"}>
      <Text
        {...props}
        lineHeight="normal"
        whiteSpace="pre-wrap"
        textOverflow="clip"
        onClick={(isOverflow || isExpanded) ? handleExpand : undefined}
        noOfLines={isExpanded ? 0 : noOfLines}
        ref={textRef}
      >
        {props.children}
      </Text>
      {noOfLines > 0 && (isOverflow || isExpanded) && <Button variant='link'
        colorScheme={"blue"}
        fontWeight={props.fontWeight ?? "medium"}
        fontSize={props.fontSize}
        onClick={handleExpand}>
        {text && text.length > 0 &&
          (isExpanded ? expandedButtonText : collapedButtonText)
        }
      </Button>
      }
    </VStack>
  );
}