import { useContractCall } from "@usedapp/core";
import { useMarketplaceAddress, useTokenControllerAddress } from "./useAddress";
import ControllerAbi from "../../abis/TokenController.json";
import { Interface } from "@ethersproject/abi";
import { useMemo } from "react";

const CONTROLLER_INTERFACE = new Interface(ControllerAbi);

export const useSellerStatus = (account: string | null | undefined) => {
  const controllerAddress = useTokenControllerAddress();
  const [isRegistered] =
    useContractCall(
      controllerAddress &&
        account && {
          abi: CONTROLLER_INTERFACE,
          address: controllerAddress,
          method: "sellers",
          args: [account],
        }
    ) ?? [];

  return useMemo(() => {
    return isRegistered;
  }, [isRegistered]);
};

export const useCreditStatus = (address: string | null | undefined) => {
  const controllerAddress = useTokenControllerAddress();
  const [isClaimed] =
    useContractCall(
      controllerAddress &&
        address && {
          abi: CONTROLLER_INTERFACE,
          address: controllerAddress,
          method: "buyers",
          args: [address],
        }
    ) ?? [];

  return useMemo(() => {
    return isClaimed;
  }, [isClaimed]);
};
