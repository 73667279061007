import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { NftResource } from "../../../../components/NftResource";
import { I18N_NAMESPACE } from "../../../../core/constants/i18n";
import { IMoralisNft, INftV2 } from "../../../../core/models/nft";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  nft: INftV2;
}

export const PurchaseSuccessModal = React.memo((props: Props) => {
  const { t } = useTranslation([I18N_NAMESPACE.ASSET_DETAIL]);
  return (
    <Modal isOpen={props.isVisible} onClose={props.onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody padding={"30px"}>
          <Stack flexDirection={"column"} alignContent={"center"} justifyContent={"center"} spacing={6}>
            <Box height={"250px"} width={"250px"} alignSelf={"center"} borderWidth={1} borderRadius={"md"}>
              <NftResource image={props.nft?.metadata?.image} animationUrl={props.nft?.metadata?.animation_url} muted />
            </Box>
            <Box textAlign={"center"}>
              <Text>
                {t("You successfully purchased")} <b>{props.nft.metadata?.name}</b>
              </Text>
              <Text color={"yellow.400"} fontSize={"small"} marginTop={1}>
                {t("Note: System may take time to sync data. Please wait for a few minutes")}.
              </Text>
            </Box>

            <Button variant={"outline"} onClick={props.onClose}>
              {t("Continue")}
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});
