import { Interface } from "@ethersproject/abi";
import { useContractCall } from "@usedapp/core";
import { Falsy } from "@usedapp/core/dist/esm/src/model/types";
import { useMemo } from "react";
import RoyaltiesRegistry from "../../abis/RoyaltiesRegistry.json";
import { useRoyaltiesRegistryAddress } from "./useAddress";

const ROYALTIES_REGISTRY_INTERFACE = new Interface(RoyaltiesRegistry);

export const useRoyaltyInfo = (address: string | Falsy, tokenId: string | Falsy) => {
  const royaltiesRegistryAddress = useRoyaltiesRegistryAddress();
  const [royalties] =
    useContractCall(
      address &&
        tokenId &&
        royaltiesRegistryAddress && {
          abi: ROYALTIES_REGISTRY_INTERFACE,
          address: royaltiesRegistryAddress,
          method: "getRoyalties",
          args: [address, tokenId],
        }
    ) ?? [];

  return useMemo(() => {
    if (!royalties || royalties?.length === 0) return;
    const royalty = royalties[0];
    return { receiver: royalty["account"], royaltyPercent: royalty["value"]?.toNumber() / 100 };
  }, [royalties]);
};
