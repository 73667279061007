import { mapValues } from "lodash-es";
import { env } from "../environment";

export const MARKETPLACE_ADDRESS: { [chainId: number]: string } = mapValues(env.network, (i) => i.nftMarket.address);

export const TOKEN_CONTROLLER_ADDRESS: { [chainId: number]: string } = mapValues(
  env.network,
  (i) => i.controller.address
);

export const NFT_TRANSFER_PROXY_ADDRESS: { [chainId: number]: string } = mapValues(
  env.network,
  (i) => i.transferProxies.nft.address
);

export const ERC20_TRANSFER_PROXY_ADDRESS: { [chainId: number]: string } = mapValues(
  env.network,
  (i) => i.transferProxies.erc20.address
);

export const ERC721_LAZY_TRANSFER_PROXY_ADDRESS: { [chainId: number]: string } = mapValues(
  env.network,
  (i) => i.transferProxies.erc721_lazy.address
);

export const ERC1155_LAZY_TRANSFER_PROXY_ADDRESS: { [chainId: number]: string } = mapValues(
  env.network,
  (i) => i.transferProxies.erc1155_lazy.address
);

export const ERC1155_BEPAY_INSTANCE_ADDRESS: { [chainId: number]: string } = mapValues(
  env.network,
  (i) => i.nfts.erc1155.instances.erc1155.address
);

export const ROYALTIES_REGISTRY_ADDRESS: { [chainId: number]: string } = mapValues(
  env.network,
  (i) => i.royaltiesRegistry.address
);

export const ERC721_BEPAY_FACTORY_ADDRESS: { [chainId: number]: string } = mapValues(
  env.network,
  (i) => i.nfts.erc721.factory.address
);

export const ERC721_BEPAY_INSTANCE_ADDRESS: { [chainId: number]: string } = mapValues(
  env.network,
  (i) => i.nfts.erc721.instances.erc721.address
);

export const ERC1155_BEPAY_FACTORY_ADDRESS: { [chainId: number]: string } = mapValues(
  env.network,
  (i) => i.nfts.erc1155.factory.address
);

export const HELPER_CONTRACT_ADDRESS: { [chainId: number]: string } = mapValues(
  env.network,
  (i) => i.contractHelper.address
);
