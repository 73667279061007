import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Avatar, Box, Button, Center, Flex, HStack, Link, Spinner, Stack, Text, Tooltip } from "@chakra-ui/react";
import { shortenAddress, useEthers } from "@usedapp/core";
import BigNumber from "bignumber.js";
import { format } from "date-fns";
import { utils } from "ethers";
import { isEmpty } from "lodash-es";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { colors } from "../../../core/constants/colors";
import { I18N_NAMESPACE } from "../../../core/constants/i18n";
import { INftActivity } from "../../../core/models/nft";
import { getExplorerTxUrl } from "../../../core/utils/getExplorerUrl";
import { formatNumber } from "../../../core/utils/number";
import { useAppSelector } from "../../../store/hook";

interface Props {
  activities: INftActivity[];
  onLoadMore: () => void;
  isLoadingMore: boolean;
  isEndOfList: boolean;
  isLoading: boolean;
}

export const ActivitiesInfo = React.memo((props: Props) => {
  const supportedTokens = useAppSelector((state) => state.token.supportedTokens);
  const navigate = useNavigate();
  const { chainId } = useEthers();
  const { t } = useTranslation([I18N_NAMESPACE.ASSET_DETAIL]);

  const onPressAddress = useCallback(
    (address: string) => () => {
      navigate(`/profile/assets/${address}/minted`);
    },
    []
  );

  if (props.isLoading) {
    return (
      <Center marginTop={5}>
        <Spinner thickness="3px" speed="0.65s" emptyColor="gray.200" color={colors.primary} size="lg" />
      </Center>
    );
  }

  return (
    <Stack spacing={5}>
      {props.activities.length > 0 ? (
        props.activities.map((activity, index) => {
          if (activity.transactionType === "transfer") {
            const token = activity?.order?.currency ? supportedTokens.byId[activity.order.currency] : null;
            return (
              <>
                <HStack key={`activity-${index}`} spacing={3}>
                  <Avatar src={activity?.fromAccount?.image || ""} size={"sm"} />
                  <Box fontSize={"sm"} fontWeight={500} color="gray.500">
                    <Text fontWeight={500}>
                      {t("Tranferred to")}{" "}
                      <Text as="b" color="black">
                        <Link onClick={onPressAddress(activity.toAddress)}>
                          {activity.toAccount?.displayName || shortenAddress(activity.toAddress)}
                        </Link>
                      </Text>
                    </Text>
                    <Flex alignItems={"flex-end"}>
                      <Text fontSize={"xs"} fontWeight={300}>
                        {t("by")}{" "}
                        <Text as="span" color="black" fontWeight={400}>
                          <Link onClick={onPressAddress(activity.fromAddress)}>
                            {shortenAddress(activity.fromAddress)}
                          </Link>
                        </Text>{" "}
                        {format(new Date(activity.timestamp), "dd/MM/yyyy HH:mm")}
                      </Text>
                      {!!chainId && (
                        <Link
                          target={"_blank"}
                          href={getExplorerTxUrl(activity.transactionHash, chainId)}
                          marginLeft={2}
                        >
                          <Tooltip hasArrow label="View on explorer" borderRadius="lg" padding="6px" placement="top">
                            <ExternalLinkIcon fontSize={16} _hover={{ color: "#000" }} />
                          </Tooltip>
                        </Link>
                      )}
                    </Flex>
                  </Box>
                </HStack>
                {!isEmpty(activity.order) && (
                  <HStack key={`activity-purchase-${index}`} spacing={3}>
                    <Avatar src={activity?.toAccount?.image || ""} size={"sm"} />
                    <Box fontSize={"sm"} fontWeight={500} color="gray.500">
                      <Text fontWeight={500}>
                        {t("Purchased for")}{" "}
                        <Text as="b" color="black">
                          {token
                            ? formatNumber(
                                new BigNumber(activity?.order?.total || 0).div(Math.pow(10, token.decimals)).toNumber()
                              )
                            : 0}{" "}
                          {token?.symbol}
                        </Text>
                      </Text>
                      <Flex alignItems={"flex-end"}>
                        <Text fontSize={"xs"} fontWeight={300}>
                          {" "}
                          {t("by")}{" "}
                          <Text as="span" color="black" fontWeight={400}>
                            <Link onClick={onPressAddress(activity.toAddress)}>
                              {activity.toAccount?.displayName || shortenAddress(activity.toAddress)}
                            </Link>
                          </Text>{" "}
                          {activity.timestamp ? format(new Date(activity.timestamp), "dd/MM/yyyy HH:mm") : ""}{" "}
                        </Text>
                        {!!chainId && (
                          <Link
                            target={"_blank"}
                            href={getExplorerTxUrl(activity.transactionHash, chainId)}
                            marginLeft={2}
                          >
                            <Tooltip
                              hasArrow
                              label={t("View on explorer")}
                              borderRadius="lg"
                              padding="6px"
                              placement="top"
                            >
                              <ExternalLinkIcon fontSize={16} _hover={{ color: "#000" }} />
                            </Tooltip>
                          </Link>
                        )}
                      </Flex>
                    </Box>
                  </HStack>
                )}
              </>
            );
          }

          if (activity.transactionType === "mint") {
            return (
              <HStack key={`activity-${index}`} spacing={3}>
                <Avatar src={activity?.toAccount?.image || ""} size={"sm"} />
                <Box fontSize={"sm"} fontWeight={500} color="gray.500">
                  <Text fontWeight={500}>
                    {t("Minted by")}{" "}
                    <Text as="b" color="black">
                      <Link onClick={onPressAddress(activity.toAddress)}>
                        {activity.toAccount?.displayName || shortenAddress(activity.toAddress)}
                      </Link>
                    </Text>
                  </Text>
                  <Flex alignItems={"flex-end"}>
                    <Text fontSize={"xs"} fontWeight={300}>
                      {format(new Date(activity.timestamp), "dd/MM/yyyy HH:mm")}{" "}
                    </Text>
                    {!!chainId && (
                      <Link target={"_blank"} href={getExplorerTxUrl(activity.transactionHash, chainId)} marginLeft={2}>
                        <Tooltip hasArrow label={t("View on explorer")} borderRadius="lg" padding="6px" placement="top">
                          <ExternalLinkIcon fontSize={16} _hover={{ color: "#000" }} />
                        </Tooltip>
                      </Link>
                    )}
                  </Flex>
                </Box>
              </HStack>
            );
          }
        })
      ) : (
        <Box>
          <Text color={"gray.500"} fontSize="sm">
            {t("No item activity yet")}
          </Text>
        </Box>
      )}

      {!props.isEndOfList && (
        <Button colorScheme={"blue"} onClick={props.onLoadMore} isLoading={props.isLoadingMore}>
          {t("Load more")}
        </Button>
      )}
    </Stack>
  );
});
