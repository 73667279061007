import { Interface } from "@ethersproject/abi";
import { useContractCall, useEthers } from "@usedapp/core";
import { useMemo } from "react";
import TokenControllerAbi from "../../abis/TokenController.json";
import { useTokenControllerAddress } from "./useAddress";

const TOKEN_CONTROLLER_INTERFACE = new Interface(TokenControllerAbi);

export const useAccountCredit = (): number => {
  const tokenControllerAddress = useTokenControllerAddress();
  const { account } = useEthers();
  const buyer: any =
    useContractCall(
      tokenControllerAddress &&
        account && {
          abi: TOKEN_CONTROLLER_INTERFACE,
          address: tokenControllerAddress,
          method: "buyers",
          args: [account],
        }
    ) ?? [];

  return useMemo(() => {
    return +(buyer["value"]?.toString() || "0");
  }, [buyer]);
};
