import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEthers } from "@usedapp/core";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { NftResource } from "../../../../components/NftResource";
import { colors } from "../../../../core/constants/colors";
import { I18N_NAMESPACE } from "../../../../core/constants/i18n";
import { NFT_TYPE } from "../../../../core/constants/nft";
import { INftV2 } from "../../../../core/models/nft";
import { toPlainString } from "../../../../core/utils/number";
import { useAppSelector } from "../../../../store/hook";
import { BiddingProcessModal } from "./BiddingProcessModal";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onBiddingSuccess: () => void;
  nft: INftV2;
}

enum BIDDING_STATE {
  COMFIRMING,
  BIDDING,
}

export const PlaceBidFormModal = React.memo((props: Props) => {
  const { account } = useEthers();
  const { t } = useTranslation([I18N_NAMESPACE.ASSET_DETAIL]);
  const supportedTokens = useAppSelector((state) => state.token.supportedTokens);
  const [biddingState, setBiddingState] = useState<BIDDING_STATE>(BIDDING_STATE.COMFIRMING);

  const biddingSchema = yup.object({
    quantity: yup
      .number()
      .required(t("Quantity is required"))
      .integer(t("Quantity must be integer"))
      .min(1, t("Quantity must be greater than 0")),
    price: yup
      .number()
      .required(t("Price is required"))
      .min(0, t("Price must greater than 0"))
      .max(Number.MAX_SAFE_INTEGER, t("Price must be a safe number"))
      .test(
        "length",
        (_) => t("Price must be a safe number"),
        (currentValue) => {
          if (!currentValue) return true;
          return toPlainString(currentValue).replace(".", "").length < 17;
        }
      )
      .test({
        name: "decimal",
        params: { decimals: yup.ref("token.decimals") },
        message: t("Decimal places must be less than or equal to ${decimals} numbers"),
        test: (currentValue, context) => {
          if (!currentValue) return true;
          const [_, fraction] = toPlainString(currentValue)?.split(".");
          const { token } = context.parent;
          return !fraction || fraction.length <= token.decimals;
        },
      }),
  });

  const supportedBiddingTokenIds = useMemo(
    () =>
      supportedTokens.allIds.filter((id) => {
        const token = supportedTokens.byId[id];
        return !token.isNative;
      }),
    [supportedTokens.allIds, supportedTokens.byId]
  );

  const formik = useFormik({
    initialValues: {
      price: undefined,
      quantity: 1,
      token: supportedTokens.byId[supportedTokens.allIds[0]],
    },
    validationSchema: biddingSchema,
    onSubmit: async (values) => {
      if (!values.price) return;

      setBiddingState(BIDDING_STATE.BIDDING);
    },
  });

  const reset = () => {
    setBiddingState(BIDDING_STATE.COMFIRMING);
    formik.resetForm();
  };

  useEffect(() => {
    reset();
  }, [account]);

  const onBiddingSuccess = () => {
    reset();
    props.onBiddingSuccess();
  };

  return (
    <>
      <Modal isOpen={props.isVisible && biddingState === BIDDING_STATE.COMFIRMING} onClose={props.onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("Place a bid")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={formik.handleSubmit}>
              <Stack spacing={7}>
                <Flex justifyContent="center" alignItems="center">
                  <Box borderWidth="1px" borderRadius="lg" height="80px" width="80px">
                    <NftResource
                      image={props.nft.metadata?.image}
                      animationUrl={props.nft.metadata?.animation_url}
                      muted
                    />
                  </Box>

                  <Flex flex="1" marginLeft="16px" justifyContent="space-between" alignItems={"center"}>
                    <Box>
                      <Box fontSize={"small"}>{props.nft?.contractName}</Box>
                      <Text color={colors.primary2} fontWeight="700" fontSize="20px">
                        {props.nft?.metadata?.name}
                      </Text>
                    </Box>
                  </Flex>
                </Flex>

                <Divider />

                <Stack>
                  <Heading size="sm">{t("Bid amount")}</Heading>
                  <Box>
                    <Menu>
                      <InputGroup>
                        <InputLeftAddon width={"7.2rem"} as={MenuButton} type="button">
                          <HStack alignItems="center" spacing={1}>
                            <Image src={formik.values.token?.logoUrl} height="20px" width="20px" />
                            <Text color="#666666" fontWeight="700">
                              {formik.values.token?.symbol}
                            </Text>
                            <ChevronDownIcon color="#666666" />
                          </HStack>
                        </InputLeftAddon>
                        <Input
                          type="number"
                          value={formik.values.price === undefined ? "" : formik.values.price}
                          name="price"
                          onChange={formik.handleChange}
                          placeholder={t("Enter item price for one piece")}
                          fontWeight="700"
                          onBlur={formik.handleBlur}
                        />
                      </InputGroup>
                      {!!formik.errors.price && formik.touched.price && (
                        <Text color={"red.500"} fontSize={"small"} marginTop={1}>
                          {formik.errors.price}
                        </Text>
                      )}

                      <MenuList minWidth="240px">
                        <MenuOptionGroup
                          value={formik.values.token.underlying}
                          title="Tokens"
                          type="radio"
                          onChange={(value) => {
                            if (typeof value !== "string") return;
                            formik.setFieldValue("token", supportedTokens.byId[value]);
                          }}
                        >
                          {supportedBiddingTokenIds.map((id) => {
                            const supportedToken = supportedTokens.byId[id];
                            return (
                              <MenuItemOption value={supportedToken.underlying} key={id}>
                                <Flex alignItems="center">
                                  <Image src={supportedToken?.logoUrl} height="20px" width="20px" marginRight="8px" />
                                  <Text color="#666666" fontWeight="700">
                                    {supportedToken?.symbol}
                                  </Text>
                                </Flex>
                              </MenuItemOption>
                            );
                          })}
                        </MenuOptionGroup>
                      </MenuList>
                    </Menu>
                  </Box>
                </Stack>

                {props.nft?.contractType === NFT_TYPE.ERC_1155 && (
                  <Stack>
                    <Heading size="sm">{t("Number of copies")}</Heading>
                    <Box>
                      <Input
                        placeholder={t("E.g: 10")}
                        value={formik.values.quantity}
                        name="quantity"
                        type={"number"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />

                      {!!formik.errors.quantity && formik.touched.quantity && (
                        <Text color={"red.500"} fontSize={"small"} marginTop={1}>
                          {formik.errors.quantity}
                        </Text>
                      )}
                    </Box>
                  </Stack>
                )}

                <Divider />

                <Flex justifyContent="flex-end">
                  <Button colorScheme="blue" width="150px" type="submit">
                    {t("Place a bid")}
                  </Button>
                </Flex>
              </Stack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>

      {!!props.nft && (
        <BiddingProcessModal
          isVisible={props.isVisible && biddingState === BIDDING_STATE.BIDDING}
          onClose={() => {
            setBiddingState(BIDDING_STATE.COMFIRMING);
          }}
          nft={props.nft}
          price={formik.values.price || 0}
          token={formik.values.token}
          quantity={formik.values.quantity}
          onSuccess={onBiddingSuccess}
        />
      )}
    </>
  );
});
