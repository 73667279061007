import { AspectRatio, Box, Image, Center } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { images } from "../core/constants/images";
import { uriToHttps } from "../core/utils/ipfs";
import { ImageWithMultipleFallback } from "./ImageWithMultipleFallback";

interface Props {
  image: string | undefined;
  fallbackSources?: string[];
  animationUrl: string | undefined;
  showVideoControls?: boolean;
  muted?: boolean;
  maxW?: number | string | undefined;
  maxH?: number | string | undefined;
}

export const NftResource = React.memo((props: Props) => {
  const [extension, setExtension] = useState<string | null>();

  const type = useMemo(() => {
    if (props.animationUrl) return "video";
    if (props.image) return "image";
    return null;
  }, [props.animationUrl, props.image]);

  const url = useMemo(
    () => (props.animationUrl ? uriToHttps(props.animationUrl) : props.image ? uriToHttps(props.image) : undefined),
    [props.animationUrl, props.image]
  );

  const fallbackSources = useMemo(
    () => [...(props.fallbackSources || []), images.placeholder],
    [props.fallbackSources]
  );

  useEffect(() => {
    const url = props.image;
    if (!url) return;

    try {
      const parseUrl = new URL(url);
      setExtension(parseUrl.searchParams.get("ext"));
    } catch (e) {
      setExtension(null);
    }
  }, [props.image]);

  return (
    <Center>
      <AspectRatio ratio={1} w="100%" maxW={props.maxW ?? props.maxH} maxH={props.maxH} m="3">
        <Box>
          {type === "video" || url?.includes(".mp4") || (url && extension && extension.includes("mp4")) ? (
            <video
              width={"100%"}
              height={"100%"}
              autoPlay
              controls={props.showVideoControls}
              loop
              src={url}
              playsInline
              muted={props.muted}
            />
          ) : (
            <ImageWithMultipleFallback
              src={url}
              fallbackSources={fallbackSources}
              maxH="full"
              maxW={"full"}
              objectFit="contain"
              borderRadius={"lg"}
            />
          )}
        </Box>
      </AspectRatio>
    </Center>
  );
});
