import normalAxios from "axios";
import axios from "../core/https";
import { uriToHttps } from "../core/utils/ipfs";
import { INft, INftActivity, INftMetadata, INftOwner, INftSale, INftV2, ISummaryNft } from "../core/models/nft";
import { IUser } from "../core/models/user";
import { UserFavorite } from "../core/models/user-favorite";
import { Quote } from "../core/models/quote";
import { ICollection } from "../core/models/collection";
import { IPagingResponse } from "../core/models/api";

const getMetatdata = (uri: string) => {
  const httpsUri = uriToHttps(uri);
  if (!httpsUri) return null;

  return normalAxios.get<INftMetadata>(httpsUri);
};

const getNftDetail = (
  chain: string,
  contract: string,
  tokenId: string,
  config?: {
    params?: { account?: string | undefined | null };
    signal?: AbortSignal;
  }
) =>
  axios.get<INftV2>(`/nfts/${chain}/${contract}/${tokenId}`, {
    headers: {
      requireToken: "0",
    },
    ...config,
  });

const checkMinted = (chain: string, contract: string, tokenId: string) =>
  axios.get(`/nfts/${chain}/${contract}/${tokenId}/check-minted`, {
    headers: {
      requireToken: "0",
    },
  });

const getListedNftsByCollection = (params: {
  sort_field?: string;
  sort_direction?: string;
  page?: number;
  size?: number;
  collection: string;
}) =>
  axios.get<INft[]>(`/marketplace/nfts/by-collection`, {
    headers: {
      requireToken: "0",
    },
    params,
  });

const get = (params: {
  sortField?: string;
  sortDirection?: string;
  offset?: number;
  limit?: number;
  contract?: string;
}) =>
  axios.get<IPagingResponse<ISummaryNft[]>>(`/nfts`, {
    headers: {
      requireToken: "0",
    },
    params,
  });

const getMintedNtfsByAccount = (chain: string, account: string, params: { offset: number; limit: number }) =>
  axios.get<{ result: ISummaryNft[] }>(`/nfts/${chain}/${account}/minted`, {
    headers: {
      requireToken: "0",
    },
    params,
  });

const getLazyNtfsByAccount = (chain: string, account: string, params: { offset: number; limit: number }) =>
  axios.get<{ result: ISummaryNft[] }>(`/nfts/${chain}/${account}/lazy`, {
    headers: {
      requireToken: "0",
    },
    params,
  });

const getActivities = (
  chain: string,
  tokenAddress: string,
  tokenId: string,
  params?: { offset?: number; limit?: number; cursor?: string }
) =>
  axios.get<IPagingResponse<INftActivity[]>>(`/nfts/${chain}/${tokenAddress}/${tokenId}/transfers`, {
    headers: {
      requireToken: "0",
    },
    params,
  });

const getSales = (chain: string, tokenAddress: string, tokenId: string) =>
  axios.get<INftSale[]>(`/nfts/${chain}/${tokenAddress}/${tokenId}/sale-history`, {
    headers: {
      requireToken: "0",
    },
  });

const getOwners = (
  chain: string,
  contract: string,
  tokenId: string,
  config?: {
    signal?: AbortSignal;
    params?: { offset?: number; limit?: number; cursor?: string };
  }
) =>
  axios.get<IPagingResponse<INftOwner[]>>(`/nfts/${chain}/${contract}/${tokenId}/owners`, {
    headers: {
      requireToken: "0",
    },
    ...config,
  });

const favorite = (body: { contract: string; tokenId: string; chain: string; favorited: boolean }) =>
  axios.post<{ numberOfFavorites: number }>(`/nfts/favorite`, body);

const getFavoritedUsers = (chain: string, tokenAddress: string | undefined, tokenId: string | undefined) =>
  axios.get<UserFavorite[]>(`/nfts/${chain}/${tokenAddress}/${tokenId}/favorites`, {
    headers: {
      requireToken: "0",
    },
  });

const create = (body: INft) =>
  axios.post(`/nfts`, body, {
    // headers: {
    //   requireToken: "0",
    // },
  });

const view = (body: { chain: string; contract: string; tokenId: string }) =>
  axios.post(`/nfts/view`, body, {
    headers: {
      requireToken: "0",
    },
  });

export const nftService = {
  getMetatdata,
  getNftDetail,
  getListedNftsByCollection,
  getMintedNtfsByAccount,
  get,
  getSales,
  getOwners,
  getActivities,
  favorite,
  getFavoritedUsers,
  create,
  getLazyNtfsByAccount,
  view,
  checkMinted,
};
